import {
    Button,
    Col,
    Form,
    Input,
    Row,
    Select,
    Table,
    type TableProps,
} from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as ResetIcon } from 'assets/icons/resetIconOrange.svg';
import {
    getMappingJson,
    setExecutedSuccess,
    setMappingFields,
} from 'redux/actions/BusinessIntegratorActions/mappingActions';
import {
    ConnectionsState,
    MappingFormFields,
    MappingState,
} from 'types/enums/businessIntegratorEnum';
import { connectionDirection, EMPTY } from 'types/enums';
import { useTranslation } from 'react-i18next';
import { type DataType } from 'types/interfaces/PropsInterfaces';

const AddMappingStep2: React.FC<any> = ({
    form,
    setIsTestMapping,
    setSelectedRows,
    selectedRows,
}: any): any => {
    const { Option } = Select;
    const dispatch = useDispatch();

    const mappingState = useSelector(
        (state: any) => state.businessIntegrator.mapping.mappingState
    );
    const jsonData = useSelector(
        (state: any) => state.businessIntegrator.mapping.jsonData
    );
    const columnDetails = useSelector(
        (state: any) => state.businessIntegrator.mapping.columnDetails
    );
    const mappingFieldsData = useSelector(
        (state: any) => state.businessIntegrator.mapping.mappingFields
    );
    const mappingById = useSelector(
        (state: any) => state.businessIntegrator.mapping.mappingById
    );
    const braboTableList = useSelector(
        (state: any) => state.businessIntegrator.mapping.braboTableList
    );

    const isMappingExecuted = useSelector(
        (state: any) => state.businessIntegrator.mapping.isMappingExecuted
    );

    const { t } = useTranslation('translation');
    const connectionName = Form.useWatch('connectionName', form);
    const sourceUrl = Form.useWatch('sourceUrl', form);
    const braboTableName = Form.useWatch(
        MappingFormFields.braboTableName,
        form
    );

    const destinationFields = columnDetails?.columnList?.map((item: any) => ({
        id: item.id,
        name: `${item.name} (${item.description}) ${
            item.isPrimaryKey ? '(Primary Key)' : ''
        } ${item.isNotNull ? '(Mandatory Key)' : ''} (${item.dataType?.name})`,
    }));

    const compareTableId = (): any => {
        if (braboTableName === mappingById?.dataTableResponse?.id) return true;
        return false;
    };
    const connectionById = useSelector(
        (state: any) => state.businessIntegrator.connections.connectionById
    );

    useEffect(() => {
        if (
            mappingState === ConnectionsState.editConnection ||
            mappingState === ConnectionsState.viewConnections
        ) {
            if (mappingById?.columnToFieldMappingResponse) {
                const columnIds = mappingById.columnToFieldMappingResponse.map(
                    (item: any) => item?.columnId
                );
                setSelectedRows(columnIds);
            }
        }
    }, [mappingById?.columnToFieldMappingResponse]);
    const tableDataMapper = (): any[] => {
        const temp: any[] = [];
        if (
            mappingById?.columnToFieldMappingResponse &&
            mappingById?.columnToFieldMappingResponse.length > 0
        ) {
            if (
                connectionById?.direction?.name === connectionDirection.inbound
            ) {
                mappingById?.columnToFieldMappingResponse?.forEach(
                    (item: any, index: number) => {
                        const columnDetail = columnDetails?.columnList?.find(
                            (col: any) => col?.id === item?.columnId
                        );
                        temp.push({
                            ...item,
                            key: index + 1,
                            jsonField: item.fieldName,
                            description: item.description,
                            destinationField: {
                                id:
                                    mappingState === MappingState.editConnection
                                        ? (compareTableId() && item.columnId) ||
                                          ''
                                        : item.columnId || '',
                                name:
                                    compareTableId() && columnDetail
                                        ? `${columnDetail?.name} (${
                                              columnDetail?.description
                                          }) ${
                                              columnDetail?.isPrimaryKey
                                                  ? '(Primary Key)'
                                                  : ''
                                          } ${
                                              columnDetail?.isNotNull
                                                  ? '(Mandatory Key)'
                                                  : ''
                                          } (${columnDetail?.dataType?.name})`
                                        : '',
                            },
                        });
                    }
                );
            } else {
                mappingById?.columnToFieldMappingResponse?.forEach(
                    (item: any, index: any) => {
                        const columnDetail = columnDetails?.columnList?.find(
                            (col: any) => col?.id === item?.columnId
                        );

                        if (columnDetail) {
                            temp.push({
                                ...item,
                                key: index + 1,
                                jsonField: columnDetail?.name,
                                destinationField: item?.fieldName,
                                description: item?.description,
                                columnId: item?.columnId,
                            });
                        }
                    }
                );
                columnDetails?.columnList?.forEach((col: any, index: any) => {
                    const alreadyMapped =
                        mappingById?.columnToFieldMappingResponse?.some(
                            (item: any) => item?.columnId === col?.id
                        );

                    if (!alreadyMapped) {
                        temp.push({
                            key: temp.length + 1,
                            jsonField: col?.name,
                            destinationField: null, // No mapping found, so this is null
                            description: col?.description,
                            columnId: col?.id,
                        });
                    }
                });
            }
        } else {
            if (
                connectionById?.direction?.name === connectionDirection.outbound
            ) {
                columnDetails?.columnList?.forEach((item: any, index: any) => {
                    temp.push({
                        ...item,
                        key: index + 1,
                        jsonField: item?.name,
                        columnId: item?.id,
                        description: item?.description,
                        destinationField: '',
                    });
                });
            } else {
                jsonData?.forEach((item: any, index: number) => {
                    temp.push({
                        ...item,
                        key: index + 1,
                        jsonField: item.jsonField,
                        description: item.description,
                        destinationField: {
                            id: '',
                            name: '',
                        },
                    });
                });
            }
        }
        return temp;
    };

    const [data, setData] = useState<any[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<any>({});
    const [isTestMappingBtnDisabled, setIsTestMappingBtnDisabled] =
        useState(true);

    useEffect(() => {
        setData(tableDataMapper());
        const selectedArr: any = {};
        mappingById?.columnToFieldMappingResponse?.map(
            (item: any, index: any) => {
                selectedArr[index + 1] = item.columnId;
            }
        );
        setSelectedOptions(selectedArr);
    }, [
        jsonData,
        mappingById?.columnToFieldMappingResponse,
        columnDetails?.columnList,
    ]);

    const handleFieldChange = (value: any, option: any, key: any): any => {
        const newData = data.map((item: any): any => {
            if (item.key === key) {
                return {
                    ...item,
                    destinationField: { id: value, name: option.children },
                };
            }
            return item;
        });
        setData(newData);
        const newSelectedOptions = { ...selectedOptions, [key]: value };
        setSelectedOptions(newSelectedOptions);
    };

    const columns = [
        {
            title: t('BusinessIntegrator.mapping.mappingForm.fieldList'),
            dataIndex: 'jsonField',
            key: 'jsonField',
        },
        {
            title: t('BusinessIntegrator.mapping.tableColumn.description'),
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: t(
                'BusinessIntegrator.mapping.mappingForm.destinationFieldCapturingData'
            ),
            dataIndex: 'destinationField',
            key: 'destinationField',
            render: (_: any, record: any) => {
                return connectionById?.direction?.name ===
                    connectionDirection.inbound ? (
                    <Select
                        value={
                            record.destinationField.id
                                ? record.destinationField.id
                                : null
                        }
                        onChange={(value, option) =>
                            handleFieldChange(value, option, record.key)
                        }
                        style={{ width: '100%' }}
                        placeholder={
                            mappingState !== MappingState.viewConnections &&
                            t(
                                'BusinessIntegrator.mapping.placeHolder.destinationFieldCapturingData'
                            )
                        }
                    >
                        {destinationFields
                            ?.filter(
                                (item: any) =>
                                    !Object.values(selectedOptions).includes(
                                        item.id
                                    ) || selectedOptions[record.key] === item.id
                            )
                            .map((item: any) => (
                                <Option key={item.id} value={item.id}>
                                    {item.name}
                                </Option>
                            ))}
                    </Select>
                ) : (
                    <Input
                        type="text"
                        style={{ width: '100%' }}
                        onChange={(e) => handleInputChange(e, record.key)}
                        defaultValue={record?.destinationField}
                    />
                );
            },
        },
    ];
    const [isDisabled, setIsDisabled] = useState(false);
    useEffect(() => {
        if (connectionById?.direction?.name === connectionDirection.outbound) {
            setIsDisabled(true);
        }
    }, [connectionById?.direction?.name]);
    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        key: number
    ): any => {
        const newValue = e.target.value;

        const updatedData = data?.map((item) => {
            if (item?.key === key) {
                return {
                    ...item,
                    destinationField: newValue,
                };
            }
            return item;
        });

        setData(updatedData);
    };

    const compareDestinationFieldIds = (
        sourceArr: any,
        destinationArr: any
    ): boolean => {
        if (sourceArr?.length !== destinationArr?.length) return false;
        for (let i = 0; i < sourceArr?.length; i++) {
            if (
                sourceArr[i]?.destinationField.id !==
                destinationArr[i]?.destinationField.id
            ) {
                return false;
            }
        }
        return true;
    };

    useEffect(() => {
        if (mappingFieldsData?.length > 0) {
            const fieldsMatch = compareDestinationFieldIds(
                mappingFieldsData,
                data
            );
            if (!fieldsMatch) {
                dispatch(setExecutedSuccess(fieldsMatch));
            } else {
                dispatch(setExecutedSuccess(isMappingExecuted));
            }
        }
    }, [mappingFieldsData, data]);

    const onResethandler = (): any => {
        dispatch(
            getMappingJson({
                connectionId: connectionName,
                sourceUrl: sourceUrl,
            })
        );
    };

    useEffect(() => {
        const allFieldsMapped = data?.some(
            (item: any) => item?.destinationField.id !== EMPTY.string
        );
        setIsTestMappingBtnDisabled(!allFieldsMapped);
    }, [data]);

    useEffect(() => {
        if (
            mappingFieldsData &&
            connectionById?.direction?.name === connectionDirection.inbound
        ) {
            setData(mappingFieldsData);
            const selectedArr: any = {};
            mappingFieldsData?.map((item: any) => {
                selectedArr[item?.key] = item?.destinationField?.id;
            });
            setSelectedOptions(selectedArr);
        }
    }, [mappingFieldsData]);

    useEffect(() => {
        form.setFieldsValue({ mappingFields: data });
    }, [data, form, mappingFieldsData, columnDetails?.columnList]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);

    useEffect(() => {
        if (selectedRows) {
            setSelectedRowKeys(selectedRows);
        }
    }, [selectedRows]);


    const rowSelection: TableProps<DataType>['rowSelection'] = {
        selectedRowKeys, 
        onChange: (
            selectedKeys: React.Key[],
            selectedRowsArray: DataType[]
        ) => {
            const selectedColumnIds = selectedRowsArray.map(
                (row: any) => row.columnId
            );

            setSelectedRowKeys(selectedKeys as number[]); 
            setSelectedRows(selectedColumnIds); 
        },
        getCheckboxProps: (record: DataType) => ({
            name: record?.name, 
        }),
    };
    return (
        <div>
            <Row>
                <Col span={18}>
                    <Form.Item
                        name="mappingName"
                        className="highcharts-credits"
                    ></Form.Item>
                    <Form.Item
                        name="description"
                        className="highcharts-credits"
                    ></Form.Item>
                    <Form.Item
                        name="label"
                        className="highcharts-credits"
                    ></Form.Item>
                    {mappingState !== MappingState.viewConnections && (
                        <Form.Item
                            label={
                                <div className="fw-400 fs-14">
                                    {t(
                                        'BusinessIntegrator.mapping.mappingForm.braboTableName'
                                    )}
                                </div>
                            }
                            name="braboTableName"
                            className="mappingStep braboTableName"
                        >
                            <Select
                                popupClassName="adapter"
                                placeholder={t(
                                    'BusinessIntegrator.mapping.placeHolder.braboTableName'
                                )}
                                disabled
                            >
                                {braboTableList?.paginatedResponse?.records?.map(
                                    (item: any) => {
                                        return (
                                            <Option
                                                key={item?.id}
                                                value={item?.id}
                                                className={'braboTableOption'}
                                            >
                                                {item?.name}
                                                {/* <span>{item?.description}</span> */}
                                            </Option>
                                        );
                                    }
                                )}
                            </Select>
                        </Form.Item>
                    )}
                </Col>
                <Col span={6} className="testMapping-btn">
                    <Form.Item
                        name="connectionName"
                        className="highcharts-credits"
                    ></Form.Item>
                    <Form.Item
                        name="sourceUrl"
                        className="highcharts-credits"
                    ></Form.Item>
                    {mappingState !== MappingState.viewConnections && (
                        <>
                            <Button
                                type="primary"
                                ghost
                                className="addNewButton"
                                disabled={
                                    isTestMappingBtnDisabled ||
                                    connectionById?.direction?.name ===
                                        connectionDirection.outbound
                                }
                                onClick={() => {
                                    setIsTestMapping(true);
                                    dispatch(setMappingFields(data));
                                }}
                            >
                                {t(
                                    'BusinessIntegrator.mapping.mappingDetails.testMapping'
                                )}
                            </Button>
                            <span
                                className="customHeader__child-resetButton"
                                onClick={() => {
                                    if (!isDisabled) {
                                        onResethandler();
                                    }
                                }}
                                style={
                                    isDisabled
                                        ? {
                                              pointerEvents: 'none',
                                              opacity: 0.5,
                                          }
                                        : {}
                                }
                            >
                                <ResetIcon />
                            </span>
                        </>
                    )}
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <div className="check">
                        <Form.Item name="mappingFields">
                            <Table
                                rowKey="columnId"
                                rowSelection={
                                    connectionById?.direction?.name ===
                                    connectionDirection.outbound
                                        ? {
                                              type: 'checkbox',
                                              ...rowSelection,
                                          }
                                        : undefined
                                }
                                dataSource={
                                    mappingState ===
                                    MappingState.viewConnections
                                        ? tableDataMapper()
                                        : data
                                }
                                columns={columns}
                                pagination={false}
                                className="mappingFieldsTable"
                            />
                        </Form.Item>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default AddMappingStep2;
