export enum ChartTypes {
    LINE = 'line',
    BAR = 'bar',
    AREA = 'area',
    SCATTER = 'scatter',
    HISTOGRAM = 'histogram',
    BOXPLOT = 'boxplot',
}

export enum statisticalFunctions {
    MODE = 'Mode',
    MOVINGAVERAGE = 'MOVINGAVERAGE',
    STANDARDDEVIATION='Standard Deviation',
    TAG = 'tag',
    STD ='STD',
    DEVIATION='DEVIATION',
    MOVING_AVERAGE ='MOVING AVERAGE'
}