import React, { useEffect, useState } from 'react';
import { Col, Input, Row, List, Checkbox, Card, Radio } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
    getResourceType,
    getResourcetypeDetails,
    getAllNodesDetails,
    setCheckedItemsForScreen,
    setResourceType,
    setCheckedItemsForKpi,
    setCheckedItemsForAsset,
} from 'redux/actions/UserManagementActions/rolesAction';
import { EMPTY, ROLETYPE, resourceTypeID } from 'types/enums';
import SideDrawer from 'components/common/SideDrawer';
import GroupDrawer from 'components/common/SideDrawer/GroupDrawer';
import { useTranslation } from 'react-i18next';
import CustomButton from 'components/common/CustomButton';
import Screen from './Screen';
import EmptyDataComponent from 'components/common/EmptyDataComponent';
import Asset from './Asset';
import { EMPTY_TREE_OBJECT } from 'redux/types/assetModellingTypes';
import {
    CLOSE_GROUP_DRAWER,
    EMPTY_ALL_NODE_DETAILS,
} from 'redux/types/userManagementTypes';
import Kpi from './KPI';
import { getAllKpisDetails } from 'redux/actions/ConfigureActions/kpiActions';
import { CLEAR_ALL_KPIS_DETAILS } from 'redux/types/configureTypes';

const CreateRoleStep1: React.FC<any> = ({
    rolesPermissionListState,
    setRolesPermissionListState,
    resourcetypeList,
    editRoleState,
}) => {
    const dispatch = useDispatch();
    const checkedScreens = useSelector(
        (state: any) => state?.userManagement?.roles?.checkedItemsScreen
    );

    const roleDetails = useSelector(
        (state: any) => state.userManagement.roles.roleDetails
    );
    const checkedKpis = useSelector(
        (state: any) => state?.userManagement?.roles?.checkedItemsKpi
    );
    const checkedAssets = useSelector(
        (state: any) => state?.userManagement?.roles?.checkedItemsAsset
    );

    const [searchScreen, setSearchScreen] = useState<string | null>(null);
    const [searchAsset, setSearchAsset] = useState<string | null>(null);
    const [searchKpi, setSearchKpi] = useState<string | null>(null);

    const { t } = useTranslation('translation');
    const [openSideDrawer, setOpenSideDrawer] = useState(false);
    const [keyProp, setKeyProp] = useState(0);

    useEffect(() => {
        setCheckedItemsScreen(checkedScreens);
    }, [checkedScreens]);

    useEffect(() => {
        setCheckedItemsKpi(checkedKpis);
    }, [checkedKpis]);
    useEffect(() => {
        setCheckedItemsAsset(checkedAssets);
    }, [checkedAssets]);

    const resourceTypeDetails = useSelector(
        (state: any) => state?.userManagement?.roles?.resourceTypeDetails
    );
    const resourceType = useSelector(
        (state: any) => state?.userManagement?.roles?.selectedResourceType
    );
    useEffect(() => {
        resourceType && setSelectedResourceTypeId(resourceType);
    }, [resourceType]);

    const createGroup = useSelector(
        (state: any) => state?.userManagement?.groups?.isCreatedResponse
    );

    useEffect(() => {
        dispatch(getResourceType());
    }, [createGroup]);

    const [selectedResourceTypeId, setSelectedResourceTypeId] = useState<any>(
        editRoleState === ROLETYPE?.create
            ? null
            : editRoleState === ROLETYPE?.viewRole
            ? resourceTypeID?.SCREEN
            : resourceTypeID?.SCREEN
    );
    const [searchTimeout, setSearchTimeout] = useState<any>(undefined);
    const handleRadioChange = (e: any): any => {
        const selectedResourceType = resourcetypeList?.find(
            (type: any) =>
                type?.resourceTypeName?.toLowerCase() === e.target.value
        );
        if (selectedResourceType) {
            setSelectedResourceTypeId(selectedResourceType?.id);
            dispatch(setResourceType(selectedResourceType?.id));
        }
    };

    useEffect(() => {
        const screenGroupNames: string[] = [];
        const kpiGroupData: any[] = [];
        const assetGroupData: any[] = [];
        if (
            editRoleState === ROLETYPE?.viewRole ||
            editRoleState === ROLETYPE?.edit
        ) {
            roleDetails?.resourceGroupDtoList?.forEach((group: any) => {
                group?.resourcePermissionList?.forEach(
                    (permissionItem: any) => {
                        const resourceTypeId =
                            permissionItem?.resource?.resourceType?.id;

                        if (resourceTypeId === resourceTypeID?.SCREEN) {
                            const matchingGroup = resourceTypeDetails?.find(
                                (resourceTypeDetail: any) =>
                                    resourceTypeDetail?.id === group?.id
                            );

                            if (matchingGroup) {
                                screenGroupNames.push(matchingGroup.groupName);
                            }
                        }
                        if (resourceTypeId === resourceTypeID?.KPI) {
                            const matchingGroup = resourceTypeDetails?.find(
                                (resourceTypeDetail: any) =>
                                    resourceTypeDetail?.id === group?.id
                            );

                            if (matchingGroup) {
                                const existingGroup = kpiGroupData.find(
                                    (kpiGroup: any) =>
                                        kpiGroup.groupName ===
                                        matchingGroup.groupName
                                );

                                if (existingGroup) {
                                    const resourceSet = new Set(
                                        existingGroup.resourceList.map(
                                            (r: any) => r.resourceId
                                        )
                                    );
                                    matchingGroup?.resourceList?.forEach(
                                        (resource: any) => {
                                            if (
                                                !resourceSet.has(
                                                    resource.resourceId
                                                )
                                            ) {
                                                existingGroup.resourceList.push(
                                                    {
                                                        resourceId:
                                                            resource.resourceId,
                                                    }
                                                );
                                                resourceSet.add(
                                                    resource.resourceId
                                                );
                                            }
                                        }
                                    );
                                } else {
                                    const uniqueResources = Array.from(
                                        new Set(
                                            matchingGroup.resourceList.map(
                                                (r: any) => r.resourceId
                                            )
                                        )
                                    ).map((resourceId: any) => {
                                        const resource =
                                            matchingGroup.resourceList.find(
                                                (r: any) =>
                                                    r.resourceId === resourceId
                                            );
                                        return {
                                            resourceId: resource?.resourceId,
                                        };
                                    });

                                    kpiGroupData.push({
                                        groupName: matchingGroup.groupName,
                                        resourceList: uniqueResources,
                                    });
                                }
                            }
                        }

                        if (resourceTypeId === resourceTypeID?.ASSET) {
                            const matchingGroup = resourceTypeDetails?.find(
                                (resourceTypeDetail: any) =>
                                    resourceTypeDetail?.id === group?.id
                            );

                            if (matchingGroup) {
                                const existingGroup = assetGroupData.find(
                                    (assetGroup: any) =>
                                        assetGroup?.groupName ===
                                        matchingGroup?.groupName
                                );

                                if (existingGroup) {
                                    const resourceSet = new Set(
                                        existingGroup.resourceList?.map(
                                            (r: any) => r?.resourceId
                                        )
                                    );

                                    matchingGroup?.resourceList?.forEach(
                                        (resource: any) => {
                                            if (
                                                !resourceSet.has(
                                                    resource?.resourceId
                                                )
                                            ) {
                                                existingGroup?.resourceList.push(
                                                    {
                                                        resourceId:
                                                            resource?.resourceId,
                                                    }
                                                );
                                                resourceSet.add(
                                                    resource?.resourceId
                                                );
                                            }
                                        }
                                    );
                                } else {
                                    const uniqueResources = Array.from(
                                        new Set(
                                            matchingGroup.resourceList?.map(
                                                (r: any) => r?.resourceId
                                            )
                                        )
                                    ).map((resourceId: any) => {
                                        const resource =
                                            matchingGroup?.resourceList.find(
                                                (r: any) =>
                                                    r?.resourceId === resourceId
                                            );
                                        return {
                                            resourceId: resource?.resourceId,
                                        };
                                    });

                                    assetGroupData?.push({
                                        groupName: matchingGroup?.groupName,
                                        resourceList: uniqueResources,
                                    });
                                }
                            }
                        }
                    }
                );
            });

            setCheckedItemsScreen(screenGroupNames);
            dispatch(setCheckedItemsForScreen(screenGroupNames));
            setCheckedItemsKpi(kpiGroupData);
            dispatch(setCheckedItemsForKpi(kpiGroupData));
            setCheckedItemsAsset(assetGroupData);
            dispatch(setCheckedItemsForAsset(assetGroupData));
        }
    }, [roleDetails, resourceTypeDetails]);
    useEffect(() => {
        const resourceMap = new Map();

        roleDetails?.resourceGroupDtoList?.forEach((group: any) => {
            group?.resourcePermissionList?.forEach((permissionItem: any) => {
                const resourceId = permissionItem?.resource?.resourceId;

                if (resourceId) {
                    if (!resourceMap.has(resourceId)) {
                        resourceMap.set(resourceId, {
                            resource: {
                                resourceId: resourceId,
                                resourceName:
                                    permissionItem?.resource?.resourceName,
                                resourceType: {
                                    id: permissionItem?.resource?.resourceType
                                        ?.id,
                                    resourceTypeName:
                                        permissionItem?.resource?.resourceType
                                            ?.resourceTypeName,
                                },
                            },
                            permission: permissionItem?.permission || [],
                        });
                    }
                }
            });
        });

        const transformedRolesList = Array?.from(resourceMap.values());
        setRolesList(transformedRolesList);
        const rolesPermissionListState = roleDetails?.resourceGroupDtoList?.map(
            (group: any) => {
                return {
                    id: group?.id,
                    resourcePermissionList: group?.resourcePermissionList?.map(
                        (permissionItem: any) => {
                            return {
                                resource: {
                                    resourceId:
                                        permissionItem?.resource?.resourceId,
                                    resourceName:
                                        permissionItem?.resource?.resourceName,
                                    resourceType: {
                                        id: permissionItem?.resource
                                            ?.resourceType?.id,
                                        resourceTypeName:
                                            permissionItem?.resource
                                                ?.resourceType
                                                ?.resourceTypeName,
                                    },
                                },
                                permission: permissionItem?.permission || [],
                            };
                        }
                    ),
                };
            }
        );

        setRolesPermissionListState(rolesPermissionListState ?? []);
    }, [roleDetails]);

    useEffect(() => {
        selectedResourceTypeId &&
            dispatch(
                getResourcetypeDetails({
                    selectedResourceTypeId: selectedResourceTypeId,
                    search:
                        selectedResourceTypeId === resourceTypeID?.SCREEN
                            ? searchScreen
                            : selectedResourceTypeId === resourceTypeID?.ASSET
                            ? searchAsset
                            : selectedResourceTypeId === resourceTypeID?.KPI
                            ? searchKpi
                            : null,
                })
            );
    }, [selectedResourceTypeId, searchScreen, searchAsset, searchKpi]);

    const handleSelectAllChange = (e: any): any => {
        const checked = e.target.checked;

        const createTransformedData = (item: any): any => ({
            id: item?.id,
            resourcePermissionList: item?.resourceList?.map(
                (resource: any) => ({
                    resource: {
                        resourceId: resource?.resourceId,
                        resourceName: resource?.resourceName,
                        resourceType: {
                            id: item?.resourceType?.id,
                            resourceTypeName:
                                item?.resourceType?.resourceTypeName,
                        },
                    },
                    permission: [],
                })
            ),
        });
        const createTransformedDataKpi = (item: any): any => ({
            id: item?.id,
            resourcePermissionList: item?.resourceList?.map(
                (resource: any) => ({
                    resource: {
                        resourceId: resource?.resourceId,
                        resourceName: resource?.resourceName,
                        resourceType: {
                            id: item?.resourceType?.id,
                            resourceTypeName:
                                item?.resourceType?.resourceTypeName,
                        },
                    },
                    permission: ['READ'],
                })
            ),
        });

        if (selectedResourceTypeId === resourceTypeID?.SCREEN) {
            if (checked) {
                const newCheckedItems = resourceTypeDetails?.map(
                    (item: any) => item?.groupName
                );
                dispatch(setCheckedItemsForScreen(newCheckedItems));
                const transformedDataList = resourceTypeDetails?.map(
                    createTransformedData
                );

                setRolesPermissionListState((prevState: any) => {
                    const newEntries = transformedDataList?.filter(
                        (newEntry: any) =>
                            !prevState.some(
                                (prevEntry: any) =>
                                    prevEntry?.id === newEntry?.id
                            )
                    );
                    return [...prevState, ...newEntries];
                });
            } else {
                dispatch(setCheckedItemsForScreen([]));
                setRolesPermissionListState((prevState: any) =>
                    prevState.filter(
                        (entry: any) =>
                            !resourceTypeDetails
                                ?.map((item: any) => item?.id)
                                .includes(entry?.id)
                    )
                );
            }
        }
        if (selectedResourceTypeId === resourceTypeID?.KPI) {
            if (checked) {
                const newCheckedItems = resourceTypeDetails?.map(
                    (item: any) => ({
                        groupName: item?.groupName,
                        resourceList: item?.resourceList?.map(
                            (resource: any) => ({
                                resourceId: resource?.resourceId,
                            })
                        ),
                    })
                );
                dispatch(setCheckedItemsForKpi(newCheckedItems));
                const transformedDataList = resourceTypeDetails?.map(
                    createTransformedDataKpi
                );
                setRolesPermissionListState((prevState: any) => {
                    const newEntries = transformedDataList?.filter(
                        (newEntry: any) =>
                            !prevState.some(
                                (prevEntry: any) =>
                                    prevEntry?.id === newEntry?.id
                            )
                    );
                    return [...prevState, ...newEntries];
                });
            } else {
                dispatch(setCheckedItemsForKpi([]));
                setRolesPermissionListState((prevState: any) =>
                    prevState.filter(
                        (entry: any) =>
                            !resourceTypeDetails
                                ?.map((item: any) => item?.id)
                                .includes(entry?.id)
                    )
                );
            }
        }

        if (selectedResourceTypeId === resourceTypeID?.ASSET) {
            if (checked) {
                const newCheckedItems = resourceTypeDetails?.map(
                    (item: any) => ({
                        groupName: item.groupName,
                        resourceList: item.resourceList.map(
                            (resource: any) => ({
                                resourceId: resource.resourceId,
                            })
                        ),
                    })
                );
                dispatch(setCheckedItemsForAsset(newCheckedItems));
                const transformedDataList = resourceTypeDetails?.map(
                    createTransformedDataKpi
                );

                setRolesPermissionListState((prevState: any) => {
                    const newEntries = transformedDataList?.filter(
                        (newEntry: any) =>
                            !prevState.some(
                                (prevEntry: any) =>
                                    prevEntry?.id === newEntry?.id
                            )
                    );
                    return [...prevState, ...newEntries];
                });
            } else {
                dispatch(setCheckedItemsForAsset([]));
                setRolesPermissionListState((prevState: any) =>
                    prevState.filter(
                        (entry: any) =>
                            !resourceTypeDetails
                                ?.map((item: any) => item?.id)
                                .includes(entry?.id)
                    )
                );
            }
        }
    };

    const [checkedItemsScreen, setCheckedItemsScreen] = useState<string[]>([]);
    const [checkedItemsAsset, setCheckedItemsAsset] = useState<string[]>([]);
    const [checkedItemsKpi, setCheckedItemsKpi] = useState<string[]>([]);
    const isIndeterminate =
        selectedResourceTypeId === resourceTypeID?.SCREEN
            ? checkedItemsScreen?.length > 0 &&
              checkedItemsScreen?.length < resourceTypeDetails?.length
            : selectedResourceTypeId === resourceTypeID?.ASSET
            ? checkedItemsAsset?.length > 0 &&
              checkedItemsAsset?.length < resourceTypeDetails?.length &&
              checkedItemsAsset?.every((item: any) =>
                  resourceTypeDetails?.some(
                      (detail: any) => detail?.groupName === item?.groupName
                  )
              )
            : selectedResourceTypeId === resourceTypeID?.KPI
            ? checkedItemsKpi?.length > 0 &&
              checkedItemsKpi?.length < resourceTypeDetails?.length &&
              checkedItemsKpi?.every((item: any) =>
                  resourceTypeDetails?.some(
                      (detail: any) => detail?.groupName === item?.groupName
                  )
              )
            : false;

    const isAllChecked =
        selectedResourceTypeId === resourceTypeID?.SCREEN
            ? checkedItemsScreen?.length === resourceTypeDetails?.length
            : selectedResourceTypeId === resourceTypeID?.ASSET
            ? checkedItemsAsset?.length === resourceTypeDetails?.length &&
              checkedItemsAsset?.every((item: any) =>
                  resourceTypeDetails?.some(
                      (detail: any) => detail?.groupName === item?.groupName
                  )
              )
            : selectedResourceTypeId === resourceTypeID?.KPI
            ? checkedItemsKpi?.length === resourceTypeDetails?.length &&
              checkedItemsKpi?.every((item: any) =>
                  resourceTypeDetails?.some(
                      (detail: any) => detail?.groupName === item?.groupName
                  )
              )
            : false;

    const [nodeIds, setNodeIds] = useState<any>([]);
    const [kpiIds, setKpiIds] = useState<any>([]);
    useEffect(() => {
        if (selectedResourceTypeId === resourceTypeID?.KPI) {
            const resourceIds = checkedItemsKpi
                ?.flatMap((item: any) =>
                    item?.resourceList?.map(
                        (resource: any) => resource?.resourceId
                    )
                )
                ?.filter(
                    (value, index, self) => self?.indexOf(value) === index
                );
            setKpiIds(resourceIds);
        }
        if (selectedResourceTypeId === resourceTypeID?.ASSET) {
            const resourceIds = checkedItemsAsset
                ?.flatMap((item: any) =>
                    item?.resourceList?.map(
                        (resource: any) => resource?.resourceId
                    )
                )
                ?.filter(
                    (value, index, self) => self?.indexOf(value) === index
                );
            setNodeIds(resourceIds);
        }
    }, [selectedResourceTypeId, checkedItemsKpi, checkedItemsAsset]);
    const [sortOrder, setSortOrder] = useState<number | null>(null);
    const [sortColumn, setSortColumn] = useState<number | null>(null);
    useEffect(() => {
        selectedResourceTypeId === resourceTypeID?.ASSET &&
            nodeIds?.length &&
            dispatch(
                getAllNodesDetails({
                    nodeIdList: nodeIds,
                    sortColumn: sortColumn,
                    sortOrder: sortOrder,
                })
            );

        selectedResourceTypeId === resourceTypeID?.KPI &&
            kpiIds?.length &&
            dispatch(
                getAllKpisDetails({
                    kpiIdList: kpiIds,
                    sortColumn: sortColumn,
                    sortOrder: sortOrder,
                })
            );
    }, [selectedResourceTypeId, nodeIds, sortOrder, sortColumn, kpiIds]);
    useEffect(() => {
        checkedItemsKpi?.length === 0 &&
            dispatch({ type: CLEAR_ALL_KPIS_DETAILS });
    }, [checkedItemsKpi]);
    useEffect(() => {
        checkedItemsAsset?.length === 0 &&
            dispatch({ type: EMPTY_ALL_NODE_DETAILS });
    }, [checkedItemsAsset]);
    const [rolesList, setRolesList] = useState<any>([]);
    const handleCheckboxChange = (data: any, checked: boolean): void => {
        const transformedData = {
            id: data?.id,
            resourcePermissionList: data?.resourceList?.map(
                (resource: any) => ({
                    resource: {
                        resourceId: resource?.resourceId,
                        resourceName: resource?.resourceName,
                        resourceType: {
                            id: data?.resourceType?.id,
                            resourceTypeName:
                                data?.resourceType?.resourceTypeName,
                        },
                    },
                    permission: [],
                })
            ),
        };
        const transformedKpiData = {
            id: data?.id,
            resourcePermissionList: data?.resourceList?.map(
                (resource: any) => ({
                    resource: {
                        resourceId: resource?.resourceId,
                        resourceName: resource?.resourceName,
                        resourceType: {
                            id: data?.resourceType?.id,
                            resourceTypeName:
                                data?.resourceType?.resourceTypeName,
                        },
                    },
                    permission: ['READ'],
                })
            ),
        };

        setRolesPermissionListState((prevState: any) => {
            if (checked) {
                if (selectedResourceTypeId === resourceTypeID?.SCREEN) {
                    return [
                        ...prevState?.filter(
                            (item: any) => item?.id !== transformedData?.id
                        ),
                        transformedData, // Add the new transformed data
                    ];
                }
                if (selectedResourceTypeId === resourceTypeID?.KPI) {
                    return [
                        ...prevState?.filter(
                            (item: any) => item?.id !== transformedKpiData?.id
                        ),
                        transformedKpiData,
                    ];
                }
                if (selectedResourceTypeId === resourceTypeID?.ASSET) {
                    return [
                        ...prevState?.filter(
                            (item: any) => item?.id !== transformedKpiData?.id
                        ),
                        transformedKpiData,
                    ];
                }
            } else {
                if (selectedResourceTypeId === resourceTypeID?.SCREEN) {
                    return prevState?.filter(
                        (item: any) => item?.id !== transformedData?.id
                    );
                }
                if (selectedResourceTypeId === resourceTypeID?.KPI) {
                    return prevState?.filter(
                        (item: any) => item?.id !== transformedKpiData?.id
                    );
                }
                if (selectedResourceTypeId === resourceTypeID?.ASSET) {
                    return prevState?.filter(
                        (item: any) => item?.id !== transformedKpiData?.id
                    );
                }
            }
        });

        if (checked) {
            if (selectedResourceTypeId === resourceTypeID?.SCREEN) {
                setCheckedItemsScreen((prevState) => {
                    const updatedItems = [...prevState, data.groupName];
                    dispatch(setCheckedItemsForScreen(updatedItems));

                    return updatedItems;
                });
            }
            if (selectedResourceTypeId === resourceTypeID?.KPI) {
                const groupWithResources = {
                    groupName: data?.groupName,
                    resourceList: data?.resourceList?.map((resource: any) => ({
                        resourceId: resource?.resourceId,
                    })),
                };

                setCheckedItemsKpi((prevState: any) => {
                    const updatedItems = [...prevState, groupWithResources];
                    dispatch(setCheckedItemsForKpi(updatedItems));
                    return updatedItems;
                });
            }
            if (selectedResourceTypeId === resourceTypeID?.ASSET) {
                const groupWithResources = {
                    groupName: data?.groupName,
                    resourceList: data?.resourceList?.map((resource: any) => ({
                        resourceId: resource?.resourceId,
                    })),
                };

                setCheckedItemsAsset((prevState: any) => {
                    const updatedItems = [...prevState, groupWithResources];
                    dispatch(setCheckedItemsForAsset(updatedItems));
                    return updatedItems;
                });
            }
        } else {
            if (selectedResourceTypeId === resourceTypeID?.SCREEN) {
                setCheckedItemsScreen((prevState) => {
                    const updatedItems = prevState.filter(
                        (groupName) => groupName !== data.groupName
                    );

                    dispatch(setCheckedItemsForScreen(updatedItems));

                    return updatedItems;
                });
            }
            if (selectedResourceTypeId === resourceTypeID?.KPI) {
                setCheckedItemsKpi((prevState: any) => {
                    const updatedItems = prevState.filter(
                        (group: any) => group.groupName !== data.groupName
                    );
                    dispatch(setCheckedItemsForKpi(updatedItems));
                    return updatedItems;
                });
            }
            if (selectedResourceTypeId === resourceTypeID?.ASSET) {
                setCheckedItemsAsset((prevState: any) => {
                    const updatedItems = prevState.filter(
                        (group: any) => group.groupName !== data.groupName
                    );
                    dispatch(setCheckedItemsForAsset(updatedItems));
                    return updatedItems;
                });
            }
        }
    };
    const updatePermissions: any = (
        resourceId: string,
        newPermissions: string[]
    ) => {
        setRolesPermissionListState((prevState: any[]) =>
            prevState.map((group) => ({
                ...group,
                resourcePermissionList: group?.resourcePermissionList?.map(
                    (resourcePermission: any) =>
                        resourcePermission?.resource?.resourceId === resourceId
                            ? {
                                  ...resourcePermission,
                                  permission: newPermissions,
                              }
                            : resourcePermission
                ),
            }))
        );

        // Update rolesList
        setRolesList((prevRolesList: any[]) => {
            const updatedRolesList = prevRolesList?.map((role) =>
                role?.resource?.resourceId === resourceId
                    ? { ...role, permission: newPermissions }
                    : role
            );

            // If the resource doesn't exist in rolesList, add it
            if (
                !updatedRolesList?.some(
                    (role) => role?.resource?.resourceId === resourceId
                )
            ) {
                const newRole = rolesPermissionListState
                    ?.flatMap((group: any) => group?.resourcePermissionList)
                    ?.find(
                        (resource: any) =>
                            resource?.resource?.resourceId === resourceId
                    );

                if (newRole) {
                    updatedRolesList?.push({
                        ...newRole,
                        permission: newPermissions,
                    });
                }
            }

            return updatedRolesList;
        });
    };

    useEffect(() => {
        const uniqueResourcesMap = new Map<string, any>();

        rolesPermissionListState?.forEach((role: any) => {
            role?.resourcePermissionList?.forEach((resourcePermission: any) => {
                const resourceId = resourcePermission?.resource?.resourceId;

                if (!uniqueResourcesMap.has(resourceId)) {
                    uniqueResourcesMap.set(resourceId, {
                        resource: { ...resourcePermission.resource },
                        permission: resourcePermission.permission,
                    });
                }
            });
        });

        const uniqueResourcesArray = Array.from(uniqueResourcesMap.values());
        setRolesList(uniqueResourcesArray);
    }, [rolesPermissionListState]);

    useEffect(() => {
        if (selectedResourceTypeId === resourceTypeID?.SCREEN) {
            setSearchAsset(null);
            setSearchKpi(null);
        } else if (selectedResourceTypeId === resourceTypeID?.ASSET) {
            setSearchScreen(null);
            setSearchKpi(null);
        } else if (selectedResourceTypeId === resourceTypeID?.KPI) {
            setSearchScreen(null);
            setSearchAsset(null);
        }
    }, [selectedResourceTypeId]);

    const resourceTypeNameMap: Record<number, string> = {
        [resourceTypeID.SCREEN]: 'screen',
        [resourceTypeID.KPI]: 'kpi',
        [resourceTypeID.ASSET]: 'asset',
    };

    useEffect(() => {
        if (editRoleState === ROLETYPE?.viewRole) {
            setSelectedResourceTypeId(resourceTypeID?.SCREEN);
            dispatch(setResourceType(resourceTypeID?.SCREEN));
        }
    }, [editRoleState, dispatch]);

    return (
        <>
            <Row className="createRoles">
                <Col span={5} className="card">
                    <Card
                        className={
                            editRoleState === ROLETYPE?.viewRole
                                ? 'viewRolesCard'
                                : 'createRoles__card'
                        }
                    >
                        <div className="createRoles__addGroup">
                            <div className="resourceTypeHeading">
                                {selectedResourceTypeId ===
                                resourceTypeID?.SCREEN
                                    ? t('roles.screenGroups')
                                    : selectedResourceTypeId ===
                                      resourceTypeID?.KPI
                                    ? t('roles.kpiGroups')
                                    : selectedResourceTypeId ===
                                      resourceTypeID?.ASSET
                                    ? t('roles.assetGroups')
                                    : ''}
                            </div>
                            <div className="addBtn" style={{ width: '36px' }}>
                                <CustomButton
                                    type={t('roles.add')}
                                    disabled={
                                        !selectedResourceTypeId ||
                                        editRoleState === ROLETYPE?.viewRole
                                    }
                                    handleClick={() => {
                                        setOpenSideDrawer(true);
                                    }}
                                />
                            </div>
                        </div>
                        <div>
                            <Input
                                disabled={
                                    !selectedResourceTypeId ||
                                    editRoleState === ROLETYPE?.viewRole
                                }
                                className="createRoles__search"
                                placeholder={t('groups.searchGroupPlaceholder')}
                                prefix={<SearchOutlined />}
                                value={
                                    selectedResourceTypeId ===
                                    resourceTypeID?.SCREEN
                                        ? searchScreen ?? EMPTY?.string
                                        : selectedResourceTypeId ===
                                          resourceTypeID?.ASSET
                                        ? searchAsset ?? EMPTY?.string
                                        : selectedResourceTypeId ===
                                          resourceTypeID?.KPI
                                        ? searchKpi ?? EMPTY?.string
                                        : EMPTY?.string
                                }
                                allowClear
                                onChange={(e) => {
                                    if (
                                        selectedResourceTypeId ===
                                        resourceTypeID?.SCREEN
                                    ) {
                                        setSearchScreen(e?.target?.value);
                                    } else if (
                                        selectedResourceTypeId ===
                                        resourceTypeID?.ASSET
                                    ) {
                                        setSearchAsset(e?.target?.value);
                                    } else if (
                                        selectedResourceTypeId ===
                                        resourceTypeID?.KPI
                                    ) {
                                        setSearchKpi(e?.target?.value);
                                    }
                                    if (searchTimeout) {
                                        clearTimeout(searchTimeout);
                                    }
                                    setSearchTimeout(
                                        setTimeout(() => {
                                            dispatch(
                                                getResourcetypeDetails({
                                                    selectedResourceTypeId:
                                                        selectedResourceTypeId,
                                                    search: e?.target?.value,
                                                })
                                            );
                                        })
                                    );
                                }}
                            />
                        </div>
                        <div className="listItems">
                            {(searchAsset ?? searchKpi ?? searchScreen) &&
                                resourceTypeDetails?.length === 0 && (
                                    <div className="noResultFound">
                                        {t(
                                            'implementation.noResultFoundInSearch'
                                        )}
                                    </div>
                                )}
                            {resourceTypeDetails?.length > 0 && (
                                <>
                                    {' '}
                                    {(!searchAsset ||
                                        !searchKpi ||
                                        !searchScreen) && (
                                        <Checkbox
                                            indeterminate={isIndeterminate}
                                            onChange={handleSelectAllChange}
                                            checked={isAllChecked}
                                            disabled={
                                                editRoleState ===
                                                ROLETYPE?.viewRole
                                            }
                                        >
                                            {t(
                                                'roles.selectAllGroupsPlaceholder'
                                            )}
                                        </Checkbox>
                                    )}
                                    <List
                                        split={false}
                                        dataSource={resourceTypeDetails}
                                        renderItem={(data: any) => (
                                            <List.Item>
                                                <Checkbox
                                                    disabled={
                                                        editRoleState ===
                                                        ROLETYPE?.viewRole
                                                    }
                                                    checked={
                                                        selectedResourceTypeId ===
                                                        resourceTypeID?.SCREEN
                                                            ? checkedItemsScreen?.includes(
                                                                  data.groupName
                                                              )
                                                            : selectedResourceTypeId ===
                                                              resourceTypeID?.ASSET
                                                            ? checkedItemsAsset?.some(
                                                                  (item: any) =>
                                                                      item.groupName ===
                                                                      data.groupName
                                                              )
                                                            : selectedResourceTypeId ===
                                                              resourceTypeID?.KPI
                                                            ? checkedItemsKpi?.some(
                                                                  (item: any) =>
                                                                      item.groupName ===
                                                                      data.groupName
                                                              )
                                                            : false
                                                    }
                                                    onChange={(e) => {
                                                        handleCheckboxChange(
                                                            data,
                                                            e.target.checked
                                                        );
                                                    }}
                                                >
                                                    {data?.groupName}
                                                </Checkbox>
                                            </List.Item>
                                        )}
                                    />
                                </>
                            )}
                        </div>
                    </Card>
                </Col>
                <Col span={19} className="card">
                    <Card
                        className={
                            editRoleState === ROLETYPE?.viewRole
                                ? 'viewRolesCard'
                                : 'createRoles__card'
                        }
                    >
                        <div className="createRoles__tabs">
                            <Radio.Group
                                style={{ marginBottom: 8 }}
                                onChange={handleRadioChange}
                                value={
                                    resourceTypeNameMap[
                                        selectedResourceTypeId
                                    ] || null
                                }
                            >
                                {resourcetypeList?.map((type: any) => (
                                    <Radio.Button
                                        key={type?.id}
                                        value={type?.resourceTypeName.toLowerCase()}
                                    >
                                        {type?.resourceTypeName}
                                    </Radio.Button>
                                ))}
                            </Radio.Group>
                        </div>
                        {selectedResourceTypeId === null ? (
                            <div className="createRoles__emptyComponent">
                                <EmptyDataComponent
                                    textValue={t(
                                        'roles.selectGroupsToAssignRole'
                                    )}
                                    customImage={true}
                                />
                            </div>
                        ) : selectedResourceTypeId ===
                          resourceTypeID?.SCREEN ? (
                            <Screen
                                setKeyProp={setKeyProp}
                                key={JSON.stringify(rolesList)}
                                keyProp={keyProp}
                                rolesPermissionListState={
                                    rolesPermissionListState
                                }
                                rolesList={rolesList}
                                updatePermissions={updatePermissions}
                            />
                        ) : selectedResourceTypeId === resourceTypeID?.KPI ? (
                            <Kpi
                                setSortOrder={setSortOrder}
                                setSortColumn={setSortColumn}
                            />
                        ) : selectedResourceTypeId === resourceTypeID?.ASSET ? (
                            <Asset
                                setSortOrder={setSortOrder}
                                setSortColumn={setSortColumn}
                            />
                        ) : (
                            ''
                        )}
                    </Card>
                </Col>
            </Row>

            {openSideDrawer && (
                <SideDrawer
                    title={t('groups.createGroup')}
                    Open={openSideDrawer}
                    onClose={() => {
                        setOpenSideDrawer(false);
                        dispatch({ type: EMPTY_TREE_OBJECT });
                        dispatch({ type: CLOSE_GROUP_DRAWER });
                    }}
                >
                    <GroupDrawer
                        onClose={() => {
                            setOpenSideDrawer(false);
                            dispatch({ type: EMPTY_TREE_OBJECT });
                            dispatch({ type: CLOSE_GROUP_DRAWER });
                        }}
                        selectedResourceTypeId={selectedResourceTypeId}
                    />
                </SideDrawer>
            )}
        </>
    );
};

export default CreateRoleStep1;