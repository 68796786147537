import React, { useEffect, useState } from 'react';
import { Table, Popover } from 'antd';
import { useSelector } from 'react-redux';
import { MoreOutlined } from '@ant-design/icons';
import EmptyDataComponent from 'components/common/EmptyDataComponent';
import { useTranslation } from 'react-i18next';
import MoreContentTable from './MoreContentTable';
import { PAGE, PAGE_SIZE } from 'utils/constants';
import './index.scss';
import MultipleDeletePopover from './MultipleDeletePopover';
import { type ColumnsType } from 'antd/es/table';
import { ReactComponent as UpArrow } from 'assets/icons/upArrowIcon.svg';
import { ReactComponent as DownArrow } from 'assets/icons/downArrowIcon.svg';
import { tableSortOrder } from 'types/enums';

const DynamicTable: React.FC<any> = ({
    columns,
    setRowId,
    setDefaultValues,
    selectedRowIds,
    setSelectedRowIds,
    setSortColumn,
    setSortOrder,
    setPage
}) => {
    const { t } = useTranslation('translation');
    const [showHeaderPopover, setShowHeaderPopover] = useState(false);

    const data = useSelector(
        (state: any) => state?.configure?.table?.tableData
    );
    useEffect(() => {
        setShowHeaderPopover(selectedRowIds?.length > 1);
    }, [selectedRowIds?.length]);

    const [popoverheaderVisible, setPopoverheaderVisible] = useState({
        actions: false,
    });

    const [popoverVisible, setPopoverVisible] = useState<
        Record<string, boolean>
    >({});

    const handlePopoverItemClick = (): void => {
        setPopoverheaderVisible({ actions: false });
    };

    const SearchNoDataText = (
        <EmptyDataComponent
            textValue={t('implementation.table.noData')}
            customClassName="SearchEmptyComponent"
        />
    );
    const handlePopoverVisibleChange = (
        visible: boolean,
        record: any
    ): void => {
        setPopoverVisible((prevState) => ({
            ...prevState,
            [record.key]: visible,
        }));
    };
    const staticColumn: any[] = [
        {
            title: (
                <div>
                    {showHeaderPopover ? (
                        <Popover
                            visible={popoverheaderVisible.actions}
                            onVisibleChange={(visible) => {
                                setPopoverheaderVisible((prevState: any) => ({
                                    ...prevState,
                                    actions: visible,
                                }));
                            }}
                            content={
                                <div className="custom-popover-content">
                                    <MultipleDeletePopover
                                        onItemClick={handlePopoverItemClick}
                                    />
                                </div>
                            }
                            placement="bottomLeft"
                            trigger="click"
                        >
                            <MoreOutlined />
                        </Popover>
                    ) : (
                        ''
                    )}
                </div>
            ),
            key: 'more',
            width: 50,
            render: (text: any, record: any) => (
                <Popover
                    visible={popoverVisible[record.key]}
                    onVisibleChange={(visible) => {
                        handlePopoverVisibleChange(visible, record);
                    }}
                    overlayClassName="blaTablePopover"
                    content={
                        <div className="moreContent__popOver">
                            <MoreContentTable
                                record={record}
                                setRowId={setRowId}
                                setDefaultValues={setDefaultValues}
                                setPopoverVisible={setPopoverVisible}
                            />
                        </div>
                    }
                    trigger="click"
                    placement="bottomLeft"
                >
                    <MoreOutlined
                        style={{ cursor: 'pointer', fontSize: '16px' }}
                    />
                </Popover>
            ),
        },
    ];

    const dynamicColumns = columns?.map((col: any, index: any) => ({
        title: (
            <div className='timeCapsuleTableWrapper__columnTitle'>
               <div> {col?.name}</div>
                <div className="sortArrows">
                    <UpArrow
                        cursor="pointer"
                        onClick={() => {
                            setSortOrder(tableSortOrder?.ascending);
                            setSortColumn(
                                col?.name
                            );
                            setPage(PAGE);
                        }}
                    />
                    <DownArrow
                        fill="white"
                        cursor="pointer"
                        onClick={() => {
                            setSortOrder(tableSortOrder?.descending);
                            setSortColumn(
                                col?.name
                            );
                            setPage(PAGE);
                        }}
                    />
                </div>
            </div>
        ),
        dataIndex: col?.name,
        key: col?.name,
        width: col?.name?.length > 12 ? 230 : 160,
        render: (text: any) => text || '-',
    }));

    const tableColumns: ColumnsType<any> = [...staticColumn, ...dynamicColumns];

    const dataSource = data?.content?.map((row: any, index: number) => ({
        ...row,
        key: row?.rowId || index, // Ensure each row has a unique key
    }));

    const onSelectChange = (
        selectedRowKeys: React.Key[],
        selectedRows: any[]
    ): any => {
        const transformedRows: any = selectedRows?.map((row) => ({
            rowId: row.rowId,
            columnDataModifyRequests: [],
        }));

        setSelectedRowIds(transformedRows);
    };

    const rowSelection = {
        onChange: onSelectChange,
    };

    return (
        <>
            <div>
                <Table
                    className="validateTable"
                    rowSelection={{
                        type: 'checkbox',
                        ...rowSelection,
                    }}
                    columns={tableColumns}
                    dataSource={dataSource}
                    pagination={false}
                    rowClassName={() => 'editable-row'}
                    scroll={{
                        y:
                            data?.totalElements > PAGE_SIZE
                                ? 'calc(100vh - 550px)'
                                : 'calc(100vh - 500px)',
                    }}
                    locale={{ emptyText: SearchNoDataText }}
                />
            </div>
        </>
    );
};

export default DynamicTable;
