import React, { useEffect } from 'react';
import './index.scss';
import CustomHeader from '../../components/common/CustomHeader';
import DynamicForm from '../FormIo/DynamicForm';
import { useDispatch, useSelector } from 'react-redux';
import { getActionslist, getFormDetails } from 'redux/actions/FormActions';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const FormIO: React.FC = () => {
    const { id } = useParams();
    const formId = id;
    const formDetails = useSelector(
        (state: any) => state?.formio?.formsDetails
    );

    const dispatch = useDispatch();
    const { t } = useTranslation('translation');
    useEffect(() => {
        formId && dispatch(getFormDetails(formId));
        formId && dispatch(getActionslist(formId));
    }, [formId]);

    return (
        <div className="dynamicForm">
            <CustomHeader
                heading={t('menu.forms')}
                customDateTimePicker={false}
                currentTimePicker={true}
                infoTooltip={true}
            />
            <div className="dynamicFormWrapperScrolContent">
                <div className="dynamicFormWrapper">
                    <>
                        <div className="form__heading">
                            {/* <div className="form__backIcon">
                                <BackIcon
                                    onClick={() => {
                                        setFormDetails(null);
                                    }}
                                />
                            </div> */}
                            <div className="form__headingLabel">
                                <div className="form__headingName fw-500 fs-20">
                                    {formDetails?.title}
                                </div>
                            </div>
                        </div>
                        <DynamicForm dataSource={'FORM'}/>
                    </>
                </div>
            </div>
        </div>
    );
};

export default FormIO;
