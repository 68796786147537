import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { message } from 'antd';

const ScatterChart = ({
  selectedTags,
  selectedKpis,
  aggregatedScatterChartData,
}: any): any => {
  const chartRef = useRef(null);

  const extractChartData = ():any => {
    const seriesData: any[][] = [];
    const polynomialRegressionData: any[][] = [];
    const regressionData = [];

    const xKey = selectedTags[0]?.key || selectedKpis[0]?.key; // X-axis key
    const yKey = selectedTags[1]?.key || selectedKpis[1]?.key; // Y-axis key

    if (!xKey || !yKey) {
      message?.info('Please select Tags or KPI for X and Y axis');
      return { seriesData: [], meanX: null, meanY: null };
    }

    const meanX = aggregatedScatterChartData?.trendFunctions?.MEAN?.[xKey] || null;
    const meanY = aggregatedScatterChartData?.trendFunctions?.MEAN?.[yKey] || null;
   

    aggregatedScatterChartData?.stream?.forEach((item:any) => {
      const x = item[xKey];
      const y = item[yKey];
      if (x !== undefined && y !== undefined) {
        seriesData?.push([x, y]);
      }
    });

    const regressionCoefficients = aggregatedScatterChartData?.regressionFunction?.LINEAR_REGRESSION?.coefficients;
   

    if (regressionCoefficients) {
      const [intercept, slope] = regressionCoefficients;
      const xValues = seriesData?.map((point) => point[0]);
      const minX = Math.min(...xValues);
      const maxX = Math.max(...xValues);
      regressionData.push([minX, intercept + slope * minX]);
      regressionData.push([maxX, intercept + slope * maxX]);
    }
    // Dynamically extract polynomial regression coefficients for any degree
  const polynomialKeys = Object.keys(aggregatedScatterChartData?.regressionFunction || {}).filter((key) =>
    key?.startsWith('POLYNOMIAL_REGRESSION')
  );

  polynomialKeys?.forEach((key) => {
    const degree = parseInt(key.replace('POLYNOMIAL_REGRESSION', ''), 10);
    const coefficients = aggregatedScatterChartData?.regressionFunction?.[key]?.coefficients;

    if (coefficients) {
      const xValues = seriesData.map((point) => point[0]);
      const minX = Math.min(...xValues);
      const maxX = Math.max(...xValues);
      const step = (maxX - minX) / 100; // Generate 100 points for a smooth curve

      polynomialRegressionData[degree] = [];
      for (let x = minX; x <= maxX; x += step) {
        let y = 0;
        coefficients.forEach((coef:any, index:any):any => {
          y += coef * Math.pow(x, index); // General polynomial formula
        });
        polynomialRegressionData[degree].push([x, y]);
      }
    }
  });
    return { seriesData, regressionData, polynomialRegressionData,meanX, meanY };
  };
 
  useEffect(() => {
    const chartInstance = echarts?.init(chartRef?.current);

    const { seriesData, regressionData, meanX, polynomialRegressionData,meanY } = extractChartData();

    const xAxisTitle = selectedTags[0]?.tagName || selectedKpis[0]?.tagName || 'X-Axis';
    const yAxisTitle = selectedTags[1]?.tagName || selectedKpis[1]?.tagName || 'Y-Axis';
   
    

    const options = {
      title: {
        text: '',
      },
     
      tooltip: {
        trigger: 'item',
        formatter: (params:any) => {
          const { value } = params;
          return `
            X: ${value[0]}<br/>
            Y: ${value[1]}<br/>
            ${meanX !== null ? `X Mean: ${meanX.toFixed(2)}` : ''}
            ${meanY !== null ? `Y Mean: ${meanY.toFixed(2)}` : ''}
          `;
        },
      },
      
      xAxis: {
        
        name: xAxisTitle,
        nameLocation: 'middle',
        nameGap: 30,
        splitLine: {
            show: false, // Disable vertical grid lines
          },
        axisLabel: {
          formatter: (value:any) => value.toFixed(2),
        },
        
        
      },
      yAxis: {
        name: yAxisTitle,
        nameLocation: 'middle',
        nameTextStyle: {
          fontSize: 14,
          padding: 30,
      },
      scale: true,
        splitLine: {
          show: true,
          interval: 2,
        }, 
        axisLabel: {
          formatter: (value: number) => {
              if (value >= 1e6) {
                  return `${(value / 1e6).toFixed(1)}M`; // Convert to millions
              } else if (value >= 1e3) {
                  return `${(value / 1e3).toFixed(1)}K`; // Convert to thousands
              }
              return value.toString();
          },
      },
      },
      dataZoom: [
        {
          type: 'inside',
          throttle: 50,
          xAxisIndex: [0],  // Zoom for X axis
        },
        {
          type: 'inside',
          throttle: 50,
          yAxisIndex: [0],  // Zoom for Y axis
        }
      ],
      toolbox: {
        left: 'right',
        itemSize: 15,
        top: -3,
        feature: {
            saveAsImage: {},
          dataZoom: {
            // yAxisIndex: 'none'
           
          },
          restore: {}
        }
      },
      series: [
        {
          name: 'Selected Data',
          type: 'scatter',
          data: seriesData,
          symbolSize: 8,
          itemStyle: {
            color: 'rgba(0, 0, 255, 0.5)',
          },
          markLine: {
            silent: true,
            data: [
              ...(meanX !== null ? [{ xAxis: meanX }] : []), // Mean line for X-axis
              ...(meanY !== null ? [{ yAxis: meanY }] : []), // Mean line for Y-axis
            ],
            lineStyle: {
              color: 'blue',
              type: 'dashed',
              width: 2,
            },
          },
        },
        
      
        {
          name: 'Linear Regression',
          type: 'line',
          data: regressionData,
          lineStyle: {
            color: 'red',
          },
          tooltip: {
            formatter: (params:any)=> `Y: ${params?.value[1]}`,
          },
        },
       // Adding polynomial regression series directly in the array
       ...(polynomialRegressionData && Object.keys(polynomialRegressionData).length > 0
       ? Object.keys(polynomialRegressionData).map((degree) => ({
           name: `Polynomial Regression (Degree ${degree})`,
           type: 'line',
           data: polynomialRegressionData[degree],
           lineStyle: {
             color: 'green',
             type: 'dotted',
           },
           tooltip: {
             formatter: (params: any) => `Y: ${params?.value[1]}`,
           },
         }))
       : [])
      ],
    };

    chartInstance?.setOption(options);

    const handleResize = ():any => {
      chartInstance?.resize();
    };
    window.addEventListener('resize', handleResize);

    return () => {
      chartInstance?.dispose();
      window?.removeEventListener('resize', handleResize);
    };
  }, [selectedTags, selectedKpis, aggregatedScatterChartData]);

  return <div ref={chartRef} style={{ width: '100%', height: '420px' }} />;
};

export default ScatterChart;
