import { Col, Form, Input, Row, Select, Tag } from 'antd';
import './index.scss';
import Password from 'antd/es/input/Password';
import { useEffect, useState } from 'react';
import { AuthMethod, EMPTY, fileFormatType } from 'types/enums';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAllAuthMethodList,
    getAllDirectionsList,
    getAllFileFormats,
} from 'redux/actions/BusinessIntegratorActions/connectionsActions';
import { useTranslation } from 'react-i18next';
import { ConnectionsState } from 'types/enums/businessIntegratorEnum';
import DynamicInputRows from './DynamicInput';

const AdapterDetailsForm: React.FC<any> = ({ form }: any): any => {
    const { t } = useTranslation('translation');

    const { Option } = Select;
    const { TextArea } = Input;
    const dispatch = useDispatch();

    const adapterList = useSelector(
        (state: any) => state.businessIntegrator.connections.adapterList
    );
    const allFileFormatsList = useSelector(
        (state: any) => state.businessIntegrator.connections.allFileFormatsList
    );
    const allDirectionsList = useSelector(
        (state: any) => state.businessIntegrator.connections.allDirectionsList
    );
    const allAuthMethodList = useSelector(
        (state: any) => state.businessIntegrator.connections.allAuthMethodList
    );

    const connectionsState = useSelector(
        (state: any) => state.businessIntegrator.connections.connectionsState
    );
    const connectionById = useSelector(
        (state: any) => state.businessIntegrator.connections.connectionById
    );

    const password = Form.useWatch('password', form);
    const confirmPassword = Form.useWatch('confirmPassword', form);
    const label = Form.useWatch('label', form);
    const [inputValue, setInputValue] = useState<any>(EMPTY.string);
    const [tags, setTags] = useState<any>([]);
    useEffect(() => {
        form.setFieldsValue({ retryCount: 3 }); 
    }, [form]);

    useEffect(() => {
        const initialTags = connectionById?.labelResponse?.map(
            (item: any) => item?.name
        );
        setTags(initialTags || []);
    }, [connectionById]);

    const handleInputChange = (e: any): any => {
        setInputValue(e.target.value);
    };

    const handleInputConfirm = (e: any): any => {
        if (e.key === 'Enter' && inputValue) {
            setTags([...tags, inputValue]);
            setInputValue(EMPTY.string);
        }
    };
    useEffect(() => {
        const jsonFormat = allFileFormatsList?.find(
            (item: any) => item?.name === fileFormatType?.JSON
        );
        if (jsonFormat) {
            form?.setFieldsValue({ fileFormat: jsonFormat?.id });
        }
    }, [allFileFormatsList, form]);

    const handleCloseTag = (removedTag: any): any => {
        setTags(tags?.filter((tag: any) => tag !== removedTag));
    };

    useEffect(() => {
        dispatch(getAllFileFormats());
        dispatch(getAllDirectionsList());
        dispatch(getAllAuthMethodList());
    }, []);

    useEffect(() => {
        form.setFieldsValue({ label: tags });
    }, [tags]);
    const [authMethod, setAuthMethod] = useState('Basic');
    const handleAuthMethod = (value: string): any => {
        const name = value.split(':')[1];
        setAuthMethod(name);
    };
    const handleCustomHeadersChange = (headers: any): any => {
        form.setFieldsValue({ customHeaders: headers });
    };
    useEffect(() => {
        Object.keys(connectionById)?.length &&
            setAuthMethod(connectionById?.authMethod?.name);
    }, [connectionById]);
    useEffect(() => {
        if (ConnectionsState.addConnections) {
            form.setFieldsValue({
                authenticationMethod:
                    '86bff74e-1949-47a9-87bf-81f36ca13570:Basic',
            });
        }
    }, []);

    return (
        <>
            <div className="adapterForm">
                <Row gutter={10}>
                    <Col span={12}>
                        <Form.Item
                            label={
                                <div className="fw-400 fs-14">
                                    {t(
                                        'BusinessIntegrator.connections.tableColumn.connectionName'
                                    )}
                                </div>
                            }
                            name="connectionName"
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        'BusinessIntegrator.connections.validationError.connectionName'
                                    ),
                                    pattern: /^[a-zA-Z0-9_]+$/,
                                },
                            ]}
                        >
                            <Input
                                placeholder="Enter Connection Name"
                                disabled={
                                    connectionsState ===
                                        ConnectionsState.editConnection ||
                                    connectionsState ===
                                        ConnectionsState.viewConnections
                                }
                                maxLength={100}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={
                                <div className="fw-400 fs-14">
                                    {t(
                                        'BusinessIntegrator.connections.tableColumn.adapterType'
                                    )}
                                </div>
                            }
                            name="adapterType"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                popupClassName="adapter"
                                placeholder="Select"
                                disabled
                            >
                                {adapterList?.map((item: any) => {
                                    return (
                                        <Option key={item?.id} value={item?.id}>
                                            {item?.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col span={12}>
                        <Form.Item
                            label={
                                <div className="fw-400 fs-14">
                                    {t(
                                        'BusinessIntegrator.connections.connectionForm.responseFormat'
                                    )}
                                </div>
                            }
                            name="fileFormat"
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        'BusinessIntegrator.connections.validationError.responseFormat'
                                    ),
                                },
                            ]}
                        >
                            <Select
                                placeholder="Select"
                                popupClassName="adapter"
                                disabled={
                                    connectionsState ===
                                        ConnectionsState.editConnection ||
                                    connectionsState ===
                                        ConnectionsState.viewConnections
                                }
                            >
                                {allFileFormatsList?.map((item: any) => {
                                    return (
                                        <Option key={item?.id} value={item?.id}>
                                            {item?.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={
                                <div className="fw-400 fs-14">
                                    {t(
                                        'BusinessIntegrator.connections.tableColumn.direction'
                                    )}
                                </div>
                            }
                            name="direction"
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        'BusinessIntegrator.connections.validationError.direction'
                                    ),
                                },
                            ]}
                        >
                            <Select
                                placeholder="Select"
                                popupClassName="adapter"
                                disabled={
                                    connectionsState ===
                                        ConnectionsState.editConnection ||
                                    connectionsState ===
                                        ConnectionsState.viewConnections
                                }
                            >
                                {allDirectionsList?.map((item: any) => {
                                    return (
                                        <Option
                                            key={item?.id}
                                            value={item?.id}
                                            // disabled={item.name === 'Outbound'} // will remove later
                                        >
                                            {item?.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col span={12}>
                        <Form.Item
                            label={
                                <div className="fw-400 fs-14">
                                    {t(
                                        'BusinessIntegrator.connections.connectionForm.authenticationMethod'
                                    )}
                                </div>
                            }
                            name="authenticationMethod"
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        'BusinessIntegrator.connections.validationError.authenticationMethod'
                                    ),
                                },
                            ]}
                        >
                            <Select
                                popupClassName="adapter"
                                placeholder="Select"
                                disabled={
                                    connectionsState ===
                                        ConnectionsState.editConnection ||
                                    connectionsState ===
                                        ConnectionsState.viewConnections
                                }
                                onSelect={handleAuthMethod}
                                defaultValue="86bff74e-1949-47a9-87bf-81f36ca13570:Basic"
                            >
                                {allAuthMethodList?.map((item: any) => {
                                    return (
                                        <Option
                                            key={item?.id}
                                            value={`${item?.id}:${item?.name}`}
                                        >
                                            {item?.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={
                                <div className="fw-400 fs-14">
                                    {t(
                                        'BusinessIntegrator.connections.tableColumn.label'
                                    )}
                                </div>
                            }
                            name="label"
                            help={
                                label && typeof label === 'string' ? (
                                    <div className="adapterConfirmPassord">
                                        Please press enter when your label is
                                        entered completely
                                    </div>
                                ) : (
                                    EMPTY.string
                                )
                            }
                        >
                            <div
                                className={`adapterLabelTag ${
                                    connectionsState ===
                                    ConnectionsState.viewConnections
                                        ? 'disabled'
                                        : EMPTY.string
                                }`}
                            >
                                {tags?.map((tag: any, index: any) => (
                                    <Tag
                                        key={`${tag}-${index}`}
                                        closable={
                                            connectionsState !==
                                            ConnectionsState.viewConnections
                                        }
                                        onClose={() => handleCloseTag(tag)}
                                    >
                                        {tag}
                                    </Tag>
                                ))}
                                {connectionsState !==
                                    ConnectionsState.viewConnections && (
                                    <Input
                                        value={inputValue}
                                        onChange={handleInputChange}
                                        onKeyDown={handleInputConfirm}
                                        style={{
                                            width: 'auto',
                                            flexGrow: 1,
                                            border: 'none',
                                            outline: 'none',
                                        }}
                                        className="tag-input"
                                        maxLength={40}
                                    />
                                )}
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col span={12}>
                        {authMethod?.toLowerCase() === AuthMethod.BASIC ||
                        authMethod?.toLowerCase() ===
                            AuthMethod.API_KEY_AUTHENTICATION ||
                        authMethod?.toLowerCase() ===
                            AuthMethod.CUSTOM_HEADER_AUTHENTICATION ||
                        authMethod?.toLowerCase() ===
                            AuthMethod.JWT_TOKEN_AUTHENTIZATION ? (
                            <Form.Item
                                label={
                                    <div className="fw-400 fs-14">
                                        {t(
                                            'BusinessIntegrator.connections.connectionForm.url'
                                        )}
                                    </div>
                                }
                                name="url"
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'BusinessIntegrator.connections.validationError.url'
                                        ),
                                    },
                                ]}
                            >
                                <Input placeholder="Enter URL" />
                            </Form.Item>
                        ) : (
                            <Form.Item
                                label={
                                    <div className="fw-400 fs-14">
                                        {t(
                                            'BusinessIntegrator.connections.connectionForm.tokenUrl'
                                        )}
                                    </div>
                                }
                                name="tokenUrl"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input placeholder="Token URL" />
                            </Form.Item>
                        )}
                    </Col>
                    {(authMethod?.toLowerCase() === AuthMethod.BASIC ||
                        authMethod?.toLowerCase() ===
                            AuthMethod.OAUTH_PASSWORD) && (
                        <Col span={12}>
                            <Form.Item
                                label={
                                    <div className="fw-400 fs-14">
                                        {t(
                                            'BusinessIntegrator.connections.connectionForm.userName'
                                        )}
                                    </div>
                                }
                                name="userName"
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'BusinessIntegrator.connections.validationError.userName'
                                        ),
                                    },
                                    // { type: 'url' }, // for future reference
                                ]}
                            >
                                <Input placeholder="Enter User Name" />
                            </Form.Item>
                        </Col>
                    )}
                    {authMethod?.toLowerCase() ===
                        AuthMethod.OAUTH__CLIENT_CREDENTIALS && (
                        <Col span={12}>
                            <Form.Item
                                label={
                                    <div className="fw-400 fs-14">
                                        {t(
                                            'BusinessIntegrator.connections.connectionForm.clientId'
                                        )}
                                    </div>
                                }
                                name="clientId"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={t(
                                        'BusinessIntegrator.connections.validationError.clientId'
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    )}
                    {authMethod?.toLowerCase() ===
                        AuthMethod.API_KEY_AUTHENTICATION && (
                        <Col span={12}>
                            <Form.Item
                                label={
                                    <div className="fw-400 fs-14">
                                        {t(
                                            'BusinessIntegrator.connections.connectionForm.apiKeyValue'
                                        )}
                                    </div>
                                }
                                name="apiKeyValue"
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'BusinessIntegrator.connections.validationError.apiKeyValue'
                                        ),
                                    },
                                ]}
                            >
                                <TextArea placeholder="API Key Value" />
                            </Form.Item>
                        </Col>
                    )}
                    {authMethod?.toLowerCase() ===
                        AuthMethod.CUSTOM_HEADER_AUTHENTICATION && (
                        <Col span={12}>
                            <Form.Item
                                label={
                                    <div className="fw-400 fs-14">
                                        {t(
                                            'BusinessIntegrator.connections.connectionForm.reconnectionTryCount'
                                        )}
                                    </div>
                                }
                                name="retryCount"
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'BusinessIntegrator.connections.validationError.reconnectionTryCount'
                                        ),
                                        pattern: /^[a-zA-Z0-9_]+$/,
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Enter Reconnection Try Count"
                                    maxLength={5}
                                />
                            </Form.Item>
                        </Col>
                    )}
                    {authMethod.toLowerCase() ===
                        AuthMethod.JWT_TOKEN_AUTHENTIZATION && (
                        <Col span={12}>
                            <Form.Item
                                label={
                                    <div className="fw-400 fs-14">
                                        {t(
                                            'BusinessIntegrator.connections.connectionForm.jwtToken'
                                        )}
                                    </div>
                                }
                                name="jwtToken"
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'BusinessIntegrator.connections.validationError.jwtToken'
                                        ),
                                    },
                                ]}
                            >
                                <TextArea placeholder="JWT Token" />
                            </Form.Item>
                        </Col>
                    )}
                </Row>
                <Row gutter={10}>
                    <Col span={12}>
                        {(authMethod?.toLowerCase() === AuthMethod.BASIC ||
                            authMethod?.toLowerCase() ===
                                AuthMethod.OAUTH_PASSWORD) && (
                            <Form.Item
                                label={
                                    <div className="fw-400 fs-14">
                                        {t(
                                            'BusinessIntegrator.connections.connectionForm.password'
                                        )}
                                    </div>
                                }
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'BusinessIntegrator.connections.validationError.password'
                                        ),
                                    },
                                ]}
                            >
                                <Password
                                    placeholder="Enter Password"
                                    className="adapterPassword"
                                />
                            </Form.Item>
                        )}
                        {authMethod?.toLowerCase() ===
                            AuthMethod.OAUTH__CLIENT_CREDENTIALS && (
                            <Form.Item
                                label={
                                    <div className="fw-400 fs-14">
                                        {t(
                                            'BusinessIntegrator.connections.connectionForm.clientSecret'
                                        )}
                                    </div>
                                }
                                name={'clientSecret'}
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'BusinessIntegrator.connections.validationError.clientSecret'
                                        ),
                                    },
                                ]}
                            >
                                <Password
                                    placeholder={'Enter client secret'}
                                    className="adapterPassword"
                                />
                            </Form.Item>
                        )}
                        {(authMethod?.toLowerCase() ===
                            AuthMethod.API_KEY_AUTHENTICATION ||
                            authMethod?.toLowerCase() ===
                                AuthMethod.JWT_TOKEN_AUTHENTIZATION) && (
                            <Form.Item
                                label={
                                    <div className="fw-400 fs-14">
                                        {t(
                                            'BusinessIntegrator.connections.connectionForm.reconnectionTryCount'
                                        )}
                                    </div>
                                }
                                name="retryCount"
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'BusinessIntegrator.connections.validationError.reconnectionTryCount'
                                        ),
                                        pattern: /^[a-zA-Z0-9_]+$/,
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Enter Reconnection Try Count"
                                    maxLength={5}
                                />
                            </Form.Item>
                        )}
                        {authMethod?.toLowerCase() ===
                            AuthMethod.CUSTOM_HEADER_AUTHENTICATION && (
                            <Form.Item
                                label={
                                    <div className="fw-400 fs-14">
                                        {t(
                                            'BusinessIntegrator.connections.connectionForm.timeoutDuration'
                                        )}
                                    </div>
                                }
                                name="connectionTimeout"
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'BusinessIntegrator.connections.validationError.timeDuration'
                                        ),
                                        pattern: /^[a-zA-Z0-9_]+$/,
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Enter Timeout Duration"
                                    maxLength={5}
                                />
                            </Form.Item>
                        )}
                    </Col>
                    <Col span={12}>
                        {(authMethod?.toLowerCase() === AuthMethod.BASIC ||
                            authMethod?.toLowerCase() ===
                                AuthMethod.OAUTH_PASSWORD) && (
                            <Form.Item
                                label={
                                    <div className="fw-400 fs-14">
                                        {t(
                                            'BusinessIntegrator.connections.connectionForm.confirmPassword'
                                        )}
                                    </div>
                                }
                                name="confirmPassword"
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'BusinessIntegrator.connections.validationError.confirmPassword'
                                        ),
                                    },
                                ]}
                                help={
                                    confirmPassword &&
                                    confirmPassword !== password ? (
                                        <div className="adapterConfirmPassord">
                                            {t(
                                                'BusinessIntegrator.connections.validationError.passwordNotMatched'
                                            )}
                                        </div>
                                    ) : (
                                        EMPTY.string
                                    )
                                }
                            >
                                <Password
                                    placeholder="Re-Enter Password"
                                    className="adapterPassword"
                                />
                            </Form.Item>
                        )}
                        {(authMethod?.toLowerCase() ===
                            AuthMethod.API_KEY_AUTHENTICATION ||
                            authMethod?.toLowerCase() ===
                                AuthMethod.JWT_TOKEN_AUTHENTIZATION) && (
                            <Form.Item
                                label={
                                    <div className="fw-400 fs-14">
                                        {t(
                                            'BusinessIntegrator.connections.connectionForm.timeoutDuration'
                                        )}
                                    </div>
                                }
                                name="connectionTimeout"
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'BusinessIntegrator.connections.validationError.timeDuration'
                                        ),
                                        pattern: /^[a-zA-Z0-9_]+$/,
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Enter Timeout Duration"
                                    maxLength={5}
                                />
                            </Form.Item>
                        )}
                        {authMethod?.toLowerCase() ===
                            AuthMethod.OAUTH__CLIENT_CREDENTIALS && (
                            <Form.Item
                                label={
                                    <div className="fw-400 fs-14">
                                        {t(
                                            'BusinessIntegrator.connections.connectionForm.reconnectionTryCount'
                                        )}
                                    </div>
                                }
                                name="retryCount"
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'BusinessIntegrator.connections.validationError.reconnectionTryCount'
                                        ),
                                        pattern: /^[a-zA-Z0-9_]+$/,
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Enter Reconnection Try Count"
                                    maxLength={5}
                                />
                            </Form.Item>
                        )}
                        {(authMethod?.toLowerCase() ===
                            AuthMethod.CUSTOM_HEADER_AUTHENTICATION )&& (
                            <Form.Item
                                label={
                                    <div className="fw-400 fs-14">
                                        {t(
                                            'BusinessIntegrator.connections.connectionForm.reconnectIn'
                                        )}
                                    </div>
                                }
                                name="retryDelay"
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'BusinessIntegrator.connections.validationError.reconnectIn'
                                        ),
                                        pattern: /^[a-zA-Z0-9_]+$/,
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Enter Reconnect In"
                                    maxLength={5}
                                />
                            </Form.Item>
                        )}
                    </Col>
                </Row>
                <Row gutter={10}>
                    {authMethod?.toLowerCase() ===
                    AuthMethod.CUSTOM_HEADER_AUTHENTICATION ? (
                        <Col span={24}>
                            <Form.Item
                                name="customHeaders"
                                label={
                                    <div className="fw-400 fs-14">
                                        {t(
                                            'BusinessIntegrator.connections.connectionForm.customHeader'
                                        )}
                                    </div>
                                }
                                rules={[
                                    {
                                        required:
                                            authMethod?.toLowerCase() ===
                                            AuthMethod.CUSTOM_HEADER_AUTHENTICATION,
                                        message: t(
                                            'BusinessIntegrator.connections.validationError.customHeader'
                                        ),
                                    },
                                ]}
                            >
                                <DynamicInputRows
                                    onChange={handleCustomHeadersChange}
                                />
                            </Form.Item>
                        </Col>
                    ) : (
                        <>
                            <Col span={12}>
                                {(authMethod?.toLowerCase() ===
                                    AuthMethod.BASIC ||
                                    authMethod?.toLowerCase() ===
                                        AuthMethod.OAUTH_PASSWORD) && (
                                    <Form.Item
                                        label={
                                            <div className="fw-400 fs-14">
                                                {t(
                                                    'BusinessIntegrator.connections.connectionForm.reconnectionTryCount'
                                                )}
                                            </div>
                                        }
                                        name="retryCount"
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    'BusinessIntegrator.connections.validationError.reconnectionTryCount'
                                                ),
                                                pattern: /^[a-zA-Z0-9_]+$/,
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder="Enter Reconnection Try Count"
                                            maxLength={5}
                                        />
                                    </Form.Item>
                                )}
                                {(authMethod?.toLowerCase() ===
                                    AuthMethod.API_KEY_AUTHENTICATION ||
                                    authMethod?.toLowerCase() ===
                                        AuthMethod.JWT_TOKEN_AUTHENTIZATION) && (
                                    <Form.Item
                                        label={
                                            <div className="fw-400 fs-14">
                                                {t(
                                                    'BusinessIntegrator.connections.connectionForm.reconnectIn'
                                                )}
                                            </div>
                                        }
                                        name="retryDelay"
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    'BusinessIntegrator.connections.validationError.reconnectIn'
                                                ),
                                                pattern: /^[a-zA-Z0-9_]+$/,
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder="Enter Reconnect In"
                                            maxLength={5}
                                        />
                                    </Form.Item>
                                )}
                                {authMethod?.toLowerCase() ===
                                    AuthMethod.OAUTH__CLIENT_CREDENTIALS && (
                                    <Form.Item
                                        label={
                                            <div className="fw-400 fs-14">
                                                {t(
                                                    'BusinessIntegrator.connections.connectionForm.timeoutDuration'
                                                )}
                                            </div>
                                        }
                                        name="connectionTimeout"
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    'BusinessIntegrator.connections.validationError.timeDuration'
                                                ),
                                                pattern: /^[a-zA-Z0-9_]+$/,
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder="Enter Timeout Duration"
                                            maxLength={5}
                                        />
                                    </Form.Item>
                                )}
                            </Col>
                            <Col span={12}>
                                {(authMethod?.toLowerCase() ===
                                    AuthMethod.BASIC ||
                                    authMethod?.toLowerCase() ===
                                        AuthMethod.OAUTH_PASSWORD) && (
                                    <Form.Item
                                        label={
                                            <div className="fw-400 fs-14">
                                                {t(
                                                    'BusinessIntegrator.connections.connectionForm.timeoutDuration'
                                                )}
                                            </div>
                                        }
                                        name="connectionTimeout"
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    'BusinessIntegrator.connections.validationError.timeDuration'
                                                ),
                                                pattern: /^[a-zA-Z0-9_]+$/,
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder="Enter Timeout Duration"
                                            maxLength={5}
                                        />
                                    </Form.Item>
                                )}
                                {authMethod?.toLowerCase() ===
                                    AuthMethod.OAUTH__CLIENT_CREDENTIALS && (
                                    <Form.Item
                                        label={
                                            <div className="fw-400 fs-14">
                                                {t(
                                                    'BusinessIntegrator.connections.connectionForm.reconnectIn'
                                                )}
                                            </div>
                                        }
                                        name="retryDelay"
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    'BusinessIntegrator.connections.validationError.reconnectIn'
                                                ),
                                                pattern: /^[a-zA-Z0-9_]+$/,
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder="Enter Reconnect In"
                                            maxLength={5}
                                        />
                                    </Form.Item>
                                )}
                            </Col>
                        </>
                    )}
                </Row>

                <Row>
                    {(authMethod?.toLowerCase() === AuthMethod.BASIC ||
                        authMethod?.toLowerCase() ===
                            AuthMethod.OAUTH_PASSWORD) && (
                        <Col span={12}>
                            <Form.Item
                                label={
                                    <div className="fw-400 fs-14">
                                        {t(
                                            'BusinessIntegrator.connections.connectionForm.reconnectIn'
                                        )}
                                    </div>
                                }
                                name="retryDelay"
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'BusinessIntegrator.connections.validationError.reconnectIn'
                                        ),
                                        pattern: /^[a-zA-Z0-9_]+$/,
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Enter Reconnect In"
                                    maxLength={5}
                                />
                            </Form.Item>
                        </Col>
                    )}
                    {(authMethod?.toLowerCase() ===
                        AuthMethod.OAUTH__CLIENT_CREDENTIALS ||
                        authMethod?.toLowerCase() ===
                            AuthMethod.API_KEY_AUTHENTICATION ||
                        authMethod?.toLowerCase() ===
                            AuthMethod.JWT_TOKEN_AUTHENTIZATION) && (
                        <Col span={24}>
                            <Form.Item
                                name="customHeaders"
                                label={
                                    <div className="fw-400 fs-14">
                                        {t(
                                            'BusinessIntegrator.connections.connectionForm.customHeader'
                                        )}
                                    </div>
                                }
                                rules={[
                                    {
                                        message: t(
                                            'BusinessIntegrator.connections.validationError.customHeader'
                                        ),
                                    },
                                ]}
                            >
                                {' '}
                                <DynamicInputRows
                                    onChange={handleCustomHeadersChange}
                                />
                            </Form.Item>
                        </Col>
                    )}
                </Row>
                <Row>
                    {(authMethod?.toLowerCase() === AuthMethod.BASIC ||
                        authMethod?.toLowerCase() ===
                            AuthMethod.OAUTH_PASSWORD) && (
                        <Col span={24}>
                            <Form.Item
                                name="customHeaders"
                                label={
                                    <div className="fw-400 fs-14">
                                        {t(
                                            'BusinessIntegrator.connections.connectionForm.customHeader'
                                        )}
                                    </div>
                                }
                                rules={[
                                    {
                                        message: t(
                                            'BusinessIntegrator.connections.validationError.customHeader'
                                        ),
                                    },
                                ]}
                            >
                                {' '}
                                <DynamicInputRows
                                    onChange={handleCustomHeadersChange}
                                />
                            </Form.Item>
                        </Col>
                    )}
                </Row>

                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item
                            label={
                                <div className="fw-400 fs-14">
                                    {t(
                                        'BusinessIntegrator.connections.tableColumn.description'
                                    )}
                                </div>
                            }
                            name="description"
                        >
                            <TextArea maxLength={100} />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default AdapterDetailsForm;
