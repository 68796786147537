import {
    Card,
    Checkbox,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    notification,
    Row,
    Select,
    Tag,
} from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMappingById } from 'redux/actions/BusinessIntegratorActions/mappingActions';
import { connectionDirection, EMPTY } from 'types/enums';
import {
    MappingState,
    OperationFormFields,
    OperationFrequencyUnit,
    OperationState,
} from 'types/enums/businessIntegratorEnum';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { testConnectionValidation } from 'redux/actions/BusinessIntegratorActions/operationActions';
import { RESET_CONNECTION_VALIDATE } from 'redux/types/businessIntegratorTypes';

const AddOperationForm: React.FC<any> = ({ form }: any): any => {
    const { t } = useTranslation('translation');
    const { Option } = Select;
    const { TextArea } = Input;
    const dispatch = useDispatch();
    const operationState = useSelector(
        (state: any) => state.businessIntegrator.operation.operationState
    );
    const mappingById = useSelector(
        (state: any) => state.businessIntegrator.mapping.mappingById
    );
    const mappingTableList = useSelector(
        (state: any) => state.businessIntegrator.mapping.mappingTableList
    );
    const operationDetails = useSelector(
        (state: any) => state.businessIntegrator.operation.operationDetails
    );
    const mappingId = Form.useWatch(OperationFormFields.mappingId, form);
    const startTime = Form.useWatch(OperationFormFields.startTime, form);
    const lastAfter = Form.useWatch(OperationFormFields.lastAfter, form);
    const frequencyUnit = Form.useWatch(
        OperationFormFields.frequencyUnit,
        form
    );
    const label = Form.useWatch(OperationFormFields.label, form);

    const [inputValue, setInputValue] = useState<any>(EMPTY.string);
    const [tags, setTags] = useState<any>([]);
    const connectionValidated = useSelector(
        (state: any) => state.businessIntegrator.operation.connectionValidated
    );
    const daysList = useSelector(
        (state: any) => state.businessIntegrator.operation.daysList
    );
    useEffect(() => {
        const initialTags = operationDetails?.labelResponse?.map(
            (item: any) => item?.name
        );
        setTags(initialTags || []);
    }, [operationDetails]);

    useEffect(() => {
        operationState !== OperationState?.viewOperation ||
            operationState !== OperationState?.editOperation ||
            (operationState !== OperationState?.copyOperation &&
                form.resetFields([OperationFormFields.frequencyValue]));
    }, [frequencyUnit]);
    useEffect(() => {
        form.resetFields([OperationFormFields.occurrences]);
    }, [lastAfter]);
    useEffect(() => {
        connectionValidated === true &&
            notification.success({
                message: 'Success Code:200',
                description:
                    'The Sender adapter has been tested successfully and it is working fine.',
                duration: 3,
            });
    }, [connectionValidated]);
    const handleInputChange = (e: any): any => {
        setInputValue(e.target.value);
    };
    const getMinValue = (): any => {
        // Note- To get the minimum input number values for corresponding frequency values
        if (
            frequencyUnit === OperationFrequencyUnit.day ||
            frequencyUnit === OperationFrequencyUnit.month
        ) {
            return 1;
        } else {
            return 0;
        }
    };
    const getMaxValue = (): any => {
        // Note- To get the maximum input number values for corresponding frequency values
        switch (frequencyUnit) {
            case OperationFrequencyUnit.minute:
                return 59; // 0-59 range for minute
            case OperationFrequencyUnit.hour:
                return 23; // 0-23 range for hour
            case OperationFrequencyUnit.day:
                return 31; // 1-31 range for day
            case OperationFrequencyUnit.week:
                return 7; // 0-7 range for week
            case OperationFrequencyUnit.month:
                return 12; // 1-12 range for month
            default:
                return 59;
        }
    };
    const getplaceHolder = (): any => {
        // Note- To get the placeholder for corresponding frequency values
        switch (frequencyUnit) {
            case OperationFrequencyUnit.minute:
                return '0-59';
            case OperationFrequencyUnit.hour:
                return '0-23';
            case OperationFrequencyUnit.day:
                return '1-31';
            case OperationFrequencyUnit.week:
                return '0-7';
            case OperationFrequencyUnit.month:
                return '1-12';
            default:
                return '0-59';
        }
    };
    const handleInputConfirm = (e: any): any => {
        if (e.key === 'Enter' && inputValue) {
            setTags([...tags, inputValue]);
            setInputValue(EMPTY.string);
        }
    };

    const handleCloseTag = (removedTag: any): any => {
        setTags(tags?.filter((tag: any) => tag !== removedTag));
    };

    useEffect(() => {
        form.setFieldsValue({ label: tags });
    }, [tags]);
    useEffect(() => {
        (operationState === OperationState?.addOperation ||
            operationState === OperationState?.editOperation ||
            operationState === OperationState?.copyOperation) &&
            mappingId &&
            dispatch(getMappingById({ mappingId: mappingId })) &&
            dispatch({ type: RESET_CONNECTION_VALIDATE });
    }, [mappingId, operationState]);

    useEffect(() => {
        form.setFieldsValue({
            connectionName: mappingById?.connectionResponse?.name,
            adapterType: mappingById?.connectionResponse?.adapter?.name,
            direction: mappingById?.connectionResponse?.direction?.name,
        });
    }, [mappingById]);
    const direction = Form.useWatch('direction', form);

    return (
        <div className="adapterForm">
            <Row gutter={10}>
                <Col span={8}>
                    <Form.Item
                        label={
                            <div className="fw-400 fs-14">
                                {t(
                                    'BusinessIntegrator.operations.operationName'
                                )}
                            </div>
                        }
                        name="operationName"
                        rules={[
                            {
                                required: true,
                                message: t(
                                    'BusinessIntegrator.operations.operationNameValidation'
                                ),
                                pattern: /^[a-zA-Z0-9_]+$/,
                            },
                        ]}
                    >
                        <Input
                            placeholder={t(
                                'BusinessIntegrator.operations.enterOperationNamePlaceholder'
                            )}
                            disabled={
                                operationState ===
                                    OperationState.editOperation ||
                                operationState === OperationState.viewOperation
                            }
                            maxLength={100}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label={
                            <div className="fw-400 fs-14">
                                {t('BusinessIntegrator.operations.mappingName')}
                            </div>
                        }
                        name="mappingId"
                        rules={[
                            {
                                required: true,
                                message: t(
                                    'BusinessIntegrator.operations.mappingNameValidation'
                                ),
                            },
                        ]}
                    >
                        <Select
                            popupClassName="adapter"
                            placeholder={t(
                                'BusinessIntegrator.operations.selectMappingPlaceholder'
                            )}
                        >
                            {mappingTableList?.paginatedResponse?.records?.map(
                                (item: any) => {
                                    if (item?.isScheduled) {
                                        return (
                                            <Option
                                                key={item?.id}
                                                value={item?.id}
                                                className={'braboTableOption'}
                                            >
                                                {item?.mappingName}
                                            </Option>
                                        );
                                    }
                                }
                            )}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label={
                            <div className="fw-400 fs-14">
                                {t(
                                    'BusinessIntegrator.operations.connectionName'
                                )}
                            </div>
                        }
                        name="connectionName"
                    >
                        <Input
                            placeholder={t(
                                'BusinessIntegrator.operations.connectionTakenPlaceholder'
                            )}
                            disabled
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={10}>
                <Col span={8}>
                    <Form.Item
                        label={
                            <div className="fw-400 fs-14">
                                {t('BusinessIntegrator.operations.adapterType')}
                            </div>
                        }
                        name="adapterType"
                    >
                        <Input
                            placeholder={t(
                                'BusinessIntegrator.operations.adapterTypeTakenPlaceholder'
                            )}
                            disabled
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label={
                            <div className="fw-400 fs-14">
                                {t('BusinessIntegrator.operations.direction')}
                            </div>
                        }
                        name="direction"
                    >
                        <Input
                            placeholder={t(
                                'BusinessIntegrator.operations.directionTypeTakenPlaceholder'
                            )}
                            disabled
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label={
                            <div className="fw-400 fs-14">
                                {t(
                                    'BusinessIntegrator.operations.operationMethod'
                                )}
                            </div>
                        }
                        name="operationMethod"
                        rules={[
                            {
                                required: true,
                                message: t(
                                    'BusinessIntegrator.operations.operationMethodValidation'
                                ),
                            },
                        ]}
                    >
                        <Select
                            placeholder={t(
                                'BusinessIntegrator.operations.selectOperationMethodPlaceholder'
                            )}
                            options={
                                mappingById?.connectionResponse?.direction
                                    ?.name === connectionDirection.inbound
                                    ? [
                                          {
                                              value: 1,
                                              label: 'GET',
                                          },
                                      ]
                                    : [
                                          {
                                              value: 2,
                                              label: 'POST',
                                          },
                                      ]
                            }
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={10}>
                <Col span={8}>
                    <Form.Item
                        label={
                            <div className="fw-400 fs-14">
                                {t('BusinessIntegrator.operations.function')}
                            </div>
                        }
                        name="function"
                        rules={[
                            {
                                required: true,
                                message: t(
                                    'BusinessIntegrator.operations.functionValidation'
                                ),
                            },
                        ]}
                    >
                        <Select
                            placeholder={t(
                                'BusinessIntegrator.operations.selectFunctionPlaceholder'
                            )}
                            options={
                                mappingById?.connectionResponse?.direction
                                    ?.name === connectionDirection.inbound
                                    ? [
                                          {
                                              value: 1,
                                              label: 'Time Based',
                                          },
                                      ]
                                    : [
                                          {
                                              value: 2,
                                              label: 'On Demand',
                                          },
                                      ]
                            }
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label={
                            <div className="fw-400 fs-14">
                                {t('BusinessIntegrator.operations.label')}
                            </div>
                        }
                        name="label"
                        help={
                            label && typeof label === 'string' ? (
                                <div className="adapterConfirmPassord">
                                    {t(
                                        'BusinessIntegrator.operations.labelValidation'
                                    )}
                                </div>
                            ) : (
                                EMPTY.string
                            )
                        }
                    >
                        <div
                            className={`adapterLabelTag ${
                                operationState === OperationState.viewOperation
                                    ? 'disabled'
                                    : EMPTY.string
                            }`}
                        >
                            {tags?.map((tag: any, index: any) => (
                                <Tag
                                    key={`${tag}-${index}`}
                                    closable={
                                        operationState !==
                                        MappingState.viewConnections
                                    }
                                    onClose={() => handleCloseTag(tag)}
                                >
                                    {tag}
                                </Tag>
                            ))}
                            {operationState !==
                                MappingState.viewConnections && (
                                <Input
                                    value={inputValue}
                                    onChange={handleInputChange}
                                    onKeyDown={handleInputConfirm}
                                    style={{
                                        width: 'auto',
                                        flexGrow: 1,
                                        border: 'none',
                                        outline: 'none',
                                    }}
                                    className="tag-input"
                                    placeholder="Enter Label"
                                    maxLength={40}
                                />
                            )}
                        </div>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={10}>
                <Col span={16}>
                    <Form.Item
                        label={
                            <div className="fw-400 fs-14">
                                {t('BusinessIntegrator.operations.description')}
                            </div>
                        }
                        name="description"
                    >
                        <TextArea
                            placeholder={t(
                                'BusinessIntegrator.operations.enterDesciptionPlaceholder'
                            )}
                            maxLength={200}
                        />
                    </Form.Item>
                </Col>
            </Row>
            {direction === connectionDirection.inbound && (
                <>
                    <Row gutter={10} className="operationFormCardLabel">
                        <Col span={16}>
                            <div>{'Time Based'}</div>
                        </Col>
                        <Col span={8}>
                            {operationState !== OperationState?.viewOperation &&
                            mappingId &&
                            mappingById?.connectionResponse?.id ? (
                                <Tag
                                    className="addOperationTag"
                                    color="orange"
                                    onClick={() => {
                                        dispatch(
                                            testConnectionValidation({
                                                connectionId:
                                                    mappingById
                                                        ?.connectionResponse
                                                        ?.id,
                                            })
                                        );
                                    }}
                                >
                                    {t(
                                        'BusinessIntegrator.operations.recieveTestMsgNow'
                                    )}
                                </Tag>
                            ) : (
                                <Tag className="addOperationDisabledTag">
                                    {t(
                                        'BusinessIntegrator.operations.recieveTestMsgNow'
                                    )}
                                </Tag>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={10}>
                        <Card className="operationFormCard">
                            <Row gutter={10}>
                                <Col className="operationInputCol" span={12}>
                                    <Form.Item
                                        label={
                                            <div className="fw-400 fs-14">
                                                {t(
                                                    'BusinessIntegrator.operations.startDateTime'
                                                )}
                                            </div>
                                        }
                                        name="startTime"
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    'BusinessIntegrator.operations.startTimeValidation'
                                                ),
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            showTime
                                            minDate={dayjs()}
                                            className="datePicker"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className="operationInputCol" span={12}>
                                    <Form.Item
                                        label={
                                            <div className="fw-400 fs-14">
                                                {t(
                                                    'BusinessIntegrator.operations.dontRunAfterDateTime'
                                                )}
                                            </div>
                                        }
                                        name="endTime"
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    'BusinessIntegrator.operations.endTimeValidation'
                                                ),
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            showTime
                                            minDate={startTime ?? dayjs()}
                                            className="datePicker"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={10}>
                                <Col className="operationInputCol" span={12}>
                                    <Form.Item
                                        label={
                                            <div className="fw-400 fs-14">
                                                {t(
                                                    'BusinessIntegrator.operations.frequencyUnit'
                                                )}
                                            </div>
                                        }
                                        name="frequencyUnit"
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    'BusinessIntegrator.operations.frequencyUnitValidation'
                                                ),
                                            },
                                        ]}
                                    >
                                        <Select
                                            placeholder={t(
                                                'BusinessIntegrator.operations.selectFrequencyUnitPlaceholder'
                                            )}
                                            options={[
                                                {
                                                    value: 1,
                                                    label: t(
                                                        'BusinessIntegrator.operations.minute'
                                                    ),
                                                },
                                                {
                                                    value: 2,
                                                    label: t(
                                                        'BusinessIntegrator.operations.hours'
                                                    ),
                                                },
                                                {
                                                    value: 3,
                                                    label: t(
                                                        'BusinessIntegrator.operations.day'
                                                    ),
                                                },
                                                {
                                                    value: 4,
                                                    label: t(
                                                        'BusinessIntegrator.operations.week'
                                                    ),
                                                },
                                                {
                                                    value: 5,
                                                    label: t(
                                                        'BusinessIntegrator.operations.month'
                                                    ),
                                                },
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className="operationInputCol" span={12}>
                                    <Form.Item
                                        label={
                                            <div className="fw-400 fs-14">
                                                {t(
                                                    'BusinessIntegrator.operations.frequencyValue'
                                                )}
                                            </div>
                                        }
                                        name="frequencyValue"
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    'BusinessIntegrator.operations.frequencyValueValidation'
                                                ),
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            min={getMinValue()}
                                            max={getMaxValue()}
                                            placeholder={getplaceHolder()}
                                            className="operationFrequencyValue"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={10}>
                                <Col className="operationInputCol" span={24}>
                                    <Form.Item name="dayValue">
                                        <Checkbox.Group
                                            className={
                                                operationState ===
                                                OperationState.viewOperation
                                                    ? 'disabledCheckbox'
                                                    : ''
                                            }
                                            options={daysList?.map(
                                                (item: any) => {
                                                    return {
                                                        value: item?.id,
                                                        label: item?.name,
                                                    };
                                                }
                                            )}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={10}>
                                <Col className="operationCheckboxCol" span={3}>
                                    <Form.Item name="lastAfter">
                                        <Checkbox.Group
                                            className={
                                                operationState ===
                                                OperationState.viewOperation
                                                    ? 'disabledCheckbox'
                                                    : ''
                                            }
                                            options={[
                                                {
                                                    label: 'last After: ',
                                                    value: 1,
                                                },
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className="operationLastCol" span={4}>
                                    <Form.Item
                                        name="occurrences"
                                        rules={[
                                            {
                                                required: lastAfter?.length > 0,
                                                message: '',
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            controls={false}
                                            min={1}
                                            max={100}
                                            disabled={!(lastAfter?.length > 0)}
                                            className="occurencesInput"
                                        />
                                    </Form.Item>
                                    <div className="labelCol">
                                        {t(
                                            'BusinessIntegrator.operations.occurences'
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Row>
                </>
            )}
        </div>
    );
};

export default AddOperationForm;
