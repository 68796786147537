import * as types from 'redux/types/formTypes';
import initialState from '../initialStates';
import { GET_TABLE_DATA_SUCCESS } from 'redux/types/configureTypes';

export default function formReducer(
    state = initialState.formio,
    action: any
): any {
    switch (action.type) {
        case types.GET_FORM_LIST:
            state = {
                ...state,
                formsList: [],
                formsLoading: true,
            };
            return state;
        case types.GET_FORM_LIST_SUCCESS:
            state = {
                ...state,
                formsList: action?.response,
                formsLoading: false,
            };
            return state;
        case types.GET_FORM_LIST_FAILURE:
            state = {
                ...state,
                formsList: [],
                formsLoading: false,
            };
            return state;
        case types.GET_FORM_DETAILS:
            state = {
                ...state,
                formsDetails: {},
                formsDetailsLoading: true,
            };
            return state;
        case types.GET_FORM_DETAILS_SUCCESS:
            state = {
                ...state,
                formsDetails: action?.response,
                formsDetailsLoading: false,
            };
            return state;
        case types.GET_FORM_DETAILS_FAILURE:
            state = {
                ...state,
                formsDetails: {},
                formsDetailsLoading: false,
            };
            return state;
        case types.SET_FORM_DATA:
            state = {
                ...state,
                formSubmit: false,
            };
            return state;
        case types.SET_FORM_DATA_SUCCESS:
            state = {
                ...state,
                formSubmit: true,
            };
            return state;
        case types.SET_FORM_DATA_FAILURE:
            state = {
                ...state,
                formSubmit: false,
            };
            return state;
        case types.SET_FORM_DATA_TABLE:
            state = {
                ...state,
                formDataInTable: false,
            };
            return state;
        case types.SET_FORM_DATA_TABLE_SUCCESS:
            state = {
                ...state,
                formDataInTable: true,
            };
            return state;
        case types.SET_FORM_DATA_TABLE_FAILURE:
            state = {
                ...state,
                formDataInTable: false,
            };
            return state;
        case types.UPDATE_FORM_DATA_TABLE:
            state = {
                ...state,
                formDataInTable: false,
            };
            return state;
        case types.UPDATE_FORM_DATA_TABLE_SUCCESS:
            state = {
                ...state,
                formDataInTable: true,
            };
            return state;
        case types.UPDATE_FORM_DATA_TABLE_FAILURE:
            state = {
                ...state,
                formDataInTable: false,
            };
            return state;
        case GET_TABLE_DATA_SUCCESS:
            state = {
                ...state,
                formDataInTable: false,
            };
            return state;

        case types.GET_ACTION_LIST:
            state = {
                ...state,
                actionsList: [],
                actionsLoading: true,
            };
            return state;
        case types.GET_ACTION_LIST_SUCCESS:
            state = {
                ...state,
                actionsList: action?.response,
                actionsLoading: false,
            };
            return state;
        case types.GET_ACTION_LIST_FAILURE:
            state = {
                ...state,
                actionsList: [],
                actionsLoading: false,
            };
            return state;
        default:
            return state;
    }
}
