import { Form } from '@formio/react';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { message, Spin } from 'antd';
import { setFormDataInTable } from 'redux/actions/FormActions';
import { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const DynamicForm = ({
    setOpenModalForm,
    setOpenEditForm,
    dataSource,
}: any): any => {
    const formDetails = useSelector(
        (state: any) => state?.formio?.formsDetails
    );
    const actionslist = useSelector(
        (state: any) => state?.formio?.actionsList?.actions
    );
    const formsDetailsLoading = useSelector(
        (state: any) => state?.formio?.formsDetailsLoading
    );
    const formDataInTable = useSelector(
        (state: any) => state?.formio?.formDataInTable
    );

    // const formSubmit = useSelector((state: any) => state?.formio?.formSubmit);

    // Use key to force form reset
    const [formKey, setFormKey] = useState<number>(0);
    const dispatch = useDispatch();

    // Helper to execute webhook action
    const executeWebhook = async (
        action: any,
        submission: any
    ): Promise<void> => {
        try {
            const response = await fetch(action.settings.url, {
                method: 'POST',
                headers: {
                    authorization: `Bearer ${
                        cookies.get('authToken') ??
                        sessionStorage.getItem('authToken')
                    }`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(submission.data),
            });

            if (!response.ok) {
                throw new Error(
                    `Webhook failed with status ${response.status}`
                );
            }

            const result = await response.json();
            console.log('Webhook executed successfully:', result);
        } catch (error) {
            console.error('Error executing webhook:', error);
        }
    };

    // Handle form submission and associated actions
    const handleSubmit = async (submission: any): Promise<void> => {
        // Transform data for custom submission
        const transformedData = Object.entries(submission.data)
            .filter(([key]) => key !== 'submit' && key !== 'cancel')
            .map(([key, value]: any) => ({
                columnName: key,
                value: key === 'columnsSelect' ? value?.name : value,
            }));

        const payload = {
            tableName: formDetails?.components?.[0].defaultValue,
            rowModifyRequests: [
                {
                    rowId: null,
                    columnDataModifyRequests: transformedData,
                },
            ],
            dataSource: dataSource,
        };

        // Dispatch custom action
        dispatch(setFormDataInTable(payload));

        // Execute other actions from actionsList
        actionslist?.forEach((action: any) => {
            if (action.name === 'webhook' && action.handler.includes('after')) {
                // Execute webhook
                executeWebhook(action, submission);
            }
            // Handle other custom actions if needed
            if (action.name === 'save' && action.handler.includes('before')) {
                console.log(
                    'Save action detected. Custom logic can be implemented.'
                );
            }
        });
    };

    useEffect(() => {
        if (formDataInTable) {
            setFormKey((prev) => prev + 1);
            message.success('Form submitted successfully');
            if (setOpenModalForm) {
                setOpenModalForm(false);
            }
            if (setOpenEditForm) {
                setOpenEditForm(false);
            }
        }
    }, [formDataInTable]);

    const formConfig = {
        ...formDetails,
        components: formDetails?.components,
        settings: {
            ...formDetails?.settings,
            noAlerts: true,
        },
    };

    return (
        <div className="custom-form-container">
            <div className="custom-form">
                <div>
                    {formsDetailsLoading ? (
                        <div className="view__loader">
                            <Spin />
                        </div>
                    ) : (
                        <Form
                            key={`form-${formKey}-${formDataInTable}`}
                            form={formConfig}
                            onSubmit={handleSubmit}
                            options={{
                                readOnly: false,
                                noAlerts: true,
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default DynamicForm;
