import { Drawer } from 'antd';
import DynamicForm from 'pages/FormIo/DynamicForm';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFormDetails } from 'redux/actions/FormActions';
import './index.scss';
import EditDynamicForm from 'pages/FormIo/EditDynamicForm';
const AddRowForm: React.FC<any> = ({
    open,
    setOpenModalForm,
    openEditForm,
    rowId,
    setOpenEditForm,
    defaultValues,
    setRowId,
    onClose,
}) => {
    const tableDetails = useSelector(
        (state: any) => state.configure?.table?.tableDetails
    );
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getFormDetails(tableDetails?.formId));
    }, [tableDetails]);

    return (
        <>
            <Drawer
                title={open ? 'Add Row Details' : 'Edit Row Details'}
                width="550px"
                open={open || openEditForm}
                onClose={onClose}
            >
                {openEditForm ? (
                    <EditDynamicForm
                        rowId={rowId}
                        setOpenModalForm={setOpenModalForm}
                        setOpenEditForm={setOpenEditForm}
                        defaultValues={defaultValues}
                        setRowId={setRowId}
                    />
                ) : (
                    <DynamicForm
                        setOpenModalForm={setOpenModalForm}
                        setOpenEditForm={setOpenEditForm}
                        dataSource={'MANUAL'}
                    />
                )}
            </Drawer>
        </>
    );
};

export default AddRowForm;
