import React, { useEffect } from 'react';
import { Collapse, Switch, Input, Select, Drawer } from 'antd';
import './index.scss';

import { useDispatch, useSelector } from 'react-redux';
import {
    getAlertList,
    getTimeCapsuleList,
} from 'redux/actions/DataExplorer/DataVisualizationActions';

const { Panel } = Collapse;
const { Option } = Select;

const ChartProperties = ({
    handleAlertChange,
    handleTimeCapsuleChange,
    visible,
    onClose,
    selectedTrendFunctions,
    handleToggleTrendFunction,
    trendsFunction,
    isMovingAverageEnabled,
    averageValue,
    setIsMovingAverageEnabled,
    setAverageValue,
    selectedModel,
}: any): any => {
    const dispatch = useDispatch();

    const alertList = useSelector(
        (state: any) => state?.dataVisualization?.alertList
    );

    const timeCapsuleList = useSelector(
        (state: any) => state?.dataVisualization?.timeCapsuleList
    );

    useEffect(() => {
        if (visible) {
            dispatch(getAlertList({ assetId: selectedModel }));
            dispatch(getTimeCapsuleList());
        }
    }, [visible]);

    const handleMovingAverageToggle = (checked: boolean): any => {
        setIsMovingAverageEnabled(checked);
        if (!checked) {
            setAverageValue(undefined); // Reset the value if the switch is turned off
        }
        handleToggleTrendFunction('MOVINGAVERAGE');
    };

    return (
        <Drawer
            title="Chart Properties"
            className="chartProperties"
            placement="right"
            onClose={onClose}
            open={visible}
            width={350} // Adjusted width to fit design
        >
            <Collapse defaultActiveKey={['1']} className="custom-collapse">
                <Panel
                    header="Functions"
                    key="1"
                    className="custom-panel-header"
                >
                    {/* Div for Moving Average */}
                    <div className="moving-average-wrapper">
                        {trendsFunction?.map((key: any) => {
                            const isMovingAverage = key === 'MOVINGAVERAGE';
                            return isMovingAverage ? (
                                <div
                                    key={key}
                                    className="trend-function-switch"
                                >
                                    <Switch
                                        checked={isMovingAverageEnabled}
                                        onChange={handleMovingAverageToggle}
                                        className="custom-switch"
                                    />
                                    <span>{key}</span>
                                    <Input
                                        value={averageValue}
                                        onChange={(e) => {
                                            const newValue = Number(
                                                e.target.value
                                            );
                                            setAverageValue(newValue);
                                        }}
                                        className="custom-input"
                                        style={{ width: 60, marginLeft: 10 }}
                                    />
                                </div>
                            ) : null;
                        })}
                    </div>

                    {/* Div for other statistical functions */}
                    <div className="statistical-functions-wrapper">
                        {trendsFunction?.map((key: any) => {
                            const isStatisticalFunction =
                                key === 'MEAN' ||
                                key === 'MEDIAN' ||
                                key === 'MODE' ||
                                key === 'STD';

                            return isStatisticalFunction ? (
                                <div
                                    key={key}
                                    className="trend-function-switch"
                                >
                                    <Switch
                                        checked={selectedTrendFunctions.includes(
                                            key
                                        )}
                                        onChange={(checked: boolean) => {
                                            // Update the selected trend functions based on toggle
                                            handleToggleTrendFunction(key, checked); 
                                        }}
                                        className="custom-switch"
                                    />
                                    <span>{key}</span>
                                </div>
                            ) : null;
                        })}
                    </div>
                </Panel>

                <Panel
                    header="Alerts / Time Capsule"
                    key="2"
                    className="custom-panel-header"
                >
                    <div style={{ marginBottom: 10 }}>
                        <label>Select Alert</label>
                        <Select
                            placeholder="Select an Alert"
                            style={{ width: '100%' }}
                            onChange={handleAlertChange}
                            className="custom-select"
                        >
                            {(alertList?.pageResponse?.records ?? []).map(
                                (alert: any) => (
                                    <Option key={alert.id} value={alert.id}>
                                        {alert.name}
                                    </Option>
                                )
                            )}
                        </Select>
                    </div>

                    <div>
                        <label>Select Time Capsule</label>
                        <Select
                            placeholder="Select a Time Capsule"
                            style={{ width: '100%' }}
                            onChange={handleTimeCapsuleChange}
                            className="custom-select"
                        >
                            {(timeCapsuleList?.pageResponse?.records ?? []).map(
                                (capsule: any) => (
                                    <Option key={capsule.id} value={capsule.id}>
                                        {capsule.name}
                                    </Option>
                                )
                            )}
                        </Select>
                    </div>
                </Panel>
            </Collapse>
        </Drawer>
    );
};

export default ChartProperties;
