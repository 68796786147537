import React, { useState, useEffect } from 'react';
import { Input, Button, Row, Col, message } from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

interface CustomHeader {
    headerKey: string;
    headerValue: string;
}

interface DynamicInputRowsProps {
    onChange: (headers: Record<string, string>) => void;
}

const DynamicInputRows: React.FC<DynamicInputRowsProps> = ({ onChange }) => {
    const connectionById = useSelector(
        (state: any) => state.businessIntegrator.connections.connectionById
    );

    const [rows, setRows] = useState<Array<{ key: string; value: string }>>(
        connectionById?.customHeaders?.map((header: CustomHeader) => ({
            key: header.headerKey || '',
            value: header.headerValue || '',
        })) || [{ key: '', value: '' }]
    );

    useEffect(() => {
        if (connectionById?.customHeaders) {
            setRows(
                connectionById.customHeaders.map((header: CustomHeader) => ({
                    key: header.headerKey || '',
                    value: header.headerValue || '',
                }))
            );
        }
    }, [connectionById?.customHeaders]);

    const handleAddRow = (): void => {
        setRows([...rows, { key: '', value: '' }]);
    };

    const handleRemoveRow = (index: number): void => {
        const updatedRows = rows.filter((_, i) => i !== index);
        setRows(updatedRows);
    };

    const handleInputChange = (
        index: number,
        field: 'key' | 'value',
        value: string
    ): void => {
        const updatedRows = [...rows];
        const existingKeys = new Set(rows.map((row) => row.key));

        if (field === 'key') {
            if (existingKeys.has(value) && value !== rows[index].key) {
                message.error('Key must be unique');
                return; // Prevent duplicate key entry
            }
        }

        updatedRows[index][field] = value;
        setRows(updatedRows);
    };
    useEffect(() => {
        const headersObject = rows.reduce<Record<string, string>>(
            (acc, row) => {
                if (row.key) acc[row.key] = row.value;
                return acc;
            },
            {}
        );
        onChange(headersObject);
    }, [rows]);

    return (
        <div>
            {rows.map((row, index) => (
                <Row key={index} gutter={16} style={{ marginBottom: 8 }}>
                    <Col span={11}>
                        <Input
                            placeholder="Key"
                            value={row.key}
                            onChange={(e) => {
                                handleInputChange(index, 'key', e.target.value);
                            }}
                        />
                    </Col>
                    <Col span={11}>
                        <Input
                            placeholder="Value"
                            value={row.value}
                            onChange={(e) => {
                                handleInputChange(
                                    index,
                                    'value',
                                    e.target.value
                                );
                            }}
                        />
                    </Col>
                    <Col span={2}>
                        <Button
                            icon={<CloseOutlined />}
                            type="text"
                            onClick={() => {
                                handleRemoveRow(index);
                            }}
                        />
                    </Col>
                </Row>
            ))}
            <Button
                icon={<PlusOutlined />}
                type="dashed"
                style={{ width: '100%' }}
                onClick={handleAddRow}
            >
                Add Row
            </Button>
        </div>
    );
};

export default DynamicInputRows;
