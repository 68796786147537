import React, { useState } from 'react';
import { Modal, Button, Form, Input, Select, Divider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAggregatedChartData,
    getKpiTrends,
    getScatterAggregrateData,
} from 'redux/actions/DataExplorer/DataVisualizationActions';
import { EMPTY, tagOriginId } from 'types/enums';
import utf8 from 'utf8';
import base64 from 'base-64';
import './index.scss';
import { ChartTypes } from 'types/enums/dataVisualizationEnum';

const { Option } = Select;

const DataFilterModal = ({
    isModalVisible,
    setIsModalVisible,
    record,
    setTagUUID,
    selectedTagRows,
    frequencyState,
    selectedKpiRows,
    setKpiUUID,
    kpiUUID,
    setKpiId,
    kpiID,
    chartType, 
}: any): any => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [filter, setFilter] = useState('LESS_THAN');
    const [inputValues, setInputValues] = useState({ first: '', second: '' });

    const handleCancel = (): any => {
        setIsModalVisible(false);
    };

    const dateRange = useSelector(
        (state: any) => state.nocilDashboard.filterValues
    );
    const timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

    const handleApply = (): void => {
        let tagUUIDString: string = EMPTY?.string;
        let urlEncodedStr: string = EMPTY?.string;
        
        // Process KPI Rows
        if (selectedKpiRows?.length) {
            let kpiIdString: string = EMPTY?.string;
            selectedKpiRows.forEach((selectedKpiValues: any) => {
                kpiIdString = kpiIdString === EMPTY?.string
                    ? String(selectedKpiValues?.id)
                    : `${kpiIdString},${String(selectedKpiValues?.id)}`;
            });
            setKpiId(kpiIdString);
    
            const transformedArray = selectedKpiRows.map((kpi: any) => ({
                assetId: kpi.assetId,
                kpiId: kpi.id,
            }));
            
            const jsonString = JSON.stringify(transformedArray);
            const bytes = utf8.encode(jsonString);
            let base64EncodedStr = base64.encode(bytes);
    
            // Remove trailing '=' characters and URL-encode the string
            base64EncodedStr = base64EncodedStr.replace(/=+$/, '');
            urlEncodedStr = encodeURIComponent(base64EncodedStr);
            setKpiUUID(urlEncodedStr);
        }
    
        // Process Tag Rows
        if (selectedTagRows?.length) {
            selectedTagRows.forEach((selectedTagValues: any) => {
                tagUUIDString = tagUUIDString === EMPTY?.string
                    ? String(selectedTagValues?.uuid)
                    : `${tagUUIDString},${String(selectedTagValues?.uuid)}`;
            });
            setTagUUID(tagUUIDString);
        }
    
        // Check for Scatter Chart Data Filter Condition
        if (chartType === ChartTypes.SCATTER) {
            dispatch(
                getScatterAggregrateData({
                    startTime: new Date(dateRange?.startTime)?.getTime(),
                    endTime: new Date(dateRange?.endTime)?.getTime(),
                    tagUuidList: tagUUIDString,
                    tagOriginId: tagOriginId?.PLC,
                    timezone,
                    frequency: frequencyState ?? 0,
                    list: urlEncodedStr,
                    // trendFunctions: 'MEAN,MEDIAN,MODE,STD',
                    filter, // Add filter parameter
                    filterValues: `${inputValues?.first}${
                        filter === 'IN_BETWEEN' || filter === 'OUT_BETWEEN'
                            ? `,${inputValues?.second}`
                            : ''
                    }`, // Pass filter values
                })
            );
        } else {
            // Default logic for non-scatter charts (if required)
            if (selectedTagRows?.length) {
                dispatch(
                    getAggregatedChartData({
                        startTime: new Date(dateRange?.startTime)?.getTime(),
                        endTime: new Date(dateRange?.endTime)?.getTime(),
                        tagUuidList: tagUUIDString,
                        tagOriginId: tagOriginId?.PLC,
                        timezone,
                        frequency: frequencyState ?? 0,
                        trendFunctions: 'MEAN,MEDIAN,MODE,STD',
                        filter, // Add filter parameter
                        filterValues: `${inputValues?.first}${
                            filter === 'IN_BETWEEN' || filter === 'OUT_BETWEEN'
                                ? `,${inputValues?.second}`
                                : ''
                        }`, // Pass filter values
                    })
                );
            }
    
            if (selectedKpiRows?.length) {
                dispatch(
                    getKpiTrends({
                        startTime: new Date(dateRange?.startTime)?.getTime(),
                        endTime: new Date(dateRange?.endTime)?.getTime(),
                        list: urlEncodedStr,
                        frequency: frequencyState ?? 0,
                        trendFunctions: 'MEAN,MEDIAN,MODE,STD',
                        filter, // Add filter parameter
                        filterValues: `${inputValues?.first}${
                            filter === 'IN_BETWEEN' || filter === 'OUT_BETWEEN'
                                ? `,${inputValues?.second}`
                                : ''
                        }`, // Pass filter values
                    })
                );
            }
        }
    
        // Close modal after processing
        setIsModalVisible(false);
    };
    

    
    
    const renderInputs = (): any => {
        return (
            <div className="input-container">
                {filter === 'IN_BETWEEN' || filter === 'OUT_BETWEEN' ? (
                    <>
                        <Input
                            placeholder="Enter First Value"
                            value={inputValues.first}
                            onChange={(e) => {
                                setInputValues({
                                    ...inputValues,
                                    first: e.target.value,
                                });
                            }}
                        />
                        <Input
                            placeholder="Enter Second Value"
                            value={inputValues.second}
                            onChange={(e) => {
                                setInputValues({
                                    ...inputValues,
                                    second: e.target.value,
                                });
                            }}
                        />
                    </>
                ) : (
                    <Input
                        placeholder="Enter Value"
                        value={inputValues.first}
                        onChange={(e) => {
                            setInputValues({
                                ...inputValues,
                                first: e.target.value,
                            });
                        }}
                    />
                )}
            </div>
        );
    };

    return (
        <>
            <Modal
                title="Data Filter"
                className="DataFilter"
                open={isModalVisible}
                onCancel={handleCancel}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleApply}>
                        Apply
                    </Button>,
                ]}
            >
                <Form form={form} layout="vertical" onFinish={handleApply}>
                    <Form.Item>
                        <span>Selected Data: {record?.tagName}</span>
                    </Form.Item>
                    <div className="inputList">
                        <Form.Item>
                            <Select
                                defaultValue="LESS_THAN"
                                onChange={setFilter}
                            >
                                <Option value="IN_BETWEEN">In Between</Option>
                                <Option value="LESS_THAN">Less Than</Option>
                                <Option value="GREATER_THAN">
                                    Greater Than
                                </Option>
                                <Option value="OUT_BETWEEN">
                                    Outer Between
                                </Option>
                                <Option value="EQUALS">Equals</Option>
                                <Option value="NOT_EQUALS">Not Equals</Option>
                                <Option value="LESS_THAN_EQUALS">
                                    Less Than Equals
                                </Option>
                                <Option value="GREATER_THAN_EQUALS">
                                    Greater Than Equals
                                </Option>
                            </Select>
                        </Form.Item>

                        <Form.Item>{renderInputs()}</Form.Item>
                    </div>
                    <Divider />
                </Form>
            </Modal>
        </>
    );
};

export default DataFilterModal;
