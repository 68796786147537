import React, { useEffect, useState } from 'react';
import { Card, Col, Divider, Form, Input, Radio, Row, Select } from 'antd';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
    ALERTTYPE,
    EMPTY,
    alertCondition,
    alertVariables,
    operatorId,
    operatorName,
} from 'types/enums';
import { getAlertOperatorById } from 'redux/actions/AlertActions/alertActions';
import { maxInputNumberLength } from 'utils/constants';
import { useTranslation } from 'react-i18next';

const AlertStepTwo: React.FC<any> = ({
    conditions,
    setConditions,
    index,
    submittable,
    setSubmittable,
    conditionList,
    deleted,
    setDeleted,
    selectedAsset,
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('translation');
    const [form] = Form.useForm();
    const [variableList, setVariableList] = useState([]);
    const [variableType, setVariableType] = useState<null | Number>(null);
    const [subVariableListOne, setSubVariableListOne] = useState([]);
    const [subVariableOne, setSubVariableOne] = useState<null | Number>(null);
    const [subVariableListTwo, setSubVariableListTwo] = useState([]);
    const [subVariableTwo, setSubVariableTwo] = useState<null | Number>(null);
    const [operator, setOperator] = useState<any>({});
    const [operatorType, setOperatorType] = useState<any>({});
    const kpiList = useSelector((state: any) => state?.alert?.kpiListByAsset);
    const attributeList = useSelector(
        (state: any) =>
            state?.implementation?.attribute?.assignedAttributeList
                ?.pageResponse?.records
    );
    const tagList = useSelector(
        (state: any) => state?.implementation?.attribute?.tagsList
    );
    const operatorsList = useSelector(
        (state: any) => state?.alert?.operatorsList
    );
    const operatorsListById = useSelector(
        (state: any) => state?.alert?.operatorsListById
    );
    const conditionObj = conditions[index];
    const values = Form.useWatch([], form);
    const initialValues = {
        variableType: conditions[index]?.variableTypeId,
        variable: conditions[index]?.variableId,
        operator: conditions[index]?.conditionId,
        operatorType: conditions[index]?.conditionTypeId,
        variableTypeOne: conditions[index]?.valueList[0]?.variableTypeId,
        variableOne: conditions[index]?.valueList[0]?.variableId,
        variableTypeTwo: conditions[index]?.valueList[1]?.variableTypeId,
        variableTwo: conditions[index]?.valueList[1]?.variableId,
        valueOne: conditions[index]?.valueList[0]?.values,
        valueTwo: conditions[index]?.valueList[0]?.values,
        valueThree: conditions[index]?.valueList[0]?.values,
        lowerValue: conditions[index]?.valueList[0]?.values,
        upperValue: conditions[index]?.valueList[1]?.values,
        operandId: conditions[index]?.operandId,
    };
    useEffect(() => {
        form.resetFields();
        const operatorObj =
            operatorsList?.length &&
            operatorsList?.find(
                (item: any) => item?.id === conditions[index].conditionId
            );
        operatorObj &&
            setOperator({
                label: operatorObj?.name,
                value: operatorObj?.id,
            });
    }, [deleted]);

    useEffect(() => {
        const operatorObj =
            operatorsList?.length &&
            operatorsList?.find(
                (item: any) => item?.id === conditions[index].conditionId
            );
        const operatorTypeObj =
            operatorsListById[index]?.length &&
            operatorsListById[index]?.find(
                (item: any) => item?.id === conditions[index].conditionTypeId
            );
        setVariableType(conditions[index]?.variableTypeId);
        setSubVariableOne(conditions[index]?.valueList[0]?.variableTypeId);
        setSubVariableTwo(conditions[index]?.valueList[1]?.variableTypeId);
        !Object.keys(operator)?.length &&
            operatorObj &&
            setOperator({
                label: operatorObj?.name,
                value: operatorObj?.id,
            });
        operatorTypeObj &&
            setOperatorType({
                label: operatorTypeObj?.name,
                value: operatorTypeObj?.id,
            });
    }, [conditions[index], operatorsList, operatorsListById[index]]);

    useEffect(() => {
        const submittableArr = [...submittable];
        form.validateFields()
            .then(() => {
                submittableArr[index] = true;
            })
            .catch(() => {
                submittableArr[index] = false;
            });
        index && submittableArr[index - 1] && setSubmittable(submittableArr);
        !index && setSubmittable(submittableArr);
    }, [form, values, submittable[index - 1], conditions.length]);
    useEffect(() => {
        if (
            !conditionList?.length ||
            conditionList?.length < conditions?.length
        ) {
            operator?.value &&
                dispatch(
                    getAlertOperatorById({ index: index, id: operator?.value })
                );
        } else {
            if (index) {
                operator?.value &&
                    operatorsListById[index - 1]?.length &&
                    dispatch(
                        getAlertOperatorById({
                            index: index,
                            id: operator?.value,
                        })
                    );
            } else {
                operator?.value &&
                    dispatch(
                        getAlertOperatorById({
                            index: index,
                            id: operator?.value,
                        })
                    );
            }
        }
    }, [operator, operatorsListById[index - 1]]);

    useEffect(() => {
        variableListUpdate(variableType, setVariableList);
    }, [variableType, kpiList, attributeList, tagList]);
    useEffect(() => {
        variableListUpdate(subVariableOne, setSubVariableListOne);
    }, [subVariableOne, kpiList, attributeList, tagList]);
    useEffect(() => {
        variableListUpdate(subVariableTwo, setSubVariableListTwo);
    }, [subVariableTwo, kpiList, attributeList, tagList]);

    const alertState = useSelector((state: any) => state?.alert?.alertState);
    const items = [
        {
            label: t('alerts.tags'),
            value: alertVariables.Tags,
        },
        {
            label: t('alerts.kpis'),
            value: alertVariables.KPIs,
        },
    ];
    const handleChange = (value: any): any => {
        setVariableType(value);
        const conditionArray = [...conditions];
        conditionArray[index] = {
            ...conditionArray[index],
            variableTypeId: value,
            variableId: null,
        };
        setConditions(conditionArray);
        form.setFieldsValue({
            variable: null,
        });
    };
    const handleVariableOne = (value: any): any => {
        setSubVariableOne(value);
        const conditionArray = [...conditions];
        const valueListArray = [...conditionObj?.valueList];
        valueListArray.splice(0, 1, {
            ...conditionObj?.valueList[0],
            variableTypeId: value,
            variableId: null,
        });
        conditionArray[index] = {
            ...conditionArray[index],
            valueList: [...valueListArray],
        };
        setConditions(conditionArray);
        form.setFieldsValue({
            variableOne: null,
        });
    };
    const handleVariableTwo = (value: any): any => {
        setSubVariableTwo(value);
        const conditionArray = [...conditions];
        const valueListArray = [...conditionObj?.valueList];
        valueListArray.splice(1, 1, {
            ...conditionObj?.valueList[1],
            variableTypeId: value,
            variableId: null,
        });
        conditionArray[index] = {
            ...conditionArray[index],
            valueList: [...valueListArray],
        };
        setConditions(conditionArray);
        form.setFieldsValue({
            variableTwo: null,
        });
    };

    const handleInputChange = (val: any, id: any): any => {
        const conditionArray = [...conditions];
        const valueListArray = [...conditionObj?.valueList];
        valueListArray.splice(id, 1, {
            ...conditionObj?.valueList[id],
            values: val.split('%')[0],
        }); // Note- splice the string with % to get the number
        conditionArray[index] = {
            ...conditionArray[index],
            isAbsolute: !val.includes('%'),
            valueList: [...valueListArray],
        };
        setConditions(conditionArray);
    };

    const variableListUpdate = (value: any, setVariable: any): any => {
        value === alertVariables.Tags &&
            setVariable(
                tagList?.content?.map((item: any) => {
                    return {
                        label: item?.tagName,
                        value: item?.uuid,
                    };
                })
            );
        value === alertVariables.KPIs &&
            setVariable(
                kpiList?.records?.map((item: any) => {
                    return {
                        label: item?.name,
                        value: item?.id,
                    };
                })
            );
        !value && setVariable([]);
    };

    return (
        <>
            <div className="createAlertWrapper__clearTextDiv">
                {index !== 0 && alertState !== ALERTTYPE?.view && (
                    <>
                        <div
                            className="deleteText"
                            onClick={() => {
                                const conditionArray = [...conditions];
                                conditionArray.splice(index, 1);
                                setConditions(conditionArray);
                                const submittableArr = [...submittable];
                                submittableArr.splice(index, 1);
                                setSubmittable(submittableArr);
                                setDeleted(!deleted);
                            }}
                        >
                            {t('alerts.delete')}
                        </div>
                        <Divider type="vertical"></Divider>
                    </>
                )}
                {alertState !== ALERTTYPE?.view && (
                    <div
                        className="clearText"
                        onClick={() => {
                            const conditionArray = [...conditions];
                            conditionArray[index] = {
                                valueList: [],
                                isAbsolute: false,
                            };
                            setConditions(conditionArray);
                            setVariableType(null);
                            setOperator({});
                            setOperatorType({});
                            setDeleted(!deleted);
                        }}
                    >
                        {t('alerts.clear')}
                    </div>
                )}
            </div>
            <Form form={form} initialValues={initialValues}>
                <Card className="createAlertWrapper__innerCard">
                    <Row className="createAlertWrapper__stepTwoInfo">
                        <Col span={5}>
                            <Form.Item
                                className="createAlertWrapper__selectDropdown"
                                name="variableType"
                                rules={[
                                    { required: true, message: EMPTY.string },
                                ]}
                            >
                                <Select
                                    disabled={
                                        alertState === ALERTTYPE?.view ||
                                        !selectedAsset
                                    }
                                    placeholder={t('alerts.selectVarType')}
                                    options={items}
                                    value={variableType}
                                    defaultValue={
                                        conditions[index]?.variableTypeId
                                    }
                                    onChange={handleChange}
                                    getPopupContainer={(triggerNode) =>
                                        triggerNode?.parentElement
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item
                                className="createAlertWrapper__selectDropdown"
                                name="variable"
                                rules={[
                                    { required: true, message: EMPTY.string },
                                ]}
                            >
                                <Select
                                    disabled={
                                        alertState === ALERTTYPE?.view ||
                                        !selectedAsset
                                    }
                                    placeholder={t('alerts.selectVariable')}
                                    options={variableList}
                                    value={conditionObj?.variableId}
                                    defaultValue={conditions[index].variableId}
                                    onChange={(value: any) => {
                                        const conditionArray = [...conditions];
                                        conditionArray[index] = {
                                            ...conditionArray[index],
                                            variableId: value,
                                        };
                                        setConditions(conditionArray);
                                    }}
                                    getPopupContainer={(triggerNode) =>
                                        triggerNode.parentElement
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                className="createAlertWrapper__selectDropdown"
                                name="operator"
                                rules={[
                                    { required: true, message: EMPTY.string },
                                ]}
                            >
                                <Select
                                    disabled={alertState === ALERTTYPE?.view}
                                    placeholder={t('alerts.selectCondition')}
                                    value={operator?.value}
                                    options={operatorsList?.map((item: any) => {
                                        return {
                                            label: item?.name,
                                            value: item?.id,
                                        };
                                    })}
                                    defaultValue={conditions[index].conditionId}
                                    onChange={(value: any, item: any) => {
                                        setOperator(item);
                                        setOperatorType({});
                                        form.setFieldsValue({
                                            operatorType: null,
                                            valueOne: null,
                                            variableTypeTwo: null,
                                            variableTwo: null,
                                            variableTypeOne: null,
                                            variableOne: null,
                                            valueThree: null,
                                            valueTwo: null,
                                            upperValue: null,
                                            lowerValue: null,
                                        });
                                        const conditionArray = [...conditions];
                                        conditionArray[index] = {
                                            ...conditionArray[index],
                                            conditionId: value,
                                            conditionTypeId: null,
                                            valueList: [],
                                        };
                                        setConditions(conditionArray);
                                    }}
                                    getPopupContainer={(triggerNode) =>
                                        triggerNode.parentElement
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                className="createAlertWrapper__selectDropdown"
                                name="operatorType"
                                rules={[
                                    { required: true, message: EMPTY.string },
                                ]}
                            >
                                <Select
                                    disabled={alertState === ALERTTYPE?.view}
                                    placeholder={t('alerts.selectType')}
                                    value={conditions[index].conditionTypeId}
                                    options={operatorsListById[index]?.map(
                                        (item: any) => {
                                            return {
                                                label: item?.name,
                                                value: item?.id,
                                                disabled: [
                                                    operatorName.Standard,
                                                    operatorName.DefinedLH,
                                                    operatorName.DefinedLLHH,
                                                ].includes(item?.name),
                                            };
                                        }
                                    )}
                                    defaultValue={
                                        conditions[index].conditionTypeId
                                    }
                                    onChange={(value: any, item: any) => {
                                        setOperatorType(item);
                                        form.setFieldsValue({
                                            valueOne: null,
                                            variableTypeTwo: null,
                                            variableTwo: null,
                                            variableTypeOne: null,
                                            variableOne: null,
                                            valueThree: null,
                                            valueTwo: null,
                                            upperValue: null,
                                            lowerValue: null,
                                        });
                                        const conditionArray = [...conditions];
                                        conditionArray[index] = {
                                            ...conditionArray[index],
                                            conditionTypeId: item?.value,
                                            valueList: [],
                                        };
                                        setConditions(conditionArray);
                                    }}
                                    getPopupContainer={(triggerNode) =>
                                        triggerNode.parentElement
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            {operator?.label &&
                                operator?.label !== operatorId.Between &&
                                operator?.label !== operatorId.NotBetween &&
                                operatorType?.label !==
                                    operatorName.Variable && (
                                    <Row className="createAlertWrapper__conditionalRow">
                                        <Col span={12}>
                                            <Form.Item
                                                className="createAlertWrapper__selectInput"
                                                name="valueOne"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: EMPTY.string,
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    disabled={
                                                        alertState ===
                                                        ALERTTYPE?.view
                                                    }
                                                    placeholder={t('alerts.by')}
                                                    maxLength={
                                                        maxInputNumberLength
                                                    }
                                                    value={
                                                        conditionObj
                                                            ?.valueList[0]
                                                            ?.values
                                                    }
                                                    defaultValue={
                                                        conditions[index]
                                                            ?.valueList[0] // Note- getting the value for first input from the array
                                                            ?.values
                                                    }
                                                    onChange={(e: any) =>
                                                        handleInputChange(
                                                            e.target.value,
                                                            0
                                                        )
                                                    }
                                                    onKeyPress={(e: any) => {
                                                        if (
                                                            operatorType?.label ===
                                                                operatorName.Constant &&
                                                            /^[a-zA-Z!@#$%^&*()_=\\[\]{};':"|,<>?]*$/.test(
                                                                e?.key
                                                            )
                                                        ) {
                                                            e.preventDefault();
                                                        }
                                                        if (
                                                            operatorType?.label ===
                                                                operatorName.Standard &&
                                                            /^[a-zA-Z!@#$^&*()_=\\[\]{};':"|,<>?]*$/.test(
                                                                e?.key
                                                            )
                                                        ) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            {operatorType?.label ===
                                                operatorName.Standard && (
                                                <div className="createAlertWrapper__selectText">
                                                    Absolute or %
                                                </div>
                                            )}
                                        </Col>
                                    </Row>
                                )}
                            {operator?.label &&
                                (operator?.label === operatorId.Between || // Note- checking operator value for conditional rendering of dropdowns and inputs
                                    operator?.label ===
                                        operatorId.NotBetween) && (
                                    <Row className="createAlertWrapper__conditionalRow">
                                        {operatorType?.label ===
                                            operatorName.Constant && (
                                            <>
                                                <Col
                                                    span={12}
                                                    className="lowerValInput"
                                                >
                                                    <Form.Item
                                                        className="createAlertWrapper__selectInput"
                                                        name="lowerValue"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    EMPTY.string,
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            disabled={
                                                                alertState ===
                                                                ALERTTYPE?.view
                                                            }
                                                            placeholder={t(
                                                                'alerts.lcl'
                                                            )}
                                                            maxLength={
                                                                maxInputNumberLength
                                                            }
                                                            value={
                                                                conditionObj
                                                                    ?.valueList[0]
                                                                    ?.values // Note- getting the value for first input from the array
                                                            }
                                                            defaultValue={
                                                                conditions[
                                                                    index
                                                                ]?.valueList[0] // Note- getting the value for first input from the array
                                                                    ?.values
                                                            }
                                                            onChange={(
                                                                e: any
                                                            ) =>
                                                                handleInputChange(
                                                                    e.target
                                                                        .value,
                                                                    0
                                                                )
                                                            }
                                                            onKeyPress={(
                                                                e: any
                                                            ) => {
                                                                if (
                                                                    /^[a-zA-Z!@#$%^&*()_=\\[\]{};':"|,<>?]*$/.test(
                                                                        e?.key
                                                                    )
                                                                ) {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col
                                                    span={12}
                                                    className="upperValInput"
                                                >
                                                    <Form.Item
                                                        className="createAlertWrapper__selectInput"
                                                        name="upperValue"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    EMPTY.string,
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            disabled={
                                                                alertState ===
                                                                ALERTTYPE?.view
                                                            }
                                                            placeholder={t(
                                                                'alerts.ucl'
                                                            )}
                                                            maxLength={
                                                                maxInputNumberLength
                                                            }
                                                            value={
                                                                conditionObj
                                                                    ?.valueList[1]
                                                                    ?.values // Note: getting the value for second input from array
                                                            }
                                                            defaultValue={
                                                                conditions[
                                                                    index
                                                                ]?.valueList[1] // Note: getting the value for second input from array
                                                                    ?.values
                                                            }
                                                            onChange={(
                                                                e: any
                                                            ) =>
                                                                handleInputChange(
                                                                    e.target
                                                                        .value,
                                                                    1
                                                                )
                                                            }
                                                            onKeyPress={(
                                                                e: any
                                                            ) => {
                                                                if (
                                                                    /^[a-zA-Z!@#$%^&*()_=\\[\]{};':"|,<>?]*$/.test(
                                                                        e?.key
                                                                    )
                                                                ) {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </>
                                        )}
                                        {(operatorType?.label ===
                                            'Defined L,H' || // Note- checking operator type value for conditional rendering of dropdowns and inputs
                                            operatorType?.label ===
                                                operatorName.DefinedLLHH) && (
                                            <>
                                                <Col span={12}>
                                                    <Form.Item
                                                        className="createAlertWrapper__selectInput"
                                                        name="valueTwo"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    EMPTY.string,
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            disabled={
                                                                alertState ===
                                                                ALERTTYPE?.view
                                                            }
                                                            placeholder={t(
                                                                'alerts.by'
                                                            )}
                                                            maxLength={
                                                                maxInputNumberLength
                                                            }
                                                            value={
                                                                conditionObj
                                                                    ?.valueList[0]
                                                                    ?.values
                                                            }
                                                            defaultValue={
                                                                conditions[
                                                                    index
                                                                ]?.valueList[0] // Note- getting the value for first input from the array
                                                                    ?.values
                                                            }
                                                            onChange={(
                                                                e: any
                                                            ) =>
                                                                handleInputChange(
                                                                    e.target
                                                                        .value,
                                                                    0
                                                                )
                                                            }
                                                            onKeyPress={(
                                                                e: any
                                                            ) => {
                                                                if (
                                                                    /^[a-zA-Z!@#$^&*()_=\\[\]{};':"|,<>?]*$/.test(
                                                                        e?.key
                                                                    )
                                                                ) {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <div className="createAlertWrapper__selectText">
                                                        Absolute or %
                                                    </div>
                                                </Col>
                                            </>
                                        )}
                                    </Row>
                                )}
                            {operator?.label &&
                                operator?.label !== operatorId.Between &&
                                operator?.label !== operatorId.NotBetween &&
                                operatorType?.label ===
                                    operatorName.Variable && (
                                    <Row className="createAlertWrapper__conditionalRow">
                                        <Col
                                            span={12}
                                            className="createAlertWrapper__conditionalCol lowerValInput"
                                        >
                                            <Form.Item
                                                className="createAlertWrapper__selectDropdown"
                                                name="variableTypeOne"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: EMPTY.string,
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    disabled={
                                                        alertState ===
                                                        ALERTTYPE?.view
                                                    }
                                                    placeholder={t(
                                                        'alerts.selectVarType'
                                                    )}
                                                    options={items}
                                                    defaultValue={
                                                        conditions[index]
                                                            ?.valueList[0]
                                                            ?.variableTypeId
                                                    }
                                                    value={subVariableOne}
                                                    onChange={handleVariableOne}
                                                    getPopupContainer={(
                                                        triggerNode
                                                    ) =>
                                                        triggerNode.parentElement
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col
                                            span={12}
                                            className="createAlertWrapper__conditionalCol upperValInput"
                                        >
                                            <Form.Item
                                                className="createAlertWrapper__selectDropdown"
                                                name="variableOne"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: EMPTY.string,
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    disabled={
                                                        alertState ===
                                                        ALERTTYPE?.view
                                                    }
                                                    placeholder={t(
                                                        'alerts.selectVariable'
                                                    )}
                                                    options={subVariableListOne}
                                                    value={
                                                        conditionObj
                                                            ?.valueList[0]
                                                            ?.variableId
                                                    }
                                                    defaultValue={
                                                        conditions[index]
                                                            ?.valueList[0]
                                                            ?.variableId
                                                    }
                                                    onChange={(value: any) => {
                                                        const conditionArray = [
                                                            ...conditions,
                                                        ];
                                                        const valueListArray = [
                                                            ...conditionObj?.valueList,
                                                        ];
                                                        valueListArray.splice(
                                                            0,
                                                            1,
                                                            {
                                                                ...conditionObj
                                                                    ?.valueList[0],
                                                                variableId:
                                                                    value,
                                                            }
                                                        );
                                                        conditionArray[index] =
                                                            {
                                                                ...conditionArray[
                                                                    index
                                                                ],
                                                                valueList: [
                                                                    ...valueListArray,
                                                                ],
                                                            };
                                                        setConditions(
                                                            conditionArray
                                                        );
                                                    }}
                                                    getPopupContainer={(
                                                        triggerNode
                                                    ) =>
                                                        triggerNode.parentElement
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                className="createAlertWrapper__selectInput"
                                                name="valueThree"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: EMPTY.string,
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    disabled={
                                                        alertState ===
                                                        ALERTTYPE?.view
                                                    }
                                                    placeholder={t('alerts.by')}
                                                    maxLength={
                                                        maxInputNumberLength
                                                    }
                                                    value={
                                                        conditionObj
                                                            ?.valueList[0]
                                                            ?.values
                                                    }
                                                    defaultValue={
                                                        conditions[index]
                                                            ?.valueList[0] // Note- getting the value for first input from the array
                                                            ?.values
                                                    }
                                                    onChange={(e: any) =>
                                                        handleInputChange(
                                                            e.target.value,
                                                            0
                                                        )
                                                    }
                                                    onKeyPress={(e: any) => {
                                                        if (
                                                            /^[a-zA-Z!@#$^&*()_=\\[\]{};':"|,<>?]*$/.test(
                                                                e?.key
                                                            )
                                                        ) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <div className="createAlertWrapper__selectText">
                                                Absolute or %
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                            {operator?.label &&
                                (operator?.label === operatorId.Between ||
                                    operator?.label ===
                                        operatorId.NotBetween) &&
                                operatorType?.label ===
                                    operatorName.Variable && (
                                    <Row className="createAlertWrapper__conditionalRow">
                                        <Col
                                            span={12}
                                            className="createAlertWrapper__conditionalCol lowerValInput"
                                        >
                                            <Form.Item
                                                className="createAlertWrapper__selectDropdown"
                                                name="variableTypeOne"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: EMPTY.string,
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    disabled={
                                                        alertState ===
                                                        ALERTTYPE?.view
                                                    }
                                                    placeholder={t(
                                                        'alerts.selectVarType'
                                                    )}
                                                    options={items}
                                                    defaultValue={
                                                        conditions[index]
                                                            ?.valueList[0]
                                                            ?.variableTypeId
                                                    }
                                                    value={subVariableOne}
                                                    onChange={handleVariableOne}
                                                    getPopupContainer={(
                                                        triggerNode
                                                    ) =>
                                                        triggerNode.parentElement
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col
                                            span={12}
                                            className="createAlertWrapper__conditionalCol upperValInput"
                                        >
                                            <Form.Item
                                                className="createAlertWrapper__selectDropdown"
                                                name="variableOne"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: EMPTY.string,
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    disabled={
                                                        alertState ===
                                                        ALERTTYPE?.view
                                                    }
                                                    placeholder={t(
                                                        'alerts.selectVariable'
                                                    )}
                                                    options={subVariableListOne}
                                                    value={
                                                        conditionObj
                                                            ?.valueList[0]
                                                            ?.variableId
                                                    }
                                                    defaultValue={
                                                        conditions[index]
                                                            ?.valueList[0]
                                                            ?.variableId
                                                    }
                                                    onChange={(value: any) => {
                                                        const conditionArray = [
                                                            ...conditions,
                                                        ];
                                                        const valueListArray = [
                                                            ...conditionObj?.valueList,
                                                        ];
                                                        valueListArray.splice(
                                                            0,
                                                            1,
                                                            {
                                                                ...conditionObj
                                                                    ?.valueList[0],
                                                                variableId:
                                                                    value,
                                                            }
                                                        );
                                                        conditionArray[index] =
                                                            {
                                                                ...conditionArray[
                                                                    index
                                                                ],
                                                                valueList: [
                                                                    ...valueListArray,
                                                                ],
                                                            };
                                                        setConditions(
                                                            conditionArray
                                                        );
                                                    }}
                                                    getPopupContainer={(
                                                        triggerNode
                                                    ) =>
                                                        triggerNode.parentElement
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col
                                            span={12}
                                            className="lowerValInput"
                                        >
                                            <Form.Item
                                                className="createAlertWrapper__selectDropdown"
                                                name="variableTypeTwo"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: EMPTY.string,
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    disabled={
                                                        alertState ===
                                                        ALERTTYPE?.view
                                                    }
                                                    placeholder={t(
                                                        'alerts.selectVarType'
                                                    )}
                                                    options={items}
                                                    defaultValue={
                                                        conditions[index]
                                                            ?.valueList[1]
                                                            ?.variableTypeId
                                                    }
                                                    value={subVariableTwo}
                                                    onChange={handleVariableTwo}
                                                    getPopupContainer={(
                                                        triggerNode
                                                    ) =>
                                                        triggerNode.parentElement
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col
                                            span={12}
                                            className="upperValInput"
                                        >
                                            <Form.Item
                                                className="createAlertWrapper__selectDropdown "
                                                name="variableTwo"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: EMPTY.string,
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    disabled={
                                                        alertState ===
                                                        ALERTTYPE?.view
                                                    }
                                                    placeholder={t(
                                                        'alerts.selectVariable'
                                                    )}
                                                    options={subVariableListTwo}
                                                    value={
                                                        conditionObj
                                                            ?.valueList[1]
                                                            ?.variableId
                                                    }
                                                    defaultValue={
                                                        conditions[index]
                                                            ?.valueList[0]
                                                            ?.variableId
                                                    }
                                                    onChange={(value: any) => {
                                                        const conditionArray = [
                                                            ...conditions,
                                                        ];
                                                        const valueListArray = [
                                                            ...conditionObj?.valueList,
                                                        ];
                                                        valueListArray.splice(
                                                            1,
                                                            1,
                                                            {
                                                                ...conditionObj
                                                                    ?.valueList[1],
                                                                variableId:
                                                                    value,
                                                            }
                                                        );
                                                        conditionArray[index] =
                                                            {
                                                                ...conditionArray[
                                                                    index
                                                                ],
                                                                valueList: [
                                                                    ...valueListArray,
                                                                ],
                                                            };
                                                        setConditions(
                                                            conditionArray
                                                        );
                                                    }}
                                                    getPopupContainer={(
                                                        triggerNode
                                                    ) =>
                                                        triggerNode.parentElement
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )}
                        </Col>
                    </Row>
                </Card>
                <div className="createAlertWrapper__verticalButtonsDiv">
                    <Form.Item
                        name="operandId"
                        rules={[
                            {
                                required: conditions?.length - index >= 2,
                                message: EMPTY.string,
                            },
                        ]}
                    >
                        <Radio.Group
                            disabled={alertState === ALERTTYPE?.view}
                            className="condtionalButtonDiv"
                            buttonStyle="solid"
                            defaultValue={conditions[index]?.operandId}
                            onChange={(e: any) => {
                                const conditionArray = [...conditions];
                                conditionArray[index] = {
                                    ...conditionArray[index],
                                    operandId: e.target.value,
                                };
                                setConditions(conditionArray);
                            }}
                        >
                            <Radio.Button
                                value={alertCondition.AND}
                                className="condtionalAndButton"
                                checked={conditions[index]?.operandId}
                            >
                                AND
                            </Radio.Button>
                            <Radio.Button
                                value={alertCondition.OR}
                                className="condtionalOrButton"
                                checked={conditions[index]?.operandId}
                            >
                                OR
                            </Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                </div>
            </Form>
        </>
    );
};

export default AlertStepTwo;
