import React, { useState } from 'react';
import { ReactComponent as ControlLimit } from 'assets/icons/controlLimit.svg';
import { ReactComponent as DataFilter } from 'assets/icons/DataFilter.svg';
import './index.scss';
import DataFilterModal from '../DataFilterModal';
import ControlLimitModal from '../ControlLimitModal';

const DataMoreContent: React.FC<any> = ({
    setUclValue,
    uclValue,
    lclValue,
    setLclValue, 
    record,
    controlLimits,
    setControlLimitsValue,
    tagUUID,
    setTagUUID,
    selectedTagRows,
    setSelectedTagRows,
    setKpiUUID,
    kpiUUID,
    setKpiId,
    kpiID,
    chartType,


}: any): any => {
  
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [controlModal, setcontrolModal] = useState(false);

    const showModal = (): any => {
        setIsModalVisible(true);
    };

    const showControlModal = (): any => {
        setcontrolModal(true);
    };

    return (
        <>
            <div className="more-container">
                <div className="more-content">
                    <ul>
                        <li className="moreContent__items" onClick={showModal}>
                            <ControlLimit /> Data Filter
                        </li>
                        <li
                            className="moreContent__items"
                            onClick={showControlModal}
                        >
                            <DataFilter /> Control Limits
                        </li>
                    </ul>
                </div>
            </div>
            <DataFilterModal
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                record={record}
                tagUUID={tagUUID}
                setTagUUID={setTagUUID}
                selectedTagRows={selectedTagRows}
                setSelectedTagRows={setSelectedTagRows}
                setKpiUUID={setKpiUUID}
                kpiUUID={kpiUUID}
                setKpiId={setKpiId}
                kpiID={kpiID}
                chartType={chartType}
                
            />
            <ControlLimitModal
                controlModal={controlModal}
                setcontrolModal={setcontrolModal}
                setUclValue={setUclValue}
                uclValue={uclValue}
                lclValue={lclValue}
                setLclValue={setLclValue}
                record={record}
                setControlLimitsValue={setControlLimitsValue}
            />
        </>
    );
};

export default DataMoreContent;
