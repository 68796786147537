import {
    CLEAR_DATA_TABLE_LIST,
    CLEAR_DATA_VISUALIZATION_VALUES,
    GET_AGGREGATED_CHART_DATA_FAILURE,
    GET_AGGREGATED_CHART_DATA_LOADING,
    GET_AGGREGATED_CHART_DATA_SUCCESS,
    GET_DATA_TABLE_LIST,
    GET_DATA_TABLE_LIST_FAILURE,
    GET_DATA_TABLE_LIST_SUCCESS,
    GET_MODEL_LIST_SUCCESS,
    GET_NODE_LIST_SUCCESS,
    GET_STREAM_TAG_LIST_SUCCESS,
    GET_STREAM_TAG_LIST,
    GET_STREAM_TAG_LIST_FAILURE,
    GET_KPI_INFO,
    GET_KPI_INFO_SUCCESS,
    GET_KPI_INFO_FAILURE,
    GET_KPI_TRENDS_SUCCESS,
    EMPTY_TAGS_PLOTTING,
    EMPTY_KPIS_PLOTTING,
    GET_KPI_TRENDS,
    GET_KPI_TRENDS_FAILURE,
    GET_FREQUENCY_SUCCESS,
    GET_FREQUENCY_FAILURE,
    CONTROL_LIMITS,
    SET_CHARTS,
    GET_ALERT,
    GET_ALERT_SUCCESS,
    GET_ALERT_FAILURE,
    GET_TIME_CAPSULE_LIST,
    GET_TIME_CAPSULE_LIST_SUCCESS,
    GET_TIME_CAPSULE_LIST_FAILURE,
    GET_AGGREGATED_SCATTER_CHART_DATA,
    GET_AGGREGATED_SCATTER_CHART_DATA_FAILURE,
    GET_AGGREGATED_SCATTER_CHART_DATA_SUCCESS,
} from 'redux/actions/actionTypes';
import initialState from '../initialStates';
import {
    GET_TRENDS_FUNCTION,
    GET_TRENDS_FUNCTION_FAILURE,
    GET_TRENDS_FUNCTION_SUCCESS,
} from 'redux/types/dataVisulaizationTypes';

export default function dataVisualizationReducer(
    state = initialState.dataVisualization,
    action: any
): any {
    switch (action.type) {
        case GET_NODE_LIST_SUCCESS:
            state = {
                ...state,
                nodeList: action.payload,
            };
            return state;
        case GET_STREAM_TAG_LIST:
            state = {
                ...state,
                tagDataLoader: true,
            };
            return state;
        case GET_STREAM_TAG_LIST_SUCCESS:
            state = {
                ...state,
                streamTagList: action.payload,
                tagDataLoader: false,
            };
            return state;
        case GET_STREAM_TAG_LIST_FAILURE:
            state = {
                ...state,
                tagDataLoader: false,
            };
            return state;
        case GET_KPI_INFO:
            state = {
                ...state,
                kpiDataLoader: true,
            };
            return state;
        case GET_KPI_INFO_SUCCESS:
            state = {
                ...state,
                kpiList: action?.payload,
                kpiDataLoader: false,
            };
            return state;
        case GET_KPI_INFO_FAILURE:
            state = {
                ...state,
                kpiDataLoader: false,
            };
            return state;
        case GET_AGGREGATED_CHART_DATA_SUCCESS:
            state = {
                ...state,
                aggregatedChartData: action.payload,
                aggregatedChartDataLoading: false,
                controlLimits: false,
            };
            return state;
        case GET_AGGREGATED_CHART_DATA_FAILURE:
            state = {
                ...state,
                aggregatedChartData: action.payload,
                aggregatedChartDataLoading: false,
            };
            return state;
        case GET_AGGREGATED_CHART_DATA_LOADING:
            state = {
                ...state,
                aggregatedChartDataLoading: true,
            };
            return state;
        case GET_MODEL_LIST_SUCCESS:
            state = {
                ...state,
                modelList: action.payload?.records,
            };
            return state;
        case GET_KPI_TRENDS:
            state = {
                ...state,
                aggregatedChartDataLoading: true,
            };
            return state;
        case GET_KPI_TRENDS_SUCCESS:
            state = {
                ...state,
                kpiTrendsData: action?.payload?.data,
                aggregatedChartDataLoading: false,
            };
            return state;
        case GET_KPI_TRENDS_FAILURE:
            state = {
                ...state,
                aggregatedChartDataLoading: false,
            };
            return state;
        case CLEAR_DATA_VISUALIZATION_VALUES:
            state = {
                ...state,
                aggregatedChartData: [],
                streamTagList: [],
                modelList: [],
                nodeList: [],
            };
            return state;
        case GET_DATA_TABLE_LIST:
            state = {
                ...state,
                dataTableList: {
                    ...state.dataTableList,
                    loader: true,
                },
            };
            return state;
        case GET_DATA_TABLE_LIST_SUCCESS:
            state = {
                ...state,
                dataTableList: {
                    ...state.dataTableList,
                    loader: false,
                    data: action?.payload,
                },
            };
            return state;
        case GET_DATA_TABLE_LIST_FAILURE:
            state = {
                ...state,
                dataTableList: {
                    ...state.dataTableList,
                    loader: false,
                    data: action?.response?.data,
                },
            };
            return state;
        case CLEAR_DATA_TABLE_LIST:
            return {
                ...state,
                dataTableList: {
                    data: [],
                },
            };
        case EMPTY_TAGS_PLOTTING:
            state = {
                ...state,
                aggregatedChartData: [],
            };
            return state;
        case EMPTY_KPIS_PLOTTING:
            state = {
                ...state,
                kpiTrendsData: {},
            };
            return state;
        case GET_FREQUENCY_SUCCESS:
            state = {
                ...state,
                frequency: action?.response?.data,
            };
            return state;
        case GET_FREQUENCY_FAILURE:
            state = {
                ...state,
                frequency: [],
            };
            return state;
        case GET_TRENDS_FUNCTION:
            state = {
                ...state,
            };
            return state;
        case GET_TRENDS_FUNCTION_SUCCESS:
            state = {
                ...state,
                trendsFunction: action?.response?.data,
            };
            return state;
        case GET_TRENDS_FUNCTION_FAILURE:
            state = {
                ...state,
            };
            return state;
        case GET_ALERT:
            state = {
                ...state,
            };
            return state;
        case GET_ALERT_SUCCESS:
            state = {
                ...state,
                alertList: action?.response?.data,
            };
            return state;
        case GET_ALERT_FAILURE:
            state = {
                ...state,
            };
            return state;
        case GET_TIME_CAPSULE_LIST:
            state = {
                ...state,
            };
            return state;
        case GET_TIME_CAPSULE_LIST_SUCCESS:
            state = {
                ...state,
                timeCapsuleList: action?.response?.data,
            };
            return state;
        case GET_TIME_CAPSULE_LIST_FAILURE:
            state = {
                ...state,
            };
            return state;
        case CONTROL_LIMITS:
            state = {
                ...state,
                controlLimits: true,
            };
            return state;

        case SET_CHARTS:
            state = {
                ...state,
                chartSelected: action?.payload,
            };
            return state;
            case GET_AGGREGATED_SCATTER_CHART_DATA:
            state = {
                ...state,
                aggregatedChartDataLoading: true,
            };
            return state;
        case GET_AGGREGATED_SCATTER_CHART_DATA_FAILURE:
            state = {
                ...state,
                aggregatedScatterChartData: action.payload,
                aggregatedChartDataLoading: false,
            };
            return state;
        case GET_AGGREGATED_SCATTER_CHART_DATA_SUCCESS:
            state = {
                ...state,
                aggregatedChartDataLoading: false,
                aggregatedScatterChartData: action.payload,
            };
            return state;

        default:
            return state;
    }
}
