// state of connections screen

export const SET_CONNECTIONS_STATE = "SET_CONNECTIONS_STATE";

export const GET_ADAPTER_LIST = "GET_ADAPTER_LIST";
export const GET_ADAPTER_LIST_SUCCESS = "GET_ADAPTER_LIST_SUCCESS";
export const GET_ADAPTER_LIST_FAILURE = "GET_ADAPTER_LIST_FAILURE";

export const GET_ALL_FILE_FORMATS = "GET_ALL_FILE_FORMATS";
export const GET_ALL_FILE_FORMATS_SUCCESS = "GET_ALL_FILE_FORMATS_SUCCESS";
export const GET_ALL_FILE_FORMATS_FAILURE = "GET_ALL_FILE_FORMATS_FAILURE";

export const GET_ALL_DIRECTIONS_LIST = "GET_ALL_DIRECTIONS_LIST";
export const GET_ALL_DIRECTIONS_LIST_SUCCESS =
  "GET_ALL_DIRECTIONS_LIST_SUCCESS";
export const GET_ALL_DIRECTIONS_LIST_FAILURE =
  "GET_ALL_DIRECTIONS_LIST_FAILURE";

export const GET_ALL_AUTH_METHOD_LIST = "GET_ALL_AUTH_METHOD_LIST";
export const GET_ALL_AUTH_METHOD_LIST_SUCCESS =
  "GET_ALL_AUTH_METHOD_LIST_SUCCESS";
export const GET_ALL_AUTH_METHOD_LIST_FAILURE =
  "GET_ALL_AUTH_METHOD_LIST_FAILURE";

export const CREATE_CONNECTIONS = "CREATE_CONNECTIONS";
export const CREATE_CONNECTIONS_SUCCESS = "CREATE_CONNECTIONS_SUCCESS";
export const CREATE_CONNECTIONS_FAILURE = "CREATE_CONNECTIONS_FAILURE";

export const GET_CONNECTIONS_LIST = "GET_CONNECTIONS_LIST";
export const GET_CONNECTIONS_LIST_SUCCESS = "GET_CONNECTIONS_LIST_SUCCESS";
export const GET_CONNECTIONS_LIST_FAILURE = "GET_CONNECTIONS_LIST_FAILURE";

export const GET_CONNECTION_BY_ID = "GET_CONNECTION_BY_ID";
export const GET_CONNECTION_BY_ID_SUCCESS = "GET_CONNECTION_BY_ID_SUCCESS";
export const GET_CONNECTION_BY_ID_FAILURE = "GET_CONNECTION_BY_ID_FAILURE";

export const UPDATE_CONNECTION = "UPDATE_CONNECTION";
export const UPDATE_CONNECTION_SUCCESS = "UPDATE_CONNECTION_SUCCESS";
export const UPDATE_CONNECTION_FAILURE = "UPDATE_CONNECTION_FAILURE";

export const CONNECTION_STREAMING = "CONNECTION_STREAMING";
export const CONNECTION_STREAMING_SUCCESS = "CONNECTION_STREAMING_SUCCESS";
export const CONNECTION_STREAMING_FAILURE = "CONNECTION_STREAMING_FAILURE";

export const CONNECTION_STREAMING_DEACTIVATE_SUCCESS =
  "CONNECTION_STREAMING_DEACTIVATE_SUCCESS";

export const CLEAR_CONNECTION_STREAMING = "CLEAR_CONNECTION_STREAMING";

// Monitoring

export const SET_MOINITORING_STATE = "SET_MOINITORING_STATE";

export const GET_CREATEDBY_LIST = "GET_CREATEDBY_LIST";
export const GET_CREATEDBY_LIST_SUCCESS = "GET_CREATEDBY_LIST_SUCCESS";
export const GET_CREATEDBY_LIST_FAILURE = "GET_CREATEDBY_LIST_FAILURE";

export const GET_ALL_MONITORING_LIST = "GET_ALL_MONITORING_LIST";
export const GET_ALL_MONITORING_LIST_SUCCESS = "GET_ALL_MONITORING_LIST_SUCCESS";
export const GET_ALL_MONITORING_LIST_FAILURE = "GET_ALL_MONITORING_LIST_FAILURE";

export const GET_LABELS_LIST = "GET_LABELS_LIST";
export const GET_LABELS_LIST_SUCCESS = "GET_LABELS_LIST_SUCCESS";
export const GET_LABELS_LIST_FAILURE = "GET_LABELS_LIST_FAILURE";

export const GET_MONITORING_LIST = "GET_MONITORING_LIST";
export const GET_MONITORING_LIST_SUCCESS = "GET_MONITORING_LIST_SUCCESS";
export const GET_MONITORING_LIST_FAILURE = "GET_MONITORING_LIST_FAILURE";

export const GET_MONITORING_BY_ID = "GET_MONITORING_BY_ID";
export const GET_MONITORING_BY_ID_SUCCESS = "GET_MONITORING_BY_ID_SUCCESS";
export const GET_MONITORING_BY_ID_FAILURE = "GET_MONITORING_BY_ID_FAILURE";

export const REPROCESS_MONITORING = "REPROCESS_MONITORING";
export const REPROCESS_MONITORING_SUCCESS = "REPROCESS_MONITORING_SUCCESS";
export const REPROCESS_MONITORING_FAILURE = "REPROCESS_MONITORING_FAILURE";

// mapping

export const SET_MAPPING_STATE = "SET_MAPPING_STATE";

export const GET_MAPPING_JSON = "GET_MAPPING_JSON";
export const GET_MAPPING_JSON_SUCCESS = "GET_MAPPING_JSON_SUCCESS";
export const GET_MAPPING_JSON_FAILURE = "GET_MAPPING_JSON_FAILURE";

export const GET_BRABOTABLE_LIST = "GET_BRABOTABLE_LIST";
export const GET_BRABOTABLE_LIST_SUCCESS = "GET_BRABOTABLE_LIST_SUCCESS";
export const GET_BRABOTABLE_LIST_FAILURE = "GET_BRABOTABLE_LIST_FAILURE";

export const SET_MAPPING_FIELDS = "SET_MAPPING_FIELDS";
export const SET_EXECUTED_SUCCESS = "SET_EXECUTED_SUCCESS";

export const GET_COLUMN_DETAILS_BY_TABLE_ID = "GET_COLUMN_DETAILS_BY_TABLE_ID";
export const GET_COLUMN_DETAILS_BY_TABLE_ID_SUCCESS =
  "GET_COLUMN_DETAILS_BY_TABLE_ID_SUCCESS";
export const GET_COLUMN_DETAILS_BY_TABLE_ID_FAILURE =
  "GET_COLUMN_DETAILS_BY_TABLE_ID_FAILURE";

export const EXECUTE_MAPPING = "EXECUTE_MAPPING";
export const EXECUTE_MAPPING_SUCCESS = "EXECUTE_MAPPING_SUCCESS";
export const EXECUTE_MAPPING_FAILURE = "EXECUTE_MAPPING_FAILURE";

export const CREATE_MAPPING = "CREATE_MAPPING";
export const CREATE_MAPPING_SUCCESS = "CREATE_MAPPING_SUCCESS";
export const CREATE_MAPPING_FAILURE = "CREATE_MAPPING_FAILURE";

export const GET_MAPPING_BY_ID = "GET_MAPPING_BY_ID";
export const GET_MAPPING_BY_ID_SUCCESS = "GET_MAPPING_BY_ID_SUCCESS";
export const GET_MAPPING_BY_ID_FAILURE = "GET_MAPPING_BY_ID_FAILURE";

export const GET_MAPPING_LIST = "GET_MAPPING_LIST";
export const GET_MAPPING_LIST_SUCCESS = "GET_MAPPING_LIST_SUCCESS";
export const GET_MAPPING_LIST_FAILURE = "GET_MAPPING_LIST_FAILURE";

export const GET_ALL_MAPPING = "GET_ALL_MAPPING";
export const GET_ALL_MAPPING_SUCCESS = "GET_ALL_MAPPING_SUCCESS";
export const GET_ALL_MAPPING_FAILURE = "GET_MAPPING_LIST_FAILURE";

export const GET_LABELS_DATA = "GET_LABELS_DATA";
export const GET_LABELS_DATA_SUCCESS = "GET_LABELS_DATA_SUCCESS";
export const GET_LABELS_DATA_FAILURE = "GET_LABELS_DATA_FAILURE";

export const MAPPING_DELETED = "MAPPING_DELETED";
export const MAPPING_DELETED_SUCCESS = "MAPPING_DELETED_SUCCESS";
export const MAPPING_DELETED_FAILURE = "MAPPING_DELETED_FAILURE";

export const MAPPING_ACTIVATED = "MAPPING_ACTIVATED";
export const MAPPING_ACTIVATED_SUCCESS = "MAPPING_ACTIVATED_SUCCESS";
export const MAPPING_ACTIVATED_FAILURE = "MAPPING_ACTIVATED_FAILURE";
export const UPDATE_MAPPING = "UPDATE_MAPPING";
export const UPDATE_MAPPING_SUCCESS = "UPDATE_MAPPING_SUCCESS";
export const UPDATE_MAPPING_FAILURE = "UPDATE_MAPPING_FAILURE";

// operation
export const SET_OPERATION_STATE = "SET_OPERATION_STATE";

export const GET_OPERATION_DETAILS_BY_ID= 'GET_OPERATION_DETAILS_BY_ID';
export const GET_OPERATION_DETAILS_BY_ID_SUCCESS= 'GET_OPERATION_DETAILS_BY_ID_SUCCESS';
export const GET_OPERATION_DETAILS_BY_ID_FAILURE= 'GET_OPERATION_DETAILS_BY_ID_FAILURE';

export const DELETE_OPERATION='DELETE_OPERATION';
export const DELETE_OPERATION_SUCCESS='DELETE_OPERATION_SUCCESS';
export const DELETE_OPERATION_FAILURE='DELETE_OPERATION_FAILURE';

export const ACTIVATE_DEACTIVATE_OPERATION='ACTIVATE_DEACTIVATE_OPERATION';
export const ACTIVATE_DEACTIVATE_OPERATION_SUCCESS='ACTIVATE_DEACTIVATE_OPERATION_SUCCESS';
export const ACTIVATE_DEACTIVATE_OPERATION_FAILURE='ACTIVATE_DEACTIVATE_OPERATION_FAILURE';

export const CREATE_OPERATION = "CREATE_OPERATION";
export const CREATE_OPERATION_SUCCESS = "CREATE_OPERATION_SUCCESS";
export const CREATE_OPERATION_FAILURE = "CREATE_OPERATION_FAILURE";

export const RESET_OPERATION_STATES = "RESET_OPERATION_STATES"

export const UPDATE_OPERATION='UPDATE_OPERATION';
export const UPDATE_OPERATION_SUCCESS='UPDATE_OPERATION_SUCCESS';
export const UPDATE_OPERATION_FAILURE='UPDATE_OPERATION_FAILURE';
export const GET_OPERATION_LIST = "GET_OPERATION_LIST";
export const GET_OPERATION_LIST_SUCCESS = "GET_OPERATION_LIST_SUCCESS";
export const GET_OPERATION_LIST_FAILURE = "GET_OPERATION_LIST_FAILURE";

export const GET_ALL_OPERATION = "GET_ALL_OPERATION";
export const GET_ALL_OPERATION_SUCCESS = "GET_ALL_OPERATION_SUCCESS";
export const GET_ALL_OPERATION_FAILURE = "GET_ALL_OPERATION_FAILURE";

export const GET_DAYS_LIST = "GET_DAYS_LIST";
export const GET_DAYS_LIST_SUCCESS = "GET_DAYS_LIST_SUCCESS";
export const GET_DAYS_LIST_FAILURE = "GET_DAYS_LIST_FAILURE";

export const CONNECTION_VALIDATE = "CONNECTION_VALIDATE";
export const CONNECTION_VALIDATE_SUCCESS = "CONNECTION_VALIDATE_SUCCESS";
export const CONNECTION_VALIDATE_FAILURE = "CONNECTION_VALIDATE_FAILURE";

export const RESET_CONNECTION_VALIDATE = "RESET_CONNECTION_VALIDATE"

export const CREATE_OUTBOUND_OPERATION = "CREATE_OUTBOUND_OPERATION";
export const CREATE_OUTBOUND_OPERATION_SUCCESS = "CREATE_OUTBOUND_OPERATION_SUCCESS";
export const CREATE_OUTBOUND_OPERATION_FAILURE = "CREATE_OUTBOUND_OPERATION_FAILURE";

export const SYNC_OPERATION='SYNC_OPERATION';
export const SYNC_OPERATION_SUCCESS='SYNC_OPERATION_SUCCESS';
export const SYNC_OPERATION_FAILURE='SYNC_OPERATION_FAILURE';