import { Col, Form, Input, Row, Select, Tag } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getConnectionById } from 'redux/actions/BusinessIntegratorActions/connectionsActions';
import { EMPTY } from 'types/enums';
import {
    ConnectionsState,
    MappingState,
} from 'types/enums/businessIntegratorEnum';
import { useTranslation } from 'react-i18next';

const AddMappingStep1: React.FC<any> = ({ form }: any): any => {
    const { Option } = Select;
    const { TextArea } = Input;
    const dispatch = useDispatch();
    const { t } = useTranslation('translation');

    const mappingState = useSelector(
        (state: any) => state.businessIntegrator.mapping.mappingState
    );
    const connectionById = useSelector(
        (state: any) => state.businessIntegrator.connections.connectionById
    );
    const connectionsTableList = useSelector(
        (state: any) => state.businessIntegrator.connections.connectionsList
    );
    const braboTableList = useSelector(
        (state: any) => state.businessIntegrator.mapping.braboTableList
    );
    const mappingById = useSelector(
        (state: any) => state.businessIntegrator.mapping.mappingById
    );

    const connectionName = Form.useWatch('connectionName', form);
    const label = Form.useWatch('label', form);

    const [inputValue, setInputValue] = useState<any>(EMPTY.string);
    const [tags, setTags] = useState<any>([]);

    useEffect(() => {
        const initialTags = mappingById?.labelResponse?.map(
            (item: any) => item?.name
        );
        setTags(initialTags || []);
    }, [mappingById]);

    const handleInputChange = (e: any): any => {
        setInputValue(e.target.value);
    };

    const handleInputConfirm = (e: any): any => {
        if (e.key === 'Enter' && inputValue) {
            setTags([...tags, inputValue]);
            setInputValue(EMPTY.string);
        }
    };

    const handleCloseTag = (removedTag: any): any => {
        setTags(tags?.filter((tag: any) => tag !== removedTag));
    };

    useEffect(() => {
        form.setFieldsValue({ label: tags });
    }, [tags]);

    useEffect(() => {
        connectionName && dispatch(getConnectionById(connectionName));
    }, [connectionName]);

    useEffect(() => {
        form.setFieldsValue({
            adapterType: connectionById?.adapter?.name,
            fileType: connectionById?.fileFormat?.name,
            direction: connectionById?.direction?.name,
        });
    }, [connectionById]);


    return (
        <div className="adapterForm">
            <Row gutter={10}>
                <Col span={8}>
                    <Form.Item
                        label={<div className="fw-400 fs-14">{t('BusinessIntegrator.mapping.mappingForm.mappingName')}</div>}
                        name="mappingName"
                        rules={[
                            {
                                required: true,
                                message:
                                    'Enter Mapping Name that can only accept alpha numeric with underscore',
                                pattern: /^[a-zA-Z0-9_]+$/,
                            },
                        ]}
                    >
                        <Input
                            placeholder= {t('BusinessIntegrator.mapping.placeHolder.mappingName')}
                            disabled={
                                mappingState ===
                                    ConnectionsState.editConnection ||
                                mappingState ===
                                    ConnectionsState.viewConnections
                            }
                            maxLength={100}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label={
                            <div className="fw-400 fs-14">{t('BusinessIntegrator.mapping.mappingForm.braboTableName')}</div>
                        }
                        name="braboTableName"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select
                            popupClassName="adapter"
                            placeholder={t('BusinessIntegrator.mapping.placeHolder.braboTableName')}
                        >
                            {braboTableList?.paginatedResponse?.records?.map(
                                (item: any) => {
                                    return (
                                        <Option
                                            key={item?.id}
                                            value={item?.id} 

                                            className={'braboTableOption'}
                                        >
                                            {item?.name}
                                        </Option>
                                    );
                                }
                            )}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label={
                            <div className="fw-400 fs-14">{t('BusinessIntegrator.mapping.mappingForm.connectionName')}</div>
                        }
                        name="connectionName"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select
                            popupClassName="adapter"
                            placeholder={t('BusinessIntegrator.mapping.placeHolder.connectionName')}
                        >
                            {connectionsTableList?.paginatedResponse?.records?.map(
                                (item: any) => {
                                    return (
                                        <Option key={item?.id} value={item?.id}>
                                            {item?.name}
                                        </Option>
                                    );
                                }
                            )}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={10}>
                <Col span={8}>
                    <Form.Item
                        label={<div className="fw-400 fs-14">{t('BusinessIntegrator.mapping.mappingForm.adapterType')}</div>}
                        name="adapterType"
                    >
                        <Input
                            placeholder={t('BusinessIntegrator.mapping.placeHolder.adapterType')}
                            disabled
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label={<div className="fw-400 fs-14">{t('BusinessIntegrator.mapping.mappingForm.fileType')}</div>}
                        name="fileType"
                    >
                        <Input
                            placeholder={t('BusinessIntegrator.mapping.placeHolder.fileType')}
                            disabled
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label={<div className="fw-400 fs-14">{t('BusinessIntegrator.mapping.mappingForm.direction')}</div>}
                        name="direction"
                    >
                        <Input
                            placeholder={t('BusinessIntegrator.mapping.placeHolder.direction')}
                            disabled
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={10}>
                <Col span={8}>
                    <Form.Item
                        label={<div className="fw-400 fs-14">{t('BusinessIntegrator.mapping.mappingForm.sourceUrl')}</div>}
                        name="sourceUrl"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input placeholder={t('BusinessIntegrator.mapping.placeHolder.sourceUrl')} />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label={<div className="fw-400 fs-14">{t('BusinessIntegrator.mapping.mappingForm.label')}</div>}
                        name="label"
                        help={
                            label && typeof label === 'string' ? (
                                <div className="adapterConfirmPassord">
                                    Please press enter when your label is
                                    entered completely
                                </div>
                            ) : (
                                EMPTY.string
                            )
                        }
                    >
                        <div
                            className={`adapterLabelTag ${
                                mappingState ===
                                ConnectionsState.viewConnections
                                    ? 'disabled'
                                    : EMPTY.string
                            }`}
                        >
                            {tags?.map((tag: any, index: any) => (
                                <Tag
                                    key={`${tag}-${index}`}
                                    closable={
                                        mappingState !==
                                        MappingState.viewConnections
                                    }
                                    onClose={() => handleCloseTag(tag)}
                                >
                                    {tag}
                                </Tag>
                            ))}
                            {mappingState !== MappingState.viewConnections && (
                                <Input
                                    value={inputValue}
                                    onChange={handleInputChange}
                                    onKeyDown={handleInputConfirm}
                                    style={{
                                        width: 'auto',
                                        flexGrow: 1,
                                        border: 'none',
                                        outline: 'none',
                                    }}
                                    className="tag-input"
                                    placeholder={t('BusinessIntegrator.mapping.placeHolder.label')}
                                    maxLength={40}
                                />
                            )}
                        </div>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={10}>
                <Col span={16}>
                    <Form.Item
                        label={<div className="fw-400 fs-14">{t('BusinessIntegrator.mapping.mappingForm.description')}</div>}
                        name="description"
                    >
                        <TextArea
                            placeholder={t('BusinessIntegrator.mapping.placeHolder.description')}
                            maxLength={200}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
};

export default AddMappingStep1;
