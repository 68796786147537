import { Form } from '@formio/react';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { message, Spin } from 'antd';
import { updateFormDataInTable } from 'redux/actions/FormActions';
import { useEffect, useState } from 'react';

const EditDynamicForm = ({
    defaultValues,
    rowId,
    setOpenEditForm,
    setOpenModalForm,
    setRowId,
}: any): any => {
    const formDetails = useSelector(
        (state: any) => state?.formio?.formsDetails
    );
    const formsDetailsLoading = useSelector(
        (state: any) => state?.formio?.formsDetailsLoading
    );
    const formDataInTable = useSelector(
        (state: any) => state?.formio?.formDataInTable
    );
    const tableDetails = useSelector(
        (state: any) => state.configure?.table?.tableDetails
    );

    const onFormReady = (formio: any): any => {
        const { key, rowId, ...filteredValues } = defaultValues; // Exclude 'key' and 'rowId'
        formio.submission = { data: filteredValues };
    };

    // const formSubmit = useSelector((state: any) => state?.formio?.formSubmit);

    // Use key to force form reset
    const [formKey, setFormKey] = useState<number>(0);
    const dispatch = useDispatch();

    const handleSubmit = async (submission: any): Promise<void> => {
        const transformedData = Object.entries(submission.data)
            .filter(([key]) => key !== 'submit' && key !== 'cancel')
            .map(([key, value]: any) => ({
                columnName: key,
                value: value,
                // key === 'columnsSelect' ? value :
            }));

        const updatePayload = {
            tableName: tableDetails?.name,
            rowModifyRequests: [
                {
                    rowId: rowId,
                    columnDataModifyRequests: transformedData,
                },
            ],
            dataSource: 'MANUAL',
        };

        dispatch(updateFormDataInTable(updatePayload));
    };

    useEffect(() => {
        if (formDataInTable) {
            setFormKey((prev) => prev + 1);
            message.success('Form updated successfully');
            setOpenEditForm(false);
            setOpenModalForm(false);
            setRowId('');
        }
    }, [formDataInTable]);

    const formConfig = {
        ...formDetails,
        components: formDetails?.components,
        settings: {
            ...formDetails?.settings,
            noAlerts: true,
        },
    };

    return (
        <div className="custom-form-container">
            <div className="custom-form">
                <div>
                    {formsDetailsLoading ? (
                        <div className="view__loader">
                            <Spin />
                        </div>
                    ) : (
                        <Form
                            key={`form-${formKey}-${formDataInTable}`}
                            form={formConfig}
                            onSubmit={handleSubmit}
                            options={{
                                readOnly: false,
                                noAlerts: true,
                            }}
                            formReady={onFormReady}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default EditDynamicForm;
