import { baseUrlSetter } from 'utils/commonFunction';
import Cookies from 'universal-cookie';
import Api from '..';
import { message } from 'antd';

const cookies = new Cookies();

export const getFormListsService = async (payload: any): Promise<any> => {
    const baseUrl = baseUrlSetter('formBuilder');
    try {
        const response = await fetch(`${baseUrl}/form?type=form`, {
            headers: {
                authorization: `Bearer ${
                    cookies.get('authToken') ??
                    sessionStorage.getItem('authToken')
                }`,
            },
        });
        const data = await response.json();
        return await Promise.resolve(data);
    } catch (error: any) {
        throw new Error(error);
    }
};

export const insertDataIntoTable = async (payload: any): Promise<any> => {
    baseUrlSetter('deviceManagement');

    try {
        const response = await Api.post(`/table-data/insert`, payload);
        message.success('Data saved successfully');
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(
            error.response.data.message
                ? error.response.data.message
                : 'Internal Server Error'
        );
        throw new Error(error);
    }
};

export const setFormDataService = async (payload: any): Promise<any> => {
    const baseUrl = baseUrlSetter('formBuilder');
    const formId = payload?.formId; // Consider making this a parameter

    try {
        const authToken =
            cookies.get('authToken') || sessionStorage.getItem('authToken');

        if (!authToken) {
            throw new Error('Authentication token not found');
        }

        const response = await fetch(`${baseUrl}/form/${formId}/submission`, {
            method: 'POST', // Explicitly set the method to POST
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify(payload?.formData),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error: any) {
        message.error('Error in saving data');
        throw error; // Re-throw the error for handling in the calling function
    }
};

export const getFormDetailsService = async (payload: any): Promise<any> => {
    const baseUrl = baseUrlSetter('formBuilder');
    try {
        const response = await fetch(`${baseUrl}/form/${payload}`, {
            headers: {
                authorization: `Bearer ${
                    cookies.get('authToken') ??
                    sessionStorage.getItem('authToken')
                }`,
            },
        });
        const data = await response.json();
        return await Promise.resolve(data);
    } catch (error: any) {
        throw new Error(error);
    }
};

export const updateDataIntoTable = async (payload: any): Promise<any> => {
    baseUrlSetter('deviceManagement');
 
    try {
        const response = await Api.put(`/table-data/update`, payload);
        message.success('Data updated successfully');
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(
            error.response.data.message
                ? error.response.data.message
                : 'Internal Server Error'
        );
        throw new Error(error);
    }
};
export const getActionsListsService = async (payload: any): Promise<any> => {
    const baseUrl = baseUrlSetter('formBuilder');
    try {
        const response = await fetch(
            `${baseUrl}/form/${payload}/available-actions`,
            {
                headers: {
                    authorization: `Bearer ${
                        cookies.get('authToken') ??
                        sessionStorage.getItem('authToken')
                    }`,
                },
            }
        );
        const data = await response.json();
        return await Promise.resolve(data);
    } catch (error: any) {
        throw new Error(error);
    }
};
