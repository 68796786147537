import React, { useEffect, useState } from 'react';
import {
    Collapse,
    Switch,
    Input,
    Select,
    Drawer,
    Button,
    Checkbox,
} from 'antd';
import './index.scss';
import { EMPTY, tagOriginId } from 'types/enums';
import utf8 from 'utf8';
import base64 from 'base-64';
import { useDispatch, useSelector } from 'react-redux';
import { getScatterAggregrateData } from 'redux/actions/DataExplorer/DataVisualizationActions';
import RegressionDetailsModal from '../RegressionDetailsModal';

const { Panel } = Collapse;
const { Option } = Select;

const ChartPropertiesScatter = ({
    visible,
    onClose,
    setAxisSelectorEnabled,
    setRegressionEnabled,
    setConfidenceIntervalEnabled,
    setStatFunctionsEnabled,
    axisSelectorEnabled,
    regressionEnabled,
    confidenceIntervalEnabled,
    statFunctionsEnabled,
    tagUUID,
    setTagUUID,
    selectedTagRows,
    frequencyState,
    selectedKpiRows,
    setKpiUUID,
    kpiUUID,
    setKpiId,
    kpiID,
    xAxis,
    setXAxis,
    yAxis,
    setYAxis,
    aggregatedScatterChartData
}: any): JSX.Element => {
    const dateRange = useSelector(
        (state: any) => state.nocilDashboard.filterValues
    );
    const dispatch = useDispatch();
    const timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

    const [selectedTrendFunctions, setSelectedTrendFunctions] = useState<
        string[]
    >([]);
    const [regressionFunctions, setRegressionFunction] = useState<
        string | null
    >(null);
    const [polynomialDegree, setPolynomialDegree] = useState<number | null>(
        null
    ); // Fix: Initialize polynomialDegree state

    const [
        isRegressionDetailsModalVisible,
        setIsRegressionDetailsModalVisible,
    ] = useState(false); // State for the regression details modal
    const handleCheckboxChange = (checked: boolean, value: string): any => {
        setSelectedTrendFunctions((prev) =>
            checked ? [...prev, value] : prev.filter((item) => item !== value)
        );
    };
    const trendFunctionsString = selectedTrendFunctions?.join(',');
    const handlePolynomialDegreeChange = (value: number): any => {
        setPolynomialDegree(value > 0 ? value : null); // Ensure the value is positive
    };
    useEffect(() => {
        let tagUUIDString: string = EMPTY?.string;
        let urlEncodedStr: string = EMPTY?.string;
        if (selectedKpiRows?.length) {
            let kpiIdString: string = EMPTY?.string;
            selectedKpiRows?.forEach((selectedKpiValues: any) => {
                if (kpiIdString === EMPTY?.string) {
                    kpiIdString = String(selectedKpiValues?.id);
                } else {
                    kpiIdString += ',' + String(selectedKpiValues?.id);
                }
                setKpiId(kpiIdString);
            });

            const transformedArray = selectedKpiRows?.map((kpi: any) => ({
                assetId: kpi?.assetId,
                kpiId: kpi?.id,
            }));
            const jsonString = JSON.stringify(transformedArray);
            const bytes = utf8.encode(jsonString);
            let base64EncodedStr = base64.encode(bytes);
            // Replace function to remove trailing '=' characters
            base64EncodedStr = base64EncodedStr.replace(/=+$/, '');
            urlEncodedStr = encodeURIComponent(base64EncodedStr);
            setKpiUUID(urlEncodedStr);
        }

        if (selectedTagRows?.length) {
            selectedTagRows?.forEach((selectedTagValues: any) => {
                if (tagUUIDString === EMPTY?.string) {
                    tagUUIDString = String(selectedTagValues?.uuid);
                } else {
                    tagUUIDString += ',' + String(selectedTagValues?.uuid);
                }
                setTagUUID(tagUUIDString);
            });
        }

        dispatch(
            getScatterAggregrateData({
                startTime: new Date(dateRange?.startTime)?.getTime(),
                endTime: new Date(dateRange?.endTime)?.getTime(),
                tagUuidList: tagUUIDString,
                tagOriginId: tagOriginId?.PLC,
                timezone,
                frequency: frequencyState ?? 0,
                list: urlEncodedStr,
                xAxis,
                yAxis,
                trendFunctions: trendFunctionsString,
            })
        );
    }, [xAxis, yAxis, selectedTrendFunctions]);

    return (
        <Drawer
            title="Chart Properties"
            className="chart-properties-scatter-drawer"
            placement="right"
            onClose={onClose}
            open={visible}
            width={350} // Adjusted width as per design
        >
            <Collapse defaultActiveKey={[]} className="custom-collapse">
                {/* Axis Selector Section */}
                <Panel
                    header={
                        <div className="panel-header">
                            <span>Axis Selector</span>
                            <Switch
                                checked={axisSelectorEnabled}
                                onChange={(checked) =>
                                    setAxisSelectorEnabled(checked)
                                }
                            />
                        </div>
                    }
                    key="1"
                    className="custom-panel"
                >
                    {axisSelectorEnabled ? (
                        <div className="axis-selector-wrapper">
                            <div className="axis-field">
                                <label>X-Axis</label>
                                <Select
                                    placeholder="Select X-Axis"
                                    style={{ width: '100%' }}
                                    value={xAxis}
                                    onChange={(value) => {
                                        setXAxis(value); // Set the selected ID directly
                                    }}
                                >
                                    {selectedTagRows?.map((tag: any) => (
                                        <Option
                                            key={`tag-${tag.uuid}`}
                                            value={tag.uuid} // Use the unique ID here
                                        >
                                            {tag.name}
                                        </Option>
                                    ))}
                                    {selectedKpiRows?.map((kpi: any) => (
                                        <Option
                                            key={`kpi-${kpi.id}`}
                                            value={kpi.id} // Use the unique ID here
                                        >
                                            {kpi.name}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                            <div className="axis-field">
                                <label>Y-Axis</label>
                                <Select
                                    placeholder="Select Y-Axis"
                                    style={{ width: '100%' }}
                                    value={yAxis}
                                    onChange={(value) => {
                                        setYAxis(value); // Set the selected ID directly
                                    }}
                                >
                                    {selectedTagRows?.map((tag: any) => (
                                        <Option
                                            key={`tag-${tag.uuid}`}
                                            value={tag.uuid} // Use the unique ID here
                                        >
                                            {tag.name}
                                        </Option>
                                    ))}
                                    {selectedKpiRows?.map((kpi: any) => (
                                        <Option
                                            key={`kpi-${kpi.id}`}
                                            value={kpi.id} // Use the unique ID here
                                        >
                                            {kpi.name}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    ) : null}
                </Panel>

                {/* Regression Analysis Section */}

                <Panel
                    header={
                        <div className="panel-header">
                            <span>Regression Analysis</span>
                            <Switch
                                checked={regressionEnabled}
                                onChange={(checked) =>
                                    setRegressionEnabled(checked)
                                }
                            />
                        </div>
                    }
                    key="2"
                    className="custom-panel"
                >
                    {regressionEnabled ? (
                        <div className="regression-analysis-wrapper">
                            <label>Regression Type</label>
                            <Select
                                placeholder="Select Regression Type"
                                style={{ width: '100%' }}
                                value={regressionFunctions}
                                onChange={(value) => {
                                    setRegressionFunction(value);
                                }}
                            >
                                <Option value="LINEAR_REGRESSION">
                                    Linear
                                </Option>
                                <Option value="POLYNOMIAL_REGRESSION">
                                    Polynomial
                                </Option>
                                <Option value="Exponential">Exponential</Option>
                            </Select>

                            {/* Polynomial Degree Input Field */}
                            {regressionFunctions ===
                                'POLYNOMIAL_REGRESSION' && (
                                <div
                                    className="polynomial-degree-wrapper"
                                    style={{ marginTop: 10 }}
                                >
                                    <label>Polynomial Degree</label>
                                    <Input
                                        type="number"
                                        placeholder="Enter degree"
                                        style={{ width: '100%' }}
                                        value={polynomialDegree ?? ''}
                                        onChange={(e) =>
                                            handlePolynomialDegreeChange(
                                                Number(e.target.value)
                                            )
                                        } // Custom handler for degree changes
                                    />
                                </div>
                            )}
                            <div className="button-group">
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        // Prepare regression type with degree for POLYNOMIAL_REGRESSION
                                        const regressionPayload =
                                            regressionFunctions ===
                                                'POLYNOMIAL_REGRESSION' &&
                                            polynomialDegree !== null
                                                ? `${regressionFunctions}${polynomialDegree}`
                                                : regressionFunctions;
                                        // API Call for Calculate
                                        dispatch(
                                            getScatterAggregrateData({
                                                startTime: new Date(
                                                    dateRange?.startTime
                                                )?.getTime(),
                                                endTime: new Date(
                                                    dateRange?.endTime
                                                )?.getTime(),
                                                tagUuidList: tagUUID,
                                                tagOriginId: tagOriginId?.PLC,
                                                timezone,
                                                frequency: frequencyState ?? 0,
                                                list: kpiUUID,
                                                xAxis,
                                                yAxis,
                                                trendFunctions:
                                                    selectedTrendFunctions.join(
                                                        ','
                                                    ),
                                                regressionFunctions:
                                                    regressionPayload, // Updated payload
                                            })
                                        );
                                    }}
                                >
                                    Calculate
                                </Button>
                                <Button
                                    onClick={() => {
                                        setIsRegressionDetailsModalVisible(
                                            true
                                        );
                                    }}
                                >
                                    Regression Details
                                </Button>
                            </div>
                        </div>
                    ) : null}
                </Panel>

                {/* Confidence Interval Section */}
                <Panel
                    header={
                        <div className="panel-header">
                            <span>Confidence Interval</span>
                            <Switch
                                checked={confidenceIntervalEnabled}
                                onChange={(checked) =>
                                    setConfidenceIntervalEnabled(checked)
                                }
                            />
                        </div>
                    }
                    key="3"
                    className="custom-panel"
                >
                    {confidenceIntervalEnabled ? (
                        <div className="confidence-interval-wrapper">
                            <div className="input-wrapper">
                                <div className="input-group">
                                    <label>Min</label>
                                    <Input
                                        type="number"
                                        placeholder="10"
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div className="input-group">
                                    <label>Max</label>
                                    <Input
                                        type="number"
                                        placeholder="50"
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                            <Button type="primary" style={{ marginTop: 10 }}>
                                Apply Confidence Interval Shading
                            </Button>
                        </div>
                    ) : null}
                </Panel>

                <Panel
                    header={
                        <div className="panel-header">
                            <span>Statistical Functions</span>
                            <Switch
                                checked={statFunctionsEnabled}
                                onChange={(checked) =>
                                    setStatFunctionsEnabled(checked)
                                }
                            />
                        </div>
                    }
                    key="4"
                    className="custom-panel"
                >
                    {statFunctionsEnabled ? (
                        <div className="statistical-functions-wrapper">
                            <div className="function-item">
                                <Checkbox
                                    checked={selectedTrendFunctions.includes(
                                        'MEAN'
                                    )}
                                    onChange={(e) =>
                                        handleCheckboxChange(
                                            e.target.checked,
                                            'MEAN'
                                        )
                                    }
                                >
                                    MEAN
                                </Checkbox>
                            </div>
                            <div className="function-item">
                                <Checkbox
                                    checked={selectedTrendFunctions.includes(
                                        'STD'
                                    )}
                                    onChange={(e) =>
                                        handleCheckboxChange(
                                            e.target.checked,
                                            'STD'
                                        )
                                    }
                                >
                                    STANDARD DEVIATION
                                </Checkbox>
                            </div>
                        </div>
                    ) : null}
                </Panel>
            </Collapse>
            <RegressionDetailsModal
                isModalVisible={isRegressionDetailsModalVisible}
                setIsModalVisible={setIsRegressionDetailsModalVisible}
                aggregatedScatterChartData={aggregatedScatterChartData}
                // Pass necessary data to the modal (e.g., regression results)
            />
        </Drawer>
    );
};

export default ChartPropertiesScatter;
