import React from 'react';
import { Modal, Button, Input, Row, Col, Divider, Form } from 'antd';
import '../index.scss';

const ControlLimitModal = ({
    controlModal,
    setcontrolModal,
    setUclValue,
    setLclValue,
    record,
    setControlLimitsValue,
}: any): any => {
    const [form] = Form.useForm();
    const handleCancel = (): any => {
        setcontrolModal(false);
        setUclValue('');
        setLclValue('');
    };

    const handleApply = (values: any): any => {
        // Get UCL and LCL values from the form
        const { uclValue, lclValue } = values;
        setUclValue(uclValue);
        setLclValue(lclValue);
        setControlLimitsValue((prev: any) => ({
            ...prev,
            [record?.key]: [uclValue, lclValue],
        }));
        setcontrolModal(false);
    };

    return (
        <>
            <Modal
                title="Control Limits"
                className="controlLimits"
                visible={controlModal}
                onCancel={handleCancel}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button
                        key="apply"
                        type="primary"
                        onClick={() => {
                            form.submit();
                        }}
                    >
                        Apply
                    </Button>,
                ]}
            >
                <Form form={form} layout="vertical" onFinish={handleApply}>
                    <Form.Item>
                        <span>Selected Data : {record?.tagName}</span>
                    </Form.Item>

                    <Row gutter={12}>
                        <Col span={6}>
                            <Form.Item name="uclValue">
                                <Input placeholder="Enter UCL " />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="lclValue">
                                <Input placeholder="Enter LCL " />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider />
                </Form>
            </Modal>
        </>
    );
};

export default ControlLimitModal;
