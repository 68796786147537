import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import clockImage from 'assets/icons/time.svg';
import {
    MULTI_AXIS_CHART_COLORS,
    tooltipDateTimeFormat,
} from 'utils/constants';
import { convertToBrowserTimezone } from 'utils/commonFunction';
// import darkUnicaTheme from 'highcharts/themes/dark-unica';
import { useContext } from 'react';
import { ThemeContext } from 'components/Context/ThemeContext';
const MultiAxisLineChart = ({
    chartSeriesData,
    yAxisSeriesData,
    chartRef = null,
    dateRange,
    key,
    selectedTags,
    selectedKpis,
}: any): any => {
    
    const { theme } = useContext(ThemeContext);
    const xAxisMin = new Date(dateRange?.startTime).getTime();
    const xAxisMax = new Date(dateRange?.endTime).getTime();
   // Highcharts options for trend styles
   const trendStyles: any = {
    Mean: {
        color: 'blue',
        dashStyle: 'Dash',
    },
    Median: {
        color: 'green',
        dashStyle: 'ShortDash',
    },
    Mode: {
        color: 'orange',
        dashStyle: 'ShortDash',
    },
    Deviation: {
        color: 'red',
        dashStyle: 'Dash',
    },
    MOVINGAVERAGE: {
        color: 'pink',
        dashStyle: 'Dash',
    },
};

 // Combine tags and KPIs into a single array for processing
 const combinedSelections = [
    ...selectedTags?.map((tag: any) => ({ ...tag, type: 'tag' })),
    ...selectedKpis?.map((kpi: any) => ({ ...kpi, type: 'kpi' })),
];

    const optionsDark = {
        chart: {
            zoomType: 'x',
            backgroundColor: '#02071c',
            resetZoomButton: {
                theme: {
                    fill: '#02071c',
                    style: {
                        color: '#FFFFFF',
                    },
                    states: {
                        hover: {
                            fill: '#02071c',
                            style: {
                                color: '#FFFFFF',
                            },
                        },
                    },
                },
            },
        },
        colors: MULTI_AXIS_CHART_COLORS,
        navigation: {
            buttonOptions: {
                enabled: true,
            },
        },
        title: {
            text: '',
            style: {
                fill: '#FFFFFF',
                color: '#FFFFFF',
            },
        },
        legend: {
            itemStyle: {
                color: '#FFFFFF',
            },
        },

        xAxis: {
            type: 'datetime',
            labels: {
                dateTimeLabelFormats: {
                    millisecond: '%Y-%m-%d %H:%M:%S',
                    second: '%Y-%m-%d %H:%M:%S',
                    minute: '%Y-%m-%d %H:%M:%S',
                    hour: '%Y-%m-%d %H:%M:%S',
                    day: '%Y-%m-%d %H:%M:%S',
                    week: '%Y-%m-%d %H:%M:%S',
                    month: '%Y-%m-%d %H:%M:%S',
                    year: '%Y-%m-%d %H:%M:%S',
                },
                formatter: function (axis: any): any {
                    const date = new Date(axis?.value);
                    const browserTimestamp =
                        date.getTime() - date.getTimezoneOffset() * 60000;
                    return Highcharts.dateFormat(
                        axis.dateTimeLabelFormat,
                        browserTimestamp
                    );
                },
                style: {
                    color: '#FFFFFF',
                },
            },
            scrollbar: {
                enabled: true,
            },
            min: xAxisMin,
            max: xAxisMax,
        },
        yAxis: yAxisSeriesData.map((yAxis: any, index: number) => ({
            key: index,
            title: {
                text: yAxis?.title?.text,
            },
            // opposite: yAxis.opposite,
            // showEmpty: yAxis.showEmpty,
            labels: {
                style: {
                    color: '#FFFFFF',
                },
            },
        })),

        tooltip: {
            backgroundColor: '#02071c',
            style: {
                color: '#ffffff',
            },
            shared: true,
            useHTML: true, // Allow HTML content in the tooltip
            xDateFormat: `<div style="display:flex;justify-content:center;"> <div style="color:red; font-sixe:12px; font-weight:500"><div style="display:flex; align-items:center;gap:2px"> <img src="${clockImage}" alt="Clock" style="margin-right: 5px; height: 15px; width: 15px;" /><span> %Y-%m-%d %H:%M:%S</span></div></div></div>`,
            formatter: function (this: any): any {
                // points[0] is to get the current x value of point
                const currentPoint = this.points[0];
                const series = currentPoint.series;
                const index = currentPoint.point.index;
                const nextPoint =
                    index < series.points.length - 1
                        ? series.points[index + 1]
                        : null;

                const currentBrowserTimestamp = convertToBrowserTimezone(
                    this.x
                );
                let tooltip =
                    'Start: ' +
                    Highcharts.dateFormat(
                        tooltipDateTimeFormat,
                        currentBrowserTimestamp
                    ) +
                    '<br>';

                // Add next point timestamp if available
                if (nextPoint) {
                    const nextBrowserTimestamp = convertToBrowserTimezone(
                        nextPoint.x
                    );
                    tooltip +=
                        'End: ' +
                        Highcharts.dateFormat(
                            tooltipDateTimeFormat,
                            nextBrowserTimestamp
                        ) +
                        '<br>';
                }

                this.points.forEach(function (point: any) {
                    tooltip +=
                        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                        '<span style="color:' +
                        point.color +
                        '">\u25CF</span> ' +
                        point.series.name +
                        ': ' +
                        point?.y?.toFixed(2) +
                        '<br>';
                });
                return tooltip;
            },
        },

        series: chartSeriesData,
    };
    const options = {
        chart: {
            zoomType: 'x',
        },
        colors: MULTI_AXIS_CHART_COLORS,
        navigation: {
            buttonOptions: {
                enabled: true,
            },
        },
        title: {
            text: '',
        },
        xAxis: {
            type: 'datetime',
            labels: {
                dateTimeLabelFormats: {
                    millisecond: '%Y-%m-%d %H:%M:%S',
                    second: '%Y-%m-%d %H:%M:%S',
                    minute: '%Y-%m-%d %H:%M:%S',
                    hour: '%Y-%m-%d %H:%M:%S',
                    day: '%Y-%m-%d %H:%M:%S',
                    week: '%Y-%m-%d %H:%M:%S',
                    month: '%Y-%m-%d %H:%M:%S',
                    year: '%Y-%m-%d %H:%M:%S',
                },
                formatter: function (axis: any): any {
                    const date = new Date(axis?.value);
                    const browserTimestamp =
                        date.getTime() - date.getTimezoneOffset() * 60000;
                    return Highcharts.dateFormat(
                        axis?.dateTimeLabelFormat,
                        browserTimestamp
                    );
                },
            },
            scrollbar: {
                enabled: true,
            },
            min: xAxisMin,
            max: xAxisMax,
        },
        yAxis: yAxisSeriesData.map((yAxis: any, index: number) => ({
            key: index,
            title: {
                text: yAxis?.title?.text,
            },
            // opposite: yAxis.opposite,
            // showEmpty: yAxis.showEmpty,
        })),

        tooltip: {
            shared: true,
            useHTML: true, // Allow HTML content in the tooltip
            xDateFormat: `<div style="display:flex;justify-content:center;"> <div style="color:white; font-sixe:12px; font-weight:500"><div style="display:flex; align-items:center;gap:2px"> <img src="${clockImage}" alt="Clock" style="margin-right: 5px; height: 15px; width: 15px;" /><span> %Y-%m-%d %H:%M:%S</span></div></div></div>`,
            formatter: function (this: any): any {
                // points[0] is to get the current x value of point
                const currentPoint = this.points[0];
                const series = currentPoint.series;
                const index = currentPoint.point.index;
                const nextPoint =
                    index < series.points.length - 1
                        ? series.points[index + 1]
                        : null;

                const currentBrowserTimestamp = convertToBrowserTimezone(
                    this.x
                );
                let tooltip =
                    'Start: ' +
                    Highcharts.dateFormat(
                        tooltipDateTimeFormat,
                        currentBrowserTimestamp
                    ) +
                    '<br>';

                // Add next point timestamp if available
                if (nextPoint) {
                    const nextBrowserTimestamp = convertToBrowserTimezone(
                        nextPoint.x
                    );
                    tooltip +=
                        'End: ' +
                        Highcharts.dateFormat(
                            tooltipDateTimeFormat,
                            nextBrowserTimestamp
                        ) +
                        '<br>';
                }

                this.points.forEach(function (point: any) {
                    tooltip +=
                        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                        '<span style="color:' +
                        point.color +
                        '">\u25CF</span> ' +
                        point.series.name +
                        ': ' +
                        point?.y?.toFixed(2) +
                        '<br>';
                });
                return tooltip;
            },
        },

        series: chartSeriesData,
        
        legend: {
            labelFormatter: function (
                this: Highcharts.Series
            ): string {
                const seriesName = this.name;
                const trendFunctionName = Object.keys(
                    trendStyles
                ).find((trend) => seriesName?.includes(trend));
                if (trendFunctionName) {
                    return trendFunctionName; // Only return the trend name (e.g., "Mean", "Mode")
                }

                // Return the original tag or KPI name only once (by checking if it doesn't match any trend)
                return seriesName;
            },
        },
    };

    return (
        <HighchartsReact
        key={`${key}-${combinedSelections.length}`}
            highcharts={Highcharts}
            options={theme === 'dark' ? optionsDark : options}
            containerProps={{ className: 'multiAxis' }}
            ref={chartRef}
        />
    );
};
export default MultiAxisLineChart;
