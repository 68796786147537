import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    activateDeactivateOperationServices,
    deleteOperationService,
    getOperationDetailsByIdService,
    createOperationServices,
    updateOperationService,
    getDaysListServices,
    getOperationListServices,
    connectionValidateService,
    createOutboundOperationServices,
    syncOperationService,
} from 'redux/services/BusinessIntegratorServices/operationServices';
import * as types from 'redux/types/businessIntegratorTypes';

export function* getOperationDetailsByIdSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        const getOperationDetails = yield call(
            getOperationDetailsByIdService,
            payload
        );
        yield put({
            type: types.GET_OPERATION_DETAILS_BY_ID_SUCCESS,
            response: getOperationDetails,
        });
    } catch (error: any) {
        yield put({
            type: types.GET_OPERATION_DETAILS_BY_ID_FAILURE,
        });
    }
}

export function* deleteOperationSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        const deleteOperation = yield call(deleteOperationService, payload);
        yield put({
            type: types.DELETE_OPERATION_SUCCESS,
            response: deleteOperation,
        });
    } catch (error: any) {
        yield put({
            type: types.DELETE_OPERATION_FAILURE,
        });
    }
}

export function* activateDeactivateOperationSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        const activateDeactivateOperation = yield call(
            activateDeactivateOperationServices,
            payload
        );
        yield put({
            type: types.ACTIVATE_DEACTIVATE_OPERATION_SUCCESS,
            response: activateDeactivateOperation,
        });
    } catch (error: any) {
        yield put({
            type: types.ACTIVATE_DEACTIVATE_OPERATION_FAILURE,
        });
    }
}

export function* createOperationSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        const isOperationCreated = yield call(createOperationServices, payload);
        yield put({
            type: types.CREATE_OPERATION_SUCCESS,
            response: isOperationCreated,
        });
    } catch (error: any) {
        yield put({
            type: types.CREATE_OPERATION_FAILURE,
        });
    }
}

export function* createOutboundOperationSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        const isOutboundOperationCreated = yield call(createOutboundOperationServices, payload);
        yield put({
            type: types.CREATE_OUTBOUND_OPERATION_SUCCESS,
            response: isOutboundOperationCreated,
        });
    } catch (error: any) {
        yield put({
            type: types.CREATE_OUTBOUND_OPERATION_FAILURE,
        });
    }
}

export function* updateOperationSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        const updateOperationDetails = yield call(
            updateOperationService,
            payload
        );
        yield put({
            type: types.UPDATE_OPERATION_SUCCESS,
            response: updateOperationDetails,
        });
    } catch (error: any) {
        yield put({
            type: types.UPDATE_OPERATION_FAILURE,
        });
    }
}

export function* getOperationListSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        const operationList = yield call(getOperationListServices, payload);
        yield put({
            type: types.GET_OPERATION_LIST_SUCCESS,
            response: operationList,
        });
    } catch (error: any) {
        yield put({
            type: types.GET_OPERATION_LIST_FAILURE,
        });
    }
}

export function* getAllOperationSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        const operationList = yield call(getOperationListServices, payload);
        yield put({
            type: types.GET_ALL_OPERATION_SUCCESS,
            response: operationList,
        });
    } catch (error: any) {
        yield put({
            type: types.GET_ALL_OPERATION_FAILURE,
        });
    }
}

export function* getDaysListSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        const daysList = yield call(getDaysListServices);
        yield put({
            type: types.GET_DAYS_LIST_SUCCESS,
            response: daysList,
        });
    } catch (error: any) {
        yield put({
            type: types.GET_DAYS_LIST_FAILURE,
        });
    }
}

export function* connectionValidateSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
      const validated = yield call(
          connectionValidateService,
          payload
      );
      yield put({
          type: types.CONNECTION_VALIDATE_SUCCESS,
          response: validated,
      });
  } catch (error: any) {
      yield put({
          type: types.CONNECTION_VALIDATE_FAILURE,
      });
  }
}

export function* syncOperationSaga({
    type,
  payload, 
}:{
    type: string;
  payload: any;
}): Generator<any>{
    try{
        const operationSynced=yield call(syncOperationService, payload);
        yield put({
            type:types.SYNC_OPERATION_SUCCESS,
            response:operationSynced
        });
    } catch(error:any){
        yield put({
            type: types.SYNC_OPERATION_FAILURE,
        });
    }
}

export function* operationSaga(): any {
    yield all([
        takeLatest(
            types.GET_OPERATION_DETAILS_BY_ID,
            getOperationDetailsByIdSaga
        ),
    ]);
    yield all([takeLatest(types.DELETE_OPERATION, deleteOperationSaga)]);
    yield all([
        takeLatest(
            types.ACTIVATE_DEACTIVATE_OPERATION,
            activateDeactivateOperationSaga
        ),
    ]);
    yield all([takeLatest(types.CREATE_OPERATION, createOperationSaga)]);
    yield all([takeLatest(types.UPDATE_OPERATION, updateOperationSaga)]);
    yield all([takeLatest(types.GET_OPERATION_LIST, getOperationListSaga)]);
    yield all([takeLatest(types.GET_ALL_OPERATION, getAllOperationSaga)]);
    yield all([takeLatest(types.GET_DAYS_LIST, getDaysListSaga)]);
    yield all([takeLatest(types.CONNECTION_VALIDATE, connectionValidateSaga)]);
    yield all([takeLatest(types.CREATE_OUTBOUND_OPERATION, createOutboundOperationSaga)]);
    yield all([takeLatest(types.SYNC_OPERATION, syncOperationSaga)]);

}
