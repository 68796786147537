import Table, { type ColumnsType } from 'antd/es/table';
import { ReactComponent as UpArrow } from 'assets/icons/upArrowIcon.svg';
import { ReactComponent as DownArrow } from 'assets/icons/downArrowIcon.svg';
import './index.scss';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { mappedTagsSortOption, sortOrder } from 'types/enums';
import { PAGE } from 'utils/constants';

const OTTable: React.FC<any> = ({
    setSelectedRowIds,
    selectedRowIds,
    data,
    setSortOrder,
    setSortColumn,
    setPage,
}) => {
    const { t } = useTranslation('translation');
    const loader = useSelector(
        (state: any) =>
            state?.assetModelling?.modelOverview?.tagListByDeviceLoader
    );

    const rowSelection: any = {
        onSelect: (record: any, selected: boolean, selectedRows: any) => {
            const selectedActiveRows: number[] = [];
            const selectedInactiveRows: number[] = [];
            selectedRows?.map((item: any) =>
                item?.isActive
                    ? selectedActiveRows.push(item?.id)
                    : selectedInactiveRows.push(item?.id)
            );

            if (selected) {
                setSelectedRowIds([...selectedRowIds, record?.uuid]);
            } else {
                setSelectedRowIds(
                    selectedRowIds?.filter((id: number) => id !== record?.uuid)
                );
            }
        },
        onSelectAll: (selected: boolean, selectedRows: any) => {
            const selectedActiveRows: number[] = [];
            const selectedInactiveRows: number[] = [];
            selectedRows?.map((item: any) =>
                item?.isActive
                    ? selectedActiveRows.push(item?.id)
                    : selectedInactiveRows.push(item?.id)
            );

            if (selected) {
                const newSelectedIds = selectedRows?.map(
                    (record: any) => record?.uuid
                );
                setSelectedRowIds(newSelectedIds);
            } else {
                setSelectedRowIds([]);
            }
        },
    };
    const columns: ColumnsType<any> = [
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>{t('assetModeller.modelOverview.tagId')}</div>
                </div>
            ),
            dataIndex: 'tagId',
            key: 'tagId',
            width: '150px',
            render: (_: any, record: any) => (
                <>
                    <div
                        className="attributeTableWrapper__nameData"
                        onClick={() => {}}
                    >
                        <div className="blaTableWrapper__status">
                            <span className="fs-14 fw-500 name">
                                {record?.tagId}
                            </span>
                        </div>
                    </div>
                </>
            ),
        },

        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>{t('assetModeller.modelOverview.tagName')}</div>
                    <div className="sortArrows">
                    <UpArrow
                                cursor="pointer"
                                onClick={() => {
                                    setSortColumn(
                                        mappedTagsSortOption?.TAG_NAME
                                    );
                                    setSortOrder(sortOrder?.ascending);
                                    setPage(PAGE);
                                }}
                            />
                            <DownArrow
                                fill="white"
                                cursor="pointer"
                                onClick={() => {
                                    setSortColumn(
                                        mappedTagsSortOption?.TAG_NAME
                                    );
                                    setSortOrder(sortOrder?.descending);
                                    setPage(PAGE);
                                }}
                            />
                    </div>
                </div>
            ),
            dataIndex: 'tagName',
            key: 'tagName',
            width: '200px',
            render: (_: any, record: any) => (
                <>
                    <div>{record?.tagName}</div>
                </>
            ),
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>{t('assetModeller.modelOverview.deviceName')}</div>
                </div>
            ),
            dataIndex: 'deviceName',
            key: 'deviceName',
            width: '200px',
            render: (_: any, record: any) => (
                <>
                    <div className="attributeTableWrapper__status">
                        {record?.deviceName}
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>{t('assetModeller.modelOverview.uom')}</div>
                </div>
            ),
            key: 'uom',
            dataIndex: 'uom',
            width: '200px',
            render: (_: any, record: any) => (
                <>
                    <div>{record?.dataType?.unit}</div>
                </>
            ),
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>{t('assetModeller.modelOverview.assignedStatus')}</div>
                </div>
            ),
            key: 'createdOn',
            dataIndex: 'createdOn',
            width: '200px',
            render: (_: any, record: any) => (
                <>
                    <div>
                        {Object.prototype.hasOwnProperty.call(
                            record,
                            'parentNode'
                        )
                            ? 'Assigned'
                            : 'Unassigned'}
                    </div>
                </>
            ),
        },
    ];
    return (
        <>
            {loader ? (
                <div className="view__loader">
                    <Spin />
                </div>
            ) : (
                <>
                    <div className="tagOverview">
                        <Table
                            rowSelection={{
                                type: 'checkbox',
                                ...rowSelection,
                                selectedRowKeys: selectedRowIds,
                            }}
                            columns={columns}
                            dataSource={data}
                            pagination={false}
                            scroll={{ y: '65px' }}
                        />
                    </div>
                </>
            )}
        </>
    );
};
export default OTTable;
