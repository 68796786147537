import React from 'react';
import { Modal, Button, Table } from 'antd';
import './index.scss';
import { useSelector } from 'react-redux';

const RegressionDetailsModal = ({
  isModalVisible,
  setIsModalVisible,
}: any): JSX.Element => {
  const aggregatedScatterChartData = useSelector(
    (state: any) => state?.dataVisualization?.aggregatedScatterChartData
  );

  // Dynamically determine the active regression type
  const regressionFunction = aggregatedScatterChartData?.regressionFunction || {};
  const activeRegressionType = Object.keys(regressionFunction)[0]; // E.g., 'POLYNOMIAL_REGRESSION9', 'LINEAR_REGRESSION'
  const regressionProps = regressionFunction[activeRegressionType]?.props;
  const coefficients = regressionFunction[activeRegressionType]?.coefficients || [];

 
  const handleCancel = (): void => {
    setIsModalVisible(false);
  };

  const columns = [
    { title: 'Metric', dataIndex: 'metric', key: 'metric', width: '60%' },
    { title: 'Value', dataIndex: 'value', key: 'value' },
  ];

  const dataSource = [
    { key: '1', metric: 'Number of observations (Modeled)', value: regressionProps?.count || 'N/A' },
    { key: '2', metric: 'Degrees of freedom (Model)', value: regressionProps?.degModel || 'N/A' },
    { key: '3', metric: 'Degrees of freedom (Residual)', value: regressionProps?.degResudual || 'N/A' },
    { key: '4', metric: 'SSE (Sum of Squared Errors)', value: regressionProps?.sse || 'N/A' },
    { key: '5', metric: 'MSE (Mean of Squared Errors)', value: regressionProps?.mse || 'N/A' },
    { key: '6', metric: 'R-Squared', value: regressionProps?.r2 || 'N/A' },
    { key: '7', metric: 'Standard Error', value: regressionProps?.standardError || 'N/A' },
    { key: '8', metric: 'p-value (significance)', value: regressionProps?.pValue || 'N/A' },
  ];

  const coefficientColumns = [
    { title: 'Variable', dataIndex: 'variable', key: 'variable' },
    { title: 'Coefficient', dataIndex: 'coefficient', key: 'coefficient' },
    { title: 'Standard Error', dataIndex: 'standardError', key: 'standardError' },
    { title: 't-Statistic', dataIndex: 'tStatistic', key: 'tStatistic' },
    { title: 'p-value', dataIndex: 'pValue', key: 'pValue' },
  ];

  return (
    <Modal
      title={`Regression Details: ${activeRegressionType}`}
      className="regression-details-modal"
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={[<Button key="cancel" onClick={handleCancel}>Close</Button>]}
    >
      <div>
        <p>
          <strong>Model Formula:</strong> {regressionProps?.formula || 'N/A'}
        </p>
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          bordered
          size="small"
          style={{ marginBottom: '20px' }}
        />
        {coefficients?.length > 0 && (
          <Table
            columns={coefficientColumns}
            dataSource={coefficients?.map((coeff:any, index:any):any => ({
              key: `${index + 1}`,
              ...coeff,
            }))}
            pagination={false}
            bordered
            size="small"
          />
        )}
      </div>
    </Modal>
  );
};

export default RegressionDetailsModal;
