import { Button, Card, Col, Input, Row } from 'antd';
import './index.scss';
import { ReactComponent as BackIcon } from 'assets/icons/backIcon.svg';
import { ReactComponent as HealthyIcon } from 'assets/icons/healthyMonitoringIcon.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warningMonitoringIcon.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/errorMonitoringIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getMonitoringById, reprocessMonitoring, setMonitoringState } from 'redux/actions/BusinessIntegratorActions/monitoringActions';
import { ConnectionsState, ConnectionsStatus, MonitoringState, MonitoringType, TabName } from 'types/enums/businessIntegratorEnum';
import { DATE_FORMAT } from 'utils/constants';
import moment from 'moment';
import { setConnectionsState } from 'redux/actions/BusinessIntegratorActions/connectionsActions';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

const ViewMonitoring: React.FC<any> = ({
    setActiveTabKey
}): any => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { currentTab, paramAdapterId } = useParams();
    const [json, setJson] = useState<any>();
    const { TextArea } = Input;
    const monitoringById = useSelector(
        (state: any) => state.businessIntegrator.monitoring.monitoringById
    );
    const monitoringState = useSelector(
        (state: any) => state.businessIntegrator.monitoring.monitoringState
    );
    useEffect(() => {
        if (
            currentTab !== TabName.monitoring
        ) {
            dispatch(setMonitoringState(MonitoringState.tableView))
        }
    }, [currentTab]);
    useEffect(() => {
        setJson(monitoringById?.reason)
    }, [monitoringById])

    useEffect(() => {
        currentTab === TabName.monitoring && monitoringState !== MonitoringState.tableView &&
            dispatch(
                getMonitoringById(paramAdapterId)
            );
    }, [monitoringState, paramAdapterId]);

    const getMessageText = (status: any, type: any): any => {
        if(type ===  MonitoringType.message ) {
            switch (status) {
                case ConnectionsStatus.healthy:
                    return "The Message was successfully processed.";
                case ConnectionsStatus.warning:
                    return "The Message was processed with some warnings.";
                case ConnectionsStatus.error:
                    return "The Message encountered an error during processing.";
            }
        }
        else if (type === MonitoringType.connection){
            switch (status) {
                case ConnectionsStatus.healthy:
                    return "Connection is running fine.";
                case ConnectionsStatus.warning:
                    return "Connection is running fine, however with some warnings.";
                case ConnectionsStatus.error:
                    return "Connection has encountered an error.";
            }

        }
    };
    return (
        <>
            <div className="monitoringViewWrapper">
                <Card bordered={false}>
                    <Row className="monitoringViewWrapper__header">
                        <Col
                            className="monitoringViewWrapper__backIcon"
                            span={0.5}
                        >
                            <BackIcon
                                onClick={() => {
                                    dispatch(setMonitoringState(MonitoringState.tableView))
                                }}
                            />
                        </Col>
                        <Col
                            className="monitoringViewWrapper__title fw-400 fs-16"
                            span={18}
                        >
                            {'View'}
                        </Col>
                    </Row>
                    <Card 
                        className={
                            monitoringById?.connectionStatus === ConnectionsStatus.healthy ?
                            'monitoringViewWrapper__headingCardHealthy' :
                            monitoringById?.connectionStatus === ConnectionsStatus.error ?
                            'monitoringViewWrapper__headingCardError' :
                            'monitoringViewWrapper__headingCardWarning'
                        }
                    >
                        <Row>
                            <Col
                                span={2}
                            >
                                {
                                    monitoringById?.connectionStatus === ConnectionsStatus.healthy ?
                                    <HealthyIcon /> :
                                    monitoringById?.connectionStatus === ConnectionsStatus.warning ?
                                    <WarningIcon /> :
                                    <ErrorIcon />
                                }
                            </Col>
                            <Col
                                className='cardTitle'
                                span={18}
                            >
                                <div className='fw-500 fs-24'>{monitoringById?.connectionStatus}</div>
                                <div className='fw-400 fs-16'>{getMessageText(monitoringById?.connectionStatus, monitoringById?.monitoringType)}</div>
                            </Col>
                            <Col
                                className='cardButton'
                                span={4}
                            >
                                {
                                    monitoringById?.monitoringType === "Message" ? 
                                    <Button 
                                        type="primary"
                                        onClick={() => {
                                            dispatch(reprocessMonitoring({monitorId: monitoringById?.id, jsonResponse: JSON.parse(json)}))
                                        }}
                                    >
                                        Reprocess Message
                                    </Button> :
                                    <Button 
                                        type="primary" 
                                        onClick={() => {
                                            dispatch(
                                                setConnectionsState(
                                                    ConnectionsState.viewConnections
                                                )
                                            );
                                            setActiveTabKey(TabName.connections)
                                            navigate(
                                                `/connect/business-integrator/connections`
                                            );
                                            navigate(
                                                `/connect/business-integrator/connections/view/${monitoringById?.connectionResponse?.id}`
                                            );
                                            dispatch(setMonitoringState(MonitoringState.tableView))
                                        }}
                                    >
                                        Go to Connection Page
                                    </Button>
                                }
                                
                            </Col>
                        </Row>

                    </Card>
                    <Card className='monitoringViewWrapper__detailsCard'>
                        <Row >
                            <Col
                                span={3}
                                className="fw-400 fs-14"
                            >
                                {"ID"}
                            </Col>
                            <Col
                                span={8}
                            >
                                <Input disabled value={monitoringById?.monitoringType === "Message" ? monitoringById?.messageMonitoringName : monitoringById?.connectionResponse?.name}/>
                            </Col>
                            <Col
                                span={3}
                                className="fw-400 fs-14"
                            >
                                {"Monitoring Type"}
                            </Col>
                            <Col
                                span={8}
                            >
                                <Input disabled value={monitoringById?.monitoringType}/>
                            </Col>
                        </Row>
                        <Row >
                            <Col
                                span={3}
                                className="fw-400 fs-14"
                            >
                                {"Adapter Type"}
                            </Col>
                            <Col
                                span={8}
                            >
                                <Input disabled value={monitoringById?.connectionResponse?.adapter?.name}/>
                            </Col>
                            <Col
                                span={3}
                                className="fw-400 fs-14"
                            >
                                {"Direction"}
                            </Col>
                            <Col
                                span={8}
                            >
                                <Input disabled value={monitoringById?.connectionResponse?.direction?.name}/>
                            </Col>
                        </Row>
                        <Row >
                            <Col
                                span={3}
                                className="fw-400 fs-14"
                            >
                                {"Timestamp"}
                            </Col>
                            <Col
                                span={8}
                            >
                                <Input disabled value={moment(monitoringById?.pollingTime).format(DATE_FORMAT)}/>
                            </Col>
                            <Col
                                span={3}
                                className="fw-400 fs-14"
                            >
                                {"Label"}
                            </Col>
                            <Col
                                span={8}
                            >
                                <Input disabled value={monitoringById?.monitoringType === "Message" ? monitoringById?.operationMappingResponse?.labels?.join(",") : monitoringById?.connectionResponse?.labelResponse?.map((item: any) => {return item?.name})?.join(",")} />
                            </Col>
                        </Row>
                        <Row >
                            <Col
                                span={3}
                                className="fw-400 fs-14"
                            >
                                {"Operation Id"}
                            </Col>
                            <Col
                                span={8}
                            >
                                <Input disabled value={monitoringById?.monitoringType === "Message" ? monitoringById?.operationMappingResponse?.operationName : "NA"}/>
                            </Col>
                            <Col
                                span={3}
                                className="fw-400 fs-14"
                            >
                                {"Mapping Id"}
                            </Col>
                            <Col
                                span={8}
                            >
                                <Input disabled value={monitoringById?.monitoringType === "Message" ? monitoringById?.operationMappingResponse?.mappingName : "NA"}/>
                            </Col>
                        </Row>
                        <Row >
                            <Col
                                span={3}
                                className="fw-400 fs-14"
                            >
                                {"Created By"}
                            </Col>
                            <Col
                                span={8}
                            >
                                <Input disabled value={monitoringById?.createdBy}/>
                            </Col>
                            <Col
                                span={3}
                                className="fw-400 fs-14"
                            >
                                {"Created On"}
                            </Col>
                            <Col
                                span={8}
                            >
                                <Input disabled value={moment(monitoringById?.createdOn).format(DATE_FORMAT)}/>
                            </Col>
                        </Row>
                        <Row >
                            <Col
                                span={3}
                                className="fw-400 fs-14"
                            >
                                {"Changed By"}
                            </Col>
                            <Col
                                span={8}
                            >
                                <Input disabled value={monitoringById?.updatedBy}/>
                            </Col>
                            <Col
                                span={3}
                                className="fw-400 fs-14"
                            >
                                {"Changed On"}
                            </Col>
                            <Col
                                span={8}
                            >
                                <Input disabled value={moment(monitoringById?.updatedOn).format(DATE_FORMAT)}/>
                            </Col>
                        </Row>
                        {
                            monitoringById?.monitoringType === "Message" &&
                            <>
                                <Row><span>{"Whole Message"}</span><TextArea key={`${monitoringById?.reason}`} className='fs-20 fw-500' rows={5} onChange={((e: any) => {setJson(e.target.value)})}  defaultValue={monitoringById?.reason}></TextArea></Row>
                            </>
                        }
                        
                    </Card>
                </Card>
            </div>
        </>
    );
};

export default ViewMonitoring;
