import Table, { type ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { ReactComponent as UpArrow } from 'assets/icons/upArrowIcon.svg';
import { ReactComponent as DownArrow } from 'assets/icons/downArrowIcon.svg';
import { useSelector } from 'react-redux';
import EmptyDataComponent from 'components/common/EmptyDataComponent';
import { Spin, message } from 'antd';
import { dataVisualization, sortOrder } from 'types/enums';
import { useTranslation } from 'react-i18next';
import { maxRowSelectionCount } from 'utils/constants';
import './index.scss';
const KpiTable: React.FC<any> = ({
    data,
    searchKpi,
    setSortColumn,
    setSortOrder,
    selectedDevice,
    setSelectedKpiRows,
    selectedKpiRows,
    maxRange,
}) => {
    const { t } = useTranslation('translation');
    const [tableData, setTableData] = useState<any>([]);
    const kpiDataLoader: any = useSelector(
        (state: any) => state.dataVisualization.kpiDataLoader
    );

    const tableDataMapper = (): [] => {
        const temp: any = [];
        data?.records?.map((item: any, index: number) => {
            temp.push({
                ...item,
                key: item.ruleId,
                name: item.name,
            });
        });
        return temp;
    };

    useEffect(() => {
        setTableData(tableDataMapper());
    }, [data]);

    const SearchNoDataText = (
        <EmptyDataComponent
            customClassName="SearchEmptyComponent"
            textValue={t('dataVisualization.noResultText')}
            loading={kpiDataLoader}
        />
    );

    const rowSelection: any = {
        onSelect: (record: any, selected: boolean, selectedRows: any) => {
            if (maxRange && selected) {
                message.info(
                    `${t('dataVisualization.maxRow')} ${maxRowSelectionCount}`
                );
                return;
            }
            if (selected) {
                setSelectedKpiRows([...selectedKpiRows, record]);
            } else {
                setSelectedKpiRows(
                    selectedKpiRows.filter(
                        (item: any) => item.ruleId !== record.ruleId
                    )
                );
            }
        },
        selectedRowKeys: selectedKpiRows?.map((item: any) => item.ruleId),
    };
    const TableColumns: ColumnsType<any> = [
        {
            key: 'kpiName',
            title: (
                <>
                    <div className="blaTableWrapper__columnTitle">
                        <div>{t('dataVisualization.kpiName')}</div>
                        <div className="sortArrows">
                            <UpArrow
                                cursor="pointer"
                                onClick={() => {
                                    setSortOrder(sortOrder.ascending);
                                    setSortColumn(dataVisualization.KPI_NAME);
                                }}
                            />
                            <DownArrow
                                fill="white"
                                cursor="pointer"
                                onClick={() => {
                                    setSortOrder(sortOrder.descending);
                                    setSortColumn(dataVisualization.KPI_NAME);
                                }}
                            />
                        </div>
                    </div>
                </>
            ),
            dataIndex: 'kpiName',
            render: (_: any, record: any) => {
                return (
                    <>
                        <div>{record?.name}</div>
                    </>
                );
            },
        },
        {
            key: 'assetName',
            title: (
                <>
                    <div className="blaTableWrapper__columnTitle">
                        <div>{t('dataVisualization.assetName')}</div>
                        <div className="sortArrows">
                            <UpArrow
                                cursor="pointer"
                                onClick={() => {
                                    setSortOrder(sortOrder.ascending);
                                    setSortColumn(dataVisualization.ASSET_NAME);
                                }}
                            />
                            <DownArrow
                                fill="white"
                                cursor="pointer"
                                onClick={() => {
                                    setSortOrder(sortOrder.descending);
                                    setSortColumn(dataVisualization.ASSET_NAME);
                                }}
                            />
                        </div>
                    </div>
                </>
            ),
            dataIndex: 'assetName',
            render: (_: any, record: any) => {
                return (
                    <>
                        <div>{record?.assetName}</div>
                    </>
                );
            },
        },
    ];

    return (
        <>
            {kpiDataLoader ? (
                <div className="view__loader">
                    <Spin />
                </div>
            ) : (
                <Table
                    className="kpiTable"
                    rowSelection={{
                        type: 'checkbox',
                        ...rowSelection,
                        selectedRowKeys: selectedKpiRows?.map((item: any) => {
                            return item?.ruleId;
                        }),
                    }}
                    pagination={false}
                    columns={TableColumns}
                    size="small"
                    dataSource={
                        selectedDevice &&
                        tableData?.filter((item: any) => item.isSelectable)
                    }
                    locale={
                        searchKpi?.length && { emptyText: SearchNoDataText }
                    }
                    scroll={{ y: 'calc(100vh - 735px)' }}
                />
            )}
        </>
    );
};
export default KpiTable;
