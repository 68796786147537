import * as types from 'redux/types/formTypes';

export const getFormList = (payload?: string | null): any => ({
    type: types.GET_FORM_LIST,
    payload: payload,
});

export const getFormDetails = (payload?: string | null): any => ({
    type: types.GET_FORM_DETAILS,
    payload: payload,
});

export const setFormData = (payload?: any): any => ({
    type: types.SET_FORM_DATA,
    payload: payload,
});

export const setFormDataInTable = (payload?: any): any => ({
    type: types.SET_FORM_DATA_TABLE,
    payload: payload,
});

export const updateFormDataInTable = (payload?: any): any => ({
    type: types.UPDATE_FORM_DATA_TABLE,
    payload: payload,
});
export const getActionslist = (payload?: string | null): any => ({
    type: types.GET_ACTION_LIST,
    payload: payload,
});
