import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import './index.scss';
import SideDrawer from 'components/common/SideDrawer';
import { ReactComponent as One2One } from 'assets/icons/One2One.svg';
import { ReactComponent as One2Many } from 'assets/icons/One2Many.svg';
import { ReactComponent as Many2One } from 'assets/icons/Many2One.svg';
import { ReactComponent as Many2Many } from 'assets/icons/Many2Many.svg';
import {
    Button,
    Card,
    Form,
    Input,
    Popconfirm,
    Select,
    Table,
    TreeSelect,
    Checkbox,
    Divider,
    Row,
    Col,
} from 'antd';
import { useEffect, useState } from 'react';
import {
    BUTTONTYPE,
    TABLETYPE,
    EMPTY,
    relationshipType,
    dataTypeWithId,
} from 'types/enums';
import {
    createTable,
    getFeildTypeList,
    getTableList,
    setTableState,
    getTypeList,
} from 'redux/actions/ConfigureActions/tableAction';
import { useDispatch, useSelector } from 'react-redux';
import { parseJwt } from 'utils/jwtTokenFunction';
import CustomButton from 'components/common/CustomButton';
import ConfirmationModal from 'components/common/Modals/ConfirmationModal';
import { ReactComponent as QuestionMarkIcon } from 'assets/icons/questionMark.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/DeleteIconTable.svg';
import { useTranslation } from 'react-i18next';
import { getUomList } from 'redux/actions/ConfigureActions/attributeActions';

const CreateTable: React.FC<any> = () => {
    const { Option } = Select;
    const [selectedIcon, setSelectedIcon] = useState<relationshipType | null>(
        relationshipType.ONE_TO_ONE
    );
    const [nameFieldValue, setNameFieldValue] = useState(EMPTY.string);
    const [descriptionFieldValue, setDescriptionFieldValue] = useState(
        EMPTY.string
    );
    const [typeFieldValue, setTypeFieldValue] = useState(EMPTY.string);
    const [editDrawer, setEditDrawer] = useState(false);
    const [dataSource, setDataSource] = useState<any>([
        {
            key: 0,
            columnName: '',
            dataType: '',
            uom: '',
            isPrimaryKey: false,
            isNotNull: false,
            isNotEditable: false,
            referenceInfoList: [],
        },
    ]);
    const [selectedFormId, setSelectedFormId] = useState('');
    const [selectedFormName, setSelectedFormName] = useState('');

    const handleClearDrawer = (): void => {
        setSelectedTableValue(null);
        setSelectedColumnValue(null);
        setSelectedIcon(null);
    };

    const handleSaveDrawer = (): void => {
        const newDataSource = dataSource.map((item: any) => {
            // Check if referenceInfoList exists and is not empty
            const referenceInfoList = item.referenceInfoList || [];

            // If empty, add a new referenceInfo object
            const updatedReferenceInfoList =
                referenceInfoList.length > 0
                    ? referenceInfoList.map((referenceInfo: any) => ({
                          ...referenceInfo,
                          referenceTableId: selectedTableValue?.value,
                          referenceColumnId: selectedColumnValue,
                          tableRelationshipType: selectedIcon,
                      }))
                    : [
                          {
                              referenceTableId: selectedTableValue?.value,
                              referenceColumnId: selectedColumnValue,
                              tableRelationshipType: selectedIcon,
                          },
                      ];

            return {
                ...item,
                referenceInfoList: updatedReferenceInfoList,
            };
        });

        setDataSource(newDataSource);
        setEditDrawer(false);
        handleClearDrawer();
    };

    const dispatch = useDispatch();
    const [count, setCount] = useState(1);
    const [selectedTableValue, setSelectedTableValue] = useState<any>(null);
    const [selectedColumnValue, setSelectedColumnValue] = useState<any>(null);
    const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
    const { t } = useTranslation('translation');
    const uomList = useSelector(
        (state: any) => state?.configure?.attributes?.uomList
    );
    const createTableResponse = useSelector(
        (state: any) => state?.configure?.table?.createTable
    );
    const feildTypeList = useSelector(
        (state: any) => state?.configure?.table?.feildTypeList
    );
    const typeList = useSelector(
        (state: any) => state?.configure?.table?.typeList
    );

    const tableData = useSelector(
        (state: any) => state.configure?.table?.tableList
    );
    const allFormsList = useSelector((state: any) => state?.formio?.formsList);

    const handleIconClick = (iconType: relationshipType): void => {
        setSelectedIcon(iconType);
    };

    const handleFormChange = (value: string, option: any) :any=> {
        setSelectedFormId(value); 
        setSelectedFormName(option?.children);
    };
    const details = parseJwt();

    useEffect(() => {
        dispatch(getUomList());
        dispatch(getFeildTypeList());
        dispatch(getTypeList());
        dispatch(
            getTableList({
                page: -1,
            })
        );
    }, []);

    useEffect(() => {
        if (createTableResponse) {
            dispatch(setTableState(TABLETYPE.display));
        }
    }, [createTableResponse]);

    const convertDataToTreeData = (data: any): any => {
        return (
            data &&
            Object.keys(data).map((category) => ({
                title: category,
                value: category,
                selectable: false,
                children: data[category].map((item: any) => ({
                    key: item.id,
                    title: item.name,
                    value: item.id,
                    id: item.id,
                    abbreviation: item.abbreviation,
                })),
            }))
        );
    };

    const filterTreeNode = (input: any, treeNode: any): any => {
        const title = treeNode.title.toLowerCase();
        return title.includes(input.toLowerCase());
    };

    const columns = [
        {
            title: 'Column Name' + ' *',
            dataIndex: 'columnName',
            key: 'columnName',
            width: '30%',
            className: 'columnName',
            render: (text: any, record: any) => (
                <Input
                    value={text}
                    onChange={(e) => {
                        handleInputChange(e, 'columnName', record.key);
                    }}
                    placeholder={t(
                        'tableDefinition.CreateTable.columnPlaceHolder'
                    )}
                    bordered={false}
                />
            ),
        },
        {
            title: 'Field Type',
            dataIndex: 'fieldType',
            key: 'fieldType',
            width: '30%',
            className: 'fieldType',
            render: (text: any, record: any) => (
                <Select
                    style={{ width: '100%' }}
                    showSearch
                    allowClear
                    value={text}
                    bordered={false}
                    onChange={(value) => {
                        handleDropdownChange(value, 'dataType', record.key);
                    }}
                    placeholder={t(
                        'tableDefinition.CreateTable.fieldTypePlaceHolder'
                    )}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        (option?.label as string)
                            .toLowerCase()
                            .includes(input.toLowerCase())
                    }
                    options={feildTypeList?.map(
                        (item: { id: string; name: string }) => ({
                            value: item?.id,
                            label: item?.name,
                            key: item?.id,
                        })
                    )}
                />
            ),
        },
        {
            title: 'UOM',
            dataIndex: 'uomId',
            key: 'uom',
            width: '30%',
            className: 'uom',
            render: (text: any, record: any) => (
                <TreeSelect
                    style={{ width: '100%' }}
                    placeholder={t(
                        'tableDefinition.CreateTable.uomPlaceHolder'
                    )}
                    value={text}
                    bordered={false}
                    onChange={(value) => {
                        handleDropdownChange(value, 'uom', record.key);
                    }}
                    filterTreeNode={filterTreeNode}
                    dropdownStyle={{
                        maxHeight: 400,
                        overflow: 'auto',
                    }}
                    allowClear
                    treeData={convertDataToTreeData(uomList?.uomMap || [])}
                ></TreeSelect>
            ),
        },
        {
            title: 'Primary Key',
            dataIndex: 'isPrimaryKey',
            key: 'isPrimaryKey',
            width: '20%',
            render: (text: any, record: any) => (
                <Checkbox
                    checked={record.isPrimaryKey}
                    onChange={(e): any => {
                        handlePermissionChange(
                            (e.target as HTMLInputElement).checked,
                            'isPrimaryKey',
                            record.key
                        );
                    }}
                ></Checkbox>
            ),
        },
        {
            title: 'Not Null',
            dataIndex: 'isNotNull',
            key: 'isNotNull',
            width: '20%',
            render: (text: any, record: any) => (
                <Checkbox
                    checked={record.isNotNull}
                    onChange={(e): any => {
                        handlePermissionChange(
                            (e.target as HTMLInputElement).checked,
                            'isNotNull',
                            record.key
                        );
                    }}
                    style={{ marginLeft: 8 }}
                ></Checkbox>
            ),
        },
        {
            title: 'Not Editable',
            dataIndex: 'isNotEditable',
            key: 'isNotEditable',
            width: '20%',
            render: (text: any, record: any) => (
                <Checkbox
                    checked={record?.isNotEditable}
                    onChange={(e): any => {
                        handlePermissionChange(
                            (e.target as HTMLInputElement)?.checked,
                            'isNotEditable',
                            record?.key
                        );
                    }}
                    style={{ marginLeft: 8 }}
                ></Checkbox>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: '20%',
            className: 'column__action',
            render: (_: any, record: any) =>
                dataSource.length >= 1 ? (
                    <Popconfirm
                        title={t('commonStr.sureToDelete')}
                        onConfirm={() => handleDelete(record.key)}
                    >
                        <a className="deleteicon">
                            <DeleteIcon />
                        </a>
                    </Popconfirm>
                ) : null,
        },
    ];

    const handleInputChange = (e: any, field: any, key: any): any => {
        const newData = dataSource.map((item: any) => {
            if (item.key === key) {
                return { ...item, [field]: e.target.value };
            }
            return item;
        });

        setDataSource(newData);
    };

    const handleDropdownChange = (value: any, field: any, key: any): any => {
        const newData = dataSource.map((item: any) => {
            if (item.key === key) {
                return { ...item, [field]: value };
            }
            return item;
        });
        if (value === dataTypeWithId.linkRecords) {
            setEditDrawer(true);
        }

        setDataSource(newData);
    };

    const handlePermissionChange = (
        checked: boolean,
        field: string,
        key: any
    ): void => {
        const newData = dataSource.map((item: any) => {
            if (item.key === key) {
                return {
                    ...item,
                    [field]: checked,
                };
            }
            return item;
        });

        setDataSource(newData);
    };

    const handleAdd = (): void => {
        const newData = {
            key: count,
            columnName: '',
            dataType: '',
            uom: '',
            isPrimaryKey: false,
            isNotNull: false,
            isNotEditable: false,
            referenceInfoList: [],
        };

        setDataSource([...dataSource, newData]);
        setCount(count + 1);
    };

    const handleDelete = (key: any): any => {
        const newData = dataSource.filter((item: any) => item.key !== key);
        setDataSource(newData);
    };

    const checkAllRequiredFields = (dataSource: any): boolean => {
        for (const obj of dataSource) {
            for (const key in obj) {
                if (
                    obj[key] === undefined ||
                    obj[key] === '' ||
                    obj?.columnName?.trim() === ''
                ) {
                    return true;
                }
            }
        }
        if (!dataSource?.length) {
            return true;
        }
        if (!nameFieldValue) {
            return true;
        }
        if (!typeFieldValue) {
            return true;
        }
        return false;
    };

    const handleConfirmationModalYes = (): any => {
        const createTablePayload = dataSource.map((item: any) => {
            return {
                key: item.key,
                columnName: item.columnName,
                dataType: item.dataType,
                uom: item.uom,
                uomId: item.uom,
                dataTypeId: item.dataType,
                name: item.columnName,
                columnSequence: item.key,
                isPrimaryKey: item.isPrimaryKey,
                isCompositeKey: false,
                isNotNull: item.isNotNull,
                isNotEditable: item?.isNotEditable,
                description: item.description || 'Description of the column',
                referenceInfoList:
                    item?.dataType === dataTypeWithId.linkRecords // Checking ID used for linkRecords to open drawer
                        ? item.referenceInfoList?.length > 0
                            ? item.referenceInfoList.map(
                                  (referenceInfo: any) => {
                                      return {
                                          referenceTableId:
                                              referenceInfo.referenceTableId,
                                          referenceColumnId:
                                              referenceInfo.referenceColumnId,
                                          tableRelationshipType:
                                              referenceInfo.tableRelationshipType,
                                      };
                                  }
                              )
                            : []
                        : [],
            };
        });

        const payload = {
            name: nameFieldValue,
            desc: descriptionFieldValue,
            type: typeFieldValue,
            columnList: createTablePayload,
            requestedBy: details.username,
            formId:selectedFormId,
            formName:selectedFormName
        };
        dispatch(createTable(payload));
        setIsSaveModalOpen(false);
    };

    const handleNameChange = (value: any): any => {
        setNameFieldValue(value);
    };

    const handleDescriptionChange = (value: any): any => {
        setDescriptionFieldValue(value);
    };
    const handleTypeChange = (value: any): any => {
        setTypeFieldValue(value);
    };

    return (
        <div className="create-table-template">
            <Card>
                <div className="wrapper">
                    <div className="header">
                        <ArrowLeftOutlined
                            onClick={() =>
                                dispatch(setTableState(TABLETYPE.display))
                            }
                            style={{
                                color: 'rgba(0, 0, 0, 0.45)',
                            }}
                        />
                        <div>
                            {t(
                                'tableDefinition.CreateTable.createTableTemplate'
                            )}
                        </div>
                    </div>
                </div>
                <div className="form">
                    <div className="name-description">
                        <Form layout="vertical">
                            <Form.Item
                                label={t('tableDefinition.name')}
                                name="name"
                                className="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input name!',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={t(
                                        'tableDefinition.namePlaceHolder'
                                    )}
                                    onChange={(e) =>
                                        handleNameChange(e.target.value)
                                    }
                                />
                            </Form.Item>
                            <Form.Item
                                label={t('tableDefinition.description')}
                                name="description"
                                className="description"
                            >
                                <Input.Group compact>
                                    <Input
                                        style={{ width: 'calc(100% - 88px)' }} // Adjust width to make space for the button
                                        placeholder={t(
                                            'tableDefinition.descriptionPlaceHolder'
                                        )}
                                        onChange={(e) =>
                                            handleDescriptionChange(
                                                e.target.value
                                            )
                                        }
                                    />
                                </Input.Group>
                            </Form.Item>
                            <Form.Item
                                label={t('users.userDetails.forms')}
                                name="formIds"
                                className="formIds"
                            >
                                <Select
                                    maxTagCount="responsive"
                                    allowClear={true}
                                    popupClassName={'CustomOverlay'}
                                    placeholder={t(
                                        'users.userDetails.formsPlaceholder'
                                    )}
                                    onChange={handleFormChange}
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option?.label as string)
                                            ?.toLowerCase()
                                            ?.includes(input?.toLowerCase())
                                    }
                                >
                                    {allFormsList?.map((item: any) => {
                                        return (
                                            <Option
                                                value={item?._id}
                                                key={item?._id}
                                                label={item?.title}
                                            >
                                                {item?.title}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label={t(
                                    'attributeDefinition.tableColumn.type'
                                )}
                                name="type"
                                className="type"
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder="Select Type"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label as string)
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    options={typeList?.map((item: any) => ({
                                        value: item,
                                        key: item,
                                        label: item,
                                    }))}
                                    onChange={(value) =>
                                        handleTypeChange(value)
                                    }
                                />
                            </Form.Item>
                        </Form>
                        <SideDrawer
                            title="Linked Records Details"
                            Open={editDrawer}
                            onClose={() => {
                                setEditDrawer(false);
                                handleClearDrawer();
                            }}
                        >
                            <Form layout="vertical">
                                <Form.Item
                                    name="fieldType"
                                    label="Field Type"
                                    initialValue="Linked Records"
                                    rules={[
                                        { required: true, message: 'Required' },
                                    ]}
                                >
                                    <Input
                                        disabled
                                        placeholder={t(
                                            'tableDefinition.CreateTable.linkedRecords'
                                        )}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="linkedTo"
                                    label="Linked To Table"
                                    rules={[
                                        { required: true, message: 'Required' },
                                    ]}
                                >
                                    <Select
                                        style={{ width: '100%' }}
                                        showSearch
                                        allowClear
                                        value={selectedTableValue}
                                        onChange={(__, data) => {
                                            setSelectedTableValue(data);
                                        }}
                                        placeholder={t(
                                            'tableDefinition.CreateTable.selectTable'
                                        )}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            (option?.label as string)
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        options={tableData?.paginatedResponse?.records?.map(
                                            (item: {
                                                id: string;
                                                name: string;
                                            }) => ({
                                                value: item?.id,
                                                label: item?.name,
                                                key: item?.id,
                                                data: item,
                                            })
                                        )}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="columnName"
                                    label="Column Name"
                                    rules={[
                                        { required: true, message: 'Required' },
                                    ]}
                                >
                                    <Select
                                        disabled={!selectedTableValue}
                                        style={{ width: '100%' }}
                                        showSearch
                                        allowClear
                                        value={selectedColumnValue}
                                        onChange={(value) => {
                                            setSelectedColumnValue(value);
                                        }}
                                        placeholder={t(
                                            'tableDefinition.CreateTable.selectColumn'
                                        )}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            (option?.label as string)
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        options={selectedTableValue?.data?.columnList?.map(
                                            (item: {
                                                id: string;
                                                name: string;
                                            }) => ({
                                                value: item?.id,
                                                label: item?.name,
                                                key: item?.id,
                                            })
                                        )}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="linkType"
                                    label={t(
                                        'tableDefinition.CreateTable.linkType'
                                    )}
                                >
                                    <div className="icon-pair-container">
                                        <div
                                            className={`icon-container ${
                                                selectedIcon ===
                                                relationshipType.ONE_TO_ONE
                                                    ? 'selected'
                                                    : ''
                                            }`}
                                            onClick={() => {
                                                handleIconClick(
                                                    relationshipType.ONE_TO_ONE
                                                );
                                            }}
                                        >
                                            <div className="icon-content">
                                                <One2One />
                                            </div>
                                            <div className="icon-label">
                                                {t(
                                                    'tableDefinition.CreateTable.oneToOne'
                                                )}
                                            </div>
                                        </div>

                                        <div
                                            className={`icon-container ${
                                                selectedIcon ===
                                                relationshipType.ONE_TO_MANY
                                                    ? 'selected'
                                                    : ''
                                            }`}
                                            onClick={() => {
                                                handleIconClick(
                                                    relationshipType.ONE_TO_MANY
                                                );
                                            }}
                                        >
                                            <div className="icon-content">
                                                <One2Many />
                                            </div>
                                            <div className="icon-label">
                                                {t(
                                                    'tableDefinition.CreateTable.oneToMany'
                                                )}
                                            </div>
                                        </div>

                                        <div
                                            className={`icon-container ${
                                                selectedIcon ===
                                                relationshipType.MANY_TO_ONE
                                                    ? 'selected'
                                                    : ''
                                            }`}
                                            onClick={() => {
                                                handleIconClick(
                                                    relationshipType.MANY_TO_ONE
                                                );
                                            }}
                                        >
                                            <div className="icon-content">
                                                <Many2One />
                                            </div>
                                            <div className="icon-label">
                                                {t(
                                                    'tableDefinition.CreateTable.manyToOne'
                                                )}
                                            </div>
                                        </div>

                                        <div
                                            className={`icon-container ${
                                                selectedIcon ===
                                                relationshipType.MANY_TO_MANY
                                                    ? 'selected'
                                                    : ''
                                            }`}
                                            onClick={() => {
                                                handleIconClick(
                                                    relationshipType.MANY_TO_MANY
                                                );
                                            }}
                                        >
                                            <div className="icon-content">
                                                <Many2Many />
                                            </div>
                                            <div className="icon-label">
                                                {t(
                                                    'tableDefinition.CreateTable.manytoMany'
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Form.Item>

                                <div className="addTableDrawer__button">
                                    <Divider />
                                    <Row className="addTableDrawer__footerButtons">
                                        <Col
                                            span={4}
                                            className="addTagDrawer__cancelButton"
                                        >
                                            <CustomButton
                                                type={BUTTONTYPE.cancel}
                                                disabled={false}
                                                handleClick={() => {
                                                    setEditDrawer(false);
                                                    handleClearDrawer();
                                                }}
                                            />
                                        </Col>
                                        <Col
                                            span={4}
                                            className="addTableDrawer__saveButton"
                                        >
                                            <CustomButton
                                                type={BUTTONTYPE.save}
                                                disabled={
                                                    !selectedTableValue ||
                                                    !selectedColumnValue ||
                                                    !selectedIcon
                                                }
                                                typeOfButton={'submit'}
                                                handleClick={handleSaveDrawer}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </Form>
                        </SideDrawer>
                        <div className="EditTableCreateTable">
                            <div className="EditTableCreateTable__Wrapper__button">
                                <Button
                                    onClick={handleAdd}
                                    className="custom-add-button"
                                    type="default"
                                    style={{
                                        marginBottom: 16,
                                    }}
                                    icon={<PlusOutlined className="addIcon" />}
                                >
                                    {t('UomConfigurator.addClassModal')}
                                </Button>
                            </div>

                            <div className="EditTableCreateTable__Wrapper__Table">
                                <Table
                                    dataSource={dataSource}
                                    columns={columns}
                                    pagination={false}
                                    scroll={{ y: 'calc(100vh - 64vh)' }}
                                />
                            </div>
                            <div className="EditTableCreateTable__Wrapper__Footer">
                                <div className="EditTableCreateTable__Wrapper__FooterWrapper">
                                    <CustomButton
                                        type={t('commonStr.cancel')}
                                        disabled={false}
                                        handleClick={() => {
                                            dispatch(
                                                setTableState(TABLETYPE.display)
                                            );
                                        }}
                                    />
                                </div>
                                <div className="EditTableCreateTable__Wrapper__FooterWrapper">
                                    <CustomButton
                                        type={t('commonStr.save')}
                                        disabled={checkAllRequiredFields(
                                            dataSource
                                        )}
                                        typeOfButton={'submit'}
                                        handleClick={() => {
                                            setIsSaveModalOpen(true);
                                        }}
                                    />
                                </div>
                            </div>
                            {isSaveModalOpen && (
                                <ConfirmationModal
                                    customClassName="confirmationModal attributeImplementationModal"
                                    icon={<QuestionMarkIcon />}
                                    open={isSaveModalOpen}
                                    onOk={() => {
                                        handleConfirmationModalYes();
                                    }}
                                    onCancel={() => {
                                        setIsSaveModalOpen(false);
                                    }}
                                    text={t(
                                        'tableDefinition.confirmationMessage'
                                    )}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default CreateTable;
