import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import './index.scss';
import {
    Button,
    Card,
    Checkbox,
    Col,
    Divider,
    Form,
    Input,
    Row,
    Select,
    Spin,
    Table,
    TreeSelect,
} from 'antd';
import { useEffect, useState } from 'react';
import {
    BUTTONTYPE,
    dataTypeWithId,
    relationshipType,
    TABLETYPE,
} from 'types/enums';
import {
    deleteTableRowAction,
    getTableDetails,
    setTableState,
    updateTable,
} from 'redux/actions/ConfigureActions/tableAction';
import { ReactComponent as One2One } from 'assets/icons/One2One.svg';
import { ReactComponent as One2Many } from 'assets/icons/One2Many.svg';
import { ReactComponent as Many2One } from 'assets/icons/Many2One.svg';
import { ReactComponent as Many2Many } from 'assets/icons/Many2Many.svg';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from 'components/common/CustomButton';
import ConfirmationModal from 'components/common/Modals/ConfirmationModal';
import { ReactComponent as QuestionMarkIcon } from 'assets/icons/questionMark.svg';
import { ReactComponent as DeleteRedIcon } from 'assets/icons/deleteIcon.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/DeleteIconTable.svg';
import { parseJwt } from 'utils/jwtTokenFunction';
import { useTranslation } from 'react-i18next';
import SideDrawer from 'components/common/SideDrawer';
const EditTable: React.FC<any> = () => {
    const tableDetails = useSelector(
        (state: any) => state.configure?.table?.tableDetails
    );
    const [nameFieldValue, setNameFieldValue] = useState('');
    const [searchState] = useState('');
    const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
    const [descriptionFieldValue, setDescriptionFieldValue] = useState('');
    const [selectedFormId, setSelectedFormId] = useState('');
    const [selectedFormName, setSelectedFormName] = useState('');
    const [isEditChanges, setIsEditChanges] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<any>(null);
    const { t } = useTranslation('translation');
    const [dataSource, setDataSource] = useState<any>([]);
    const dispatch = useDispatch();
    const uomList = useSelector(
        (state: any) => state?.configure?.attributes?.uomList
    );
    const allFormsList = useSelector((state: any) => state?.formio?.formsList);
    const updateTableResponse = useSelector(
        (state: any) => state?.configure?.table?.updateTable
    );
    const feildTypeList = useSelector(
        (state: any) => state?.configure?.table?.feildTypeList
    );
    const deleteTableRowResponse = useSelector(
        (state: any) => state?.configure?.table?.deleteTableRow
    );
    const [selectedTableValue, setSelectedTableValue] = useState<any>(null);
    const [selectedColumnValue, setSelectedColumnValue] = useState<any>(null);
    const [selectedIcon, setSelectedIcon] = useState<relationshipType | null>(
        relationshipType.ONE_TO_ONE
    );
    const [editDrawer, setEditDrawer] = useState(false);
    const [key, setKey] = useState(null);
    const details = parseJwt();

    const handleClearDrawer = (): void => {
        setSelectedTableValue(null);
        setSelectedColumnValue(null);
        setSelectedIcon(null);
    };
    const handleIconClick = (iconType: relationshipType): void => {
        setSelectedIcon(iconType);
    };
    const handleSaveDrawer = (): void => {
        const createReferenceInfo = (): any => ({
            referenceTableId: selectedTableValue?.value,
            referenceColumnId: selectedColumnValue,
            tableRelationshipType: selectedIcon,
        });

        const newDataSource = dataSource.map((item: any) => {
            const referenceInfoList = item?.referenceInfoList || [];

            const updatedReferenceInfoList =
                referenceInfoList?.length > 0
                    ? referenceInfoList?.map((referenceInfo: any) => ({
                          ...referenceInfo,
                          ...createReferenceInfo(),
                      }))
                    : [createReferenceInfo()];

            return {
                ...item,
                referenceInfoList: updatedReferenceInfoList,
            };
        });
        setDataSource(newDataSource);
        handleClearDrawer();
        setEditDrawer(false);
    };
    const handleConfirmationModalYes = async (): Promise<void> => {
        const mappedDataSource = dataSource?.map((item: any) => {
            return {
                uomId: item?.uom,
                dataTypeId: item?.dataType,
                name: item?.columnName,
                id: item?.id,
                isPrimaryKey: item?.isPrimaryKey,
                isNotNull: item?.isNotNull,
                isNotEditable: item?.isNotEditable,
            };
        });
        const payload = {
            id: tableDetails?.id,
            name: nameFieldValue,
            desc: descriptionFieldValue || null,
            columnList: mappedDataSource,
            requestedBy: details?.username,
            formId:selectedFormId,
            formName:selectedFormName
        };

        dispatch(updateTable(payload));
    };

    const tableDetailLoading = useSelector(
        (state: any) => state.configure?.table?.tableDetailLoading
    );
    const handlePermissionChange = (
        checked: boolean,
        field: string,
        key: any
    ): void => {
        const newData = dataSource.map((item: any) => {
            if (item.key === key) {
                return {
                    ...item,
                    [field]: checked,
                };
            }
            return item;
        });

        setDataSource(newData);
        setIsEditChanges(true);
    };

    const tableData = (): [] => {
        const temp: any = [];
        tableDetails?.columnList?.map((item: any, index: number) => {
            temp.push({
                ...item,
                key: index + 1,
                id: item?.id || null,
                columnName: item?.name,
                dataType: item?.dataType?.id,
                uom: item?.unitOfMeasurement?.id,
            });
        });
        return temp;
    };
    useEffect(() => {
        setNameFieldValue(tableDetails?.name);
        setDescriptionFieldValue(tableDetails?.description);
        setSelectedFormId(tableDetails?.formId);
        setSelectedFormName(tableDetails?.formName);
        setDataSource(tableData());
    }, [tableDetails]);

    useEffect(() => {
        if (tableData && tableData.length > 0) {
            setDataSource(tableData);
        }
    }, [searchState]);

    useEffect(() => {
        if (updateTableResponse) {
            dispatch(setTableState(TABLETYPE.display));
        }
    }, [updateTableResponse]);

    useEffect(() => {
        if (deleteTableRowResponse) {
            dispatch(getTableDetails(tableDetails?.id));
        }
    }, [deleteTableRowResponse]);

    const { Option } = Select;

    const convertDataToTreeData = (data: any): any => {
        return (
            data &&
            Object.keys(data).map((category) => ({
                title: category,
                value: category,
                selectable: false,
                children: data[category].map((item: any) => ({
                    key: item.id,
                    title: item.name,
                    value: item.id,
                    id: item.id,
                    abbreviation: item.abbreviation,
                })),
            }))
        );
    };

    const filterTreeNode = (input: any, treeNode: any): any => {
        const title = treeNode.title.toLowerCase();
        return title.includes(input.toLowerCase());
    };

    const columns = [
        {
            title: 'Column Name',
            dataIndex: 'columnName',
            key: 'columnName',
            width: '40%',
            className: 'columnName',
            render: (text: any, record: any, index: any) => (
                <Input
                    value={text}
                    onChange={(e) => {
                        handleInputChange(e, 'columnName', record.key, index);
                    }}
                    placeholder={t(
                        'tableDefinition.CreateTable.columnPlaceHolder'
                    )}
                    bordered={false}
                />
            ),
        },
        {
            title: 'Field Type',
            dataIndex: 'dataType',
            key: 'fieldType',
            width: '30%',
            className: 'fieldType',
            render: (text: any, record: any, index: any) => {
                const isLinkedRecords = text === dataTypeWithId.linkRecords;
                return (
                    <Select
                        style={{ width: '100%' }}
                        showSearch
                        allowClear
                        value={text}
                        bordered={false}
                        disabled={isLinkedRecords} // Disable the dropdown if it's Linked Records
                        onChange={(value) => {
                            handleDropdownChange(
                                value,
                                'dataType',
                                record.key,
                                index
                            );
                        }}
                        placeholder={t(
                            'tableDefinition.CreateTable.fieldTypePlaceHolder'
                        )}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label as string)
                                .toLowerCase()
                                .includes(input.toLowerCase())
                        }
                        options={feildTypeList?.map(
                            (item: { id: string; name: string }) => ({
                                value: item?.id,
                                label: item?.name,
                                key: item?.id,
                            })
                        )}
                    />
                );
            },
        },
        {
            title: 'UOM',
            dataIndex: 'uom',
            key: 'uom',
            width: '30%',
            className: 'uom',
            render: (text: any, record: any, index: any) => (
                <TreeSelect
                    placeholder={t(
                        'tableDefinition.CreateTable.uomPlaceHolder'
                    )}
                    value={text || null}
                    bordered={false}
                    onChange={(value) => {
                        handleDropdownChange(value, 'uom', record.key, index);
                    }}
                    filterTreeNode={filterTreeNode}
                    dropdownStyle={{
                        maxHeight: 400,
                        overflow: 'auto',
                    }}
                    allowClear
                    treeData={convertDataToTreeData(uomList?.uomMap || [])}
                ></TreeSelect>
            ),
        },
        {
            title: 'Primary Key',
            dataIndex: 'isPrimaryKey',
            key: 'isPrimaryKey',
            width: '20%',
            render: (text: any, record: any) => (
                <Checkbox
                    checked={record?.isPrimaryKey}
                    onChange={(e): any => {
                        handlePermissionChange(
                            (e.target as HTMLInputElement)?.checked,
                            'isPrimaryKey',
                            record?.key
                        );
                    }}
                ></Checkbox>
            ),
        },
        {
            title: 'Not Null',
            dataIndex: 'isNotNull',
            key: 'isNotNull',
            width: '20%',
            render: (text: any, record: any) => (
                <Checkbox
                    checked={record.isNotNull}
                    onChange={(e): any => {
                        handlePermissionChange(
                            (e.target as HTMLInputElement).checked,
                            'isNotNull',
                            record.key
                        );
                    }}
                    style={{ marginLeft: 8 }}
                ></Checkbox>
            ),
        },
        {
            title: 'Not Editable',
            dataIndex: 'isNotEditable',
            key: 'isNotEditable',
            width: '20%',
            render: (text: any, record: any) => (
                <Checkbox
                    checked={record?.isNotEditable}
                    onChange={(e): any => {
                        handlePermissionChange(
                            (e.target as HTMLInputElement)?.checked,
                            'isNotEditable',
                            record?.key
                        );
                    }}
                    style={{ marginLeft: 8 }}
                ></Checkbox>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: '15%',
            className: 'column__action',
            render: (_: any, record: any) =>
                dataSource.length >= 1 ? (
                    <a className="deleteicon">
                        <DeleteIcon
                            onClick={() => {
                                handleDelete(record);
                            }}
                        />
                    </a>
                ) : null,
        },
    ];

    const showDeleteModal = (record: any): void => {
        setSelectedRecord(record);
        setIsDeleteModalVisible(true);
    };

    // Function to handle closing the delete confirmation modal
    const handleCancelDelete = (): void => {
        setIsDeleteModalVisible(false);
    };

    // Modified handleDelete function to open the delete confirmation modal
    const handleDelete = (record: any): void => {
        showDeleteModal(record);
    };

    const handleConfirmDelete = (): void => {
        // Check if the row is from the API or added locally
        if (selectedRecord?.id) {
            // If the row is from the API, dispatch an action to delete it from the server
            dispatch(
                deleteTableRowAction({
                    tableId: tableDetails?.id,
                    columnIdList: [selectedRecord?.id],
                    requestedBy: details?.username,
                })
            );
            setIsDeleteModalVisible(false);
        } else {
            // If the row is added locally, delete it from the local state
            handleLocalDelete(selectedRecord?.key);
            setIsDeleteModalVisible(false);
        }
    };

    const handleLocalDelete = (key: any): any => {
        const newData = dataSource.filter((item: any) => item.key !== key);
        setDataSource(newData);
    };

    const handleInputChange = (
        e: any,
        field: any,
        key: any,
        indexValue: any
    ): any => {
        const newData = dataSource.map((item: any, index: any): any => {
            if (indexValue === index) {
                return { ...item, [field]: e.target.value };
            }
            return item;
        });

        setDataSource(newData);
        setIsEditChanges(true);
    };

    const checkAllRequiredFields = (dataSource: any): boolean => {
        const data = dataSource.map((item: any): any => {
            const { columnName, dataType, isNotNull = false, isPrimaryKey= false, key, referenceInfoList=[], uom} = item; // Destructure the object, leaving out 'updatedBy'
            return { columnName, dataType, isNotNull, isPrimaryKey, key, referenceInfoList, uom}; // Return the object without 'updatedBy'
        });
        for (const obj of data) {
            for (const key in obj) {
                if (
                    obj[key] === undefined ||
                    obj[key] === '' ||
                    obj?.columnName.trim() === ''
                ) {
                    return true;
                }
            }
        }
        if (!nameFieldValue) {
            return true;
        }
        if (!isEditChanges) {
            return true;
        }

        return false;
    };

    const handleAdd = (): any => {
        const totalRecordsInATable = dataSource?.length;
        const newData = {
            key: totalRecordsInATable + 1,
            columnName: '',
            dataType: '',
            uom: '',
        };

        setDataSource([...dataSource, { ...newData }]);
    };

    const handleDropdownChange = (
        value: any,
        field: any,
        key: any,
        indexValue: any
    ): any => {
        const newData = dataSource?.map((item: any, index: any) => {
            if (indexValue === index) {
                return { ...item, [field]: value };
            }
            return item;
        });
        if (value === dataTypeWithId?.linkRecords) {
            setEditDrawer(true);
            setKey(key);
        }

        setDataSource(newData);
        setIsEditChanges(true);
    };

    const handleFormChange = (value: string, option: any): any => {
        setSelectedFormId(value);
        setSelectedFormName(option?.children);
        setIsEditChanges(true);
    };
    const handleNameChange = (value: string): any => {
        setNameFieldValue(value);
        setIsEditChanges(true);
    };

    const handleDescriptionChange = (value: string): any => {
        setDescriptionFieldValue(value);
        setIsEditChanges(true);
    };

    const [form] = Form.useForm();

    // Use initialValues to set initial values for the form
    const initialValues = {
        name: tableDetails?.name || '',
        description: tableDetails?.description || '',
        type: tableDetails?.type || '',
        formIds: tableDetails?.formName || '',
    };

    return (
        <>
            {tableDetailLoading ? (
                <div className="view__loader">
                    <Spin />
                </div>
            ) : (
                tableDetails?.name && (
                    <div className="editTable-template">
                        <Card className='editCard'>
                            <div className="wrapper">
                                <div>
                                    <ArrowLeftOutlined
                                        onClick={() =>
                                            dispatch(
                                                setTableState(TABLETYPE.view)
                                            )
                                        }
                                        style={{
                                            color: 'rgba(0, 0, 0, 0.45)',
                                        }}
                                    />
                                    <span>
                                        {' '}
                                        {t(
                                            'tableDefinition.editTable.editTableTemplate'
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div className="form">
                                <div className="name-description">
                                    <Form
                                        form={form}
                                        layout="vertical"
                                        initialValues={initialValues}
                                    >
                                        <Form.Item
                                            label={t('tableDefinition.name')}
                                            name="name"
                                            className="name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please input name!',
                                                },
                                            ]}
                                        >
                                            <Input
                                                onChange={(e) =>
                                                    handleNameChange(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label={t(
                                                'tableDefinition.description'
                                            )}
                                            name="description"
                                            className="description"
                                        >
                                            <Input
                                                onChange={(e) =>
                                                    handleDescriptionChange(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label={t('users.userDetails.forms')}
                                            name="formIds"
                                            className="formIds"
                                        >
                                            <Select
                                                maxTagCount="responsive"
                                                allowClear={true}
                                                popupClassName={'CustomOverlay'}
                                                placeholder={t(
                                                    'users.userDetails.formsPlaceholder'
                                                )}
                                                onChange={handleFormChange}
                                                showSearch
                                            >
                                                {allFormsList?.map(
                                                    (item: any) => {
                                                        return (
                                                            <Option
                                                                value={
                                                                    item?._id
                                                                }
                                                                key={item?._id}
                                                            >
                                                                {item?.title}
                                                            </Option>
                                                        );
                                                    }
                                                )}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            label={t(
                                                'attributeDefinition.tableColumn.type'
                                            )}
                                            name="type"
                                            className="type"
                                        >
                                            <Select
                                                showSearch
                                                allowClear
                                                placeholder="Enter Type"
                                                disabled
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label as string)
                                                        .toLowerCase()
                                                        .includes(
                                                            input.toLowerCase()
                                                        )
                                                }
                                            />
                                        </Form.Item>
                                    </Form>
                                    <div className="EditTableCreateTable">
                                        <div className="EditTableCreateTable__Wrapper__button">
                                            <Button
                                                onClick={handleAdd}
                                                className="custom-add-button"
                                                type="default"
                                                style={{
                                                    marginBottom: 16,
                                                }}
                                                icon={
                                                    <PlusOutlined className="addIcon" />
                                                }
                                            >
                                                {t('tableDefinition.add')}
                                            </Button>
                                        </div>
                                        <div className="EditTableCreateTable__Wrapper__Table">
                                            <Table
                                                dataSource={dataSource}
                                                columns={columns}
                                                pagination={false}
                                                scroll={{
                                                    y: 'calc(100vh - 700px)',
                                                }}
                                            />
                                        </div>
                                        <SideDrawer
                                            title={t(
                                                'tableDefinition.CreateTable.linkedRecordDetails'
                                            )}
                                            Open={editDrawer}
                                            key={key}
                                            onClose={() => {
                                                setEditDrawer(false);
                                                handleClearDrawer();
                                            }}
                                        >
                                            <Form layout="vertical">
                                                <Form.Item
                                                    name="fieldType"
                                                    label={t(
                                                        'tableDefinition.CreateTable.fieldType'
                                                    )}
                                                    initialValue="Linked Record"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Required',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        disabled
                                                        placeholder={t(
                                                            'tableDefinition.CreateTable.linkedRecord'
                                                        )}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    name="linkedTo"
                                                    label={t(
                                                        'tableDefinition.CreateTable.linkedToTable'
                                                    )}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Required',
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        showSearch
                                                        allowClear
                                                        value={
                                                            selectedTableValue
                                                        }
                                                        onChange={(
                                                            __,
                                                            data
                                                        ) => {
                                                            setSelectedTableValue(
                                                                data
                                                            );
                                                        }}
                                                        placeholder={t(
                                                            'tableDefinition.CreateTable.selectTable'
                                                        )}
                                                        optionFilterProp="children"
                                                        filterOption={(
                                                            input,
                                                            option
                                                        ) =>
                                                            (
                                                                option?.label as string
                                                            )
                                                                .toLowerCase()
                                                                .includes(
                                                                    input.toLowerCase()
                                                                )
                                                        }
                                                        options={tableData()?.map(
                                                            (item: {
                                                                id: string;
                                                                name: string;
                                                            }) => ({
                                                                value: item?.id,
                                                                label: item?.name,
                                                                key: item?.id,
                                                                data: item,
                                                            })
                                                        )}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    name="columnName"
                                                    label={t(
                                                        'tableDefinition.CreateTable.columnName'
                                                    )}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Required',
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        disabled={
                                                            !selectedTableValue
                                                        }
                                                        showSearch
                                                        allowClear
                                                        value={
                                                            selectedColumnValue
                                                        }
                                                        onChange={(value) => {
                                                            setSelectedColumnValue(
                                                                value
                                                            );
                                                        }}
                                                        placeholder={t(
                                                            'tableDefinition.CreateTable.selectColumn'
                                                        )}
                                                        optionFilterProp="children"
                                                        filterOption={(
                                                            input,
                                                            option
                                                        ) =>
                                                            (
                                                                option?.label as string
                                                            )
                                                                .toLowerCase()
                                                                .includes(
                                                                    input.toLowerCase()
                                                                )
                                                        }
                                                        options={selectedTableValue?.data?.columnList?.map(
                                                            (item: {
                                                                id: string;
                                                                name: string;
                                                            }) => ({
                                                                value: item?.id,
                                                                label: item?.name,
                                                                key: item?.id,
                                                            })
                                                        )}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    name="linkType"
                                                    label={t(
                                                        'tableDefinition.CreateTable.linkType'
                                                    )}
                                                >
                                                    <div className="icon-pair-container">
                                                        <div
                                                            className={`icon-container ${
                                                                selectedIcon ===
                                                                relationshipType.ONE_TO_ONE
                                                                    ? 'selected'
                                                                    : ''
                                                            }`}
                                                            onClick={() => {
                                                            
                                                                handleIconClick(
                                                                    relationshipType.ONE_TO_ONE
                                                                );
                                                            }}
                                                        >
                                                            <div className="icon-content">
                                                                <One2One />
                                                            </div>
                                                            <div className="icon-label">
                                                                {t(
                                                                    'tableDefinition.CreateTable.oneToOne'
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`icon-container ${
                                                                selectedIcon ===
                                                                relationshipType.ONE_TO_MANY
                                                                    ? 'selected'
                                                                    : ''
                                                            }`}
                                                            onClick={() => {
                                                                handleIconClick(
                                                                    relationshipType.ONE_TO_MANY
                                                                );
                                                            }}
                                                        >
                                                            <div className="icon-content">
                                                                <One2Many />
                                                            </div>
                                                            <div className="icon-label">
                                                                {t(
                                                                    'tableDefinition.CreateTable.oneToMany'
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`icon-container ${
                                                                selectedIcon ===
                                                                relationshipType.MANY_TO_ONE
                                                                    ? 'selected'
                                                                    : ''
                                                            }`}
                                                            onClick={() => {
                                                                handleIconClick(
                                                                    relationshipType.MANY_TO_ONE
                                                                );
                                                            }}
                                                        >
                                                            <div className="icon-content">
                                                                <Many2One />
                                                            </div>
                                                            <div className="icon-label">
                                                                {t(
                                                                    'tableDefinition.CreateTable.manyToOne'
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`icon-container ${
                                                                selectedIcon ===
                                                                relationshipType.MANY_TO_MANY
                                                                    ? 'selected'
                                                                    : ''
                                                            }`}
                                                            onClick={() => {
                                                                handleIconClick(
                                                                    relationshipType.MANY_TO_MANY
                                                                );
                                                            }}
                                                        >
                                                            <div className="icon-content">
                                                                <Many2Many />
                                                            </div>
                                                            <div className="icon-label">
                                                                {t(
                                                                    'tableDefinition.CreateTable.manyToMany'
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form.Item>
                                                <div className="addTableDrawer__button">
                                                    <Divider />
                                                    <Row className="addTableDrawer__footerButtons">
                                                        <Col
                                                            span={4}
                                                            className="addTagDrawer__cancelButton"
                                                        >
                                                            <CustomButton
                                                                type={
                                                                    BUTTONTYPE.cancel
                                                                }
                                                                disabled={false}
                                                                handleClick={() => {
                                                                    setEditDrawer(
                                                                        false
                                                                    );
                                                                    handleClearDrawer();
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col
                                                            span={4}
                                                            className="addTableDrawer__saveButton"
                                                        >
                                                            <CustomButton
                                                                type={
                                                                    BUTTONTYPE.save
                                                                }
                                                                disabled={
                                                                    !selectedTableValue ||
                                                                    !selectedColumnValue ||
                                                                    !selectedIcon
                                                                }
                                                                typeOfButton={
                                                                    'submit'
                                                                }
                                                                handleClick={() => {
                                                                    handleSaveDrawer();
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Form>
                                        </SideDrawer>
                                        <div className="EditTableCreateTable__Wrapper__Footer">
                                            <div className="EditTableCreateTable__Wrapper__FooterWrapper">
                                                <CustomButton
                                                    type={BUTTONTYPE.cancel}
                                                    disabled={false}
                                                    handleClick={() => {
                                                        dispatch(
                                                            setTableState(
                                                                TABLETYPE.view
                                                            )
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <div className="EditTableCreateTable__Wrapper__FooterWrapper">
                                                <CustomButton
                                                    type={BUTTONTYPE.save}
                                                    disabled={checkAllRequiredFields(
                                                        dataSource
                                                    )}
                                                    typeOfButton={'submit'}
                                                    handleClick={() => {
                                                        setIsSaveModalOpen(
                                                            true
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {isSaveModalOpen && (
                                            <ConfirmationModal
                                                customClassName="confirmationModal attributeImplementationModal"
                                                icon={<QuestionMarkIcon />}
                                                open={isSaveModalOpen}
                                                onOk={() => {
                                                    handleConfirmationModalYes();
                                                }}
                                                onCancel={() => {
                                                    setIsSaveModalOpen(false);
                                                }}
                                                text={t(
                                                    'tableDefinition.confirmationMessageEdit'
                                                )}
                                            />
                                        )}
                                        {isDeleteModalVisible && (
                                            <ConfirmationModal
                                                customClassName="confirmationModal attributeImplementationModal"
                                                icon={<DeleteRedIcon />}
                                                open={isDeleteModalVisible}
                                                onOk={handleConfirmDelete}
                                                onCancel={handleCancelDelete}
                                                text={t(
                                                    'tableDefinition.deleteMessage'
                                                )}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                )
            )}
        </>
    );
};

export default EditTable;
