import {
    commonTransformData,
    formatDate,
    isSelectedTrendIncluded,
} from 'utils/commonFunction';
import { statisticalFunctions } from 'types/enums/dataVisualizationEnum';
import { tooltipDateTimeFormat } from 'utils/constants';
import ReactECharts from 'echarts-for-react';
import { useRef } from 'react';

// TODO need to use this later
// import { getAggregatedChartData } from 'redux/actions/DataExplorer/DataVisualizationActions';
// import { useDispatch } from 'react-redux';
// import { EMPTY, tagOriginId } from 'types/enums';

const DemergeLineChart = ({
    name,
    showMarkers,
    dateRange,
    selectedTags,
    selectedKpis,
    chartSeriesData,
    selectedTrendFunctions,
    selectedAlert,
    selectedTimeCapsule,
    controlLimitsValue,
}: any): any => {
    const chartRef: any = useRef<HTMLDivElement>(null);
    const xAxisMin = new Date(dateRange?.startTime).getTime();
    const xAxisMax = new Date(dateRange?.endTime).getTime();

    // Combine tags and KPIs into a single array for processing
    const combinedSelections = [
        ...selectedTags?.map((tag: any) => ({ ...tag, type: 'tag' })),
        ...selectedKpis?.map((kpi: any) => ({ ...kpi, type: 'kpi' })),
    ];

    const trendStyles: any = {
        Mean: {
            color: 'blue',
            dashStyle: 'dashed',
        },
        Median: {
            color: 'green',
            dashStyle: 'dotted',
        },
        Mode: {
            color: 'orange',
            dashStyle: 'dotted',
        },
        Deviation: {
            color: 'red',
            dashStyle: 'dashed',
        },
        'Moving Average': {
            color: 'pink',
            dashStyle: 'dashed',
        },
    };

    return (
        <div style={{ display: 'grid', gap: '20px' }}>
            {combinedSelections?.map((item: any) => {
                const isTag = item?.type === statisticalFunctions.TAG;
                const itemName = isTag ? item.tagName : item.name;

                // Extract UCL and LCL values for the current item using its unique identifier
                const uclValue = controlLimitsValue?.[item?.uuid]?.[0];
                const lclValue = controlLimitsValue?.[item?.uuid]?.[1];

                // Filter chart series data based on the itemName and selected trend functions
                const filteredChartSeriesData = chartSeriesData?.filter(
                    (series: any) => {
                        return (
                            series?.name === itemName ||
                            (series?.name?.includes(itemName) &&
                                selectedTrendFunctions?.some((item: string) =>
                                    isSelectedTrendIncluded(series?.name, item)
                                ))
                        );
                    }
                );

                // Process filtered chart series data to apply styles, type (line), and configurations for rendering.
                const seriesData = filteredChartSeriesData?.map(
                    (series: any) => {
                        const trendFunctionName: any = Object.keys(
                            trendStyles
                        ).find((trend) => series?.name?.includes(trend));

                        const isTrendLine = !!trendFunctionName;
                        const trendStyle = trendStyles[trendFunctionName] || {};

                        return {
                            name: series?.name,
                            data: commonTransformData(series),
                            type: 'line',
                            showSymbol: isTrendLine,
                            itemStyle: {
                                color: isTrendLine
                                    ? trendStyle.color
                                    : 'purple',
                            },
                            lineStyle: isTrendLine
                                ? {
                                      type: trendStyle.dashStyle?.toLowerCase(),
                                      width: 2,
                                  }
                                : {
                                      type: 'solid',
                                      width: 2,
                                  },
                            z: isTrendLine ? 2 : 1,
                        };
                    }
                );

                const option: any = {
                    xAxis: {
                        type: 'time',
                        min: xAxisMin,
                        max: xAxisMax,
                    },
                    yAxis: {
                        type: 'value',
                        name: item?.dataType?.unit || "Value",
                        nameLocation: 'middle',
                        nameTextStyle: {
                            fontSize: 12,
                            padding: 30,
                        },
                        scale: true,
                        axisLabel: {
                            formatter: (value: number) => {
                                if (value >= 1e6) {
                                    return `${(value / 1e6).toFixed(1)}M`; // Convert to millions
                                } else if (value >= 1e3) {
                                    return `${(value / 1e3).toFixed(1)}K`; // Convert to thousands
                                }
                                return value.toString();
                            },
                        },
                        splitLine: {
                            show: true,
                        },
                    },
                    series: [
                        ...seriesData,
                        uclValue
                            ? {
                                  name: 'UCL',
                                  data: commonTransformData(
                                      filteredChartSeriesData[0]
                                  )?.map((point: any) => [
                                      point[0],
                                      Number(uclValue),
                                  ]),
                                  type: 'line',
                                  itemStyle: {
                                      color: 'red',
                                  },
                                  lineStyle: {
                                      type: 'solid',
                                      width: 2,
                                  },
                                  symbol: 'none',
                                  z: 3,
                              }
                            : null,
                        lclValue
                            ? {
                                  name: 'LCL',
                                  data: commonTransformData(
                                      filteredChartSeriesData[0]
                                  )?.map((point: any) => [
                                      point[0],
                                      Number(lclValue),
                                  ]),
                                  type: 'line',
                                  itemStyle: {
                                      color: 'green',
                                  },
                                  lineStyle: {
                                      type: 'solid',
                                      width: 2,
                                  },
                                  symbol: 'none',
                                  z: 3,
                              }
                            : null,
                        selectedAlert && filteredChartSeriesData[0]
                            ? {
                                  name: selectedAlert?.name?.children,
                                  data: commonTransformData(
                                      filteredChartSeriesData[0]?.data?.map(
                                          (point: any) => [point[0], point[1]]
                                      )
                                  ),
                                  type: 'line',
                                  itemStyle: {
                                      color: 'red',
                                  },
                                  lineStyle: {
                                      type: 'dashed',
                                      width: 2,
                                  },
                                  symbol: 'none',
                                  z: 3,
                              }
                            : null,
                        selectedTimeCapsule && filteredChartSeriesData[0]
                            ? {
                                  name: selectedTimeCapsule?.name?.children,
                                  data: commonTransformData(
                                      filteredChartSeriesData[0]?.data?.map(
                                          (point: any) => [point[0], point[1]]
                                      )
                                  ),
                                  type: 'line',
                                  itemStyle: {
                                      color: 'orange',
                                  },
                                  lineStyle: {
                                      type: 'dashed',
                                      width: 2,
                                  },
                                  symbol: 'circle',
                                  symbolSize: 10,
                                  z: 3,
                              }
                            : null,
                    ]?.filter(Boolean),
                    dataZoom: {
                        type: 'inside',
                    },
                    toolbox: {
                        feature: {
                            saveAsImage: {},
                            dataZoom: {
                                yAxisIndex: 'none',
                            },
                            restore: {},
                        },
                    },
                    tooltip: {
                        trigger: 'axis',
                        backgroundColor: '#ffffff',
                        borderWidth: 0,
                        textStyle: {
                            color: '#000000',
                        },
                        formatter: (params: any) => {
                            const currentPoint = params[0];
                            const index = currentPoint.dataIndex;
                            const series = params?.map((point: any) => point);

                            const currentBrowserTimestamp =
                                currentPoint.value[0];
                            let tooltip = ``;
                            tooltip += `<div>Start: ${formatDate(
                                currentBrowserTimestamp,
                                tooltipDateTimeFormat
                            )}<div>`;

                            const extractSeriresData = seriesData[0].data;
                            const nextPoint =
                                index < extractSeriresData.length - 1
                                    ? extractSeriresData[index + 1]
                                    : null;

                            if (nextPoint) {
                                const nextBrowserTimestamp = nextPoint[0];
                                tooltip += `End: ${formatDate(
                                    nextBrowserTimestamp,
                                    tooltipDateTimeFormat
                                )}<br>`;
                            }

                            const uniqueSeries = new Map();
                            series?.forEach((point: any) => {
                                if (!uniqueSeries.has(point?.seriesName)) {
                                    uniqueSeries.set(point?.seriesName, true);
                                    tooltip += `<span style="color:${
                                        point?.color
                                    };">\u25CF</span> ${
                                        point?.seriesName
                                    }: ${point?.value[1]?.toFixed(2)}<br>`;
                                }
                            });

                            return `<div style="display:flex;justify-content:center;">
                                            <div style="font-size:12px; font-weight:500">
                                                ${tooltip}
                                            </div>
                                        </div>`;
                        },
                    },

                    legend: {
                        bottom: 10,
                        left: 'center',
                        formatter: (name: string) => {
                            const trendFunctionName = Object.keys(
                                trendStyles
                            ).find((trend) => name?.includes(trend));
                            return trendFunctionName ?? name;
                        },
                    },
                };

                return (
                    <div
                        key={item?.id || item?.tagId || item?.kpiId}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                        }}
                    >
                        <ReactECharts
                            ref={chartRef}
                            option={{ ...option }}
                            notMerge={true}
                            lazyUpdate={true}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default DemergeLineChart;
