import {
    Button,
    Dropdown,
    Input,
    Menu,
    Radio,
    Select,
    Spin,
    Table,
    TreeSelect,
    Switch,
    Popover,
} from 'antd';
import './index.scss';
import type { ColumnsType } from 'antd/es/table';
import MultiAxisTimeSeriesChart from 'components/common/charts/MultiAxisTimeSeriesChart';
import { useEffect, useRef, useState } from 'react';
import {
    getAggregatedChartData,
    getNodeList,
    getStreamTagList,
    getModelList,
    clearDataVisualizationValues,
    getAssetCSVData,
    getDataTableList,
    clearDataTableList,
    getKpiData,
    getKpiTrends,
    getExportAggregrateCsvData,
    getFrequency,
    getTrendsFunction,
    getChartsType,
    getScatterAggregrateData,
} from 'redux/actions/DataExplorer/DataVisualizationActions';
import { useDispatch, useSelector } from 'react-redux';
import {
    checkTimeBeforeShiftEndTime,
    // TODO :need this later
    // convertToChartAreaData,
    mapTree,
} from 'utils/commonFunction';
import CustomHeader from 'components/common/CustomHeader';
import { PAGE, PAGE_SIZE, maxRowSelectionCount } from 'utils/constants';
import { changeDateFilter } from 'redux/actions/NocilDashboardActions/nocilDashboardAction';
import { DateRangeFilter, ShiftTiming } from 'types/enums/kpiEnum';
import {
    EMPTY,
    TagOriginId,
    TemplateTypeAsset,
    dateFormat,
    sortingOrder,
    tagOriginId,
} from 'types/enums';
import {
    ChartTypes,
    statisticalFunctions,
} from 'types/enums/dataVisualizationEnum';
import dayjs from 'dayjs';
import DataTable from './DataTable';
import { type DataType } from 'types/interfaces/PropsInterfaces';
import {
    DownloadOutlined,
    SearchOutlined,
    MoreOutlined,
} from '@ant-design/icons';
import KpiTable from './KpiTable';
import TagTable from './TagTable';
import { ReactComponent as CloseIcon } from 'assets/icons/closeIcon.svg';
import { ReactComponent as KpiBadge } from '../../../../src/assets/icons/kpiBadge.svg';
import { ReactComponent as TagBadge } from '../../../../src/assets/icons/tagBadge.svg';
import { ReactComponent as Sampling } from 'assets/icons/samplingIcon.svg';
import { ReactComponent as Charts } from 'assets/icons/activitySquare.svg';
import { ReactComponent as LineChartIcon } from 'assets/icons/lineChart.svg';
import { ReactComponent as AreaChart } from 'assets/icons/areachart.svg';
import { ReactComponent as BarChart } from 'assets/icons/barChart.svg';
import { ReactComponent as BoxPlot } from 'assets/icons/boxplot.svg';
import { ReactComponent as Histrogram } from 'assets/icons/histrogram.svg';
import { ReactComponent as Scatter } from 'assets/icons/scatter.svg';
import { ReactComponent as Marker } from 'assets/icons/marker.svg';
import { ReactComponent as CollapseIcon } from 'assets/icons/ChartCollapse.svg';
import { ReactComponent as Settings } from 'assets/icons/Settings.svg';
import { useTranslation } from 'react-i18next';
import {
    EMPTY_KPIS_PLOTTING,
    EMPTY_TAGS_PLOTTING,
} from 'redux/actions/actionTypes';
import base64 from 'base-64';
import utf8 from 'utf8';
import DemergeLineChart from './Charts/DemergeLineChart';
import ChartPropertiesPanel from './ChartPropertiesPanel';
import DataMoreContent from './DataMoreContent';
import DemergeBarChart from './Charts/DemergeBarChart';
import MergedBarChart from './Charts/MergedBarChart';
import DemergeBoxChart from './Charts/DemergeBoxPlotChart';
import MergedBoxPlot from './Charts/MergedBoxPlotChart';
import MergedAreaChart from './Charts/MergedAreaChart';
import DemergeAreaChart from './Charts/DemergeAreaChart';
import ChartPropertiesScatter from './ChartPropertiesScatter';
import ScatterChart from './Charts/ScatterChart';
import CustomPagination from 'components/common/CustomPagination';

const DataVisualization: React.FC = () => {
    const { t } = useTranslation('translation');
    const [count, setCount] = useState(1);
    // State to hold the input values for UCL and LCL
    const [uclValue, setUclValue] = useState<string>('');
    const [lclValue, setLclValue] = useState<string>('');
    const [controlLimitsValue, setControlLimitsValue] = useState({});
    const { Option } = Select;
    const columns: ColumnsType<DataType> = [
        {
            key: 'more',
            width: 56,
            render: (_: any, record: any) => {
                return (
                    <>
                        <div>
                            <Popover
                                placement="bottomLeft"
                                content={
                                    <DataMoreContent
                                        setUclValue={setUclValue}
                                        uclValue={uclValue}
                                        lclValue={lclValue}
                                        setLclValue={setLclValue}
                                        record={record}
                                        setControlLimitsValue={
                                            setControlLimitsValue
                                        }
                                        tagUUID={tagUUID}
                                        setTagUUID={setTagUUID}
                                        selectedTagRows={selectedTagRows}
                                        setSelectedTagRows={setSelectedTagRows}
                                        frequencyState={frequencyState}
                                        selectedKpiRows={selectedKpiRows}
                                        setSelectedKpiRows={setSelectedKpiRows}
                                        setKpiUUID={setKpiUUID}
                                        kpiUUID={kpiUUID}
                                        setKpiId={setKpiId}
                                        kpiID={kpiID}
                                        chartType={chartType}
                                    />
                                }
                                overlayStyle={{ width: '162px' }}
                            >
                                <MoreOutlined />
                            </Popover>
                        </div>
                    </>
                );
            },
        },
        {
            title: t('dataVisualization.tagKpiName'),
            dataIndex: 'tagName',
            render: (_: any, record: any) => (
                <>
                    {record?.type === 'kpi' ? <KpiBadge /> : <TagBadge />}
                    <span style={{ marginLeft: '8px' }}>{record?.tagName}</span>
                </>
            ),
        },
        {
            title: t('dataVisualization.assetName'),
            dataIndex: 'assetName',
            render: (_: any, record: any) => <>{record?.parentNode?.name}</>,
        },
        {
            title: t('dataVisualization.dataPoint'),
            dataIndex: 'dataPoint',
            width: '100px',
            render: (_: any, record: any) => <>{record?.totalCount}</>,
        },
        {
            title: t('dataVisualization.unit'),
            dataIndex: 'unit',
            width: '100px',
            render: (_: any, record: any) => <>{record?.dataType?.unit}</>,
        },
        {
            title: 'Aggregated Value',
            dataIndex: 'aggregratedValue',
            render: (_: any, record: any) => (
                <>{record?.aggregatedValue?.toFixed(2)}</>
            ),
        },
        {
            title: 'Mean',
            dataIndex: 'Mean',
            render: (_: any, record: any) => <>{record?.Mean?.toFixed(2)}</>,
        },

        {
            title: 'Median',
            dataIndex: 'Median',
            render: (_: any, record: any) => <>{record?.Median?.toFixed(2)}</>,
        },
        {
            title: 'Mode',
            dataIndex: 'Mode',
            render: (_: any, record: any) => (
                <>
                    {Array.isArray(record?.Mode)
                        ? record?.Mode.join(', ') // Join the array with commas
                        : record?.Mode?.toFixed(2)}
                </>
            ),
        },
        {
            title: 'SD',
            dataIndex: 'SD',
            render: (_: any, record: any) => <>{record?.SD?.toFixed(2)}</>,
        },
        {
            title: (
                <Button
                    type="link"
                    danger
                    onClick={() => {
                        handleDeleteAllSelected();
                        // Handle header button click if necessary
                    }}
                >
                    Clear All
                </Button>
            ),
            dataIndex: 'actions',
            width: '100px',
            render: (_: any, record: any) => (
                <Button
                    type="link"
                    danger
                    onClick={() => {
                        handleDelete(record);
                    }}
                >
                    <CloseIcon className="selecedTagCloseIcon" />
                </Button>
            ),
        },
    ];

    // Will use later on
    // const location = useLocation();
    // const { pathname } = location;
    // const isTrendingPage = pathname === '/data-visualization/trending';

    const [checkedValues, setCheckedValues] = useState([]);
    const [page, setPage] = useState<number>(PAGE);
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const [chartType, setChartType] = useState('line');
    const [trendData, setTrendData] = useState<any>({});
    const [demergeLineChartData, setDemergeLineChartData] = useState<any>([]);
    const [demergeBarChartData, setDemergeBarChartData] = useState<any>([]);
    const [demergeBoxChartData, setDemergeBoxChartData] = useState<any>([]);
    const [demergeAreaChartData, setDemergeAreaChartData] = useState<any>([]);
    const [showMarkers, setShowMarkers] = useState(false);
    const [showMerge, setshowMerge] = useState(false);
    const [selectedTrendFunctions, setSelectedTrendFunctions] = useState<
        string[]
    >([]);

    const [selectedAlert, setSelectedAlert] = useState<any>(null);
    const [selectedTimeCapsule, setSelectedTimeCapsule] = useState<any>(null);
    const [axisSelectorEnabled, setAxisSelectorEnabled] = useState(false);
    const [regressionEnabled, setRegressionEnabled] = useState(false);
    const [confidenceIntervalEnabled, setConfidenceIntervalEnabled] =
        useState(false);
    const [statFunctionsEnabled, setStatFunctionsEnabled] = useState(false);

    const handleAlertChange = (value: any, name: any): any => {
        setSelectedAlert({ value, name });
    };

    const handleTimeCapsuleChange = (value: any, name: any): any => {
        setSelectedTimeCapsule({ value, name });
    };

    // const [dataTablePageSize, setDataTablePageSize] = useState(PAGE_SIZE);

    const [tagUUID, setTagUUID] = useState<string>(EMPTY?.string);
    const [kpiUUID, setKpiUUID] = useState<string>(EMPTY?.string);
    const [kpiID, setKpiId] = useState<string>(EMPTY?.string);
    const handlePage = (page: any): any => {
        setPage(page);
    };
    const handlePageSize = (current: any): any => {
        setPageSize(current);
    };
    const trendsFunction: any = useSelector(
        (state: any) => state.dataVisualization.trendsFunction
    );

    
    const streamTagsList: any = useSelector(
        (state: any) => state.dataVisualization.streamTagList
    );

    const nodeList: any = useSelector(
        (state: any) => state.dataVisualization.nodeList
    );

    const modelList: any = useSelector(
        (state: any) => state.dataVisualization.modelList
    );

    const frequencyList: any = useSelector(
        (state: any) => state.dataVisualization.frequency
    );

    const [frequencyState, setFrequencyState] = useState(0);

    const startDateRedux = useSelector(
        (state: any) => state?.nocilDashboard?.filterValues?.startTime
    );
    const endDateRedux = useSelector(
        (state: any) => state?.nocilDashboard?.filterValues?.endTime
    );
    const [selectedRowsKeys, setSelectedRowsKeys] = useState<any>([]);
    const [selectedModel, setSelectedModel] = useState(null);
    const [selectedDevice, setSelectedDevice] = useState<any>();
    // const [selectedOption, setSelectedOption] = useState('kpis');
    const [selectedTagRows, setSelectedTagRows] = useState<any>([]);
    const [tagTableData, setTagTableData] = useState(streamTagsList);
    const [selectedKpiRows, setSelectedKpiRows] = useState<any>([]);
    const [chartPropertiesModal, setChartPropertiesModal] = useState(false);
    const [isMovingAverageEnabled, setIsMovingAverageEnabled] = useState(false);
    const [xAxis, setXAxis] = useState<string | null>(null);
    const [yAxis, setYAxis] = useState<string | null>(null);
    const [averageValue, setAverageValue] = useState<number | undefined>(
        undefined
    );
    const dispatch = useDispatch();
    const [uuids, setUuids] = useState([]);
    useEffect(() => {
        const newUuids = selectedTagRows.map((row: any) => row.uuid);
        setUuids(newUuids);
    }, [selectedTagRows]);

    useEffect(() => {
        dispatch(
            changeDateFilter({
                startTime: checkTimeBeforeShiftEndTime(7)
                    ? dayjs()
                          .subtract(1, 'days')
                          .format(
                              `${dateFormat.formatWithoutTime} ${ShiftTiming.shiftAStartTime}`
                          )
                    : dayjs().format(
                          `${dateFormat.formatWithoutTime} ${ShiftTiming.shiftAStartTime}`
                      ),
                endTime: dayjs().format(`${dateFormat.format}`),
                dateRange: DateRangeFilter?.Today,
            })
        );
        dispatch(getModelList({ page: 1, pageSize: 0 }));
        dispatch(clearDataVisualizationValues());

        return () =>
            dispatch(
                changeDateFilter({
                    startTime: checkTimeBeforeShiftEndTime(7)
                        ? dayjs()
                              .subtract(1, 'days')
                              .format(
                                  `${dateFormat.formatWithoutTime} ${ShiftTiming.shiftAStartTime}`
                              )
                        : dayjs().format(
                              `${dateFormat.formatWithoutTime} ${ShiftTiming.shiftAStartTime}`
                          ),
                    endTime: dayjs().format(`${dateFormat.format}`),
                    dateRange: DateRangeFilter?.Today,
                })
            );
    }, []);

    const handleDelete = (record: any): void => {
        // Filter out the deleted row from selectedRows
        const updatedSelectedRows = selectedTagRows?.filter(
            (row: any) => row?.key !== record?.key
        );
        setSelectedTagRows(updatedSelectedRows);

        // Update selectedRowsKeys, removing the deleted key
        const updatedSelectedRowsKeys = selectedRowsKeys?.filter(
            (key: any) => key !== record?.key
        );
        setSelectedRowsKeys(updatedSelectedRowsKeys);

        // Update tagTableData to remove the deleted row
        const updatedTagTableData = tagTableData?.filter(
            (row: any) => row?.key !== record?.key
        );
        setTagTableData(updatedTagTableData);

        // Filter out the deleted row from the info array
        const updatedInfo = info.filter((item: any) => item.key !== record.key);
        setInfo(updatedInfo);

        // Check if the deleted ID exists in selectedKpiRows and remove it
        const updatedSelectedKpiRows = selectedKpiRows?.filter(
            (row: any) => row.key !== record.key
        );
        setSelectedKpiRows(updatedSelectedKpiRows);

        // Check if the deleted ID exists in selectedTagRows and remove it
        const updatedSelectedTagRows = selectedTagRows?.filter(
            (row: any) => row.key !== record.key
        );
        setSelectedTagRows(updatedSelectedTagRows);
    };

    const handleDeleteAllSelected = (): void => {
        // Filter out the selected rows from tagTableData and info

        const updatedTagTableData = tagTableData?.filter(
            (row: any) =>
                !selectedTagRows?.some(
                    (selectedRow: any) => selectedRow.key === row.key
                )
        );

        const updatedInfo = info?.filter(
            (item: any) =>
                !selectedTagRows?.some(
                    (selectedRow: any) => selectedRow.key === item.key
                )
        );

        // Filter out the selected rows from selectedRowsKeys
        const updatedSelectedRowsKeys = selectedRowsKeys?.filter(
            (key: any) =>
                !selectedTagRows.some(
                    (selectedRow: any) => selectedRow.key === key
                )
        );

        // Filter out the selected KPI rows
        const updatedSelectedKpiRows = selectedKpiRows?.filter(
            (row: any) =>
                !selectedKpiRows.some(
                    (selectedRow: any) => selectedRow.key === row.key
                )
        );

        // Filter out the selected Tag rows
        const updatedSelectedTagRows = selectedTagRows?.filter(
            (row: any) =>
                !selectedTagRows.some(
                    (selectedRow: any) => selectedRow.key === row.key
                )
        );

        // Update the state with the filtered arrays
        setTagTableData(updatedTagTableData);
        setInfo(updatedInfo);
        setSelectedRowsKeys(updatedSelectedRowsKeys);
        setSelectedKpiRows(updatedSelectedKpiRows);
        setSelectedTagRows(updatedSelectedTagRows);
    };

    const selectHandler = (value: any): any => {
        setSelectedDevice(value);
    };

    const handleModalOpen = (): any => {
        setChartPropertiesModal(true);
    };
    const handleClosePropertiesPanel = (): any => {
        setChartPropertiesModal(false);
    };
    const aggregatedChartData = useSelector(
        (state: any) => state?.dataVisualization?.aggregatedChartData
    );

    const aggregatedChartDataLoading = useSelector(
        (state: any) => state.dataVisualization.aggregatedChartDataLoading
    );

    const aggregatedScatterChartData = useSelector(
        (state: any) => state?.dataVisualization?.aggregatedScatterChartData
    );

    const dateRange = useSelector(
        (state: any) => state.nocilDashboard.filterValues
    );

    const dataTableList = useSelector(
        (state: any) => state?.dataVisualization?.dataTableList?.data
    );

    const loading = useSelector(
        (state: any) => state?.dataVisualization?.dataTableList?.loader
    );
    const kpiData = useSelector(
        (state: any) => state?.dataVisualization?.kpiList
    );
    const kpiTrendsData = useSelector(
        (state: any) =>
            state?.dataVisualization?.kpiTrendsData?.kpiReadingResponses
    );

    const controlLimits = useSelector(
        (state: any) => state?.dataVisualization?.controlLimits
    );

    const timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
    // const trendFunctionsString = checkedValues.join(',');

    dispatch(getChartsType(chartType));
    const applyHandler = (): any => {
           
        if (count === 1) {  
            if (chartType === ChartTypes.SCATTER) {
                let tagUUIDString: string = EMPTY?.string;
                let urlEncodedStr: string = EMPTY?.string;
                if (selectedKpiRows?.length) {
                    let kpiIdString: string = EMPTY?.string;
                    selectedKpiRows?.forEach((selectedKpiValues: any,index: number) => {
                       
                        if (kpiIdString === EMPTY?.string) {
                            kpiIdString = String(selectedKpiValues?.id);
                        } else {
                            kpiIdString += ',' + String(selectedKpiValues?.id);
                        }
                        setKpiId(kpiIdString);
                        if (index === 0) {
                            setXAxis(selectedKpiValues?.id?? null);
                        }
               
                        // Set yAxis to the second selected tag's UUID, if it exists
                        if (index === 1) {
                            setYAxis(selectedKpiValues?.id?? null);
                        }
                       
                    });
           
                    const transformedArray = selectedKpiRows?.map((kpi: any) => ({
                        assetId: kpi?.assetId,
                        kpiId: kpi?.id,
                    }));
                    const jsonString = JSON.stringify(transformedArray);
                    const bytes = utf8.encode(jsonString);
                    let base64EncodedStr = base64.encode(bytes);
                    // Replace function to remove trailing '=' characters
                    base64EncodedStr = base64EncodedStr.replace(/=+$/, '');
                    urlEncodedStr = encodeURIComponent(base64EncodedStr);
                    setKpiUUID(urlEncodedStr);
                   
                }
           
                if (selectedTagRows?.length) {
                    selectedTagRows?.forEach((selectedTagValues: any,index: number) => {
                        if (tagUUIDString === EMPTY?.string) {
                            tagUUIDString = String(selectedTagValues?.uuid);
                        } else {
                            tagUUIDString += ',' + String(selectedTagValues?.uuid);
                        }
                        setTagUUID(tagUUIDString);
                       // Set xAxis to the first selected tag's UUID
            if (index === 0) {
                setXAxis(selectedTagValues?.uuid ?? null);
            }
   
            // Set yAxis to the second selected tag's UUID, if it exists
            if (index === 1) {
                setYAxis(selectedTagValues?.uuid ?? null);
            }      
                    });
                }
            // Set xAxis and yAxis logic
        if (selectedKpiRows?.length && selectedTagRows?.length) {
            // Assign KPI ID to xAxis and Tag UUID to yAxis
            setXAxis(selectedKpiRows[0]?.id ?? null);
            setYAxis(selectedTagRows[0]?.uuid ?? null);
        }  
                dispatch(
                    getScatterAggregrateData({
                        startTime: new Date(dateRange?.startTime)?.getTime(),
                        endTime: new Date(dateRange?.endTime)?.getTime(),
                        tagUuidList: tagUUIDString,
                        tagOriginId: tagOriginId?.PLC,
                        timezone,
                        frequency: frequencyState ?? 0,
                        list: urlEncodedStr,
                        xAxis,
                        yAxis
                       
                    })
                );
            }      
            if (selectedTagRows?.length) {
                let tagUUIDString: string = EMPTY?.string;
                selectedTagRows?.map((selectedTagValues: any) => {
                    if (tagUUIDString === EMPTY?.string) {
                        tagUUIDString = String(selectedTagValues?.uuid);
                    } else {
                        tagUUIDString += ',' + String(selectedTagValues?.uuid);
                    }
                    setTagUUID(tagUUIDString);
                });
                dispatch(
                    getAggregatedChartData({
                        startTime: new Date(dateRange?.startTime)?.getTime(),
                        endTime: new Date(dateRange?.endTime)?.getTime(),
                        tagUuidList: tagUUIDString,
                        tagOriginId: tagOriginId?.PLC,
                        timezone,
                        pageSize: pageSize,
                        pageNumber: page,
                        frequency: frequencyState ?? 0,
                        trendFunctions: 'MEAN,MEDIAN,MODE,STD',
                    })
                );
            }
            if (selectedKpiRows?.length) {
                let kpiIdString: string = EMPTY?.string;
                selectedKpiRows?.map((selectedKpiValues: any) => {
                    if (kpiIdString === EMPTY?.string) {
                        kpiIdString = String(selectedKpiValues?.ruleId);
                    } else {
                        kpiIdString += ',' + String(selectedKpiValues?.ruleId);
                    }
                    setKpiId(kpiIdString);
                });
                const transformedArray = selectedKpiRows.map((kpi: any) => ({
                    assetId: kpi.assetId,
                    kpiId: kpi.id,
                }));
                const jsonString = JSON.stringify(transformedArray);
                const bytes = utf8.encode(jsonString);
                let base64EncodedStr = base64.encode(bytes);
                // Replace function is used to replace a string into empty string (=+$ is a regular expression that matches one or more = characters at the end of the string)
                base64EncodedStr = base64EncodedStr.replace(/=+$/, '');
                const urlEncodedStr = encodeURIComponent(base64EncodedStr);
                setKpiUUID(urlEncodedStr);
                dispatch(
                    getKpiTrends({
                        startTime: new Date(dateRange?.startTime)?.getTime(),
                        endTime: new Date(dateRange?.endTime)?.getTime(),
                        list: urlEncodedStr,
                        frequency: frequencyState ?? 0,
                        trendFunctions: 'MEAN,MEDIAN,MODE,STD',
                    })
                );
 
 
               
            } else {
                setChartSeriesData([]);
                setDemergeLineChartData([]);
                setDemergeBarChartData([]);
                setDemergeBoxChartData([]);
                setYAxisSeriesData([]);
                dispatch(clearDataTableList());
            }
        } else {
            let tagUUIDString: string = EMPTY?.string;
            let urlEncodedStr: string = EMPTY?.string;
            if (selectedTagRows?.length) {
                selectedTagRows?.map((selectedTagValues: any) => {
                    if (tagUUIDString === EMPTY?.string) {
                        tagUUIDString = String(selectedTagValues?.uuid);
                    } else {
                        tagUUIDString += ',' + String(selectedTagValues?.uuid);
                    }
                    setTagUUID(tagUUIDString);
                });
            }
            if (selectedKpiRows?.length) {
                let kpiIdString: string = EMPTY?.string;
                selectedKpiRows?.map((selectedKpiValues: any) => {
                    if (kpiIdString === EMPTY?.string) {
                        kpiIdString = String(selectedKpiValues?.id);
                    } else {
                        kpiIdString += ',' + String(selectedKpiValues?.id);
                    }
                    setKpiId(kpiIdString);
                });
                const transformedArray = selectedKpiRows.map((kpi: any) => ({
                    assetId: kpi.assetId,
                    kpiId: kpi.id,
                }));
                const jsonString = JSON.stringify(transformedArray);
                const bytes = utf8.encode(jsonString);
                let base64EncodedStr = base64.encode(bytes);
                // Replace function is used to replace a string into empty string (=+$ is a regular expression that matches one or more = characters at the end of the string)
                base64EncodedStr = base64EncodedStr.replace(/=+$/, '');
                urlEncodedStr = encodeURIComponent(base64EncodedStr);
                setKpiUUID(urlEncodedStr);
            } else {
                dispatch(clearDataTableList());
            }
            dispatch(
                getDataTableList({
                    requestStartTime: new Date(dateRange?.startTime)?.getTime(),
                    requestEndTime: new Date(dateRange?.endTime)?.getTime(),
                    tagUuidList: tagUUIDString ?? '',
                    kpiPayload: urlEncodedStr ?? '',
                    tagOriginId: tagOriginId?.PLC,
                    timezone,
                    pageSize: pageSize,
                    pageNumber: page,
                    sortOrder: sortingOrder.descending,
                    frequency: frequencyState ?? 0,
                })
            );
        }
        setCheckedValues([]);
    };

    const handleToggleTrendFunction = (
        trendFunction: string,
        key: string,
        isChecked: boolean
    ): any => {
        let tagUUIDString: string = EMPTY?.string;
        let kpiIdString: string = EMPTY?.string;
        let urlEncodedStr: string = EMPTY?.string;
        if (isChecked) {
            // If checked, add the key to the array
            setSelectedTrendFunctions((prev) => [...prev, key]);
        } else {
            // If unchecked, remove the key from the array
            setSelectedTrendFunctions((prev) =>
                prev.filter((item) => item !== key)
            );
        }
        // Process selectedTagRows for tags if present
        if (selectedTagRows?.length) {
            selectedTagRows.forEach((selectedTagValues: any) => {
                if (tagUUIDString === EMPTY?.string) {
                    tagUUIDString = String(selectedTagValues?.uuid);
                } else {
                    tagUUIDString += ',' + String(selectedTagValues?.uuid);
                }
            });
        }

        // Process selectedKpiRows for KPIs if present
        if (selectedKpiRows?.length) {
            selectedKpiRows.forEach((selectedKpiValues: any) => {
                if (kpiIdString === EMPTY?.string) {
                    kpiIdString = String(selectedKpiValues?.id);
                } else {
                    kpiIdString += ',' + String(selectedKpiValues?.id);
                }
            });

            // Transform selected KPIs to JSON format, encode to base64, and URL encode
            const transformedArray = selectedKpiRows.map((kpi: any) => ({
                assetId: kpi.assetId,
                kpiId: kpi.id,
            }));
            const jsonString = JSON.stringify(transformedArray);
            const bytes = utf8.encode(jsonString);
            let base64EncodedStr = base64.encode(bytes);
            // Replace function is used to replace a string into empty string (=+$ is a regular expression that matches one or more = characters at the end of the string)
            base64EncodedStr = base64EncodedStr.replace(/=+$/, '');
            urlEncodedStr = encodeURIComponent(base64EncodedStr);
            setKpiUUID(urlEncodedStr);
        }

        // Update selected trend functions
        setSelectedTrendFunctions((prev) => {
            const updatedTrendFunctions = prev?.includes(trendFunction)
                ? prev.filter((fn) => fn !== trendFunction)
                : [...prev, trendFunction];

            return updatedTrendFunctions;
        });
    };

    useEffect(() => {
        if (isMovingAverageEnabled && averageValue !== undefined) {
            // Prepare tagUUIDString if there are selectedTagRows
            const tagUUIDString = selectedTagRows
                .map((selectedTagValues: any) => selectedTagValues?.uuid)
                .join(',');

            // Prepare KPI payload if there are selectedKpiRows
            let urlEncodedStr: string = EMPTY?.string;
            if (selectedKpiRows?.length) {
                const transformedArray = selectedKpiRows.map((kpi: any) => ({
                    assetId: kpi.assetId,
                    kpiId: kpi.id,
                }));
                const jsonString = JSON.stringify(transformedArray);
                const bytes = utf8.encode(jsonString);
                const base64EncodedStr = base64
                    .encode(bytes)
                    .replace(/=+$/, '');
                urlEncodedStr = encodeURIComponent(base64EncodedStr);
            }

            // Dispatch for tags if selectedTagRows are present
            if (selectedTagRows?.length) {
                const trendFunctionsString = `MEAN,MEDIAN,MODE,STD,MOVINGAVERAGE${averageValue}`;
                dispatch(
                    getAggregatedChartData({
                        startTime: new Date(dateRange?.startTime)?.getTime(),
                        endTime: new Date(dateRange?.endTime)?.getTime(),
                        tagUuidList: tagUUIDString,
                        tagOriginId: tagOriginId?.PLC,
                        timezone,
                        pageSize,
                        pageNumber: page,
                        frequency: frequencyState ?? 0,
                        trendFunctions: trendFunctionsString,
                    })
                );
            }

            // Dispatch for KPIs if selectedKpiRows are present
            if (selectedKpiRows?.length) {
                const trendFunctionsString = `MEAN,MEDIAN,MODE,STD,MOVINGAVERAGE${averageValue}`;
                dispatch(
                    getKpiTrends({
                        startTime: new Date(dateRange?.startTime)?.getTime(),
                        endTime: new Date(dateRange?.endTime)?.getTime(),
                        list: urlEncodedStr,
                        frequency: frequencyState ?? 0,
                        trendFunctions: trendFunctionsString,
                    })
                );
            }
        }
    }, [isMovingAverageEnabled, averageValue]);

    useEffect(() => {
        if (controlLimits) {
            applyHandler();
        }
    }, [controlLimits]);

    useEffect(() => {
        applyHandler();
    }, [
        selectedTagRows,
        pageSize,
        page,
        selectedKpiRows,
        frequencyState,
        count,
    ]);

    const [chartSeriesData, setChartSeriesData] = useState<any>([]);
    const [yAxisSeriesData, setYAxisSeriesData] = useState<any>([]);
    const [chartKpiSeriesData, setChartKpiSeriesData] = useState<any>([]);
    const [yAxisKpiSeriesData, setYAxisKpiSeriesData] = useState<any>([]);
    const UnitsMapper: any = {};
    const unitMapperArray: any = [];
    const [updatedSelectedTagRows, setUpdatedSelectedTagRows] = useState<any>(
        []
    );

    const getYAxisCount = (type: any): any => {
        UnitsMapper[type] = unitMapperArray?.length;
        unitMapperArray?.push(type);

        return unitMapperArray?.length - 1;
    };

    useEffect(() => {
        const multiLineGraphDataSeries: any = [];
        const trendData: any = []; // Trend data for LineChart
        const demergeLineChartData: any = []; // Demerge data for line chart
        const demergeBarChartData: any = []; // Demerge data for bar chart
        const demergeBoxChartData: any = []; // Demerge data for box chart
        const demergeAreaChartData: any = []; // Demerge data for area chart
        const yAxis: any = [];

        aggregatedChartData?.streams?.map((tagValue: any, index: any) => {
            const dataArray: any = [];
            const barDataArray: any = []; // For bar chart data
            const boxDataArray: any = []; // For bar chart data
            const updatedRow = updatedSelectedTagRows.find(
                (row: any) => row.uuid === tagValue.uuid
            );

            if (updatedRow) {
                tagValue.tagName = updatedRow?.tagName;
            }

            if (!UnitsMapper[tagValue.unit])
                if (UnitsMapper[tagValue.unit] !== 0)
                    if (tagValue?.readings?.length) {
                        yAxis.push({
                            title: {
                                text: tagValue.unit,
                            },
                            opposite: !!unitMapperArray?.length,
                            showEmpty: false,
                        });
                    }

            const sortedTimestamps = tagValue?.readings?.sort(
                (a: any, b: any) => a?.timestamp - b?.timestamp
            );

            // Line chart data structure
            sortedTimestamps?.forEach((tagTimestampData: any) => {
                dataArray.push([
                    tagTimestampData?.timestamp,
                    tagTimestampData?.value,
                ]);
                barDataArray.push({
                    category: tagTimestampData?.timestamp, // X-axis category for bar
                    value: tagTimestampData?.value,
                });
                boxDataArray.push({
                    category: tagTimestampData?.timestamp, // X-axis category for bar
                    value: tagTimestampData?.value,
                });
            });

            if (sortedTimestamps?.length) {
                multiLineGraphDataSeries.push({
                    name: tagValue?.tagName,
                    data: dataArray,
                    yAxis:
                        UnitsMapper[tagValue?.unit] ||
                        UnitsMapper[tagValue?.unit] === 0
                            ? UnitsMapper[tagValue?.unit]
                            : getYAxisCount(tagValue?.unit),
                });

                demergeLineChartData?.push({
                    name: tagValue?.tagName,
                    data: dataArray,
                    yAxis:
                        UnitsMapper[tagValue?.unit] ||
                        UnitsMapper[tagValue?.unit] === 0
                            ? UnitsMapper[tagValue?.unit]
                            : getYAxisCount(tagValue?.unit),
                });

                demergeBarChartData.push({
                    name: tagValue?.tagName,
                    data: barDataArray, // Bar data format
                    yAxis:
                        UnitsMapper[tagValue?.unit] ||
                        UnitsMapper[tagValue?.unit] === 0
                            ? UnitsMapper[tagValue?.unit]
                            : getYAxisCount(tagValue?.unit),
                });
                demergeAreaChartData?.push({
                    name: tagValue?.tagName,
                    data: dataArray,
                    yAxis:
                        UnitsMapper[tagValue?.unit] ||
                        UnitsMapper[tagValue?.unit] === 0
                            ? UnitsMapper[tagValue?.unit]
                            : getYAxisCount(tagValue?.unit),
                });
                demergeBoxChartData.push({
                    name: tagValue?.tagName,
                    data: boxDataArray, // Box data format
                    median: tagValue?.trendFunctions?.Median,
                    yAxis:
                        UnitsMapper[tagValue?.unit] ||
                        UnitsMapper[tagValue?.unit] === 0
                            ? UnitsMapper[tagValue?.unit]
                            : getYAxisCount(tagValue?.unit),
                });
            }
            // Process trend functions

            if (tagValue?.trendFunctions) {
                Object.keys(tagValue?.trendFunctions).forEach((key) => {
                    if (
                        key === statisticalFunctions.MODE &&
                        Array.isArray(tagValue?.trendFunctions[key])
                    ) {
                        tagValue?.trendFunctions[key].forEach(
                            (modeValue: any) => {
                                demergeLineChartData?.push({
                                    name: `${tagValue?.tagName} ${statisticalFunctions.MODE}`, // Trend function name
                                    data: [
                                        [
                                            sortedTimestamps[0]?.timestamp,
                                            modeValue,
                                        ], // Start point
                                        [
                                            sortedTimestamps[
                                                sortedTimestamps?.length - 1
                                            ]?.timestamp,
                                            modeValue,
                                        ], // End point
                                    ],
                                    yAxis:
                                        UnitsMapper[tagValue?.unit] ||
                                        UnitsMapper[tagValue?.unit] === 0
                                            ? UnitsMapper[tagValue?.unit]
                                            : getYAxisCount(tagValue?.unit),
                                });

                                demergeBarChartData.push({
                                    name: `${tagValue?.tagName} ${statisticalFunctions.MODE}`,
                                    data: [
                                        [
                                            sortedTimestamps[0]?.timestamp,
                                            modeValue,
                                        ],
                                        [
                                            sortedTimestamps[
                                                sortedTimestamps.length - 1
                                            ]?.timestamp,
                                            modeValue,
                                        ],
                                    ],
                                    yAxis:
                                        UnitsMapper[tagValue?.unit] ||
                                        UnitsMapper[tagValue?.unit] === 0
                                            ? UnitsMapper[tagValue?.unit]
                                            : getYAxisCount(tagValue?.unit),
                                });
                                demergeAreaChartData?.push({
                                    name: `${tagValue?.tagName} ${statisticalFunctions.MODE}`,
                                    data: [
                                        [
                                            sortedTimestamps[0]?.timestamp,
                                            modeValue,
                                        ],
                                        [
                                            sortedTimestamps[
                                                sortedTimestamps.length - 1
                                            ]?.timestamp,
                                            modeValue,
                                        ],
                                    ],
                                    yAxis:
                                        UnitsMapper[tagValue?.unit] ||
                                        UnitsMapper[tagValue?.unit] === 0
                                            ? UnitsMapper[tagValue?.unit]
                                            : getYAxisCount(tagValue?.unit),
                                });
                                demergeBoxChartData?.push({
                                    name: `${tagValue?.tagName} ${statisticalFunctions.MODE}`,
                                    data: [
                                        [
                                            sortedTimestamps[0]?.timestamp,
                                            modeValue,
                                        ],
                                        [
                                            sortedTimestamps[
                                                sortedTimestamps.length - 1
                                            ]?.timestamp,
                                            modeValue,
                                        ],
                                    ],
                                    yAxis:
                                        UnitsMapper[tagValue?.unit] ||
                                        UnitsMapper[tagValue?.unit] === 0
                                            ? UnitsMapper[tagValue?.unit]
                                            : getYAxisCount(tagValue?.unit),
                                });
                                demergeAreaChartData?.push({
                                    name: `${tagValue?.tagName} ${statisticalFunctions.MODE}`,
                                    data: [
                                        [
                                            sortedTimestamps[0]?.timestamp,
                                            modeValue,
                                        ],
                                        [
                                            sortedTimestamps[
                                                sortedTimestamps.length - 1
                                            ]?.timestamp,
                                            modeValue,
                                        ],
                                    ],
                                    yAxis:
                                        UnitsMapper[tagValue?.unit] ||
                                        UnitsMapper[tagValue?.unit] === 0
                                            ? UnitsMapper[tagValue?.unit]
                                            : getYAxisCount(tagValue?.unit),
                                });
                            }
                        );
                    } else if (
                        key.startsWith(statisticalFunctions.MOVINGAVERAGE) &&
                        Array.isArray(tagValue?.trendFunctions[key])
                    ) {
                        const movingAverageData = tagValue?.trendFunctions[
                            key
                        ].map((point: any) => [point?.timestamp, point?.value]);

                        // Extract the numeric part from the key to display it appropriately
                        const movingAveragePeriod = key?.replace(
                            statisticalFunctions?.MOVINGAVERAGE,
                            ''
                        ); // Extracts the number after "MOVINGAVERAGE"

                        // Line chart Moving Average
                        demergeLineChartData?.push({
                            name: `${tagValue?.tagName} Moving Average (${movingAveragePeriod})`,
                            data: movingAverageData,
                            yAxis:
                                UnitsMapper[tagValue?.unit] !== undefined
                                    ? UnitsMapper[tagValue?.unit]
                                    : getYAxisCount(tagValue?.unit),
                        });
                        // Area chart Moving Average
                        demergeAreaChartData?.push({
                            name: `${tagValue?.tagName} Moving Average (${movingAveragePeriod})`,
                            data: movingAverageData,
                            yAxis:
                                UnitsMapper[tagValue?.unit] !== undefined
                                    ? UnitsMapper[tagValue?.unit]
                                    : getYAxisCount(tagValue?.unit),
                        });

                        // Bar chart Moving Average
                        demergeBarChartData?.push({
                            name: `${tagValue?.tagName} Moving Average (${movingAveragePeriod})`,
                            data: movingAverageData,
                            yAxis:
                                UnitsMapper[tagValue?.unit] !== undefined
                                    ? UnitsMapper[tagValue?.unit]
                                    : getYAxisCount(tagValue?.unit),
                        });

                        // Box Chart Moving Average
                        demergeBoxChartData.push({
                            name: `${tagValue?.tagName} Moving Average (${movingAveragePeriod})`,
                            data: movingAverageData,
                            yAxis:
                                UnitsMapper[tagValue?.unit] !== undefined
                                    ? UnitsMapper[tagValue?.unit]
                                    : getYAxisCount(tagValue?.unit),
                        });
                    } else {
                        // Handle other trend functions normally
                        demergeLineChartData?.push({
                            name: selectedTrendFunctions
                                ? `${tagValue?.tagName} ${key}`
                                : tagValue?.tagName, // Only include tag name if trend functions are not selected
                            data: [
                                [
                                    sortedTimestamps[0]?.timestamp,
                                    tagValue?.trendFunctions[key],
                                ],
                                [
                                    sortedTimestamps[
                                        sortedTimestamps.length - 1
                                    ]?.timestamp,
                                    tagValue?.trendFunctions[key],
                                ],
                            ],
                            yAxis:
                                UnitsMapper[tagValue?.unit] ||
                                UnitsMapper[tagValue?.unit] === 0
                                    ? UnitsMapper[tagValue?.unit]
                                    : getYAxisCount(tagValue?.unit),
                        });
                        demergeAreaChartData?.push({
                            name: selectedTrendFunctions
                                ? `${tagValue?.tagName} ${key}`
                                : tagValue?.tagName, // Only include tag name if trend functions are not selected
                            data: [
                                [
                                    sortedTimestamps[0]?.timestamp,
                                    tagValue?.trendFunctions[key],
                                ],
                                [
                                    sortedTimestamps[
                                        sortedTimestamps.length - 1
                                    ]?.timestamp,
                                    tagValue?.trendFunctions[key],
                                ],
                            ],
                            yAxis:
                                UnitsMapper[tagValue?.unit] ||
                                UnitsMapper[tagValue?.unit] === 0
                                    ? UnitsMapper[tagValue?.unit]
                                    : getYAxisCount(tagValue?.unit),
                        });

                        demergeBarChartData?.push({
                            name: selectedTrendFunctions
                                ? `${tagValue?.tagName} ${key}`
                                : tagValue?.tagName, // Only include tag name if trend functions are not selected
                            data: [
                                [
                                    sortedTimestamps[0]?.timestamp,
                                    tagValue?.trendFunctions[key],
                                ],
                                [
                                    sortedTimestamps[
                                        sortedTimestamps.length - 1
                                    ]?.timestamp,
                                    tagValue?.trendFunctions[key],
                                ],
                            ],
                            yAxis:
                                UnitsMapper[tagValue?.unit] ||
                                UnitsMapper[tagValue?.unit] === 0
                                    ? UnitsMapper[tagValue?.unit]
                                    : getYAxisCount(tagValue?.unit),
                        });

                        demergeBoxChartData?.push({
                            name: selectedTrendFunctions
                                ? `${tagValue?.tagName} ${key}`
                                : tagValue?.tagName, // Only include tag name if trend functions are not selected
                            data: [
                                [
                                    sortedTimestamps[0]?.timestamp,
                                    tagValue?.trendFunctions[key],
                                ],
                                [
                                    sortedTimestamps[
                                        sortedTimestamps.length - 1
                                    ]?.timestamp,
                                    tagValue?.trendFunctions[key],
                                ],
                            ],
                            yAxis:
                                UnitsMapper[tagValue?.unit] ||
                                UnitsMapper[tagValue?.unit] === 0
                                    ? UnitsMapper[tagValue?.unit]
                                    : getYAxisCount(tagValue?.unit),
                        });
                    }
                });
            }
        });

        setChartSeriesData(multiLineGraphDataSeries);
        setYAxisSeriesData(yAxis);
        setDemergeLineChartData(demergeLineChartData);
        setDemergeBarChartData(demergeBarChartData); // Set bar chart data here
        setDemergeBoxChartData(demergeBoxChartData); // Set bar chart data here
        setDemergeAreaChartData(demergeAreaChartData); // Set bar chart data here
        setTrendData(trendData);
    }, [aggregatedChartData, updatedSelectedTagRows]);

    const [updatedKpiData, setUpdatedKpiData] = useState<any>([]);
    useEffect(() => {
        if (selectedKpiRows && kpiTrendsData) {
            const updatedData = kpiTrendsData.map((data: any) => {
                const matchingKpi = selectedKpiRows.find(
                    (kpi: any) => kpi.ruleId === data.kpiId
                );

                if (matchingKpi) {
                    return {
                        ...data,
                        dataType: { unit: matchingKpi.unit.abbreviation },
                        tagName: matchingKpi?.name,
                        tagDesc: matchingKpi?.description,
                        type: 'kpi',
                        key: data?.kpiId,
                        parentNode: { name: matchingKpi?.assetName },
                        Mean: data?.trendFunctions?.Mean,
                        totalCount: data?.totalCount,
                        Median: data?.trendFunctions?.Median,
                        Mode: data?.trendFunctions?.Mode,
                        SD: data?.trendFunctions
                            ? data?.trendFunctions[
                                  statisticalFunctions.STANDARDDEVIATION
                              ]
                            : null,
                    };
                }

                return data;
            });
            setUpdatedKpiData(updatedData);
        }
    }, [selectedKpiRows, kpiTrendsData]);

    useEffect(() => {
        const multiLineGraphDataSeries: any = [];
        const yAxis: any = [];
        const trendData: any = []; // Trend data for LineChart
        const demergeLineChartData: any = []; // Demerge data for line chart
        const demergeBarChartData: any = []; // Demerge data for bar chart
        const demergeBoxChartData: any = []; // Demerge data for box chart
        const demergeAreaChartData: any = []; // Demerge data for bar chart

        updatedKpiData?.forEach((kpiData: any, index: any) => {
            const dataArray: any = [];
            const barDataArray: any = [];
            const boxDataArray: any = [];
            const updatedRow = selectedKpiRows.find(
                (row: any) => row.ruleId === kpiData.ruleId
            );

            if (updatedRow) {
                kpiData.name = updatedRow?.name;
            }

            if (kpiData?.readings?.length > 0) {
                yAxis.push({
                    title: {
                        text: kpiData?.dataType?.unit,
                    },
                    opposite: !!unitMapperArray?.length,
                    showEmpty: false,
                });
            }

            kpiData?.readings?.forEach((reading: any) => {
                dataArray.push([reading?.time, reading?.value]);
                // For bar chart, we could use a simple timestamp label or a different category representation
                barDataArray.push({
                    category: reading?.time, // Format the date as needed
                    value: reading?.value,
                });
                boxDataArray.push([reading?.time, reading?.value]);
            });

            if (dataArray.length > 0) {
                multiLineGraphDataSeries?.push({
                    name: kpiData?.tagName,
                    data: dataArray,
                    yAxis: index,
                });
                demergeLineChartData?.push({
                    name: kpiData?.tagName,
                    data: dataArray,
                    yAxis: index,
                });
                demergeAreaChartData?.push({
                    name: kpiData?.tagName,
                    data: dataArray,
                    yAxis: index,
                });
                demergeBarChartData?.push({
                    name: kpiData?.tagName,
                    data: barDataArray,
                    yAxis: index,
                });
                demergeBoxChartData.push({
                    name: kpiData?.tagName,
                    data: boxDataArray,
                    yAxis: index,
                    median: kpiData?.trendFunctions?.Median,
                });
            }

            if (kpiData?.trendFunctions) {
                const firstReadingTime = kpiData?.readings[0]?.time;
                const lastReadingTime =
                    kpiData?.readings[kpiData?.readings.length - 1]?.time;

                Object.keys(kpiData.trendFunctions).forEach((key) => {
                    if (
                        key === statisticalFunctions.MODE &&
                        Array.isArray(kpiData?.trendFunctions[key])
                    ) {
                        kpiData?.trendFunctions[key].forEach(
                            (modeValue: any) => {
                                demergeLineChartData?.push({
                                    name: `${kpiData?.tagName} ${statisticalFunctions.MODE}`, // KPI name with Mode
                                    data: [
                                        [firstReadingTime, modeValue], // Start point for Mode
                                        [lastReadingTime, modeValue], // End point for Mode
                                    ],
                                    yAxis: index, // Ensure the trend aligns with the correct Y-axis
                                });
                                demergeAreaChartData?.push({
                                    name: `${kpiData?.tagName} ${statisticalFunctions.MODE}`, // KPI name with Mode
                                    data: [
                                        [firstReadingTime, modeValue], // Start point for Mode
                                        [lastReadingTime, modeValue], // End point for Mode
                                    ],
                                    yAxis: index, // Ensure the trend aligns with the correct Y-axis
                                });
                                multiLineGraphDataSeries?.push({
                                    name: `${kpiData?.tagName} ${statisticalFunctions.MODE}`, // KPI name with Mode
                                    data: [
                                        [firstReadingTime, modeValue], // Start point for Mode
                                        [lastReadingTime, modeValue], // End point for Mode
                                    ],
                                    yAxis: index, // Ensure the trend aligns with the correct Y-axis
                                });
                                demergeBarChartData?.push({
                                    name: `${kpiData?.tagName} ${statisticalFunctions.MODE}`,
                                    data: [
                                        {
                                            category: 'Mode',
                                            value: modeValue,
                                        },
                                    ],
                                    yAxis: index,
                                });
                                demergeBoxChartData.push({
                                    name: `${kpiData?.tagName} ${statisticalFunctions.MODE}`,
                                    data: [
                                        [firstReadingTime, modeValue], // Start point for Mode
                                        [lastReadingTime, modeValue], // End point for Mode
                                    ],
                                    yAxis: index,
                                });
                            }
                        );
                    } else if (
                        key?.includes(statisticalFunctions.MOVINGAVERAGE) &&
                        Array.isArray(kpiData?.trendFunctions[key])
                    ) {
                        const movingAverageData = kpiData?.trendFunctions[
                            key
                        ]?.map((point: any) => [point.timestamp, point.value]);
                        // Extract the numeric part from the key to display it appropriately
                        const movingAveragePeriod = key.replace(
                            statisticalFunctions.MOVINGAVERAGE,
                            ''
                        ); // Extracts the number after "MOVINGAVERAGE"

                        demergeLineChartData?.push({
                            name: `${kpiData?.tagName} (${movingAveragePeriod})`, // KPI name with Moving Average
                            data: movingAverageData,
                            yAxis: index,
                        });
                        demergeAreaChartData?.push({
                            name: `${kpiData?.tagName} (${movingAveragePeriod})`, // KPI name with Moving Average
                            data: movingAverageData,
                            yAxis: index,
                        });
                        multiLineGraphDataSeries?.push({
                            name: `${kpiData?.tagName} (${movingAveragePeriod})`, // KPI name with Moving Average
                            data: movingAverageData,
                            yAxis: index,
                        });
                        demergeBarChartData?.push({
                            name: `${kpiData?.tagName} (${movingAveragePeriod})`, // KPI name with Moving Average
                            data: movingAverageData,
                            yAxis: index,
                        });
                        demergeBoxChartData.push({
                            name: `${kpiData?.tagName} (${movingAveragePeriod})`, // KPI name with Moving Average
                            data: movingAverageData,
                            yAxis: index,
                        });
                    } else {
                        // Handle other trend functions normally

                        demergeLineChartData?.push({
                            name: `${kpiData?.tagName} ${key}`, // KPI name with trend function name
                            data: [
                                [firstReadingTime, kpiData.trendFunctions[key]], // Start point for the trend
                                [lastReadingTime, kpiData.trendFunctions[key]], // End point for the trend
                            ],
                            yAxis: index,
                        });
                        demergeAreaChartData?.push({
                            name: `${kpiData?.tagName} ${key}`, // KPI name with trend function name
                            data: [
                                [firstReadingTime, kpiData.trendFunctions[key]], // Start point for the trend
                                [lastReadingTime, kpiData.trendFunctions[key]], // End point for the trend
                            ],
                            yAxis: index,
                        });
                        multiLineGraphDataSeries?.push({
                            name: `${kpiData?.tagName} ${key}`, // KPI name with trend function name
                            data: [
                                [firstReadingTime, kpiData.trendFunctions[key]], // Start point for the trend
                                [lastReadingTime, kpiData.trendFunctions[key]], // End point for the trend
                            ],
                            yAxis: index,
                        });
                        demergeBarChartData?.push({
                            name: `${kpiData?.tagName} ${key}`,
                            data: [
                                {
                                    category: key,
                                    value: kpiData.trendFunctions[key],
                                },
                            ],
                            yAxis: index,
                        });
                        demergeBoxChartData.push({
                            name: `${kpiData?.tagName} ${key}`,
                            data: [
                                [firstReadingTime, kpiData.trendFunctions[key]], // Start point for the trend
                                [lastReadingTime, kpiData.trendFunctions[key]], // End point for the trend
                            ],
                            yAxis: index,
                        });
                    }
                });
            }
        });

        setChartKpiSeriesData(multiLineGraphDataSeries);
        setYAxisKpiSeriesData(yAxis);
        setDemergeLineChartData(demergeLineChartData);
        setDemergeBarChartData(demergeBarChartData); // Set bar chart data here
        setDemergeBoxChartData(demergeBoxChartData); // Set bar chart data here
        setDemergeAreaChartData(demergeAreaChartData); // Set bar chart data here
        setTrendData(trendData);
    }, [updatedKpiData, selectedKpiRows]);

    const [chartData, setChartData] = useState<any>([]);

    const [yAxisData, setYAxisData] = useState<any>([]);

    useEffect(() => {
        if (chartKpiSeriesData && chartSeriesData) {
            setChartData([...chartKpiSeriesData, ...chartSeriesData]);
        }
        if (yAxisKpiSeriesData && yAxisSeriesData) {
            setYAxisData([...yAxisKpiSeriesData, ...yAxisSeriesData]);
        }
    }, [
        chartKpiSeriesData,
        chartSeriesData,
        yAxisKpiSeriesData,
        yAxisSeriesData,
    ]);
    const modelListOptions = (): any => {
        const result: any = [];
        modelList
            ?.filter((modelObject: any) => modelObject?.isSelectable)
            ?.map((modelObject: any) => {
                result.push({
                    label: modelObject?.name,
                    value: modelObject?.rootNode?.id,
                });
            });
        return result;
    };

    useEffect(() => {
        selectedModel && dispatch(getNodeList(selectedModel));
    }, [selectedModel]);

    const modelSelectHandler = (value: any): any => {
        setSelectedModel(value);
    };
    // Will Use later
    // const optionHandler = (value: any): void => {
    //     setSelectedOption(value);
    // };
    const chartRef: any = useRef(null);

    useEffect(() => {
        aggregatedChartDataLoading
            ? chartRef.current?.chart?.showLoading()
            : chartRef.current?.chart?.hideLoading();
    }, [aggregatedChartDataLoading]);

    useEffect(() => {
        setSelectedTagRows([]);
        setSelectedRowsKeys([]);
        setSelectedDevice(null);
        setSelectedModel(null);
        dispatch(clearDataTableList());
        dispatch(getFrequency());
        dispatch(getTrendsFunction());
    }, []);
    useEffect(() => {
        setSearch(null);
        setSearchKpi(null);
        setSelectedDevice(null);
        setSelectedKpiRows([]);
        setSelectedTagRows([]);
    }, [selectedModel]);

    useEffect(() => {
        setSearch(null);
        setSearchKpi(null);
    }, [selectedDevice]);
    const getCSVData = (): any => {
        dispatch(
            getAssetCSVData({
                startTime: dayjs(startDateRedux).valueOf(),
                endTime: dayjs(endDateRedux).valueOf(),
                tagUuidList: uuids,
                kpiPayload: kpiUUID,
                templateType: TemplateTypeAsset.csv, // 2 is for csv, 3 for pdf,
                tagOriginId: TagOriginId.PLC, // this denotes tag category wherr PLC = 0, USER = 1, KPI = 2
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                frequency: frequencyState ?? 0,
            })
        );
    };
    const getPDFData = (): any => {
        dispatch(
            getAssetCSVData({
                startTime: dayjs(startDateRedux).valueOf(),
                endTime: dayjs(endDateRedux).valueOf(),
                tagUuidList: uuids,
                kpiPayload: kpiUUID,
                templateType: TemplateTypeAsset.pdf, // TemplateType = 2 is for csv, 3 for pdf,
                tagOriginId: TagOriginId.PLC, // this denotes tag category wherr PLC = 0, USER = 1, KPI = 2
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                frequency: frequencyState ?? 0,
            })
        );
    };
    const getCSVAggData = (): any => {
        dispatch(
            getExportAggregrateCsvData({
                startTime: dayjs(startDateRedux).valueOf(),
                endTime: dayjs(endDateRedux).valueOf(),
                tagUuidList: uuids,
                kpiPayload: kpiUUID,
                templateType: TemplateTypeAsset.csv, // TemplateType = 2 is for csv, 3 for pdf,
                tagOriginId: TagOriginId.PLC, // this denotes tag category wherr PLC = 0, USER = 1, KPI = 2
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                frequency: frequencyState ?? 0,
            })
        );
    };
    const getPDFAggData = (): any => {
        dispatch(
            getExportAggregrateCsvData({
                startTime: dayjs(startDateRedux).valueOf(),
                endTime: dayjs(endDateRedux).valueOf(),
                tagUuidList: uuids,
                kpiPayload: kpiUUID,
                templateType: TemplateTypeAsset.pdf, // TemplateType = 2 is for csv, 3 for pdf,
                tagOriginId: TagOriginId.PLC, // this denotes tag category wherr PLC = 0, USER = 1, KPI = 2
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                frequency: frequencyState ?? 0,
            })
        );
    };

    // NOTE:will need this later
    // const exportAggregatedAsPNG = (): void => {
    //     const container = document.querySelector('.data-visualization-container__multiAxisChart');
    //     if (!container) {
    //         console.error("Container not found");
    //         return;
    //     }

    //     const charts = container.getElementsByClassName('chart-class'); // Replace 'chart-class' with the class used for individual chart containers
    //     if (charts.length === 0) {
    //         console.error("No charts found in the container");
    //         return;
    //     }

    //     const canvas = document.createElement('canvas');
    //     const ctx = canvas.getContext('2d');

    //     if (!ctx) {
    //         console.error("Canvas context is not available");
    //         return;
    //     }

    //     let totalHeight = 0;
    //     let maxWidth = 0;

    //     const drawChartToCanvas = (chartElement: HTMLElement, offsetY: number): Promise<void> => {
    //         return new Promise((resolve, reject) => {
    //             const chart = (chartElement as any).chart; // Assuming Highcharts chart instance is attached to the DOM element
    //             if (!chart) {
    //                 console.error("Chart instance not found");
    //                 resolve();
    //                 return;
    //             }

    //             const svgData = chart.getSVG();
    //             const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
    //             const url = URL.createObjectURL(svgBlob);

    //             const img = new Image();
    //             img.onload = () => {
    //                 maxWidth = Math.max(maxWidth, img.width);
    //                 totalHeight += img.height;

    //                 // Resize the canvas dynamically
    //                 canvas.width = maxWidth;
    //                 canvas.height = totalHeight;

    //                 // Draw the chart image
    //                 ctx.fillStyle = '#FFFFFF'; // White background
    //                 ctx.fillRect(0, offsetY, img.width, img.height); // Add white background
    //                 ctx.drawImage(img, 0, offsetY); // Draw the chart image

    //                 URL.revokeObjectURL(url); // Clean up the blob URL
    //                 resolve();
    //             };

    //             img.onerror = (error) => {
    //                 console.error("Error loading chart image", error);
    //                 URL.revokeObjectURL(url);
    //                 reject(error);
    //             };

    //             img.src = url;
    //         });
    //     };

    //     const drawAllCharts = async () => {
    //         try {
    //             const drawPromises = Array.from(charts).map((chartElement, index) =>
    //                 drawChartToCanvas(chartElement as HTMLElement, totalHeight)
    //             );

    //             await Promise.all(drawPromises);

    //             // Export the canvas as a PNG file
    //             const pngData = canvas.toDataURL('image/png');
    //             const link = document.createElement('a');
    //             link.href = pngData;
    //             link.download = 'all_charts.png';
    //             link.click();
    //         } catch (error) {
    //             console.error("Error exporting charts as PNG:", error);
    //         }
    //     };

    //     drawAllCharts();
    // };

    const menu = (
        <Menu onClick={({ key }): any => {}}>
            <Menu.Item key="csv" onClick={getCSVData}>
                {t('dataVisualization.exportCSV')}
            </Menu.Item>
            <Menu.Item key="pdf" onClick={getPDFData}>
                {t('dataVisualization.exportPDF')}
            </Menu.Item>
            <Menu.Item key="csvAgg" onClick={getCSVAggData}>
                {t('dataVisualization.exportAggregatedCSV')}
            </Menu.Item>
            <Menu.Item key="pdfAgg" onClick={getPDFAggData}>
                {t('dataVisualization.exportAggregatedPDF')}
            </Menu.Item>
            <Menu.Item key="pngAgg">
            Export Chart As PNG
        </Menu.Item>
        </Menu>
    );
    // Will Use later
    // const options = [
    //     {
    //         id: '1',
    //         value: 'kpis',
    //         label: 'KPIs',
    //     },
    //     {
    //         id: '2',
    //         value: 'tags',
    //         label: 'Tags',
    //     },
    // ];
    // const { Option } = Select;
    const [search, setSearch] = useState<string | null>(null);
    const [searchKpi, setSearchKpi] = useState<string | null>(null);
    const [tagSearchTimeout, setTagSearchTimeout] = useState<any>(undefined);
    const [kpiSearchTimeout, setKpiSearchTimeout] = useState<any>(undefined);
    const [sortOrder, setSortOrder] = useState<number | null>(null);
    const [sortColumn, setSortColumn] = useState<number | null>(null);
    const [kpiSortOrder, setkpiSortOrder] = useState<number | null>(null);
    const [kpiSortColumn, setKpiSortColumn] = useState<number | null>(null);
    const [maxRange, setMaxRange] = useState(false);
    useEffect(() => {
        if (
            selectedTagRows.length + selectedKpiRows.length >=
            maxRowSelectionCount
        ) {
            setMaxRange(true);
        } else {
            setMaxRange(false);
        }
    }, [selectedTagRows, selectedKpiRows]);
    useEffect(() => {
        selectedModel &&
            selectedDevice &&
            dispatch(
                getStreamTagList({
                    parentNodeId: selectedDevice,
                    search: search ?? EMPTY.string,
                    pageSize: pageSize,
                    pageNumber: page,
                })
            );
        selectedModel &&
            selectedDevice &&
            dispatch(
                getKpiData({
                    assetId: selectedDevice,
                    search: searchKpi ?? EMPTY.string,
                    pageSize: pageSize,
                    pageNumber: page,
                })
            );
    }, [selectedDevice, selectedModel,page, pageSize]);
    // Will Use later
    // }, [selectedOption, selectedDevice, selectedModel]);
    useEffect(() => {
        selectedDevice &&
            dispatch(
                getStreamTagList({
                    parentNodeId: selectedDevice,
                    search: search ?? EMPTY.string,
                    sortOrder: sortOrder,
                    sortColumn: sortColumn,
                    pageSize: pageSize,
                    pageNumber: page,
                })
            );
    }, [sortColumn, sortOrder, selectedDevice,page, pageSize]);

    useEffect(() => {
        selectedDevice &&
            dispatch(
                getKpiData({
                    assetId: selectedDevice,
                    search: searchKpi ?? EMPTY.string,
                    sortOrder: kpiSortOrder,
                    sortColumn: kpiSortColumn,
                    pageSize: pageSize,
                    pageNumber: page,
                })
            );
    }, [kpiSortColumn, kpiSortOrder, selectedDevice]);

    useEffect(() => {
        if (selectedTagRows && aggregatedChartData) {
            const updatedRows = selectedTagRows?.map((row: any) => {
                const matchingStream = aggregatedChartData?.streams?.find(
                    (stream: any) => stream?.uuid === row?.uuid
                );
                if (matchingStream) {
                    return {
                        ...row,
                        aggregatedValue: matchingStream?.aggregatedValue,
                        Mean: matchingStream?.trendFunctions?.Mean,
                        totalCount: matchingStream?.totalCount,
                        Median: matchingStream?.trendFunctions?.Median,
                        Mode: matchingStream?.trendFunctions?.Mode,
                        SD: matchingStream?.trendFunctions
                            ? matchingStream.trendFunctions[
                                  statisticalFunctions.STANDARDDEVIATION
                              ]
                            : null,
                    };
                }
                return row;
            });
            setUpdatedSelectedTagRows(updatedRows);
        }
    }, [selectedTagRows, aggregatedChartData]);
    const [transformedKpiRows, setTransformedKpiRows] = useState([]);

    useEffect(() => {
        if (selectedKpiRows && selectedKpiRows.length > 0) {
            const newArray = selectedKpiRows.map((kpi: any) => ({
                tagName: kpi.name,
                parentNode: { name: kpi.assetName },
                dataType: { unit: kpi.unit?.abbreviation },
                aggregatedValue: null,
                type: 'kpi',
                key: kpi?.ruleId,
            }));
            setTransformedKpiRows(newArray);
        }
    }, [selectedKpiRows]);
    const [info, setInfo] = useState<any>([]);

    useEffect(() => {
        if (updatedKpiData && updatedSelectedTagRows && transformedKpiRows) {
            const mergedArray = updatedKpiData.concat(
                ...updatedSelectedTagRows,
                ...transformedKpiRows
            );
            setInfo(mergedArray);
        }
    }, [updatedKpiData, updatedSelectedTagRows, transformedKpiRows]);

    useEffect(() => {
        if (!selectedTagRows?.length) {
            dispatch({ type: EMPTY_TAGS_PLOTTING });
        }
    }, [selectedTagRows]);

    useEffect(() => {
        if (!selectedKpiRows?.length) {
            setUpdatedKpiData([]);
            setTransformedKpiRows([]);

            dispatch({ type: EMPTY_KPIS_PLOTTING });
        }
    }, [selectedKpiRows]);
    const [showExpandedGraph, setShowExpandedGraph] = useState(false);
    useEffect(() => {
        const filteredTransformedKpiRows = transformedKpiRows?.filter(
            (row: any) =>
                !updatedKpiData.some(
                    (kpiData: any) => kpiData.kpiId === row.key
                )
        );
        setTransformedKpiRows(filteredTransformedKpiRows);
    }, [updatedKpiData]);

    // Handle chart selection
    const handleChartChange = (value: any): any => {
        setChartType(value);
    };

    const renderChart = (): any => {
        return (
            <>
                {chartType === ChartTypes.LINE ? (
                    showMerge ? (
                        <MultiAxisTimeSeriesChart
                            key={`${dateRange?.dateRange}-${frequencyState}`}
                            chartSeriesData={
                                chartData ||
                                chartSeriesData ||
                                chartKpiSeriesData
                            }
                            yAxisSeriesData={
                                yAxisData ||
                                yAxisSeriesData ||
                                yAxisKpiSeriesData
                            }
                            chartRef={chartRef}
                            dateRange={dateRange}
                            trendData={trendData}
                            uclValue={uclValue}
                            lclValue={lclValue}
                            selectedTags={selectedTagRows}
                            selectedKpis={selectedKpiRows}
                        />
                    ) : aggregatedChartDataLoading ? (
                        <Spin />
                    ) : (
                        <DemergeLineChart
                            key={`${dateRange?.dateRange}-${frequencyState}`}
                            name=""
                            chartSeriesData={demergeLineChartData}
                            chartRef={chartRef}
                            dateRange={dateRange}
                            trendData={trendData}
                            selectedTags={selectedTagRows}
                            selectedKpis={selectedKpiRows}
                            showMarkers={showMarkers}
                            uclValue={uclValue}
                            lclValue={lclValue}
                            selectedTrendFunctions={selectedTrendFunctions}
                            selectedAlert={selectedAlert}
                            selectedTimeCapsule={selectedTimeCapsule}
                            controlLimitsValue={controlLimitsValue}
                            tagUUID={tagUUID}
                            setTagUUID={setTagUUID}
                            selectedTagRows={selectedTagRows}
                            setSelectedTagRows={setSelectedTagRows}
                            frequencyState={frequencyState}
                            selectedKpiRows={selectedKpiRows}
                            setSelectedKpiRows={setSelectedKpiRows}
                            setKpiUUID={setKpiUUID}
                            kpiUUID={kpiUUID}
                            setKpiId={setKpiId}
                            kpiID={kpiID}
                        />
                    )
                ) : chartType === ChartTypes.BAR ? (
                    showMerge ? (
                        <MergedBarChart
                            key={`${dateRange?.dateRange}-${frequencyState}`}
                            chartSeriesData={
                                chartData ||
                                chartSeriesData ||
                                chartKpiSeriesData
                            }
                            yAxisSeriesData={
                                yAxisData ||
                                yAxisSeriesData ||
                                yAxisKpiSeriesData
                            }
                            chartRef={chartRef}
                            dateRange={dateRange}
                            trendData={trendData}
                        />
                    ) : aggregatedChartDataLoading ? (
                        <Spin />
                    ) : (
                        <DemergeBarChart
                            key={`${dateRange?.dateRange}-${frequencyState}`}
                            name=""
                            chartSeriesData={demergeBarChartData}
                            dateRange={dateRange}
                            trendData={trendData}
                            selectedTags={selectedTagRows}
                            selectedKpis={selectedKpiRows}
                            showMarkers={showMarkers}
                            selectedTrendFunctions={selectedTrendFunctions}
                            selectedAlert={selectedAlert}
                            selectedTimeCapsule={selectedTimeCapsule}
                            controlLimitsValue={controlLimitsValue}
                        />
                    )
                ) : chartType === ChartTypes.SCATTER ? (
                    <>
                        {aggregatedChartDataLoading ? ( // Loading condition only for ScatterChart
                            <Spin />
                        ) : (
                            <ScatterChart
                                key={`${dateRange?.dateRange}-${frequencyState}`}
                                // chartSeriesData={scatterChartData}
                                // dateRange={dateRange}
                                selectedTags={selectedTagRows}
                                selectedKpis={selectedKpiRows}
                                aggregatedScatterChartData={
                                    aggregatedScatterChartData
                                }
                                // showMarkers={showMarkers}
                                // uclValue={uclValue}
                                // lclValue={lclValue}
                                // selectedTrendFunctions={selectedTrendFunctions}
                                // selectedAlert={selectedAlert}
                                // selectedTimeCapsule={selectedTimeCapsule}
                            />
                        )}
                        <ChartPropertiesScatter
                            visible={chartPropertiesModal}
                            handleToggleTrendFunction={
                                handleToggleTrendFunction
                            }
                            tagUUID={tagUUID}
                            setTagUUID={setTagUUID}
                            selectedTagRows={selectedTagRows}
                            setSelectedTagRows={setSelectedTagRows}
                            frequencyState={frequencyState}
                            selectedKpiRows={selectedKpiRows}
                            setSelectedKpiRows={setSelectedKpiRows}
                            setKpiUUID={setKpiUUID}
                            kpiUUID={kpiUUID}
                            setKpiId={setKpiId}
                            kpiID={kpiID}
                            selectedTrendFunctions={selectedTrendFunctions}
                            onClose={handleClosePropertiesPanel}
                            selectedModel={selectedModel}
                            setAxisSelectorEnabled={setAxisSelectorEnabled}
                            setRegressionEnabled={setRegressionEnabled}
                            setConfidenceIntervalEnabled={
                                setConfidenceIntervalEnabled
                            }
                            setStatFunctionsEnabled={setStatFunctionsEnabled}
                            axisSelectorEnabled={axisSelectorEnabled}
                            regressionEnabled={regressionEnabled}
                            confidenceIntervalEnabled={
                                confidenceIntervalEnabled
                            }
                            statFunctionsEnabled={statFunctionsEnabled}
                            xAxis={xAxis}
                            setXAxis={setXAxis}
                            yAxis={yAxis}
                            setYAxis={setYAxis}
                            aggregatedScatterChartData={aggregatedScatterChartData}
                        />
                    </>
                ) : chartType === ChartTypes.BOXPLOT ? (
                    showMerge ? (
                        <MergedBoxPlot
                            key={`${dateRange?.dateRange}-${frequencyState}`}
                            chartSeriesData={
                                chartData ||
                                chartSeriesData ||
                                chartKpiSeriesData
                            }
                            yAxisSeriesData={
                                yAxisData ||
                                yAxisSeriesData ||
                                yAxisKpiSeriesData
                            }
                            chartRef={chartRef}
                            dateRange={dateRange}
                            trendData={trendData}
                        />
                    ) : aggregatedChartDataLoading ? (
                        <Spin />
                    ) : (
                        <DemergeBoxChart
                            key={`${dateRange?.dateRange}-${frequencyState}`}
                            name=""
                            chartSeriesData={demergeBoxChartData}
                            dateRange={dateRange}
                            trendData={trendData}
                            selectedTags={selectedTagRows}
                            selectedKpis={selectedKpiRows}
                            showMarkers={showMarkers}
                            uclValue={uclValue}
                            lclValue={lclValue}
                            selectedTrendFunctions={selectedTrendFunctions}
                            selectedAlert={selectedAlert}
                            selectedTimeCapsule={selectedTimeCapsule}
                            controlLimitsValue={controlLimitsValue}
                        />
                    )
                ) : chartType === ChartTypes.AREA ? (
                    showMerge ? (
                        <MergedAreaChart
                            chartSeriesData={
                                chartData ||
                                chartSeriesData ||
                                chartKpiSeriesData
                            }
                            yAxisSeriesData={
                                yAxisData ||
                                yAxisSeriesData ||
                                yAxisKpiSeriesData
                            }
                            chartRef={chartRef}
                            dateRange={dateRange}
                            trendData={trendData}
                        />
                    ) : aggregatedChartDataLoading ? (
                        <Spin />
                    ) : (
                        <DemergeAreaChart
                            key={`${dateRange?.dateRange}-${frequencyState}`}
                            name=""
                            chartSeriesData={demergeAreaChartData}
                            dateRange={dateRange}
                            trendData={trendData}
                            selectedTags={selectedTagRows}
                            selectedKpis={selectedKpiRows}
                            showMarkers={showMarkers}
                            uclValue={uclValue}
                            lclValue={lclValue}
                            selectedTrendFunctions={selectedTrendFunctions}
                            selectedAlert={selectedAlert}
                            selectedTimeCapsule={selectedTimeCapsule}
                            controlLimitsValue={controlLimitsValue}
                        />
                    )
                ) : (
                    <div>No chart available for the selected chart type</div>
                )}

                {/* Show ChartPropertiesPanel for non-scatter charts */}
                {chartType !== ChartTypes.SCATTER && (
                    <ChartPropertiesPanel
                        visible={chartPropertiesModal}
                        handleToggleTrendFunction={handleToggleTrendFunction}
                        selectedTrendFunctions={selectedTrendFunctions}
                        onClose={handleClosePropertiesPanel}
                        trendsFunction={trendsFunction}
                        isMovingAverageEnabled={isMovingAverageEnabled}
                        averageValue={averageValue}
                        setIsMovingAverageEnabled={setIsMovingAverageEnabled}
                        setAverageValue={setAverageValue}
                        selectedModel={selectedModel}
                        handleAlertChange={handleAlertChange}
                        handleTimeCapsuleChange={handleTimeCapsuleChange}
                    />
                )}
            </>
        );
    };

    const handleMarkerClick = (): any => {
        setShowMarkers((prev) => !prev); // Toggle marker functionality visibility
    };

    const [modifiedTree, setModifiedTree] = useState({});
    useEffect(() => {
        const newTree = mapTree(nodeList.node);
        const filteredTree = filterVisibleNodes(newTree);
        setModifiedTree(filteredTree);
    }, [nodeList]);
    const filterVisibleNodes = (tree: any): any => {
        if (!tree.visible) {
            return null;
        }
        const newNode = {
            title: tree.title,
            key: tree.key,
            value: tree.value,
            list: tree.list,
            disabled: tree.disabled,
            visible: tree.visible,
            children: Array?.isArray(tree?.children)
                ? tree?.children
                      ?.map(filterVisibleNodes)
                      ?.filter((child: any) => child !== null)
                : [],
        };

        return newNode;
    };
    return (
        <div>
            <CustomHeader
                heading={t('dataVisualization.heading')}
                currentTimePicker={true}
                customDateTimePicker={true}
                applyClickHandler={applyHandler}
            />
            <div
                className={`data-visualization-container ${
                    count === 1 ? 'trending-container' : 'other-container'
                }`}
            >
                <div className={showExpandedGraph ? 'leftCollaspe' : 'left'}>
                    <div className="device-tag-selector-header child">
                        <div className="deviceTagsHeading">
                            {t('dataVisualization.tagsKpis')}

                            <CollapseIcon
                                className={`${'expandIcon'}`}
                                onClick={() => {
                                    setShowExpandedGraph(!showExpandedGraph);
                                }}
                            />
                        </div>
                    </div>

                    <Select
                        className="child"
                        placeholder={t(
                            'dataVisualization.selectModelPlaceholder'
                        )}
                        style={{ width: '100%' }}
                        size="small"
                        options={modelListOptions()}
                        onSelect={modelSelectHandler}
                        value={selectedModel}
                    />
                    <TreeSelect
                        className="child"
                        placeholder={t(
                            'dataVisualization.selectNodePlaceholder'
                        )}
                        value={selectedDevice || undefined}
                        style={{ width: '100%' }}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        treeData={modifiedTree ? [modifiedTree] : []}
                        size="small"
                        onSelect={selectHandler}
                    />
                    <>
                        <Input
                            allowClear
                            suffix={<SearchOutlined />}
                            className="search-tag"
                            placeholder={t(
                                'dataVisualization.searchKpisPlaceholder'
                            )}
                            size="small"
                            value={searchKpi ?? EMPTY?.string}
                            onChange={(e) => {
                                setSearchKpi(e.target.value);
                                if (kpiSearchTimeout) {
                                    clearTimeout(kpiSearchTimeout);
                                }
                                setKpiSearchTimeout(
                                    setTimeout(() => {
                                        dispatch(
                                            getKpiData({
                                                assetId: selectedDevice,
                                                search: e.target.value,
                                                pageSize: pageSize,
                                                pageNumber: page,
                                                
                                            })
                                        );
                                    }, 1000)
                                );
                            }}
                        />
                        <div className="table">
                            <KpiTable
                                className="child"
                                data={kpiData}
                                selectedDevice={selectedDevice}
                                searchKpi={searchKpi}
                                setSortOrder={setkpiSortOrder}
                                setSortColumn={setKpiSortColumn}
                                setSelectedKpiRows={setSelectedKpiRows}
                                selectedKpiRows={selectedKpiRows}
                                maxRange={maxRange}
                            />

                        {kpiData?.totalRecords >
                                PAGE_SIZE && (
                                    <CustomPagination
                                    customClassName="dataTable__pagination"
                                    totalRecords={kpiData?.totalRecords}
                                    page={page}
                                    setPage={setPage}
                                    setPageSize={setPageSize}
                                    pageSize={pageSize}
                                    isPositionFixed={false}
                                />

                            )}
                        </div>
                    </>
                    <>
                        <Input
                            allowClear
                            suffix={<SearchOutlined />}
                            className="search-tag"
                            placeholder={t(
                                'dataVisualization.searchTagsPlaceholder'
                            )}
                            size="small"
                            value={search ?? EMPTY?.string}
                            onChange={(e) => {
                                setSearch(e.target.value);
                                if (tagSearchTimeout) {
                                    clearTimeout(tagSearchTimeout);
                                }
                                setTagSearchTimeout(
                                    setTimeout(() => {
                                        dispatch(
                                            getStreamTagList({
                                                sortOrder: sortOrder,
                                                sortColumn: sortColumn,
                                                parentNodeId: selectedDevice,
                                                search: e.target.value,
                                                pageSize: pageSize,
                                                pageNumber: page,
                                            })
                                        );
                                    }, 1000)
                                );
                            }}
                        />
                        <div className="table">
                            <TagTable
                                data={streamTagsList}
                                selectedDevice={selectedDevice}
                                setSelectedTagRows={setSelectedTagRows}
                                selectedTagRows={selectedTagRows}
                                search={search}
                                setSortOrder={setSortOrder}
                                setSortColumn={setSortColumn}
                                maxRange={maxRange}
                                trendFunctions={checkedValues}
                            />
                              <div>
                            {streamTagsList?.totalRecords >
                                PAGE_SIZE && (
                                    <CustomPagination
                                    customClassName="dataTable__pagination"
                                    totalRecords={streamTagsList?.totalRecords}
                                    page={page}
                                    setPage={setPage}
                                    setPageSize={setPageSize}
                                    pageSize={pageSize}
                                    isPositionFixed={false}
                                />

                            )}
                        </div>
                            
                        </div>
                    </>
                    {/* )} */}
                </div>
                <div className={showExpandedGraph ? 'rightFull' : 'right'}>
                    <div className="data-visualization-container__header">
                        <div className="data-visualization-container__header__title">
                            {showExpandedGraph && (
                                <CollapseIcon
                                    className={`${'expandIcon'}`}
                                    onClick={() => {
                                        setShowExpandedGraph(false);
                                    }}
                                />
                            )}
                            <Radio.Group
                                value={count}
                                onChange={(e: any) => {
                                    setCount(e.target.value);
                                }}
                            >
                                <Radio.Button value={1}>
                                    {t('dataVisualization.trends')}
                                </Radio.Button>
                                <Radio.Button value={2}>
                                    {t('dataVisualization.table')}
                                </Radio.Button>
                            </Radio.Group>
                        </div>
                        <div className="expandExportOption ">
                            <div className="merge">
                                <label>Merge</label>
                                <Switch
                                    checked={showMerge}
                                    onChange={(checked) => {
                                        setshowMerge(checked);
                                    }}
                                />
                            </div>
                            <div
                                className="sampling functions"
                                onClick={
                                    showMerge ? undefined : handleModalOpen
                                }
                                style={{
                                    cursor: showMerge
                                        ? 'not-allowed'
                                        : 'pointer',
                                    opacity: showMerge ? 0.5 : 1,
                                }}
                            >
                                <Settings onClick={handleModalOpen} />
                                {/* <Popover
                                    overlayClassName="functionPopover"
                                    content={content}
                                    title={null}
                                    trigger="click"
                                    placement="bottomLeft"
                                >
                                    <Button
                                        icon={<Functions />}
                                        disabled={
                                            !selectedTagRows?.length &&
                                            !selectedKpiRows?.length
                                        }
                                    ></Button>
                                </Popover> */}
                            </div>
                            <div className="sampling">
                                <div className="icon-Inside">
                                    <Sampling />
                                </div>
                                <Select
                                    disabled={
                                        !selectedTagRows?.length &&
                                        !selectedKpiRows?.length
                                    }
                                    className="expandFrequency"
                                    dropdownClassName="custom-dropdown"
                                    allowClear
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label as string)
                                            .toLowerCase()
                                            ?.includes(input.toLowerCase())
                                    }
                                    options={frequencyList?.map(
                                        (item: {
                                            id: string;
                                            samplingTime: string;
                                        }) => ({
                                            value: item?.id,
                                            label: item?.samplingTime,
                                            key: item?.id,
                                        })
                                    )}
                                    value={frequencyState}
                                    onChange={(e) => {
                                        if (e === undefined || e === null) {
                                            setFrequencyState(0);
                                        } else {
                                            setFrequencyState(e);
                                        }
                                    }}
                                    onClear={() => {
                                        setFrequencyState(0);
                                    }}
                                />
                            </div>

                            <div className="sampling charts">
                                <div className="icon-Inside">
                                    <Charts />
                                </div>
                                <Select
                                    dropdownClassName="custom-dropdown"
                                    className="expandFrequency"
                                    onChange={handleChartChange}
                                    value={chartType}
                                    disabled={
                                        !selectedTagRows?.length &&
                                        !selectedKpiRows?.length
                                    }
                                >
                                    <Option value={ChartTypes.LINE}>
                                        <LineChartIcon /> Line Chart
                                    </Option>
                                    <Option value={ChartTypes.BAR}>
                                        <BarChart /> Bar Chart
                                    </Option>
                                    <Option value={ChartTypes.AREA}>
                                        <AreaChart /> Area Chart
                                    </Option>
                                    <Option value={ChartTypes.SCATTER}>
                                        <Scatter /> Scatter Plot
                                    </Option>
                                    <Option value={ChartTypes.HISTOGRAM}>
                                        <Histrogram /> Histogram
                                    </Option>
                                    <Option value={ChartTypes.BOXPLOT}>
                                        <BoxPlot /> Boxplot
                                    </Option>
                                </Select>
                            </div>

                            <Dropdown
                                overlay={menu}
                                disabled={
                                    !selectedTagRows?.length &&
                                    !selectedKpiRows?.length
                                }
                                trigger={['click']}
                            >
                                <Button className="export-fn">
                                    <DownloadOutlined className="export-name" />
                                    {/* <span className="export-name">
                                        {t('dataVisualization.export')}
                                    </span> */}
                                </Button>
                            </Dropdown>
                            {/* {showExpandedGraph ? (
                                <div
                                    onClick={() => {
                                        setShowExpandedGraph(false);
                                    }}
                                    className="closeIcon"
                                >
                                    <CloseIcon />
                                </div>
                            ) : (
                                <Expand
                                    className={`${
                                        selectedTagRows?.length ||
                                        selectedKpiRows?.length
                                            ? 'expandIcon'
                                            : 'disableExpandBtn'
                                    }`}
                                    onClick={() => {
                                        (selectedTagRows?.length ||
                                            selectedKpiRows?.length) &&
                                            setShowExpandedGraph(true);
                                    }}
                                />
                            )} */}
                            <Marker onClick={handleMarkerClick} />
                        </div>
                    </div>

                    {count === 1 ? (
                        <div>
                            <div className="data-visualization-container__multiAxisChart">
                                {selectedModel && selectedDevice ? (
                                    <>{renderChart()}</>
                                ) : (
                                    ''
                                )}
                            </div>

                            <Table
                                columns={columns}
                                dataSource={
                                    info ||
                                    updatedSelectedTagRows ||
                                    updatedKpiData ||
                                    transformedKpiRows
                                }
                                pagination={false}
                                size="small"
                                scroll={{ y: 'calc(100vh - 700px)' }}
                            />
                        </div>
                    ) : (
                        <DataTable
                            dataSource={dataTableList}
                            selectedTagRows={selectedTagRows}
                            selectedKpiRows={selectedKpiRows}
                            page={page}
                            setPage={handlePage}
                            setPageSize={handlePageSize}
                            pageSize={pageSize}
                            loading={loading}
                            startTime={new Date(
                                dateRange?.startTime
                            )?.getTime()}
                            endTime={new Date(dateRange?.endTime)?.getTime()}
                            tagUuidList={tagUUID ?? kpiID}
                            tagOriginId={tagOriginId?.PLC}
                            timezone={timezone}
                            frequencyState={frequencyState}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default DataVisualization;
