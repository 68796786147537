export const GET_FORM_LIST = 'GET_FORM_LIST';
export const GET_FORM_LIST_SUCCESS = 'GET_FORM_LIST_SUCCESS';
export const GET_FORM_LIST_FAILURE = 'GET_FORM_LIST_FAILURE';
export const GET_FORM_DETAILS = 'GET_FORM_DETAILS';
export const GET_FORM_DETAILS_SUCCESS = 'GET_FORM_DETAILS_SUCCESS';
export const GET_FORM_DETAILS_FAILURE = 'GET_FORM_DETAILS_FAILURE';
export const SET_FORM_DATA = 'SET_FORM_DATA';
export const SET_FORM_DATA_SUCCESS = 'SET_FORM_DATA_SUCCESS';
export const SET_FORM_DATA_FAILURE = 'SET_FORM_DATA_FAILURE';
export const SET_FORM_DATA_TABLE = 'SET_FORM_DATA_TABLE';
export const SET_FORM_DATA_TABLE_SUCCESS = 'SET_FORM_DATA_TABLE_SUCCESS';
export const SET_FORM_DATA_TABLE_FAILURE = 'SET_FORM_DATA_TABLE_FAILURE';
export const UPDATE_FORM_DATA_TABLE = 'UPDATE_FORM_DATA_TABLE';
export const UPDATE_FORM_DATA_TABLE_SUCCESS = 'UPDATE_FORM_DATA_TABLE_SUCCESS';
export const UPDATE_FORM_DATA_TABLE_FAILURE = 'UPDATE_FORM_DATA_TABLE_FAILURE';
export const GET_ACTION_LIST = 'GET_ACTION_LIST';
export const GET_ACTION_LIST_SUCCESS = 'GET_ACTION_LIST_SUCCESS';
export const GET_ACTION_LIST_FAILURE = 'GET_ACTION_LIST_FAILURE';
