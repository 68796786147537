import { Col, Input, Row, Select, Spin, TreeSelect } from 'antd';
import './index.scss';
import { SearchOutlined } from '@ant-design/icons';
import CustomButton from 'components/common/CustomButton';
import AlertTable from './AlertTable';
import EmptyDataComponent from 'components/common/EmptyDataComponent';
import CustomPagination from 'components/common/CustomPagination';
import { getModelList } from 'redux/actions/DataExplorer/DataVisualizationActions';
import { useEffect, useState } from 'react';
import { PAGE, PAGE_SIZE } from 'utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getTreeByModelId } from 'redux/actions/AssetModellingActions/assetModellingAction';
import {
    getAlertList,
    setAlertState,
} from 'redux/actions/AlertActions/alertActions';
import { ALERTTYPE } from 'types/enums';
import { useTranslation } from 'react-i18next';
import { mapTree } from 'utils/commonFunction';

const AlertTableComponnet = (): any => {
    const [page, setPage] = useState<number>(PAGE);
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const [sortColumn, setSortColumn] = useState<number | null>(null);
    const [sortOrder, setSortOrder] = useState<number | null>(null);
    const [selectedModel, setSelectedModel] = useState(null);
    const dispatch = useDispatch();
    const { t } = useTranslation('translation');
    const [alertSearchTimeout, setAlertSearchTimeout] =
        useState<any>(undefined);
    const [searchState, setSearchState] = useState('');
    const { SHOW_PARENT } = TreeSelect;
    const [selectedAsset, setSelectedAsset] = useState<any>(null);
    const [filteredTreeData, setFilteredTreeData] = useState<any>([]);

    const getAllChildrenKeys = (node: any): string[] => {
        let keys: string[] = [node.value];
        if (node.children) {
            node.children.forEach((child: any) => {
                keys = keys.concat(getAllChildrenKeys(child));
            });
        }
        return keys;
    };

    const onChange = (newValue: string[]): void => {
        let allSelectedKeys: string[] = [];
        const processNode = (node: any): any => {
            if (newValue.includes(node.value)) {
                allSelectedKeys = allSelectedKeys.concat(
                    getAllChildrenKeys(node)
                );
            }
            if (node.children) {
                node.children.forEach(processNode);
            }
        };

        filteredTreeData.forEach(processNode);

        // Remove duplicates without using Set
        setSelectedAsset(
            allSelectedKeys.filter(
                (value, index, self) => self.indexOf(value) === index
            )
        );
    };

    useEffect(() => {
        selectedModel && dispatch(getTreeByModelId(selectedModel));
    }, [selectedModel]);

    const treeObject = useSelector(
        (state: any) => state?.assetModelling?.modelOverview?.getTreeByModelId
    );

    const filterTreeNode = (input: any, treeNode: any): any => {
        const title = treeNode.title.toLowerCase();
        return title.includes(input.toLowerCase());
    };

    const handlePage = (page: any): any => {
        setPage(page);
    };

    const handlePageSize = (current: any): any => {
        setPageSize(current);
    };

    const alertData = useSelector((state: any) => state.alert?.alertList);

    const alertListLoading = useSelector(
        (state: any) => state.alert?.alertListLosding
    );

    const modelList: any = useSelector(
        (state: any) => state.dataVisualization.modelList
    );

    // Helper function to flatten the tree
    const flattenTree = (tree: any): any => {
        const flatArray: any[] = [];
        const traverse = (node: any): any => {
            flatArray.push(node);
            if (node?.children) {
                node?.children?.forEach(traverse);
            }
        };
        traverse(tree);
        return flatArray;
    };

    // Helper function to reconstruct the tree from filtered flat array
    const constructTree = (flatArray: any[], tree: any): any => {
        const traverse = (node: any): any => {
            const filteredChildren = node?.children
                ?.map((child: any) => traverse(child))
                ?.filter((child: any) => child);
            if (
                filteredChildren?.length ||
                flatArray?.some((item) => item?.key === node?.key)
            ) {
                return { ...node, children: filteredChildren };
            }
            return null;
        };
        return traverse(tree);
    };

    const modelListOptions = (): any => {
        const result: any = [];
        modelList?.map((modelObject: any) => {
            result.push({
                label: modelObject?.name,
                value: modelObject?.id,
            });
        });
        return result;
    };

    const modelSelectHandler = (value: any): any => {
        setSelectedModel(value);
    };

    useEffect(() => {
        if (treeObject) {
            const flatTree = flattenTree(mapTree(treeObject));

            // Collect keys of all parent nodes leading to matched nodes (if needed)
            const expandKeys = new Set<React.Key>();
            const collectParentKeys = (
                node: any,
                parentKey: React.Key | null = null
            ): any => {
                let currentNode = node;
                while (parentKey) {
                    expandKeys.add(parentKey);
                    currentNode = flatTree?.find(
                        (item: any) => item?.key === parentKey
                    );
                    parentKey = currentNode?.parentKey || null;
                }
                node?.children?.forEach((child: any) =>
                    collectParentKeys(child, node?.key)
                );
            };

            // Optionally, you can collect all parent keys or perform some other action
            collectParentKeys(mapTree(treeObject));

            const newTree = constructTree(flatTree, mapTree(treeObject));
            setFilteredTreeData(newTree ? [newTree] : []);
        } else {
            setFilteredTreeData([mapTree(treeObject)]);
        }
    }, [treeObject]);

    useEffect(() => {
        dispatch(
            getAlertList({
                page,
                pageSize,
                search: searchState,
                sortColumn,
                sortOrder,
                ...(selectedAsset ? { assetId: selectedAsset } : {}),
                ...(selectedModel ? { modelId: selectedModel } : {}),
            })
        );
        dispatch(getModelList({ page: 1, pageSize: 0 })); // NOTE- we have used 0,1 here to display the list of all models on 1 page
    }, [page, pageSize, selectedAsset, selectedModel]);

    const handleSearchChange = (e: any): any => {
        setSearchState(e.target.value);
        if (alertSearchTimeout) {
            clearTimeout(alertSearchTimeout);
        }
        // Debouncing for search implemented
        setAlertSearchTimeout(
            setTimeout(() => {
                dispatch(
                    getAlertList({
                        page: PAGE,
                        pageSize: PAGE_SIZE,
                        search: e.target.value,
                        ...(selectedAsset ? { assetId: selectedAsset } : {}),
                        ...(selectedModel ? { modelId: selectedModel } : {}),
                    })
                );
            }, 1000)
        );
        setPage(PAGE);
        setPageSize(PAGE_SIZE);
    };

    return (
        <>
            {alertData?.totalActiveAlert + alertData?.totalInActiveAlert > 0 ? (
                <>
                    <div className="alertWrapper__rowHeader">
                        <div className="alertWrapper__rowHeaderDropdowns">
                            <Input
                                allowClear
                                className="alertWrapper__search"
                                placeholder={t('alerts.searchAlert')}
                                prefix={<SearchOutlined />}
                                value={searchState}
                                onChange={handleSearchChange}
                            />
                            <Select
                                placeholder={t('alerts.selectModel')}
                                className="alertWrapper__select"
                                options={modelListOptions()}
                                onSelect={modelSelectHandler}
                                showSearch
                                value={selectedModel}
                                allowClear
                                onClear={() => {
                                    setSelectedModel(null);
                                }}
                                filterOption={(input: any, option: any) =>
                                    option.label
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            />
                            <TreeSelect
                                className="alertWrapper__select"
                                disabled={!selectedModel}
                                treeData={filteredTreeData} // Add this line to pass the filteredTreeData to the TreeSelect
                                value={selectedAsset}
                                onChange={onChange}
                                treeCheckable={true}
                                showCheckedStrategy={SHOW_PARENT}
                                placeholder={t('alerts.selectAsset')}
                                style={{ width: '100%' }}
                                filterTreeNode={filterTreeNode}
                                allowClear
                            />
                        </div>
                        <div className="alertWrapper__button">
                            <CustomButton
                                type={'Create Alerts'}
                                disabled={false}
                                handleClick={() => {
                                    dispatch(setAlertState(ALERTTYPE.create));
                                }}
                            />
                        </div>
                    </div>
                    {alertListLoading ? (
                        <div className="view__loader">
                            <Spin />
                        </div>
                    ) : alertData?.pageResponse?.totalRecords > 0 ? (
                        <Row
                            className={
                                alertData?.pageResponse?.totalRecords > 50
                                    ? 'attributeWrapper__attributeListPagination'
                                    : 'attributeWrapper__attributeList'
                            }
                        >
                            <Col span={24}>
                                <AlertTable
                                    data={alertData?.pageResponse?.records}
                                    payload={{
                                        page,
                                        pageSize,
                                        ...(selectedAsset
                                            ? { assetId: selectedAsset }
                                            : {}),
                                        ...(selectedModel
                                            ? { modelId: selectedModel }
                                            : {}),
                                    }}
                                    search={searchState}
                                    setSortColumn={setSortColumn}
                                    setSortOrder={setSortOrder}
                                    setPage={setPage}
                                />
                            </Col>
                        </Row>
                    ) : (
                        <div className="alertWrapper__noData">
                            <EmptyDataComponent
                                textValue={t('alerts.emptySearchText')}
                                loading={alertListLoading}
                            />
                        </div>
                    )}
                </>
            ) : (
                <div className="alertWrapper__noData">
                    <EmptyDataComponent
                        textValue={t('alerts.noAlertCreated')}
                        buttonType={{
                            name: t('alerts.createAlert'),
                            disable: false,
                        }}
                        loading={alertListLoading}
                        buttonClickHandler={() => {
                            dispatch(setAlertState(ALERTTYPE.create));
                        }}
                    />
                </div>
            )}

            {alertData?.pageResponse?.totalRecords > 50 && (
                <CustomPagination
                    totalRecords={alertData?.pageResponse?.totalRecords}
                    setPage={handlePage}
                    page={page}
                    setPageSize={handlePageSize}
                    pageSize={pageSize}
                />
            )}
        </>
    );
};
export default AlertTableComponnet;
