import { Card, Col, Radio, Row, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import TableDetails from '../TableDetails';
import { ReactComponent as BackIcon } from 'assets/icons/backIcon.svg';
import './index.scss';
import { setTableState } from 'redux/actions/ConfigureActions/tableAction';
import { TABLETYPE } from 'types/enums';
import TableData from '../TableData';
import EditTable from '../EditTable';
const ViewTable: React.FC<any> = () => {
    const dispatch = useDispatch();
    const [count, setCount] = useState(1);

    const tableDetailLoading = useSelector(
        (state: any) => state.configure?.table?.tableDetailLoading
    );

    const tableDetails = useSelector(
        (state: any) => state.configure?.table?.tableDetails
    );
    const tableState = useSelector(
        (state: any) => state.configure?.table?.tableState
    );
    return (
        <>
            {tableDetailLoading ? (
                <div className="view__loader">
                    <Spin />
                </div>
            ) : (
                tableDetails?.name && (
                    <>
                        <div className="viewTableWrapper">
                            <Card bordered={false} bodyStyle={{ padding: 0 }}>
                                <Row className="viewTableWrapper__headerWrapper">
                                    <Col span={24}>
                                        <Row>
                                            <Col
                                                span={0.5}
                                                className="viewTableWrapper__backIcon"
                                            >
                                                <BackIcon
                                                    onClick={() =>
                                                        dispatch(
                                                            setTableState(
                                                                TABLETYPE.display
                                                            )
                                                        )
                                                    }
                                                />
                                            </Col>
                                            <Col
                                                span={22}
                                                className="viewTableWrapper__headingName fw-500 fs-20"
                                            >
                                                {tableState === TABLETYPE?.view
                                                    ? 'View Table'
                                                    : 'Edit Table'}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="viewTableWrapper__tabContainer">
                                    <Radio.Group
                                        value={count}
                                        onChange={(e: any) => {
                                            setCount(e.target.value);
                                        }}
                                    >
                                        <Radio.Button value={1}>
                                            Table Details
                                        </Radio.Button>
                                        <Radio.Button value={2}>
                                            Table Data
                                        </Radio.Button>
                                    </Radio.Group>
                                </Row>

                                <div className="viewTableWrapper__scrollContent">
                                    {count === 1 ? (
                                        tableState === TABLETYPE?.view ? (
                                            <TableDetails />
                                        ) : (
                                            <EditTable />
                                        )
                                    ) : (
                                        <div className="viewTableWrapper__scrollContent__content-rightPreview">
                                            <div className="rightPreviewContent">
                                                <TableData />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Card>
                        </div>
                    </>
                )
            )}
        </>
    );
};
export default ViewTable;
