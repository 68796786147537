import { ReactComponent as EditIcon } from 'assets/icons/icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { deleteTableData } from 'redux/actions/ConfigureActions/tableAction';
import { ReactComponent as DeleteIcon } from 'assets/icons/DeleteIconTable.svg';

const MoreContentTable: React.FC<any> = ({
    record,
    setRowId,
    setDefaultValues,
    setPopoverVisible
}) => {
    const tableDetails = useSelector(
        (state: any) => state.configure?.table?.tableDetails
    );

    const dispatch = useDispatch();
    const values = [
        {
            title: 'Edit',
            icon: <EditIcon />,
        },
        {
            title: 'Delete',
            icon: <DeleteIcon width={14} height={14} />,
            disable: false,
        },
    ];

    return (
        <>
            <div className="more-container">
                <div className={`more-content`}>
                    <ul>
                        {values &&
                            values.length > 0 &&
                            values.map((item) => (
                                <li
                                    key={item.title}
                                    className="moreContent__items"
                                >
                                    <span
                                        className="moreContent__option"
                                        onClick={() => {
                                            if (item.title === 'Edit') {
                                                setPopoverVisible(false);
                                                setDefaultValues(record);
                                                setRowId(record?.rowId);
                                            } else if (
                                                item.title === 'Delete'
                                            ) {
                                                setPopoverVisible(false);
                                                dispatch(
                                                    deleteTableData({
                                                        tableName:
                                                            tableDetails?.name,
                                                        rowModifyRequests: [
                                                            {
                                                                rowId: record?.rowId,
                                                                columnDataModifyRequests:
                                                                    [],
                                                            },
                                                        ],
                                                    })
                                                );
                                            }
                                        }}
                                    >
                                        <span className="moreContentIcon">
                                            {item.icon}
                                        </span>
                                        {item.title}
                                    </span>
                                </li>
                            ))}
                    </ul>
                </div>
            </div>
        </>
    );
};

export default MoreContentTable;
