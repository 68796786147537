import { GET_TRENDS_FUNCTION } from 'redux/types/dataVisulaizationTypes';
import {
    CLEAR_DATA_VISUALIZATION_VALUES,
    CONTROL_LIMITS,
    GET_AGGREGATED_CHART_DATA,
    GET_AGGREGATED_SCATTER_CHART_DATA,
    GET_AGGREGRATED_EXPORT_CSV_DATA,
    GET_ALERT,
    GET_ASSET_CSV_DATA,
    GET_DATA_TABLE_LIST,
    GET_FREQUENCY,
    GET_KPI_INFO,
    GET_KPI_TRENDS,
    GET_MODEL_LIST,
    GET_NODE_LIST,
    GET_STREAM_TAG_LIST,
    GET_TIME_CAPSULE_LIST,
    SET_CHARTS,
} from '../../actionTypes';

export const getNodeList = (payload: any): any => ({
    type: GET_NODE_LIST,
    payload,
});

export const getStreamTagList = (payload: any): any => ({
    type: GET_STREAM_TAG_LIST,
    payload,
});

export const getAggregatedChartData = (payload: any): any => ({
    type: GET_AGGREGATED_CHART_DATA,
    payload,
});

export const getModelList = (payload?: any): any => ({
    type: GET_MODEL_LIST,
    payload,
});

export const clearDataVisualizationValues = (): any => ({
    type: CLEAR_DATA_VISUALIZATION_VALUES,
});
export const getAssetCSVData = (payload: any): any => ({
    type: GET_ASSET_CSV_DATA,
    payload,
});

export const getExportAggregrateCsvData = (payload: any): any => ({
    type: GET_AGGREGRATED_EXPORT_CSV_DATA,
    payload,
});
export const getDataTableList = (payload: any): any => ({
    type: GET_DATA_TABLE_LIST,
    payload,
});
export const clearDataTableList = (): any => ({
    type: 'CLEAR_DATA_TABLE_LIST',
});

export const getKpiData = (payload: any): any => ({
    type: GET_KPI_INFO,
    payload: payload,
});
export const getKpiTrends = (payload: any): any => ({
    type: GET_KPI_TRENDS,
    payload: payload,
});

export const getFrequency = (payload?: any): any => ({
    type: GET_FREQUENCY,
    payload: payload,
});

export const getTrendsFunction = (payload?: any): any => ({
    type: GET_TRENDS_FUNCTION,
    payload: payload,
});

export const getCountLimit = (payload?: any): any => ({
    type: CONTROL_LIMITS,
    payload: payload,
});

export const getChartsType = (payload?: any): any => ({
    type: SET_CHARTS,
    payload: payload,
});

export const getAlertList = (payload?: any): any => ({
    type: GET_ALERT,
    payload: payload,
});

export const getTimeCapsuleList = (payload?: any): any => ({
    type: GET_TIME_CAPSULE_LIST,
    payload: payload,
});


export const getScatterAggregrateData = (payload?: any): any => ({
    type: GET_AGGREGATED_SCATTER_CHART_DATA,
    payload: payload,
});
