import './index.scss';
import React, { useState } from 'react';
import { Button, Card, Divider, Input, Layout, Popover } from 'antd';
import { MoreOutlined, DeleteOutlined } from '@ant-design/icons';
import { ReactComponent as CollapseIcon } from 'assets/icons/aiqCollapseIcon.svg';
import { ReactComponent as ExpandIcon } from 'assets/icons/aiqExpandIcon.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/aiqSearchIcon.svg';
import { ReactComponent as LineChartIcon } from 'assets/icons/lineChartIcon.svg';
import { ReactComponent as ImportIcon } from 'assets/icons/aiqImportIcon.svg';
import { ReactComponent as SendIcon } from 'assets/icons/aiqSendicon.svg';
import { ReactComponent as SortIcon } from 'assets/icons/aiqSortAscending.svg';
import { ReactComponent as BookmarkIcon } from 'assets/icons/aiqBookmarkIcon.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/aiqDownload.svg';
import { ReactComponent as EditIcon } from 'assets/icons/icon.svg';
import { ReactComponent as SaveIcon } from 'assets/icons/saveIcon.svg';

import AiqLogo from 'assets/icons/aiqLogo.svg';
import { useTranslation } from 'react-i18next';

const BraboAIQ: React.FC<any> = () => {
    const { t } = useTranslation('translation');
    const { Sider, Content } = Layout;
    const [collapsed, setCollapsed] = useState(false);
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [activePopover, setActivePopover] = useState<string | null>(null);
    const [searchValue, setSearchValue] = useState('');
    const [selectedConversationKey, setSelectedConversationKey] =
        useState<any>();

    const options = [
        {
            id: 1,
            title: 'Download as PDF',
            icon: <DownloadIcon />,
        },
        {
            id: 2,
            title: 'Rename',
            icon: <EditIcon />,
        },
        {
            id: 3,
            title: 'Save',
            icon: <SaveIcon />,
        },
        {
            id: 4,
            title: 'Delete',
            icon: <DeleteOutlined />,
        },
    ];

    // TODO: Function to handle search action
    const handleSearch = (): any => {};

    // TODO: Popover content
    const importPopoverContent = (
        <>
            <div>
                {options?.map((item: any, index: any) => {
                    return (
                        <div key={item.id} className="cursor-pointer">
                            {item.title}
                        </div>
                    );
                })}
            </div>
        </>
    );
    // TODO: conversation Popover content
    const conversationMorePopoverContent = (
        <>
            {options?.map((item: any, index: any) => {
                return (
                    <div
                        key={item.id}
                        className="display-start gap pb-10 cursor-pointer"
                    >
                        {item.icon}
                        {item.title}
                    </div>
                );
            })}
        </>
    );
    // TODO: will remove after api integration
    const conversation = [
        {
            date: 'Today',
            conversations: [
                {
                    id: 'conversation_5',
                    title: 'CreateTable Component in React',
                    timestamp: '2024-09-24T08:21:45Z',
                    summary:
                        'Working on dynamic table with add/edit/delete features',
                },
                {
                    id: 'conversation_6',
                    title: 'CreateTable Component in React',
                    timestamp: '2024-09-24T08:21:45Z',
                    summary:
                        'Working on dynamic table with add/edit/delete features',
                },
            ],
        },
        {
            date: 'Yesterday',
            conversations: [
                {
                    id: 'conversation_1',
                    title: 'CreateTable Component in React',
                    timestamp: '2024-09-24T08:21:45Z',
                    summary:
                        'Working on dynamic table with add/edit/delete features',
                },
                {
                    id: 'conversation_2',
                    title: 'CreateTable Component in React',
                    timestamp: '2024-09-24T08:21:45Z',
                    summary:
                        'Working on dynamic table with add/edit/delete features',
                },
            ],
        },
        {
            date: 'Yesterday',
            conversations: [
                {
                    id: 'conversation_1',
                    title: 'CreateTable Component in React',
                    timestamp: '2024-09-24T08:21:45Z',
                    summary:
                        'Working on dynamic table with add/edit/delete features',
                },
                {
                    id: 'conversation_2',
                    title: 'CreateTable Component in React',
                    timestamp: '2024-09-24T08:21:45Z',
                    summary:
                        'Working on dynamic table with add/edit/delete features',
                },
            ],
        },
        {
            date: 'Yesterday',
            conversations: [
                {
                    id: 'conversation_1',
                    title: 'CreateTable Component in React',
                    timestamp: '2024-09-24T08:21:45Z',
                    summary:
                        'Working on dynamic table with add/edit/delete features',
                },
                {
                    id: 'conversation_2',
                    title: 'CreateTable Component in React',
                    timestamp: '2024-09-24T08:21:45Z',
                    summary:
                        'Working on dynamic table with add/edit/delete features',
                },
            ],
        },
    ];
    // Note: this function is for conversation popover visibility
    const handlePopoverVisibility = (id: string, visible: boolean): any => {
        setActivePopover(visible ? id : null);
    };

    return (
        <>
            <Card bordered={false} className="braboAiq">
                <Layout className="braboAiq__sider">
                    <Sider
                        trigger={null}
                        collapsible
                        collapsed={collapsed}
                        theme="light"
                        className={
                            collapsed ? 'collapsed braboaiq' : 'expand brabiaiq'
                        }
                    >
                        <div className="sider__title">
                            <div className="fs-16 fw-600">
                                {t('braboAIQ.convesationHistory')}
                            </div>
                            <div className="sider__settings cursor-pointer">
                                <SearchIcon />
                                <SortIcon />
                                <BookmarkIcon />
                            </div>
                        </div>
                        <Divider />
                        <div className="sider__conversation">
                            {conversation?.map((item: any, index: any) => {
                                return (
                                    <div
                                        className="sider__conversation__content"
                                        key={index}
                                    >
                                        <span className="content__time fs-12 fw-400">
                                            {item.date}
                                        </span>
                                        {item.conversations.map(
                                            (conversation: any, index: any) => {
                                                return (
                                                    <div
                                                        className={`content ${
                                                            selectedConversationKey ===
                                                            conversation.id
                                                                ? 'selected'
                                                                : ''
                                                        }`}
                                                        key={conversation.id}
                                                        onClick={() => {
                                                            setSelectedConversationKey(
                                                                conversation.id
                                                            );
                                                        }}
                                                    >
                                                        <p>
                                                            {conversation.title}
                                                        </p>
                                                        <Popover
                                                            content={
                                                                conversationMorePopoverContent
                                                            }
                                                            trigger="click"
                                                            placement="bottomRight"
                                                            visible={
                                                                activePopover ===
                                                                conversation.id
                                                            }
                                                            onVisibleChange={(
                                                                visible
                                                            ) =>
                                                                handlePopoverVisibility(
                                                                    conversation.id,
                                                                    visible
                                                                )
                                                            }
                                                        >
                                                            <MoreOutlined className="cursor-pointer" />
                                                        </Popover>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </Sider>
                    <Layout className="braboAiq__contentlayout">
                        <Content>
                            <div className="braboAiq__content">
                                <div className="braboAiq__background">
                                    <Button
                                        className="braboAiq__collapsedExpandIocn"
                                        type="text"
                                        icon={
                                            collapsed ? (
                                                <ExpandIcon />
                                            ) : (
                                                <CollapseIcon />
                                            )
                                        }
                                        onClick={() => {
                                            setCollapsed(!collapsed);
                                        }}
                                    />
                                    <div className="braboAiq__data">
                                        <img src={AiqLogo} alt="aiq-icon" />
                                        <div className="braboAiq__text text-center">
                                            <h3 className="fw-700 fs-32">
                                                {t('braboAIQ.title')}
                                            </h3>
                                            <p className="fw-400 fs-16 description">
                                                {t('braboAIQ.description')}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="cardContainer">
                                        <Card
                                            className="barboAiq__card cursor-pointer"
                                            bordered={true}
                                        >
                                            <div className="content__item">
                                                <LineChartIcon />
                                                <p className="fs-12 fw-500">
                                                    {t('braboAIQ.linechart')}
                                                </p>
                                            </div>
                                        </Card>
                                        <Card
                                            className="barboAiq__card cursor-pointer"
                                            bordered={true}
                                        >
                                            <div className="content__item">
                                                <LineChartIcon />
                                                <p className="fs-12 fw-500">
                                                    {t('braboAIQ.scatterplot')}
                                                </p>
                                            </div>
                                        </Card>
                                        <Card
                                            className="barboAiq__card cursor-pointer"
                                            bordered={true}
                                        >
                                            <div className="content__item">
                                                <LineChartIcon />
                                                <p className="fs-12 fw-500">
                                                    {t(
                                                        'braboAIQ.ManufacturingIndustry'
                                                    )}
                                                </p>
                                            </div>
                                        </Card>
                                    </div>
                                    <div className="search">
                                        <Input
                                            placeholder="Type Something"
                                            value={searchValue}
                                            onChange={(e) => {
                                                setSearchValue(e.target.value);
                                            }}
                                            prefix={
                                                <Popover
                                                    content={
                                                        importPopoverContent
                                                    }
                                                    trigger="click"
                                                    visible={popoverVisible}
                                                    onVisibleChange={(
                                                        visible
                                                    ) => {
                                                        setPopoverVisible(
                                                            visible
                                                        );
                                                    }}
                                                >
                                                    <ImportIcon className="cursor-pointer" />
                                                </Popover>
                                            }
                                            suffix={
                                                <SendIcon
                                                    className="cursor-pointer"
                                                    onClick={handleSearch}
                                                />
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </Card>
        </>
    );
};

export default BraboAIQ;
