import React, { useContext, useEffect, useState } from 'react';
import { Radio, Spin } from 'antd';
import { ThemeContext } from 'components/Context/ThemeContext';
import { ReactComponent as LightTheme } from 'assets/icons/White.svg';
import { ReactComponent as DarkTheme } from 'assets/icons/Dark.svg';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
    editUser,
    getLoggedInUserDetails,
} from 'redux/actions/UserManagementActions/usersAction';
import { ThemeID } from 'types/enums';
import { useTranslation } from 'react-i18next';

const AccountPreference = (): any => {
    const { t } = useTranslation('translation');

    const { theme, setTheme } = useContext(ThemeContext);
    const loggedInUserDetails: any = useSelector(
        (state: any) => state.userManagement.users.loggedInUserDetails
    );
    const isUserEdited: any = useSelector(
        (state: any) => state.userManagement.users.isUserEdited
    );
    const themeLoader: any = useSelector(
        (state: any) => state.userManagement.users.themeLoader
    );
    const [userDetails, setUserDetails] = useState(loggedInUserDetails);
    const dispatch = useDispatch();

    useEffect(() => {
        if ('userId' in loggedInUserDetails) {
            setUserDetails({
                firstName: loggedInUserDetails.firstName,
                lastName: loggedInUserDetails.lastName,
                workEmailID: loggedInUserDetails.email,
                mobileNumber: loggedInUserDetails.mobileNo,
                assignmentValues: [],
                dashboardBuilderRole: loggedInUserDetails.dashboardBuilderRole,
                userId: loggedInUserDetails.userId,
                role: loggedInUserDetails?.roles[0]?.roleId,
            });
        }
    }, [loggedInUserDetails]);

    const onChange = (e: any): any => {
        const newTheme = e.target.value;
        setTheme(newTheme);
        const themePayload = {
            ...userDetails,
            theme: { themeId: newTheme === 'light' ? 1 : 2 },
        };
        dispatch(editUser(themePayload));
    };

    useEffect(() => {
        if (isUserEdited) {
            dispatch(getLoggedInUserDetails(loggedInUserDetails?.userId));
            const updatedTheme =
                loggedInUserDetails?.theme?.themeId === 1 ? 'light' : 'dark';
            localStorage.setItem('default-theme', updatedTheme);
        }
    }, [isUserEdited]);

    useEffect(() => {
        const storedTheme = localStorage.getItem('default-theme');
        if (storedTheme) {
            setTheme(storedTheme);
        } else if (loggedInUserDetails?.theme?.themeId) {
            setTheme(
                loggedInUserDetails.theme.themeId === ThemeID.LIGHT
                    ? 'light'
                    : 'dark'
            );
        }
    }, [loggedInUserDetails, setTheme]);

    return (
        <div className="accountPreference">
            {themeLoader ? (
                <div className="">
                    <Spin />
                </div>
            ) : (
                <Radio.Group onChange={onChange} value={theme}>
                    <Radio value={'light'}>
                        <div className="accountPreference__light">
                            <LightTheme />
                            {theme === 'light' && (
                                <div className="blue-overlay"></div>
                            )}
                            <span>
                                {t('accountSettings.myPreference.light')}
                            </span>
                        </div>
                    </Radio>
                    <Radio value={'dark'}>
                        <div className="accountPreference__dark">
                            <DarkTheme />
                            {theme === 'dark' && (
                                <div className="blue-overlay"></div>
                            )}
                            <span>
                                {t('accountSettings.myPreference.dark')}
                            </span>
                        </div>
                    </Radio>
                </Radio.Group>
            )}
        </div>
    );
};

export default AccountPreference;
