import React, { useEffect, useState } from 'react';
import { Avatar, Card, Empty, Popover, Table } from 'antd';
import './index.scss';
import EmptyDataComponent from 'components/common/EmptyDataComponent';
import moment from 'moment';
import { DATE_FORMAT, PAGE } from 'utils/constants';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    auditSortOption,
    AuditTrailsTable,
    EMPTY,
    sortOrder,
} from 'types/enums';
import { ReactComponent as UpArrow } from 'assets/icons/upArrowIcon.svg';
import { ReactComponent as DownArrow } from 'assets/icons/downArrowIcon.svg';
import { getAuditTrailsList } from 'redux/actions/AuditTrailsActions/auditTrailsAction';
import { getIntials } from 'utils/commonFunction';

const AuditTable: React.FC<any> = ({
    auditList,
    pagable,
    payload,
    setSortColumn,
    setSortOrder,
    setPage,
}) => {
    const { t } = useTranslation('translation');
    const dispatch = useDispatch();
    const auditListLoading = useSelector(
        (state: any) => state.auditTrails.auditTrailListLoading
    );
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

    const handleExpand = (expanded: boolean, record: any): any => {
        if (expanded) {
            setExpandedRowKeys([record.uniqueKey]);
        } else {
            setExpandedRowKeys([]);
        }
    };
    useEffect(() => {
        const collapseArr: any = [];
        auditList?.map((item: any) => {
            collapseArr.push(true);
        });
    }, [auditList]);

    const sortingFunction = (
        columnTobeSorted: number,
        orderOfSort: number
    ): void => {
        dispatch(
            getAuditTrailsList({
                ...payload,
                sortOrder: orderOfSort,
                sortColumn: columnTobeSorted,
            })
        );
        setSortOrder(orderOfSort);
        setSortColumn(columnTobeSorted);
        setPage(PAGE);
    };
    const addSpacesBeforeCaps = (str: any): any => {
        if (!str) return str;
        return str.replace(/([A-Z])/g, ' $1').trim(); // Note- This regex matches every uppercase letter (A to Z) in the string.The parentheses create a capturing group, which means it remembers the matched uppercase letter. This method is used to add white space before every Capital Letter.
    };
    const [isPopoverVisibles, setIsPopoverVisibles] = useState<
        Record<string, boolean>
    >({});
    const handlePopoverVisibleChanges = (visible: any): any => {
        setIsPopoverVisibles(visible);
    };
    const maxLength: any = 5;
    const columns = [
        {
            title: (
                <div className="auditTableWrapper__columnTitle">
                    <div> {t('auditTrails.entityName')}</div>
                </div>
            ),
            dataIndex: 'moduleEntityName',
        },
        {
            title: (
                <div className="auditTableWrapper__columnTitle">
                    <div> {t('auditTrails.entity')}</div>
                </div>
            ),
            render: (record: any) => addSpacesBeforeCaps(record?.entityName),
        },
        {
            title: (
                <div className="auditTableWrapper__columnTitle">
                    <div> {t('auditTrails.module')}</div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                sortingFunction(
                                    auditSortOption.Module,
                                    sortOrder.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                sortingFunction(
                                    auditSortOption.Module,
                                    sortOrder.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'module',
        },

        {
            title: (
                <div className="auditTableWrapper__columnTitle">
                    <div>{t('auditTrails.key')}</div>
                </div>
            ),
            dataIndex: 'columnKey',
        },
        {
            title: (
                <div className="auditTableWrapper__columnTitle">
                    <div>{t('auditTrails.oldValue')}</div>
                </div>
            ),
            render: (_: any, record: any) => {
                return (
                    <>
                        <div>
                            {record?.oldValue?.length >
                            AuditTrailsTable?.valueLength
                                ? record?.oldValue?.substring(
                                      0,
                                      AuditTrailsTable?.valueLength
                                  ) + '...'
                                : record?.oldValue}
                        </div>
                    </>
                );
            },
        },
        {
            title: (
                <div className="auditTableWrapper__columnTitle">
                    <div>{t('auditTrails.newValue')}</div>
                </div>
            ),
            render: (_: any, record: any) => {
                return (
                    <>
                        <div>
                            {record?.newValue?.length >
                            AuditTrailsTable?.valueLength
                                ? record?.newValue?.substring(
                                      0,
                                      AuditTrailsTable?.valueLength
                                  ) + '...'
                                : record?.newValue}
                        </div>
                    </>
                );
            },
        },
        {
            title: (
                <div className="auditTableWrapper__columnTitle">
                    <div> {t('auditTrails.operationType')}</div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                sortingFunction(
                                    auditSortOption.OperationType,
                                    sortOrder.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                sortingFunction(
                                    auditSortOption.OperationType,
                                    sortOrder.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'requestType',
            render: (_: any, data: any) => (
                <>
                    {data?.requestType === 'ADD'
                        ? t('auditTrails.create')
                        : data?.requestType === 'MOD'
                        ? t('auditTrails.edit')
                        : data?.requestType === 'DEL'
                        ? t('auditTrails.delete')
                        : EMPTY.string}
                </>
            ),
        },
        {
            title: (
                <div className="auditTableWrapper__columnTitle">
                    <div>{t('auditTrails.user')}</div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                sortingFunction(
                                    auditSortOption.User,
                                    sortOrder.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                sortingFunction(
                                    auditSortOption.User,
                                    sortOrder.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'userName',
            render: (_: any, record: any) => {
                const nameParts = record?.name?.split(' ');
                const firstName = nameParts?.length && nameParts[0];
                const lastName = nameParts?.length && nameParts[1];

                return (
                    <>
                        <div className="userTableWrapper__nameData">
                            <div className="viewName">
                                {record.name && (
                                    <Avatar
                                        className="mr-8"
                                        style={{
                                            backgroundColor: record?.avtarColor,
                                        }}
                                    >
                                        {getIntials(`${firstName} ${lastName}`)}
                                    </Avatar>
                                )}

                                <span className="fs-14 fw-500 name">
                                    <Popover
                                        content={`${record?.name}`}
                                        visible={isPopoverVisibles[record?.key]}
                                        onVisibleChange={
                                            handlePopoverVisibleChanges
                                        }
                                    >
                                        {record?.name?.length > maxLength
                                            ? `${record?.name?.slice(
                                                  0,
                                                  maxLength
                                              )}...`
                                            : record?.name}
                                    </Popover>
                                </span>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            title: (
                <div className="auditTableWrapper__columnTitle">
                    <div> {t('auditTrails.timestamp')}</div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                sortingFunction(
                                    auditSortOption.TimeStamp,
                                    sortOrder.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                sortingFunction(
                                    auditSortOption.TimeStamp,
                                    sortOrder.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'timestamp',
            render: (_: any, data: any) => (
                <>
                    <div className="tableDefinitionWrapper__status">
                        {moment(data?.timestamp).format(DATE_FORMAT)}
                    </div>
                </>
            ),
        },
    ];

    return (
        <>
            <div className="auditTableWrapper">
                <Card bordered={false}>
                    <>
                        {auditList?.length ? (
                            <Table
                                columns={columns}
                                dataSource={auditList?.filter(
                                    (item:any) => !(item?.oldValue === null && item?.newValue === '')
                                )}
                                pagination={false}
                                loading={auditListLoading}
                                scroll={
                                    pagable
                                        ? { y: 'calc(100vh - 356px)' }
                                        : { y: 'calc(100vh - 300px)' }
                                }
                                locale={{
                                    emptyText: (
                                        <Empty
                                            description={t(
                                                'auditTrails.nodata'
                                            )}
                                        />
                                    ),
                                }}
                                rowKey={(record) => record?.uniqueKey}
                                expandable={{
                                    expandedRowRender: (record) => {
                                        const innerColumns = [];

                                        if (
                                            record?.oldValue?.length >
                                            AuditTrailsTable?.valueLength
                                        ) {
                                            innerColumns.push({
                                                title: 'Old Value',
                                                dataIndex: 'oldValue',
                                                render: (
                                                    _: any,
                                                    record: any
                                                ) => {
                                                    const isLong =
                                                        record?.oldValue
                                                            ?.length >
                                                        AuditTrailsTable?.subTableValueLength;
                                                    return (
                                                        <div
                                                            className={`sub-table-component ${
                                                                isLong
                                                                    ? 'is-long'
                                                                    : ''
                                                            }`}
                                                        >
                                                            {record?.newValue}
                                                        </div>
                                                    );
                                                },
                                            });
                                        }
                                        if (
                                            record?.newValue?.length >
                                            AuditTrailsTable?.valueLength
                                        ) {
                                            innerColumns.push({
                                                title: 'New Value',
                                                dataIndex: 'newValue',
                                                render: (
                                                    _: any,
                                                    record: any
                                                ) => {
                                                    const isLong =
                                                        record?.newValue
                                                            ?.length >
                                                        AuditTrailsTable?.subTableValueLength;
                                                    return (
                                                        <div
                                                            className={`sub-table-component ${
                                                                isLong
                                                                    ? 'is-long'
                                                                    : ''
                                                            }`}
                                                        >
                                                            {record?.newValue}
                                                        </div>
                                                    );
                                                },
                                            });
                                        }

                                        if (innerColumns?.length === 0) {
                                            return null;
                                        }

                                        return (
                                            <Table
                                                dataSource={[record]}
                                                columns={innerColumns}
                                                pagination={false}
                                            />
                                        );
                                    },
                                    expandedRowKeys: expandedRowKeys,
                                    onExpand: handleExpand,
                                    rowExpandable: (record) =>
                                        record?.newValue?.length >
                                            AuditTrailsTable?.valueLength ||
                                        record?.oldValue?.length >
                                            AuditTrailsTable?.valueLength,
                                    expandIcon: ({
                                        expanded,
                                        onExpand,
                                        record,
                                    }) => {
                                        if (
                                            record?.newValue?.length >
                                                AuditTrailsTable?.valueLength ||
                                            record?.oldValue?.length >
                                                AuditTrailsTable?.valueLength
                                        ) {
                                            return (
                                                <span
                                                    onClick={(e) => {
                                                        onExpand(record, e);
                                                    }}
                                                >
                                                    {expanded ? (
                                                        <UpOutlined className="icon" />
                                                    ) : (
                                                        <DownOutlined className="icon" />
                                                    )}
                                                </span>
                                            );
                                        }
                                        return null;
                                    },
                                }}
                            />
                        ) : (
                            <div className="implementationEmptyDataComponent">
                                <EmptyDataComponent
                                    textValue={t('auditTrails.nodata')}
                                />
                            </div>
                        )}
                    </>
                </Card>
            </div>
        </>
    );
};
export default AuditTable;
