import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getTableDataService } from 'redux/services/ConfigureServices/tableServices';
import {
    getActionsListsService,
    getFormDetailsService,
    getFormListsService,
    insertDataIntoTable,
    setFormDataService,
    updateDataIntoTable,
} from 'redux/services/FormServices/formServices';

import * as types from 'redux/types/formTypes';
import { GET_TABLE_DATA_SUCCESS } from 'redux/types/configureTypes';

export function* getFormListSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        // Api call
        const getFormListData = yield call(getFormListsService, payload);
        yield put({
            type: types.GET_FORM_LIST_SUCCESS,
            response: getFormListData,
        });
    } catch (error: any) {
        yield put({ type: types.GET_FORM_LIST_FAILURE });
    }
}

export function* setFormDataSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        // Api call
        const setData = yield call(setFormDataService, payload);

        yield put({
            type: types.SET_FORM_DATA_SUCCESS,
            response: setData,
        });
    } catch (error: any) {
        yield put({ type: types.SET_FORM_DATA_FAILURE });
    }
}

export function* setFormDataInTableSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        // Api call
        const setData = yield call(insertDataIntoTable, payload);

        yield put({
            type: types.SET_FORM_DATA_TABLE_SUCCESS,
            response: setData,
        });
        if (setData) {
            const getTableData = yield call(getTableDataService, payload);
            yield put({
                type: GET_TABLE_DATA_SUCCESS,
                response: getTableData,
            });
        }
    } catch (error: any) {
        yield put({ type: types.SET_FORM_DATA_TABLE_FAILURE });
    }
}

export function* getFormDetailsSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        // Api call
        const getFormDetailsData = yield call(getFormDetailsService, payload);
        yield put({
            type: types.GET_FORM_DETAILS_SUCCESS,
            response: getFormDetailsData,
        });
    } catch (error: any) {
        yield put({ type: types.GET_FORM_DETAILS_FAILURE });
    }
}
export function* updateFormDataInTableSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        const setData = yield call(updateDataIntoTable, payload);
 
        yield put({
            type: types.UPDATE_FORM_DATA_TABLE_SUCCESS,
            response: setData,
        });
        if (setData) {
            const getTableData = yield call(getTableDataService, payload);
            yield put({
                type: GET_TABLE_DATA_SUCCESS,
                response: getTableData,
            });
        }
    } catch (error: any) {
        yield put({ type: types.UPDATE_FORM_DATA_TABLE_FAILURE });
    }
}

export function* getActionListSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        // Api call
        const getActionListData = yield call(getActionsListsService, payload);
        yield put({
            type: types.GET_ACTION_LIST_SUCCESS,
            response: getActionListData,
        });
    } catch (error: any) {
        yield put({ type: types.GET_ACTION_LIST_FAILURE });
    }
}

export function* formSaga(): any {
    yield all([takeLatest(types.GET_FORM_LIST, getFormListSaga)]);
    yield all([takeLatest(types.GET_FORM_DETAILS, getFormDetailsSaga)]);
    yield all([takeLatest(types.SET_FORM_DATA, setFormDataSaga)]);
    yield all([takeLatest(types.SET_FORM_DATA_TABLE, setFormDataInTableSaga)]);
    yield all([
        takeLatest(types.UPDATE_FORM_DATA_TABLE, updateFormDataInTableSaga),
    ]);
    yield all([takeLatest(types.GET_ACTION_LIST, getActionListSaga)]);
}
