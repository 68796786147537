import React, { useRef } from 'react';
import ReactECharts from 'echarts-for-react';
import { statisticalFunctions } from 'types/enums/dataVisualizationEnum';
import { isSelectedTrendIncluded } from 'utils/commonFunction';

const DemergeBoxChart = ({
    name,
    showMarkers,
    dateRange,
    selectedTags,
    selectedKpis,
    chartSeriesData,
    selectedTrendFunctions,
    selectedAlert,
    selectedTimeCapsule,
    controlLimitsValue,
}: any): any => {
    const chartRef: any = useRef<HTMLDivElement>(null);
    // Combine tags and KPIs into a single array for processing
    const combinedSelections = [
        ...selectedTags?.map((tag: any) => ({ ...tag, type: 'tag' })),
        ...selectedKpis?.map((kpi: any) => ({ ...kpi, type: 'kpi' })),
    ];

    // Styles for trend lines with consistent colors
    const trendStyles: any = {
        Mean: {
            color: 'blue',
            dashStyle: 'dashed',
        },
        Median: {
            color: 'green',
            dashStyle: 'dotted',
        },
        Mode: {
            color: 'orange',
            dashStyle: 'dotted',
        },
        'Standard Deviation': {
            color: 'red',
            dashStyle: 'dashed',
        },
        'Moving Average': {
            color: 'pink',
            dashStyle: 'dashed',
        },
    };

    const transformData: any = (series: any, startPosition: number = 0) => {
        if (!series?.data) return [];
        return [
            [startPosition - 0.5, series.data[0][1]],
            [startPosition + 0.5, series.data[1][1]],
        ];
    };

    const tranformBoxPlotData: any = (series: any, index: number) => {
        if (
            (!series?.data && !Array.isArray(series?.data)) ||
            series?.data?.length === 0
        )
            return [];

        const values = series?.data
            ?.map((item: { value: number }) => item.value)
            ?.filter((value: number) => value !== undefined)
            ?.sort((a: number, b: number) => a - b);

        if (values?.length === 0) return [];

        const lowestValue = values[0];
        const highestValue = values[values?.length - 1];
        const q1Index = Math.floor(values?.length * 0.25);
        const q3Index = Math.floor(values?.length * 0.75);
        const iqr = values[q3Index] - values[q1Index];
        const lowerBound = values[q1Index] - 1.5 * iqr;
        const upperBound = values[q3Index] + 1.5 * iqr;

        return [
            index, // x-axis positioning
            Math.max(lowestValue, lowerBound),
            values[q1Index],
            values[Math.floor(values.length / 2)],
            values[q3Index],
            Math.min(highestValue, upperBound),
        ];
    };

    return (
        <div style={{ display: 'grid', gap: '20px' }}>
            {combinedSelections?.map((item) => {
                const isTag = item.type === statisticalFunctions.TAG;
                const itemName = isTag ? item.tagName : item.name;

                // Extract UCL and LCL values for the current item using its unique identifier
                const uclValue = controlLimitsValue?.[item?.uuid]?.[0];
                const lclValue = controlLimitsValue?.[item?.uuid]?.[1];

                // Filter chart series data based on the itemName and selected trend functions
                const filteredChartSeriesData = chartSeriesData?.filter(
                    (series: any) => {
                        return (
                            series?.name === itemName ||
                            (series?.name?.includes(itemName) &&
                                selectedTrendFunctions?.some((item: string) =>
                                    isSelectedTrendIncluded(series?.name, item)
                                ))
                        );
                    }
                );

                // Process filtered chart series data to apply styles, type (line,boxplot), and configurations for rendering.
                const seriesData = filteredChartSeriesData?.map(
                    (series: any) => {
                        const trendFunctionName: any = Object.keys(
                            trendStyles
                        ).find((trend) => series?.name?.includes(trend));

                        const isTrendLine = !!trendFunctionName;
                        const trendStyle = trendStyles[trendFunctionName] || {};

                        return {
                            name: series?.name,
                            data: isTrendLine
                                ? transformData(series, 0)
                                : [tranformBoxPlotData(series, 0)],
                            type: isTrendLine ? 'line' : 'boxplot',
                            showSymbol: isTrendLine,
                            itemStyle: {
                                color: isTrendLine
                                    ? trendStyle.color
                                    : '#D3A6FF',
                                borderColor: 'purple',
                                borderWidth: 2,
                            },
                            lineStyle: {
                                type: trendStyle
                                    ? trendStyle.dashStyle?.toLowerCase()
                                    : 'solid',
                                width: 2,
                            },
                            z: isTrendLine ? 2 : 1,
                        };
                    }
                );

                const options = {
                    xAxis: {
                        min: -1,
                        max: +1,
                        type: 'value',
                        show: false,
                    },
                    yAxis: {
                        type: 'value',
                        name: item?.dataType?.unit || "Value",
                        nameLocation: 'middle',
                        nameTextStyle: {
                            fontSize: 12,
                            
                            padding: 40,
                        },
                        scale: true,
                        axisLabel: {
                            formatter: (value: number) => {
                                if (Math.abs(value) >= 1e6) {
                                    return `${(value / 1e6).toFixed(1)}M`; // Convert to millions
                                } else if (Math.abs(value) >= 1e3) {
                                    return `${(value / 1e3).toFixed(1)}K`; // Convert to thousands
                                }
                                return value.toString();
                            },
                        },
                        splitLine: {
                            show: true,
                        },
                    },
                    series: [
                        ...seriesData,
                        uclValue
                            ? {
                                  name: 'UCL',
                                  data: [
                                      [-0.5, Number(uclValue)],
                                      [0.5, Number(uclValue)],
                                  ],
                                  type: 'line',
                                  itemStyle: {
                                      color: 'red',
                                  },
                                  lineStyle: {
                                      type: 'solid',
                                      width: 2,
                                  },
                                  symbol: 'none',
                                  z: 3,
                              }
                            : null,
                        lclValue
                            ? {
                                  name: 'LCL',
                                  data: [
                                      [-0.5, Number(lclValue)],
                                      [0.5, Number(lclValue)],
                                  ],
                                  type: 'line',
                                  itemStyle: {
                                      color: 'green',
                                  },
                                  lineStyle: {
                                      type: 'solid',
                                      width: 2,
                                  },
                                  symbol: 'none',
                                  z: 3,
                              }
                            : null,
                    ],
                    dataZoom: {
                        type: 'inside',
                    },
                    toolbox: {
                        feature: {
                            saveAsImage: {},
                            dataZoom: {
                                yAxisIndex: 'none',
                            },
                            restore: {},
                        },
                    },
                    tooltip: {
                        trigger: 'axis',
                        formatter: (params: any) => {
                            let html = '';
                            params.forEach((series: any) => {
                                if (series.seriesType === 'boxplot') {
                                    html += `<span style="color:${series.color};">\u25CF</span> <b>${series?.seriesName}</b><br/>`;
                                    html += `Minimum: ${series?.data[1]}<br/>`;
                                    html += `Lower Quartile: ${series?.data[2]}<br/>`;
                                    html += `Median: ${series?.data[3]}<br/>`;
                                    html += `Upper Quartile: ${series?.data[4]}<br/>`;
                                    html += `Maximum: ${series?.data[5]}<br/>`;
                                } else {
                                    html += `<span style="color:${
                                        series.color
                                    };">\u25CF</span> <b>${
                                        series?.seriesName
                                    }</b>: ${series?.data[1].toFixed(2)}<br/>`;
                                    
                                }
                            });

                            return `<div style="display:flex;justify-content:center;">
                                            <div style="font-size:12px; font-weight:500">
                                                ${html}
                                            </div>
                                        </div>`;
                        },
                    },
                    legend: {
                        bottom: 10,
                        left: 'center',
                        formatter: (name: string) => {
                            const trendFunctionName = Object.keys(
                                trendStyles
                            ).find((trend) => name?.includes(trend));
                            return trendFunctionName ?? name;
                        },
                    },
                };

                return (
                    <div
                        key={item.id || item.tagId || item.kpiId}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                        }}
                    >
                        <ReactECharts
                            ref={chartRef}
                            option={{ ...options }}
                            notMerge={true}
                            lazyUpdate={true}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default DemergeBoxChart;
