import { moduleList, modelOverviewItems } from 'types/enums';
import { useDispatch } from 'react-redux';
import { deletModel } from 'redux/actions/AssetModellingActions/assetModellingAction';
import { OPEN_SUB_MODEL_TABS } from 'redux/types/assetModellingTypes';
import ConfirmationModal from 'components/common/Modals/ConfirmationModal';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteNew.svg';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PAGE, PAGE_SIZE } from 'utils/constants';
import { useNavigate } from 'react-router-dom';

const ModelOverviewMoreContent: React.FC<any> = ({
    setEditModel,
    setOpenModelInfo,
    record,
    setRecordDetails,
    setPopoverVisible,
    setPage,
}) => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const [openDeleteModal, setOpenDeleteModel] = useState(false);
    const dispatch = useDispatch();
    const values = [
        {
            title: modelOverviewItems?.edit,
        },
        {
            title: t('commonStr.viewAudits'),
        },
        {
            title: t('commonStr.viewAudits'),
        },
    ];

    const handleViewAudits = (): void => {
        const queryParams = new URLSearchParams({
            module: moduleList?.MODEL_OVERVIEW,
            moduleEntity: record?.id,
        }).toString();

        navigate(`/audit-trails?${queryParams}`);
    };

    return (
        <>
            <div className="more-container">
                <div className={`more-content`}>
                    <ul>
                        {values &&
                            values.length > 0 &&
                            values.map((item: any) => (
                                <li
                                    key={item.title}
                                    className="moreContent__items"
                                >
                                    <span
                                        className="moreContent__option"
                                        onClick={() => {
                                            if (
                                                item?.title ===
                                                modelOverviewItems?.edit
                                            ) {
                                                dispatch({
                                                    type: OPEN_SUB_MODEL_TABS,
                                                });
                                                setRecordDetails(record);
                                                setEditModel(true);
                                                setOpenModelInfo(true);
                                            }
                                            if (
                                                item?.title ===
                                                modelOverviewItems?.delete
                                            ) {
                                                setPopoverVisible(false);
                                                setOpenDeleteModel(true);
                                            }
                                            if (
                                                item.title ===
                                                t('commonStr.viewAudits')
                                            ) {
                                                handleViewAudits();
                                            }
                                        }}
                                    >
                                        {item.title}
                                    </span>
                                </li>
                            ))}
                    </ul>
                </div>
            </div>
            {openDeleteModal && (
                <ConfirmationModal
                    icon={<DeleteIcon />}
                    open={openDeleteModal}
                    onOk={() => {
                        dispatch(
                            deletModel({
                                modelId: record?.id,
                                page: PAGE,
                                pageSize: PAGE_SIZE,
                            })
                        );
                        setPage(PAGE);
                        setOpenDeleteModel(false);
                    }}
                    onCancel={() => {
                        setOpenDeleteModel(false);
                    }}
                    text={t('assetModeller.modelOverview.deleteModelModalText')}
                    customClassName="confirmationModal modelDeletion"
                />
            )}
        </>
    );
};
export default ModelOverviewMoreContent;
