import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
    OperationFormFields,
    OperationState,
    TabName,
} from 'types/enums/businessIntegratorEnum';
import { ReactComponent as BackIcon } from 'assets/icons/backIcon.svg';
import CustomButton from 'components/common/CustomButton';
import { getConnectionsList } from 'redux/actions/BusinessIntegratorActions/connectionsActions';
import './index.scss';
import { getMappingList } from 'redux/actions/BusinessIntegratorActions/mappingActions';
import dayjs from 'dayjs';
import ConfirmationModal from 'components/common/Modals/ConfirmationModal';
import { cancelHandle, modalShow } from 'utils/modalFunction';
import { ReactComponent as ConfirmationIcon } from 'assets/icons/confirmationIcon.svg';
import SuccessfulModal from 'components/common/Modals/SuccessfulModal';
import AddOperationForm from './addOperationForm';
import {
    createOperation,
    createOutboundOperation,
    getDaysList,
    getOperationDetailsById,
    setOperationState,
    updateOperation,
} from 'redux/actions/BusinessIntegratorActions/operationActions';
import { connectionDirection, dateFormat } from 'types/enums';
import { RESET_OPERATION_STATES } from 'redux/types/businessIntegratorTypes';
import { useParams } from 'react-router-dom';

const AddOperation = (): any => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { currentTab, paramAdapterId } = useParams();
    const operationState = useSelector(
        (state: any) => state.businessIntegrator.operation.operationState
    );
    const loggedInUserDetails = useSelector(
        (state: any) => state.userManagement.users?.loggedInUserDetails
    );
    const isOperationCreated = useSelector(
        (state: any) => state.businessIntegrator.operation.isOperationCreated
    );
    const isOperationUpdated = useSelector(
        (state: any) => state.businessIntegrator.operation.isOperationUpdated
    );
    const operationDetails = useSelector(
        (state: any) => state.businessIntegrator.operation.operationDetails
    );
    const mappingById = useSelector(
        (state: any) => state.businessIntegrator.mapping.mappingById
    );
    const connectionValidated = useSelector(
        (state: any) => state.businessIntegrator.operation.connectionValidated
    );
    const daysList = useSelector(
        (state: any) => state.businessIntegrator.operation.daysList
    );
    const [isMappingSaveModalOpen, setIsMappingSaveModalOpen] = useState(false);
    const [
        onOperationCreateSuccessfullModalOpen,
        setOnOperationCreateSuccessfullModalOpen,
    ] = useState(false);
    const [
        onOperationUpdatedSuccessfullModalOpen,
        setOnOperationUpdatedSuccessfullModalOpen,
    ] = useState(false);
    const [
        isOperationSaveAndActivateModalOpen,
        setIsOperationSaveAndActivateModalOpen,
    ] = useState(false);

    const operationName = Form.useWatch(
        OperationFormFields.operationName,
        form
    );
    const mappingId = Form.useWatch(OperationFormFields.mappingId, form);
    const description = Form.useWatch(OperationFormFields.description, form);
    const labels = Form.useWatch(OperationFormFields.label, form);
    const startTime: any = dayjs(
        Form.useWatch(OperationFormFields.startTime, form),
        dateFormat.format
    );
    const endTime: any = dayjs(
        Form.useWatch(OperationFormFields.endTime, form),
        dateFormat.format
    );
    const dayValue = Form.useWatch(OperationFormFields.dayValue, form);
    const frequencyUnit = Form.useWatch(
        OperationFormFields.frequencyUnit,
        form
    );
    const frequencyValue = Form.useWatch(
        OperationFormFields.frequencyValue,
        form
    );
    const lastAfter = Form.useWatch(OperationFormFields.lastAfter, form);
    const occurrences = Form.useWatch(OperationFormFields.occurrences, form);
    const operationMethod = Form.useWatch(
        OperationFormFields.operationMethod,
        form
    );
    const functionalValue = Form.useWatch(OperationFormFields.function, form);

    const direction = Form.useWatch('direction', form);
    useEffect(() => {
        if (currentTab !== TabName.operations) {
            dispatch(setOperationState(OperationState.tableView));
        }
    }, [currentTab]);
    useEffect(() => {
        if (operationDetails) {
            form.setFieldsValue({
                operationName: operationDetails?.name,
                mappingId: operationDetails?.connectionMappingResponse?.id,
                connectionName:
                    operationDetails?.connectionMappingResponse?.connectionName,
                adapterType:
                    operationDetails?.connectionResponse?.adapter?.name,
                direction:
                    operationDetails?.connectionResponse?.direction?.name,
                operationMethod: operationDetails?.method?.id,
                function: operationDetails?.function?.id,
                dayValue: operationDetails?.weekDays?.map(
                    (day: any) => day?.id
                ),
                description: operationDetails?.description,
                startTime:
                    operationDetails?.startDate &&
                    dayjs(operationDetails?.startDate),
                endTime:
                    operationDetails?.endDate &&
                    dayjs(operationDetails?.endDate),
                frequencyUnit: operationDetails?.frequencyUnit?.id,
                frequencyValue: operationDetails?.frequencyInterval,
                lastAfter: operationDetails?.lastAfter ? [1] : [],
                occurrences: operationDetails?.lastAfter
                    ? operationDetails?.occurrence
                    : null,
            });
        }
    }, [operationDetails, form]);
    const onBackClickHandler = (): any => {
        dispatch(setOperationState(OperationState.tableView));
        dispatch({ type: RESET_OPERATION_STATES });
    };

    useEffect(() => {
        dispatch(
            getConnectionsList({
                pageNum: -1,
            })
        );
        dispatch(
            getMappingList({
                pageNum: -1,
            })
        );
        dispatch(getDaysList());
    }, []);

    const getTitle = (): any => {
        if (operationState === OperationState.viewOperation) {
            return 'View Operation';
        } else if (operationState === OperationState.editOperation) {
            return 'Edit Operation';
        } else {
            return 'Create Operation';
        }
    };
    useEffect(() => {
        currentTab === TabName.operations &&
            operationState !== OperationState.addOperation &&
            dispatch(
                getOperationDetailsById({
                    operationId: paramAdapterId,
                })
            );
    }, [operationState, paramAdapterId]);

    const onSaveOperationHandler = (): any => {
        if (operationState === OperationState.editOperation) {
            if (
                mappingById?.connectionResponse?.direction?.name === connectionDirection.inbound
            ) {
                dispatch(
                    updateOperation({
                        requestedBy:
                            loggedInUserDetails?.firstName +
                            loggedInUserDetails?.lastName,
                        id: operationDetails?.id,
                        connectionMappingId: mappingId,
                        startDate: Date.parse(startTime) || null,
                        endDate: Date.parse(endTime) || null,
                        description: description,
                        labels: labels,
                        frequencyUnit: frequencyUnit,
                        frequencyInterval: frequencyValue,
                        weekDay: dayValue?.length
                            ? dayValue
                            : daysList?.map((item: any) => item?.id),
                        isActive: false,
                        name: operationName,
                        isLastAfter: lastAfter?.length > 0,
                        occurrence: occurrences,
                        functionId: functionalValue,
                        methodId: operationMethod,
                        isTested: connectionValidated,
                    })
                );
            } else if (
                mappingById?.connectionResponse?.direction?.name === connectionDirection.outbound
            ) {
                dispatch(
                    updateOperation({
                        requestedBy:
                            loggedInUserDetails.firstName +
                            loggedInUserDetails.lastName,
                        id: operationDetails?.id,
                        connectionMappingId: mappingId,
                        description: description,
                        labels: labels,
                        isActive: operationDetails?.active,
                        name: operationName,
                        functionId: functionalValue,
                        methodId: operationMethod,
                        isTested: true,
                    })
                );
            }
        } else {
            if (
                mappingById?.connectionResponse?.direction?.name === connectionDirection.inbound
            ) {
                dispatch(
                    createOperation({
                        requestedBy:
                            loggedInUserDetails.firstName +
                            loggedInUserDetails.lastName,
                        connectionMappingId: mappingId,
                        startDate: Date.parse(startTime) || null,
                        endDate: Date.parse(endTime) || null,
                        description: description,
                        labels: labels,
                        frequencyUnit: frequencyUnit,
                        frequencyInterval: frequencyValue,
                        weekDay: dayValue?.length
                            ? dayValue
                            : daysList?.map((item: any) => item?.id),
                        isActive: false,
                        name: operationName,
                        isLastAfter: lastAfter?.length > 0,
                        occurrence: occurrences,
                        functionId: functionalValue,
                        methodId: operationMethod,
                        isTested: connectionValidated,
                    })
                );
            } else if (
                mappingById?.connectionResponse?.direction?.name === connectionDirection.outbound
            ) {
                dispatch(
                    createOutboundOperation({
                        requestedBy:
                            loggedInUserDetails.firstName +
                            loggedInUserDetails.lastName,
                        connectionMappingId: mappingId,
                        description: description,
                        labels: labels,
                        isActive: true,
                        name: operationName,
                        functionId: functionalValue,
                        methodId: operationMethod,
                        isTested: true,
                    })
                );
            }
        }
        cancelHandle(isMappingSaveModalOpen, setIsMappingSaveModalOpen);
    };
    const onOperationSaveAndActivateHandler = (): any => {
        if (operationState === OperationState.editOperation) {
            dispatch(
                updateOperation({
                    requestedBy:
                        loggedInUserDetails.firstName +
                        loggedInUserDetails.lastName,
                    id: operationDetails?.id,
                    connectionMappingId: mappingId,
                    startDate: Date.parse(startTime) || null,
                    endDate: Date.parse(endTime) || null,
                    description: description,
                    labels: labels,
                    frequencyUnit: frequencyUnit,
                    frequencyInterval: frequencyValue,
                    weekDay: dayValue?.length
                        ? dayValue
                        : daysList?.map((item: any) => item?.id),
                    isActive: true,
                    name: operationName,
                    isLastAfter: lastAfter?.length > 0,
                    occurrence: occurrences,
                    functionId: functionalValue,
                    methodId: operationMethod,
                    isTested: true,
                })
            );
        } else {
            if (
                mappingById?.connectionResponse?.direction?.name === connectionDirection.inbound
            ) {
                dispatch(
                    createOperation({
                        requestedBy:
                            loggedInUserDetails.firstName +
                            loggedInUserDetails.lastName,
                        connectionMappingId: mappingId,
                        startDate: Date.parse(startTime) || null,
                        endDate: Date.parse(endTime) || null,
                        description: description,
                        labels: labels,
                        frequencyUnit: frequencyUnit,
                        frequencyInterval: frequencyValue,
                        weekDay: dayValue?.length
                            ? dayValue
                            : daysList?.map((item: any) => item?.id),
                        isActive: true,
                        name: operationName,
                        isLastAfter: lastAfter?.length > 0,
                        occurrence: occurrences,
                        functionId: functionalValue,
                        methodId: operationMethod,
                        isTested: true,
                    })
                );
            } else if (
                mappingById?.connectionResponse?.direction?.name === connectionDirection.outbound
            ) {
                dispatch(
                    createOutboundOperation({
                        requestedBy:
                            loggedInUserDetails.firstName +
                            loggedInUserDetails.lastName,
                        connectionMappingId: mappingId,
                        description: description,
                        labels: labels,
                        isActive: true,
                        name: operationName,
                        functionId: functionalValue,
                        methodId: operationMethod,
                        isTested: true,
                    })
                );
            }
        }

        cancelHandle(
            isOperationSaveAndActivateModalOpen,
            setIsOperationSaveAndActivateModalOpen
        );
    };

    useEffect(() => {
        isOperationCreated &&
        setOnOperationCreateSuccessfullModalOpen(true);
    }, [isOperationCreated]);
    useEffect(() => {
        isOperationUpdated &&
            modalShow(
                onOperationUpdatedSuccessfullModalOpen,
                setOnOperationUpdatedSuccessfullModalOpen
            );
    }, [isOperationUpdated]);

    const onSuccesfulModalCancelHanlder = (): any => {
        if (operationState === OperationState.editOperation) {
            cancelHandle(
                onOperationUpdatedSuccessfullModalOpen,
                setOnOperationUpdatedSuccessfullModalOpen
            );
        } else {
            cancelHandle(
                onOperationCreateSuccessfullModalOpen,
                setOnOperationCreateSuccessfullModalOpen
            );
        }
        dispatch(setOperationState(OperationState.tableView));
        dispatch({ type: RESET_OPERATION_STATES });
    };

    return (
        <>
            <div className="attributeWrapper adapterList">
                <Card bordered={false}>
                    <Row className="adapterList__header">
                        <Col className="adapterList__backIcon" span={0.5}>
                            <BackIcon
                                onClick={() => {
                                    onBackClickHandler();
                                }}
                            />
                        </Col>
                        <Col
                            className="adapterList__title fw-400 fs-16"
                            span={21}
                        >
                            {getTitle()}
                        </Col>
                        {operationState === OperationState.viewOperation &&
                            !operationDetails?.active && (
                                <Col span={2} className="viewEdit">
                                    <Button
                                        type="primary"
                                        ghost
                                        className="addNewButton"
                                        onClick={() => {
                                            dispatch(
                                                setOperationState(
                                                    OperationState.editOperation
                                                )
                                            );
                                        }}
                                    >
                                        Edit
                                    </Button>
                                </Col>
                            )}
                    </Row>
                    <Form
                        form={form}
                        layout="vertical"
                        disabled={
                            operationState === OperationState.viewOperation
                        }
                        onFinish={onSaveOperationHandler}
                    >
                        <div className={'adapterList__content addMapping'}>
                            <AddOperationForm form={form} />
                        </div>
                        <div className="adapterList__footer addMapping">
                            <div className="adapterList__buttons">
                                <CustomButton
                                    type={'Cancel'}
                                    disabled={false}
                                    handleClick={() => {
                                        dispatch(
                                            setOperationState(
                                                OperationState.tableView
                                            )
                                        );
                                        dispatch({
                                            type: RESET_OPERATION_STATES,
                                        });
                                    }}
                                />
                                <Button
                                    type="primary"
                                    ghost
                                    className={
                                        direction === connectionDirection.outbound? 'saveBtn' : ''
                                    }
                                    disabled={
                                        direction === connectionDirection.inbound
                                            ? !operationName ||
                                              !mappingId ||
                                              !functionalValue ||
                                              !operationMethod ||
                                              !startTime ||
                                              !endTime ||
                                              !frequencyUnit ||
                                              !frequencyValue ||
                                              operationState ===
                                                  OperationState.viewOperation
                                            : !operationName ||
                                              !mappingId ||
                                              !functionalValue ||
                                              !operationMethod ||
                                              operationState ===
                                                  OperationState.viewOperation
                                    }
                                    onClick={() => {
                                        modalShow(
                                            isMappingSaveModalOpen,
                                            setIsMappingSaveModalOpen
                                        );
                                    }}
                                >
                                    Save
                                </Button>
                                {direction === connectionDirection.inbound && (
                                    <CustomButton
                                        type={'Save & Activate'}
                                        disabled={
                                            !operationName ||
                                            !mappingId ||
                                            !functionalValue ||
                                            !operationMethod ||
                                            !startTime ||
                                            !endTime ||
                                            !frequencyUnit ||
                                            !frequencyValue ||
                                            operationState ===
                                                OperationState.viewOperation ||
                                            !(connectionValidated === true)
                                        }
                                        handleClick={() => {
                                            modalShow(
                                                isOperationSaveAndActivateModalOpen,
                                                setIsOperationSaveAndActivateModalOpen
                                            );
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </Form>
                </Card>
            </div>
            <ConfirmationModal
                open={isMappingSaveModalOpen}
                onCancel={() => {
                    cancelHandle(
                        isMappingSaveModalOpen,
                        setIsMappingSaveModalOpen
                    );
                }}
                onOk={() => {
                    form.submit();
                }}
                icon={<ConfirmationIcon />}
                text={
                    operationState !== OperationState.editOperation
                        ? 'Would you like to save the Operation?'
                        : 'Would you like to save the changes?'
                }
            />
            <SuccessfulModal
                open={onOperationCreateSuccessfullModalOpen}
                onOk={() => {}}
                onCancel={() => {
                    onSuccesfulModalCancelHanlder();
                }}
                text={`Operation created Successfully`}
            />
            <ConfirmationModal
                open={isOperationSaveAndActivateModalOpen}
                onCancel={() => {
                    cancelHandle(
                        isOperationSaveAndActivateModalOpen,
                        setIsOperationSaveAndActivateModalOpen
                    );
                }}
                onOk={() => {
                    onOperationSaveAndActivateHandler();
                }}
                icon={<ConfirmationIcon />}
                text={
                    operationState !== OperationState.editOperation
                        ? 'Would you like to save and activate the Operation?'
                        : 'Would you like to save and activate the changes?'
                }
            />
            <SuccessfulModal
                open={onOperationUpdatedSuccessfullModalOpen}
                onOk={() => {}}
                onCancel={() => {
                    onSuccesfulModalCancelHanlder();
                }}
                text={`Operation Updated Successfully`}
            />
        </>
    );
};

export default AddOperation;
