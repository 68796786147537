/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useMemo, useState } from 'react';
import {
    BellOutlined,
    UserOutlined,
    UnlockOutlined,
    SettingOutlined,
} from '@ant-design/icons';
import BraboFooterLogo from 'assets/icons/braboFooterLogo.svg';
import { Breadcrumb, Layout, Divider, Popover, Image, Button } from 'antd';
import Sidebar from 'components/layouts/sideBar';
import './index.scss';
import { getSideNavMenu } from 'utils/sideMenuFunction';
import {
    findParentMenuIds,
    getObjectsById,
    sideNavSearchFilter,
} from 'utils/sideNavSearchFunction';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAllowedMenuList,
    getSidenavMenuList,
} from 'redux/actions/SideNavActions/sidenavAction';
import SideNavRoutes from 'routes';
import { LogOutuser } from 'redux/actions/AuthAction/authAction';
import {
    dashboardEmbeddedUuid,
    getLoggedInUserDashboardBuilderDetails,
    getLoggedInUserDetails,
} from 'redux/actions/UserManagementActions/usersAction';
import { parseJwt } from 'utils/jwtTokenFunction';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { UseCasesMenu, expiresCookies } from 'types/enums';
import { ReactComponent as Reports } from 'assets/icons/dashboardReport.svg';
// import { getReportingListAction } from 'redux/actions/DataExplorer/DashboardReportingActions';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { useBreadcrumb } from 'utils/breadCrumbsFunctions';
import { ReactComponent as DeviceConnectionIcon } from 'assets/icons/deviceManagement.svg';
import { getFormList } from 'redux/actions/FormActions';
// import { getBreadcrumbItem } from 'utils/breadCrumbsFunctions';
const cookies = new Cookies();

const PageLayout: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation('translation');
    const forms = useSelector((state: any) => state?.formio?.formsList);
    const { Header, Content, Sider } = Layout;
    const [collapsed, setCollapsed] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const [menuData, setMenuData] = useState([]);
    const [paramsArray, setParamsArray] = useState<any>();
    const dispatch = useDispatch();
    const pathParams = useParams();
    useEffect(() => {
        dispatch(getFormList());
    }, []);
    const sideNavMenuList = useSelector(
        (state: any) => state.root.sideNavMenuList
    );
    const reportList = useSelector(
        (state: any) => state?.dataExplorer?.dashboardReporting?.reportingList
    );
    const loggedInUserDetails = useSelector(
        (state: any) => state.userManagement.users?.loggedInUserDetails
    );
    const loggedInUserDashboardBuilderDetails = useSelector(
        (state: any) =>
            state.userManagement.users?.loggedInUserDashboardBuilderDetails
    );
    const rememberCred = useSelector((state: any) => state.login.rememberMe);
    const allowedTabList = useSelector(
        (state: any) => state.root.allowedMenuList
    );

    const [items, setItems] = useState([]);

    useEffect(() => {
        dispatch(getAllowedMenuList(user?.UserId));
    }, [dispatch]);

    const user = parseJwt();
    useEffect(() => {
        if (user) {
            dispatch(getLoggedInUserDetails(user?.UserId));
        }
    }, []);

    useEffect(() => {
        if (loggedInUserDetails?.userOnDashboardBuilder) {
            dispatch(
                getLoggedInUserDashboardBuilderDetails(
                    loggedInUserDetails.userId
                )
            );
        }
    }, [loggedInUserDetails?.userOnDashboardBuilder]);

    useEffect(() => {
        if (loggedInUserDetails) {
            dispatch(getSidenavMenuList());
        }
        const updatedList = sideNavMenuList?.map((itemOne: any) => {
            if (itemOne.name === UseCasesMenu.Configure) {
                const nestedOne = itemOne?.children?.map((itemTwo: any) => {
                    if (itemTwo?.name === UseCasesMenu.DashboardReporting) {
                        const nestedTwo = itemTwo?.children?.map(
                            (itemThree: any) => {
                                if (itemThree.name === UseCasesMenu.Reports) {
                                    const childList = reportList?.map(
                                        (item: any) => {
                                            return {
                                                id: item?.id,
                                                name: item?.menuName,
                                                breadcrumb: item?.menuName,
                                                key: item?.menuName,
                                                icon: <Reports />,
                                            };
                                        }
                                    );
                                    return {
                                        ...itemThree,
                                        children: childList,
                                    };
                                }

                                return itemThree;
                            }
                        );
                        return {
                            ...itemTwo,
                            children: nestedTwo,
                        };
                    }
                    if (itemTwo.name === UseCasesMenu.DashboardBuilder) {
                        const childLists = {
                            ...itemTwo,
                            disabled: !loggedInUserDetails.admin,
                        };
                        return {
                            ...childLists,
                        };
                    }
                    if (itemTwo.name === UseCasesMenu.FormBuilder) {
                        const childLists = {
                            ...itemTwo,
                            disabled: !loggedInUserDetails.admin,
                        };
                        return {
                            ...childLists,
                        };
                    }
                    return itemTwo;
                });
                return {
                    ...itemOne,
                    children: nestedOne,
                };
            }
            if (itemOne.key === 'Manual Forms') {
                const nestedOne = Array.isArray(forms)
                    ? forms?.map((form: any) => ({
                          id: form?._id,
                          name: form?.title,
                          breadcrumb: form?.title,
                          key: form?._id,
                          icon: <DeviceConnectionIcon />,
                          path: `/forms/${form?._id}`,
                          exact: true,
                          children: [],
                      }))
                    : [];

                return {
                    ...itemOne,
                    children: [...itemOne?.children, ...nestedOne],
                };
            }
            return itemOne;
        });
        const dashboardUpdatedMenuList = updatedList?.map((itemOne: any) => {
            if (itemOne.name === UseCasesMenu.Dashboards) {
                const childList =
                    loggedInUserDashboardBuilderDetails?.dashboardEntries?.map(
                        (item: any) => {
                            return {
                                id: item?.id,
                                path: `/dashboards/${item?.id}`,
                                name: item?.dashboard_title,
                                breadcrumb: '',
                                key: item?.id,
                                icon: <Reports />,
                            };
                        }
                    );
                return {
                    ...itemOne,
                    children: childList?.length ? childList : [],
                };
            }
            return itemOne;
        });
        if (loggedInUserDetails) {
            setItems(getSideNavMenu(dashboardUpdatedMenuList));
        }
    }, [
        sideNavMenuList,
        reportList,
        loggedInUserDetails,
        cookies,
        allowedTabList,
        loggedInUserDashboardBuilderDetails,
        forms,
    ]);

    useEffect(() => {
        const breadcrumbArray = pathParams['*']?.split('/');
        setParamsArray(breadcrumbArray);
    }, [pathParams]);

    const onCollapse = (sidebarCollapse: any): any => {
        setCollapsed(sidebarCollapse);
    };

    useEffect(() => {
        sideNavSearchFilter(
            getObjectsById(items, findParentMenuIds(items, allowedTabList)),
            searchValue,
            setMenuData
        );
    }, [searchValue]);

    useEffect(() => {
        if (user) dispatch(getLoggedInUserDetails(user.UserId));
    }, []);
    const logouthandler = (): void => {
        const body = document.body;
        localStorage.removeItem('authToken');
        sessionStorage.removeItem('authToken');
        localStorage.removeItem('isAdmin');
        localStorage.removeItem('default-theme');
        document.cookie = `session=; expires=${expiresCookies}; path=/; domain=solulever.com;`;
        document.cookie = `isAdmin=; expires=${expiresCookies}; path=/; `;
        document.cookie = `authToken=; expires=${expiresCookies}; path=/;`;
        document.cookie = `loggedInUserDashboardEmbeddedUuid=; expires=${expiresCookies}; path=/;`;
        document.cookie = `lang=; expires=${expiresCookies}; path=/;`;
        document.cookie = `uid=; expires=${expiresCookies}; path=/;`;
        dispatch(dashboardEmbeddedUuid(''));
        dispatch(LogOutuser(rememberCred));
        body.classList.remove('theme-light', 'theme-dark');
        navigate('/login');
    };

    const menuItem = (): any => {
        if (loggedInUserDetails.admin) {
            return menuData.length > 0 ? menuData : items;
        } else {
            return menuData.length > 0
                ? menuData
                : getObjectsById(
                      items,
                      findParentMenuIds(items, [
                          ...allowedTabList,
                          UseCasesMenu.Home,
                      ])
                  );
        }
    };

    const getReportingDashboards = (items?: any): any => {
        const dashboardItem = items?.filter(
            (item: any) => item?.menuIds === UseCasesMenu.Dashboards
        );
        return dashboardItem;
    };

    // useEffect(() => {
    //     if (loggedInUserDetails?.admin === false) {
    //         if (
    //             allowedTabList?.includes('Reports') &&
    //             allowedTabList?.length > 0
    //         ) {
    //             dispatch(
    //                 getReportingListAction({
    //                     parentName: ReportsMenuName.menuName,
    //                 })
    //             );
    //         }
    //     }
    // }, [allowedTabList, loggedInUserDetails]);

    const content = (
        <div className="user-profile-popover-content">
            <p>
                <div className="popover-content">
                    <Image
                        width={60}
                        height={60}
                        src={loggedInUserDetails?.profileImage}
                    />
                    <div>
                        {loggedInUserDetails?.firstName}{' '}
                        {loggedInUserDetails?.lastName}
                    </div>
                    <div>{loggedInUserDetails?.email}</div>
                </div>
                <Divider />
                <div>
                    <div>
                        {/* Note: here 1 refers to user profile tab, 2 to user preference and 3 to change password
            To be replaced with string in routes */}
                        <Link
                            to="/account-settings/user-profile"
                            className="profile-setting"
                        >
                            <UserOutlined /> {t('profileSetting.user-profile')}
                        </Link>
                    </div>
                    <div>
                        <Link
                            to="/account-settings/user-preferences"
                            className="profile-setting"
                        >
                            <SettingOutlined />{' '}
                            {t('profileSetting.user-preferences')}
                        </Link>
                    </div>
                    <div>
                        <Link
                            to="/account-settings/change-password"
                            className="profile-setting"
                        >
                            <UnlockOutlined />{' '}
                            {t('profileSetting.change-password')}
                        </Link>
                    </div>
                </div>
                <Divider />
            </p>
            <p className="signOut">
                <Button onClick={logouthandler}>
                    {t('profileSetting.signOut')}
                </Button>
            </p>
        </div>
    );
    // NOTE: Will need later
    // const [updatedMenuItem, setUpdatedMenuItem] = useState(menuItem());
    // useEffect(() => {
    //     if (loggedInUserDetails.admin) setUpdatedMenuItem(menuItem());
    // }, [localStorage.getItem('isAdmin'), loggedInUserDetails, allowedTabList]);

    const [updatedDashboardMenuList, setUpdatedDashboardMenuList] =
        useState<any>(menuItem());

    // NOTE: Memoize menuItem to prevent unnecessary re-renders
    const memoizedMenuItem = useMemo(() => {
        if (loggedInUserDetails.admin) {
            return menuData.length > 0 ? menuData : items;
        } else {
            const filteredItems = getFilteredItems(items, allowedTabList);
            return menuData.length > 0 ? menuData : filteredItems;
        }
    }, [loggedInUserDetails, menuData, items, allowedTabList]);

    function getFilteredItems(items: any, allowedTabList: any) {
        let filteredManualFormChildrenItems: any[] = [];
        const allowedItems = getObjectsById(
            items,
            findParentMenuIds(items, [...allowedTabList, UseCasesMenu.Home])
        );

        if (allowedTabList.includes('Manual Forms')) {
            const idx = allowedItems.findIndex(
                (item: any) => item.key === 'Manual Forms'
            );

            if (idx !== -1) {
                const formChildren = Array.isArray(forms)
                    ? forms?.map((form: any) => ({
                          id: form?._id,
                          name: form?.title,
                          breadcrumb: form?.title,
                          key: form?._id,
                          icon: <DeviceConnectionIcon />,
                          path: `/forms/${form?._id}`,
                          exact: true,
                          children: [],
                          disabled: [],
                          menuIds: form?.title,
                      }))
                    : [];

                const manualFormItem: any = items?.find(
                    (item: any): any => item?.key === 'Manual Forms'
                );

                if (manualFormItem?.children?.length) {
                    filteredManualFormChildrenItems =
                        manualFormItem?.children?.filter((item: any) => {
                            const idx = formChildren.findIndex(
                                (subItem: any) => subItem.key === item.key
                            );
                            return idx !== -1;
                        });
                }
                // Simply update the children of manualFormsMenu
                allowedItems[idx].children = [
                    ...(allowedItems[idx]?.children || []),
                    ...filteredManualFormChildrenItems,
                ];
            }
        }

        return allowedItems;
    }

    useEffect(() => {
        // NOTE: for non-admin user and having access of Dashboards menu
        if (allowedTabList.includes('Dashboards') && memoizedMenuItem) {
            setUpdatedDashboardMenuList([
                ...memoizedMenuItem.filter(
                    (item: any) => item.menuIds !== UseCasesMenu.Dashboards
                ),
                getReportingDashboards(items)?.[0],
            ]);
        } else {
            setUpdatedDashboardMenuList(memoizedMenuItem);
        }
    }, [allowedTabList, memoizedMenuItem, items]);

    return (
        <>
            <Layout className="layoutWrapper">
                <Sider
                    className={collapsed ? 'collapsed' : 'expand'}
                    theme="light"
                    collapsible
                    trigger={null}
                    collapsed={collapsed}
                    onCollapse={(value: boolean) => {
                        setCollapsed(value);
                    }}
                >
                    <Sidebar
                        collapsed={collapsed}
                        menu={
                            updatedDashboardMenuList
                            // NOTE: Will need later
                            // !loggedInUserDetails?.admin
                            //     ? updatedDashboardMenuList
                            //     : updatedMenuItem
                        }
                        changeSideBar={onCollapse}
                        setSearchValue={setSearchValue}
                        searchValue={searchValue}
                    />
                    <div className="empty-space"></div>
                    {/* 
                    // NOTE: Will need later
                    {!collapsed ? (
                        <div className="switch-module">
                            <img src={BraboFooterLogo} alt="icon" />
                        </div>
                    ) : (
                        ''
                    )} */}
                </Sider>
                <Layout className="site-layout">
                    <Header className="header">
                        <Breadcrumb
                            key={pathParams['*']}
                            separator=">"
                            routes={useBreadcrumb(paramsArray)}
                        />
                        <div className="header-setting-container">
                            <div className="braboLogo-container">
                                <img src={BraboFooterLogo} alt="icon" />
                            </div>
                            <Divider type="vertical" />
                            <div className="profile-container customer-container">
                                <div>
                                    <BellOutlined />
                                </div>
                                <Popover content={content}>
                                    {loggedInUserDetails?.profileImage ? (
                                        <img
                                            style={{ width: 30 }}
                                            src={
                                                loggedInUserDetails?.profileImage
                                            }
                                            alt="logo"
                                        />
                                    ) : (
                                        <UserOutlined />
                                    )}
                                </Popover>
                            </div>
                        </div>
                    </Header>
                    <Content className="site-layout-content">
                        <SideNavRoutes />
                    </Content>
                </Layout>
            </Layout>
        </>
    );
};

export default PageLayout;
