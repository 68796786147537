import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import clockImage from 'assets/icons/time.svg';
import {
    MULTI_AXIS_CHART_COLORS,
    tooltipDateTimeFormat,
} from 'utils/constants';
import { convertToBrowserTimezone } from 'utils/commonFunction';
import { useContext } from 'react';
import { ThemeContext } from 'components/Context/ThemeContext';

// Initialize HighchartsMore
HighchartsMore(Highcharts);

const MergedBoxPlot = ({
    chartSeriesData,
    yAxisSeriesData,
    chartRef = null,
    dateRange,
    key,
}: any): any => {
    const { theme } = useContext(ThemeContext);

    // Function to prepare box plot data
    const prepareBoxPlotData = (data: any[]): any => {
        if (!Array.isArray(data) || data.length === 0) return [];

        const values = data
            ?.map((point: any) => point[1])
            .filter((value) => value !== undefined)
            .sort((a, b) => a - b);

        if (values.length === 0) return [];

        const low = values[0];
        const q1Index = Math.floor(values.length * 0.25);
        const medianIndex = Math.floor(values.length * 0.5);
        const q3Index = Math.floor(values.length * 0.75);
        const q1 = values[q1Index];
        const median = values[medianIndex];
        const q3 = values[q3Index];
        const high = values[values.length - 1];

        const iqr = q3 - q1;
        const lowerBound = q1 - 1.5 * iqr;
        const upperBound = q3 + 1.5 * iqr;

        const outliers = values.filter(
            (value) => value < lowerBound || value > upperBound
        );

        return {
            low: Math.max(low, lowerBound),
            q1,
            median,
            q3,
            high: Math.min(high, upperBound),
            outliers,
        };
    };

    // Transform series data to box plot format
    const transformedSeriesData = chartSeriesData.map(
        (series: any, index: number) => {
            const boxPlotStats: any = series
                ? prepareBoxPlotData(series?.data)
                : null;
            return {
                name: series.name,
                type: 'boxplot',
                data: [
                    [
                        boxPlotStats.low,
                        boxPlotStats.q1,
                        boxPlotStats.median,
                        boxPlotStats.q3,
                        boxPlotStats.high,
                    ],
                ],
                outliers: boxPlotStats.outliers?.map((value: any) => ({
                    y: value,
                    x: 0,
                })),
                color: MULTI_AXIS_CHART_COLORS[
                    index % MULTI_AXIS_CHART_COLORS.length
                ],
                fillColor: `${
                    MULTI_AXIS_CHART_COLORS[
                        index % MULTI_AXIS_CHART_COLORS.length
                    ]
                }33`,
                // yAxis: series.yAxis || 0,
                showInLegend: true,
                whiskerLength: '50%',
            };
        }
    );

    const darkThemeOptions = {
        chart: {
            type: 'boxplot',
            backgroundColor: '#02071c',
            zoomType: 'x',
            resetZoomButton: {
                theme: {
                    fill: '#02071c',
                    style: {
                        color: '#FFFFFF',
                    },
                    states: {
                        hover: {
                            fill: '#02071c',
                            style: {
                                color: '#FFFFFF',
                            },
                        },
                    },
                },
            },
        },
        title: {
            text: '',
            style: {
                color: '#FFFFFF',
            },
        },
        legend: {
            itemStyle: {
                color: '#FFFFFF',
            },
        },
        xAxis: {
            min: -0.5,
            max: 0.5,
            labels: { enabled: false },
            lineWidth: 0,
            tickWidth: 0,
            categories: [],
        },
        yAxis: yAxisSeriesData.map((yAxis: any, index: number) => ({
            key: index,
            title: {
                text: yAxis?.title?.text,
                style: {
                    color: '#FFFFFF',
                },
            },
            labels: {
                style: {
                    color: '#FFFFFF',
                },
            },
        })),
        tooltip: {
            backgroundColor: '#02071c',
            style: {
                color: '#ffffff',
            },
            shared: true,
            useHTML: true,
            formatter: function (this: any) {
                let tooltip = `<div style="font-size: 10px;">`;

                // Add timestamp header
                const timestamp = convertToBrowserTimezone(this.x);
                tooltip += `<div style="display:flex; align-items:center;gap:2px">
                    <img src="${clockImage}" alt="Clock" style="margin-right: 5px; height: 15px; width: 15px;" />
                    <span>${Highcharts.dateFormat(
                        tooltipDateTimeFormat,
                        timestamp
                    )}</span>
                </div><br>`;

                // Add box plot data
                this.points.forEach((point: any) => {
                    const boxplotData = point.point;
                    tooltip += `<b style="color:${point.color}">${point.series.name}</b><br>`;
                    tooltip += `Maximum: ${boxplotData.high}<br>`;
                    tooltip += `Upper Quartile: ${boxplotData.q3}<br>`;
                    tooltip += `Median: ${boxplotData.median}<br>`;
                    tooltip += `Lower Quartile: ${boxplotData.q1}<br>`;
                    tooltip += `Minimum: ${boxplotData.low}<br>`;
                    if (boxplotData.outliers?.length > 0) {
                        tooltip += `Outliers: ${boxplotData.outliers.join(
                            ', '
                        )}<br>`;
                    }
                    tooltip += '<br>';
                });

                tooltip += '</div>';
                return tooltip;
            },
        },
        plotOptions: {
            boxplot: {
                fillColor: 'rgba(255, 255, 255, 0.1)',
                whiskerLength: '50%',
            },
        },
        series: transformedSeriesData,
    };

    const lightThemeOptions = {
        ...darkThemeOptions,
        chart: {
            type: 'boxplot',
            zoomType: 'xy',
            height: 300,
            backgroundColor: '#FFFFFF',
        },
        legend: {
            itemStyle: {
                color: '#000000',
            },
        },
        xAxis: {
            min: -0.5,
            max: 0.5,
            labels: { enabled: false },
            lineWidth: 0,
            tickWidth: 0,
            categories: [],
        },
        yAxis: yAxisSeriesData.map((yAxis: any, index: number) => ({
            key: index,
            title: {
                text: yAxis?.title?.text,
                style: {
                    color: '#000000',
                },
            },
            labels: {
                style: {
                    color: '#000000',
                },
            },
        })),
        tooltip: {
            backgroundColor: '#FFFFFF',
            style: {
                color: '#000000',
            },
            shared: true,
            useHTML: true,
            formatter: function (this: any) {
                let tooltip = `<div style="font-size: 10px;">`;

                // Add timestamp header
                const timestamp = convertToBrowserTimezone(this.x);
                tooltip += `<div style="display:flex; align-items:center;gap:2px">
                    <img src="${clockImage}" alt="Clock" style="margin-right: 5px; height: 15px; width: 15px;" />
                    <span>${Highcharts.dateFormat(
                        tooltipDateTimeFormat,
                        timestamp
                    )}</span>
                </div><br>`;

                // Add box plot data
                this.points.forEach((point: any) => {
                    const boxplotData = point.point;
                    tooltip += `<b style="color:${point.color}">${point.series.name}</b><br>`;
                    tooltip += `Maximum: ${boxplotData.high}<br>`;
                    tooltip += `Upper Quartile: ${boxplotData.q3}<br>`;
                    tooltip += `Median: ${boxplotData.median}<br>`;
                    tooltip += `Lower Quartile: ${boxplotData.q1}<br>`;
                    tooltip += `Minimum: ${boxplotData.low}<br>`;
                    if (boxplotData.outliers?.length > 0) {
                        tooltip += `Outliers: ${boxplotData.outliers.join(
                            ', '
                        )}<br>`;
                    }
                    tooltip += '<br>';
                });

                tooltip += '</div>';
                return tooltip;
            },
        },
        plotOptions: {
            boxplot: {
                fillColor: 'rgba(255, 255, 255, 0.1)',
                whiskerLength: '50%',
            },
        },
        series: transformedSeriesData,
    };

    return (
        <HighchartsReact
            key={`${key}-${yAxisSeriesData?.length}`}
            highcharts={Highcharts}
            options={theme === 'dark' ? darkThemeOptions : lightThemeOptions}
            containerProps={{ className: 'multiAxis' }}
            ref={chartRef}
        />
    );
};

export default MergedBoxPlot;
