import { EMPTY } from 'types/enums';
import initialState from '../initialStates';
import * as types from 'redux/types/businessIntegratorTypes';

export default function businessIntegratorReducer(
    state = initialState.businessIntegrator,
    action: any
): any {
    switch (action.type) {
        case types.SET_CONNECTIONS_STATE:
            state = {
                ...state,
                connections: {
                    ...state.connections,
                    connectionsState: action.payload,
                    isConnectionCreated: false,
                    isConnectionUpdated: false,
                    connectionById: {},
                    isConnectionStreamingOn: EMPTY.string,
                    isConnectionStreamingCode: EMPTY.string,
                    isConnectionStreamingMessage: EMPTY.string,
                    isConnectionUpdatedCode: EMPTY.string,
                    isConnectionUpdatedMessage: EMPTY.string,
                },
                mapping: {
                    ...state.mapping,
                    isMappingCreated: false,
                },
            };
            return state;
        case types.GET_ADAPTER_LIST:
            state = {
                ...state,
                connections: {
                    ...state.connections,
                    connectionsLoader: true,
                },
            };
            return state;
        case types.GET_ADAPTER_LIST_SUCCESS:
            state = {
                ...state,
                connections: {
                    ...state.connections,
                    connectionsLoader: false,
                    adapterList: action.response.data,
                },
            };
            return state;
        case types.GET_ADAPTER_LIST_FAILURE:
            state = {
                ...state,
                connections: {
                    ...state.connections,
                    connectionsLoader: false,
                },
            };
            return state;
        case types.GET_ALL_FILE_FORMATS:
            state = {
                ...state,
                connections: {
                    ...state.connections,
                    connectionsLoader: true,
                },
            };
            return state;
        case types.GET_ALL_FILE_FORMATS_SUCCESS:
            state = {
                ...state,
                connections: {
                    ...state.connections,
                    connectionsLoader: false,
                    allFileFormatsList: action.response.data,
                },
            };
            return state;
        case types.GET_ALL_FILE_FORMATS_FAILURE:
            state = {
                ...state,
                connections: {
                    ...state.connections,
                    connectionsLoader: false,
                },
            };
            return state;
        case types.GET_ALL_DIRECTIONS_LIST:
            state = {
                ...state,
                connections: {
                    ...state.connections,
                    connectionsLoader: true,
                },
            };
            return state;
        case types.GET_ALL_DIRECTIONS_LIST_SUCCESS:
            state = {
                ...state,
                connections: {
                    ...state.connections,
                    connectionsLoader: false,
                    allDirectionsList: action.response.data,
                },
            };
            return state;
        case types.GET_ALL_DIRECTIONS_LIST_FAILURE:
            state = {
                ...state,
                connections: {
                    ...state.connections,
                    connectionsLoader: false,
                },
            };
            return state;
        case types.GET_ALL_AUTH_METHOD_LIST:
            state = {
                ...state,
                connections: {
                    ...state.connections,
                    connectionsLoader: true,
                },
            };
            return state;
        case types.GET_ALL_AUTH_METHOD_LIST_SUCCESS:
            state = {
                ...state,
                connections: {
                    ...state.connections,
                    connectionsLoader: false,
                    allAuthMethodList: action.response.data,
                },
            };
            return state;
        case types.GET_ALL_AUTH_METHOD_LIST_FAILURE:
            state = {
                ...state,
                connections: {
                    ...state.connections,
                    connectionsLoader: false,
                },
            };
            return state;
        case types.CREATE_CONNECTIONS:
            state = {
                ...state,
                connections: {
                    ...state.connections,
                    isConnectionCreated: false,
                },
            };
            return state;
        case types.CREATE_CONNECTIONS_SUCCESS:
            state = {
                ...state,
                connections: {
                    ...state.connections,
                    isConnectionCreated: true,
                },
            };
            return state;
        case types.CREATE_CONNECTIONS_FAILURE:
            state = {
                ...state,
                connections: {
                    ...state.connections,
                    isConnectionCreated: false,
                },
            };
            return state;
        case types.GET_CONNECTIONS_LIST:
            state = {
                ...state,
                connections: {
                    ...state.connections,
                    connectionTableLoader: true,
                },
            };
            return state;
        case types.GET_CONNECTIONS_LIST_SUCCESS:
            state = {
                ...state,
                connections: {
                    ...state.connections,
                    connectionTableLoader: false,
                    connectionsList: action.response.data,
                },
            };
            return state;
        case types.GET_CONNECTIONS_LIST_FAILURE:
            state = {
                ...state,
                connections: {
                    ...state.connections,
                    connectionTableLoader: false,
                },
            };
            return state;
        case types.GET_CONNECTION_BY_ID:
            state = {
                ...state,
                connections: {
                    ...state.connections,
                    connectionByIdLoader: true,
                    connectionById: {},
                },
            };
            return state;
        case types.GET_CONNECTION_BY_ID_SUCCESS:
            state = {
                ...state,
                connections: {
                    ...state.connections,
                    connectionByIdLoader: false,
                    connectionById: action.response.data,
                },
            };
            return state;
        case types.GET_CONNECTION_BY_ID_FAILURE:
            state = {
                ...state,
                connections: {
                    ...state.connections,
                    connectionByIdLoader: false,
                },
            };
            return state;
        case types.UPDATE_CONNECTION:
            state = {
                ...state,
                connections: {
                    ...state.connections,
                    isConnectionUpdated: EMPTY.string,
                    isConnectionUpdatedCode: EMPTY.string,
                    isConnectionUpdatedMessage: EMPTY.string,
                },
            };
            return state;
        case types.UPDATE_CONNECTION_SUCCESS:
            state = {
                ...state,
                connections: {
                    ...state.connections,
                    isConnectionUpdated: true,
                },
            };
            return state;
        case types.UPDATE_CONNECTION_FAILURE:
            state = {
                ...state,
                connections: {
                    ...state.connections,
                    isConnectionUpdated: action?.response?.data?.code,
                    isConnectionUpdatedCode: action?.response?.data?.code,
                    isConnectionUpdatedMessage: action?.response?.data?.message,
                },
            };
            return state;
        case types.CONNECTION_STREAMING:
            state = {
                ...state,
                connections: {
                    ...state.connections,
                    isConnectionStreamingOn: EMPTY.string,
                    isConnectionStreamingDeactivate: EMPTY.string,
                    isConnectionStreamingCode: EMPTY.string,
                    isConnectionStreamingMessage: EMPTY.string,
                },
            };
            return state;
        case types.CONNECTION_STREAMING_SUCCESS:
            state = {
                ...state,
                connections: {
                    ...state.connections,
                    isConnectionStreamingOn: true,
                    isConnectionStreamingCode: EMPTY.string,
                    isConnectionStreamingMessage: EMPTY.string,
                },
            };
            return state;
        case types.CONNECTION_STREAMING_FAILURE:
            state = {
                ...state,
                connections: {
                    ...state.connections,
                    isConnectionStreamingOn: action?.response?.data?.code,
                    isConnectionStreamingCode:
                        action?.response?.data?.httpStatusCode,
                    isConnectionStreamingMessage:
                        action?.response?.data?.message,
                },
            };
            return state;
        case types.CLEAR_CONNECTION_STREAMING:
            state = {
                ...state,
                connections: {
                    ...state.connections,
                    isConnectionStreamingOn: EMPTY.string,
                    isConnectionStreamingDeactivate: EMPTY.string,
                    isConnectionUpdated: EMPTY.string,
                    isConnectionStreamingCode: EMPTY.string,
                    isConnectionStreamingMessage: EMPTY.string,
                },
            };
            return state;
        case types.CONNECTION_STREAMING_DEACTIVATE_SUCCESS:
            state = {
                ...state,
                connections: {
                    ...state.connections,
                    isConnectionStreamingDeactivate: true,
                },
            };
            return state;
        case types.SET_MAPPING_STATE:
            state = {
                ...state,
                connections: {
                    ...state.connections,
                    connectionById: {},
                },
                mapping: {
                    ...state.mapping,
                    mappingState: action.payload,
                    isMappingCreated: false,
                    mappingById: {},
                    isMappingUpdated: false,
                    isMappingExecuted: false,
                    isTestExecutuedSuccess: false,
                },
            };
            return state;
        case types.GET_MAPPING_JSON:
            state = {
                ...state,
                mapping: {
                    ...state.mapping,
                    jsonLoader: true,
                },
            };
            return state;
        case types.GET_MAPPING_JSON_SUCCESS:
            state = {
                ...state,
                mapping: {
                    ...state.mapping,
                    jsonLoader: false,
                    jsonData: action.response.data,
                },
            };
            return state;
        case types.GET_MAPPING_JSON_FAILURE:
            state = {
                ...state,
                mapping: {
                    ...state.mapping,
                    jsonLoader: false,
                },
            };
            return state;
        case types.EXECUTE_MAPPING:
            state = {
                ...state,
                mapping: {
                    ...state.mapping,
                    isMappingExecuted: false,
                },
            };
            return state;
        case types.EXECUTE_MAPPING_SUCCESS:
            state = {
                ...state,
                mapping: {
                    ...state.mapping,
                    isMappingExecuted: true,
                    isTestExecutuedSuccess: true,
                },
            };
            return state;
        case types.EXECUTE_MAPPING_FAILURE:
            state = {
                ...state,
                mapping: {
                    ...state.mapping,
                    jsonLoader: false,
                    isMappingExecuted: false,
                },
            };
            return state;
        case types.GET_BRABOTABLE_LIST:
            state = {
                ...state,
                mapping: {
                    ...state.mapping,
                    braboTableList: [],
                },
            };
            return state;
        case types.GET_BRABOTABLE_LIST_SUCCESS:
            state = {
                ...state,
                mapping: {
                    ...state.mapping,
                    braboTableList: action.response.data,
                },
            };
            return state;
        case types.GET_BRABOTABLE_LIST_FAILURE:
            state = {
                ...state,
                mapping: {
                    ...state.mapping,
                },
            };
            return state;
        case types.GET_COLUMN_DETAILS_BY_TABLE_ID:
            state = {
                ...state,
                mapping: {
                    ...state.mapping,
                    jsonLoader: true,
                    columnDetails: [],
                },
            };
            return state;
        case types.GET_COLUMN_DETAILS_BY_TABLE_ID_SUCCESS:
            state = {
                ...state,
                mapping: {
                    ...state.mapping,
                    jsonLoader: false,
                    columnDetails: action.response.data,
                },
            };
            return state;
        case types.GET_COLUMN_DETAILS_BY_TABLE_ID_FAILURE:
            state = {
                ...state,
                mapping: {
                    ...state.mapping,
                    jsonLoader: false,
                },
            };
            return state;
        case types.SET_MAPPING_FIELDS:
            state = {
                ...state,
                mapping: {
                    ...state.mapping,
                    mappingFields: action.payload,
                },
            };
            return state;
        case types.CREATE_MAPPING:
            state = {
                ...state,
                mapping: {
                    ...state.mapping,
                    isMappingCreated: false,
                },
            };
            return state;
        case types.CREATE_MAPPING_SUCCESS:
            state = {
                ...state,
                mapping: {
                    ...state.mapping,
                    mappingFields: [],
                    isMappingCreated: true,
                },
            };
            return state;
        case types.CREATE_MAPPING_FAILURE:
            state = {
                ...state,
                mapping: {
                    ...state.mapping,
                    isMappingCreated: false,
                },
            };
            return state;
        case types.SET_EXECUTED_SUCCESS:
            state = {
                ...state,
                mapping: {
                    ...state.mapping,
                    isTestExecutuedSuccess: action.payload,
                },
            };
            return state;
        case types.GET_MAPPING_BY_ID:
            state = {
                ...state,
                mapping: {
                    ...state.mapping,
                    mappingByIdLoader: true,
                    mappingById: {},
                },
            };
            return state;
        case types.GET_MAPPING_BY_ID_SUCCESS:
            state = {
                ...state,
                mapping: {
                    ...state.mapping,
                    mappingByIdLoader: false,
                    mappingById: action.response.data,
                },
            };
            return state;
        case types.GET_MAPPING_BY_ID_FAILURE:
            state = {
                ...state,
                mapping: {
                    ...state.mapping,
                    mappingByIdLoader: false,
                    mappingById: {},
                },
            };
            return state;
        case types.GET_MAPPING_LIST:
            state = {
                ...state,
                mapping: {
                    ...state.mapping,
                    mappingTableLoader: true,
                    isMappingDeleted: false,
                    isMappingActivated: false,
                },
            };
            return state;
        case types.GET_MAPPING_LIST_SUCCESS:
            state = {
                ...state,
                mapping: {
                    ...state.mapping,
                    mappingTableLoader: false,
                    mappingTableList: action.response.data,
                    isMappingDeleted: false,
                    isMappingActivated: false,
                },
            };
            return state;
        case types.GET_MAPPING_LIST_FAILURE:
            state = {
                ...state,
                mapping: {
                    ...state.mapping,
                    mappingTableLoader: false,
                    isMappingDeleted: false,
                    isMappingActivated: false,
                },
            };
            return state;
        case types.GET_ALL_MAPPING_SUCCESS:
            state = {
                ...state,
                mapping: {
                    ...state.mapping,
                    allMappingList:
                        action.response.data?.paginatedResponse?.records,
                },
            };
            return state;
        case types.GET_LABELS_DATA_SUCCESS:
            state = {
                ...state,
                mapping: {
                    ...state.mapping,
                    labelsList: action.response.data,
                },
            };
            return state;
        case types.UPDATE_MAPPING:
            state = {
                ...state,
                mapping: {
                    ...state.mapping,
                    isMappingUpdated: false,
                },
            };
            return state;
        case types.UPDATE_MAPPING_SUCCESS:
            state = {
                ...state,
                mapping: {
                    ...state.mapping,
                    mappingFields: [],
                    isMappingUpdated: true,
                },
            };
            return state;
        case types.UPDATE_MAPPING_FAILURE:
            state = {
                ...state,
                mapping: {
                    ...state.mapping,
                    isMappingUpdated: false,
                },
            };
            return state;
        case types.MAPPING_DELETED_SUCCESS:
            state = {
                ...state,
                mapping: {
                    ...state.mapping,
                    isMappingDeleted: true,
                },
            };
            return state;
        case types.MAPPING_ACTIVATED_SUCCESS:
            state = {
                ...state,
                mapping: {
                    ...state.mapping,
                    isMappingActivated: true,
                },
            };
            return state;
        case types.GET_LABELS_LIST_SUCCESS:
            state = {
                ...state,
                monitoring: {
                    ...state.monitoring,
                    labelsList: action.response.data,
                },
            };
            return state;
        case types.GET_MONITORING_LIST:
            state = {
                ...state,
                monitoring: {
                    ...state.monitoring,
                    monitoringTableLoader: true,
                },
            };
            return state;
        case types.GET_MONITORING_LIST_SUCCESS:
            state = {
                ...state,
                monitoring: {
                    ...state.monitoring,
                    monitoringListData: action.response.data,
                    monitoringTableLoader: false,
                },
            };
            return state;
        case types.GET_MONITORING_LIST_FAILURE:
            state = {
                ...state,
                monitoring: {
                    ...state.monitoring,
                    monitoringTableLoader: false,
                },
            };
            return state;
        case types.SET_MOINITORING_STATE:
            state = {
                ...state,
                monitoring: {
                    ...state.monitoring,
                    monitoringState: action.payload,
                },
            };
            return state;
        case types.GET_CREATEDBY_LIST_SUCCESS:
            state = {
                ...state,
                monitoring: {
                    ...state.monitoring,
                    createdByList: action.response.data,
                },
            };
            return state;
        case types.GET_ALL_MONITORING_LIST_SUCCESS:
            state = {
                ...state,
                monitoring: {
                    ...state.monitoring,
                    allMonitoringList: action.response.data,
                },
            };
            return state;
        case types.GET_MONITORING_BY_ID_SUCCESS:
            state = {
                ...state,
                monitoring: {
                    ...state.monitoring,
                    monitoringById: action.response.data,
                },
            };
            return state;
        case types.REPROCESS_MONITORING_SUCCESS:
            state = {
                ...state,
                monitoring: {
                    ...state.monitoring,
                    reprocessSuccess: true,
                },
            };
            return state;
        case types.GET_OPERATION_DETAILS_BY_ID_SUCCESS:
            state = {
                ...state,
                operation: {
                    ...state.operation,
                    operationDetails: action.response.data,
                },
            };
            return state;
        case types.SET_OPERATION_STATE:
            state = {
                ...state,
                operation: {
                    ...state.operation,
                    operationState: action.payload,
                },
            };
            return state;
        case types.CREATE_OPERATION:
            state = {
                ...state,
                operation: {
                    ...state.operation,
                    isOperationCreated: false,
                },
            };
            return state;
        case types.CREATE_OPERATION_SUCCESS:
            state = {
                ...state,
                operation: {
                    ...state.operation,
                    isOperationCreated: true,
                },
            };
            return state;
        case types.CREATE_OPERATION_FAILURE:
            state = {
                ...state,
                operation: {
                    ...state.operation,
                    isOperationCreated: false,
                },
            };
            return state;
        case types.CREATE_OUTBOUND_OPERATION:
            state = {
                ...state,
                operation: {
                    ...state.operation,
                    isOutboundOperationCreated: false,
                },
            };
            return state;
        case types.CREATE_OUTBOUND_OPERATION_SUCCESS:
            state = {
                ...state,
                operation: {
                    ...state.operation,
                    isOutboundOperationCreated: true,
                    isOperationCreated:true
                },
            };
            return state;
        case types.CREATE_OUTBOUND_OPERATION_FAILURE:
            state = {
                ...state,
                operation: {
                    ...state.operation,
                    isOutboundOperationCreated: false,
                    isOperationCreated:false
                },
            };
            return state;
        case types.RESET_OPERATION_STATES:
            state = {
                ...state,
                operation: {
                    ...state.operation,
                    isOperationCreated: false,
                    isOperationUpdated: false,
                    operationDetails: {},
                    connectionValidated: false,
                },
            };
            return state;
        case types.DELETE_OPERATION_SUCCESS:
            state = {
                ...state,
                operation: {
                    ...state.operation,
                    isOperationDeleted: true,
                },
            };
            return state;
        case types.DELETE_OPERATION_FAILURE:
            state = {
                ...state,
                operation: {
                    ...state.operation,
                    isOperationDeleted: false,
                },
            };
            return state;
        case types.ACTIVATE_DEACTIVATE_OPERATION_SUCCESS:
            state = {
                ...state,
                operation: {
                    ...state.operation,
                    isStatusChanged: true,
                },
            };
            return state;
        case types.ACTIVATE_DEACTIVATE_OPERATION_FAILURE:
            state = {
                ...state,
                operation: {
                    ...state.operation,
                    isStatusChanged: false,
                },
            };
            return state;
        case types.UPDATE_OPERATION_SUCCESS:
            state = {
                ...state,
                operation: {
                    ...state.operation,
                    isOperationUpdated: true,
                },
            };
            return state;
        case types.UPDATE_OPERATION_FAILURE:
            state = {
                ...state,
                operation: {
                    ...state.operation,
                    isOperationUpdated: false,
                },
            };
            return state;
        case types.GET_OPERATION_LIST:
            state = {
                ...state,
                operation: {
                    ...state.operation,
                    operationTableLoader: true,
                    isStatusChanged: false,
                    isOperationDeleted: false,
                },
            };
            return state;
        case types.GET_OPERATION_LIST_SUCCESS:
            state = {
                ...state,
                operation: {
                    ...state.operation,
                    operationTableLoader: false,
                    operationTableList: action.response.data,
                    isStatusChanged: false,
                    isOperationDeleted: false,
                },
            };
            return state;
        case types.GET_OPERATION_LIST_FAILURE:
            state = {
                ...state,
                operation: {
                    ...state.operation,
                    operationTableLoader: false,
                    isStatusChanged: false,
                    isOperationDeleted: false,
                },
            };
            return state;
        case types.GET_ALL_OPERATION_SUCCESS:
            state = {
                ...state,
                operation: {
                    ...state.operation,
                    allOperationList:
                        action.response.data?.paginatedResponse?.records,
                },
            };
            return state;
        case types.GET_DAYS_LIST_SUCCESS:
            state = {
                ...state,
                operation: {
                    ...state.operation,
                    daysList: action.response.data,
                },
            };
            return state;
        case types.CONNECTION_VALIDATE_SUCCESS:
            state = {
                ...state,
                operation: {
                    ...state.operation,
                    connectionValidated: true,
                },
            };
            return state;
        case types.CONNECTION_VALIDATE_FAILURE:
            state = {
                ...state,
                operation: {
                    ...state.operation,
                    connectionValidated: false,
                },
            };
            return state;
        case types.RESET_CONNECTION_VALIDATE:
            state = {
                ...state,
                operation: {
                    ...state.operation,
                    connectionValidated: false,
                },
            };
            return state;
        case types.SYNC_OPERATION_SUCCESS:
            state = {
                ...state,
                operation: {
                    ...state.operation,
                    operationSynced: true,
                },
            };
            return state;
        case types.SYNC_OPERATION_FAILURE:
            state = {
                ...state,
                operation: {
                    ...state.operation,
                    operationSynced: false,
                },
            };
            return state;
        default:
            return state;
    }
}
