import { type ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, Divider, Form, Modal, Row, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { epoch } from 'utils/commonFunction';
import { ReactComponent as BackIcon } from 'assets/icons/backIcon.svg';
import './index.scss';
import {
    clrearIsConnectionStreaming,
    createConnections,
    getConnectionById,
    setConnectionsState,
    updateConnection,
} from 'redux/actions/BusinessIntegratorActions/connectionsActions';
import {
    ConnectionsState,
    CreateConnectionScreen,
} from 'types/enums/businessIntegratorEnum';
import CustomButton from 'components/common/CustomButton';
import AdapterListing from './adapterListing';
import AdapterDetailsForm from '../AdapterDetailsForm';
import ConfirmationModal from 'components/common/Modals/ConfirmationModal';
import { ReactComponent as ConfirmationIcon } from 'assets/icons/confirmationIcon.svg';
import { cancelHandle, modalShow } from 'utils/modalFunction';
import SuccessfulModal from 'components/common/Modals/SuccessfulModal';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ErrorIcon } from 'assets/icons/errorIcon.svg';
import { AuthMethod } from 'types/enums';

const AddConnections: React.FC = (): any => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const { currentTab, paramAdapterId } = useParams();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [nextClicked, setNextClicked] = useState<number>(0);
    const [isSaveDisable, setIsSaveDisable] = useState(true);
    const [onConnectionCreateModalOpen, setOnConnectionCreateModalOpen] =
        useState(false);
    const [
        onConnectionCreateSuccessfullModalOpen,
        setOnConnectionCreateSuccessfullModalOpen,
    ] = useState(false);
    const [
        onConnectionSaveActivateErrorOpen,
        setOnConnectionSaveActivateErrorOpen,
    ] = useState(false);

    const adapterSelected = Form.useWatch('adapterType', form);
    const connectionName = Form.useWatch('connectionName', form);
    const fileFormat = Form.useWatch('fileFormat', form);
    const direction = Form.useWatch('direction', form);
    const authenticationMethod = Form.useWatch('authenticationMethod', form);
    const url = Form.useWatch('url', form);
    const label = Form.useWatch('label', form);
    const userName = Form.useWatch('userName', form);
    const password = Form.useWatch('password', form);
    const confirmPassword = Form.useWatch('confirmPassword', form);
    const description = Form.useWatch('description', form);

    const tokenUrl = Form.useWatch('tokenUrl', form);
    const clientId = Form.useWatch('clientId', form);
    const clientSecret = Form.useWatch('clientSecret', form);
    const customHeaders = Form.useWatch('customHeaders', form);
    const apiKeyValue = Form.useWatch('apiKeyValue', form);
    const jwtToken = Form.useWatch('jwtToken', form);
    const connectionTimeout = Form.useWatch('connectionTimeout', form);
    const retryCount = Form.useWatch('retryCount', form);
    const retryDelay = Form.useWatch('retryDelay', form);

    const connectionsState = useSelector(
        (state: any) => state.businessIntegrator.connections.connectionsState
    );
    const loggedInUserDetails = useSelector(
        (state: any) => state.userManagement.users?.loggedInUserDetails
    );

    const isConnectionCreated = useSelector(
        (state: any) => state.businessIntegrator.connections.isConnectionCreated
    );
    const isConnectionUpdated = useSelector(
        (state: any) => state.businessIntegrator.connections.isConnectionUpdated
    );
    const connectionByIdLoader = useSelector(
        (state: any) =>
            state.businessIntegrator.connections.connectionByIdLoader
    );
    const connectionById = useSelector(
        (state: any) => state.businessIntegrator.connections.connectionById
    );

    const isConnectionUpdatedCode = useSelector(
        (state: any) =>
            state.businessIntegrator.connections.isConnectionUpdatedCode
    );

    const isConnectionUpdatedMessage = useSelector(
        (state: any) =>
            state.businessIntegrator.connections.isConnectionUpdatedMessage
    );

    useEffect(() => {
        connectionsState === ConnectionsState.viewConnections &&
            setNextClicked(1);
        connectionsState === ConnectionsState.editConnection &&
            setNextClicked(1);
        connectionsState === ConnectionsState.copyConnections &&
            setNextClicked(1);
    }, [connectionsState]);

    let component: ReactNode = <AdapterListing form={form} />;

    switch (nextClicked) {
        case 1:
            component = <AdapterDetailsForm form={form} />;
            break;

        default:
            component = <AdapterListing form={form} />;
            break;
    }

    useEffect(() => {
        const authMethod = Object.keys(connectionById)?.length
            ? authenticationMethod?.toLowerCase()
            : authenticationMethod?.split(':')[1]?.toLowerCase();

        const requiredFields: any = {
            [AuthMethod.BASIC]: [
                connectionName,
                adapterSelected,
                fileFormat,
                url,
                userName,
                password,
                confirmPassword,
                direction,
                connectionTimeout,
                retryCount,
                retryDelay,
            ],
            [AuthMethod.OAUTH_PASSWORD]: [
                connectionName,
                adapterSelected,
                fileFormat,
                tokenUrl,
                userName,
                password,
                confirmPassword,
                connectionTimeout,
                retryCount,
                retryDelay,
                direction,
            ],
            [AuthMethod.OAUTH__CLIENT_CREDENTIALS]: [
                connectionName,
                adapterSelected,
                fileFormat,
                tokenUrl,
                clientId,
                clientSecret,
                connectionTimeout,
                retryCount,
                retryDelay,
                direction,
            ],
            [AuthMethod.CUSTOM_HEADER_AUTHENTICATION]: [
                connectionName,
                adapterSelected,
                fileFormat,
                url,
                customHeaders,
                connectionTimeout,
                retryCount,
                retryDelay,
                direction,
            ],
            [AuthMethod.API_KEY_AUTHENTICATION]: [
                connectionName,
                adapterSelected,
                fileFormat,
                url,
                apiKeyValue,
                connectionTimeout,
                retryCount,
                retryDelay,
                direction,
            ],
            [AuthMethod.JWT_TOKEN_AUTHENTIZATION]: [
                connectionName,
                adapterSelected,
                fileFormat,
                url,
                jwtToken,
                connectionTimeout,
                retryCount,
                retryDelay,
                direction,
            ],
        };

        if (requiredFields[authMethod]) {
            const allFieldsFilled = requiredFields[authMethod].every(Boolean);
            setIsSaveDisable(!allFieldsFilled);
        }
    }, [
        authenticationMethod,
        connectionName,
        adapterSelected,
        fileFormat,
        url,
        userName,
        password,
        confirmPassword,
        clientId,
        clientSecret,
        direction,
        customHeaders,
        apiKeyValue,
        jwtToken,
        connectionTimeout,
        retryCount,
        retryDelay,
    ]);

    const onConnectionCreation = (): any => {
        modalShow(onConnectionCreateModalOpen, setOnConnectionCreateModalOpen);
    };

    const onConnectionSaveAndActivateHandler = (): any => {
        dispatch(
            updateConnection({
                updatedBy:
                    loggedInUserDetails.firstName +
                    loggedInUserDetails.lastName,
                updatedOn: epoch(new Date()),
                name: connectionName,
                id: paramAdapterId,
                adapterId: adapterSelected,
                password: password,
                userName: userName,
                url: url,
                authMethodId: connectionById?.authMethod?.id,
                directionId: direction,
                fileFormatId: fileFormat,
                confirmPassword: confirmPassword,
                description: description,
                labels: label,
                isActive: true,
                isDeleted: false,
                activateConnection: true,
                tokenUrl: tokenUrl,
                clientId: clientId,
                clientSecret: clientSecret,
                customHeaders: customHeaders,
                apiKeyValue: apiKeyValue,
                jwtToken: jwtToken,
                connectionTimeout: connectionTimeout,
                retryCount: retryCount,
                retryDelay: retryDelay,
            })
        );
    };
    const onOkHandler = (): any => {
        if (connectionsState === ConnectionsState.editConnection) {
            dispatch(
                updateConnection({
                    updatedBy:
                        loggedInUserDetails.firstName +
                        loggedInUserDetails.lastName,
                    updatedOn: epoch(new Date()),
                    name: connectionName,
                    id: paramAdapterId,
                    adapterId: adapterSelected,
                    password: password,
                    userName: userName,
                    url: url,
                    authMethodId: connectionById?.authMethod?.id,
                    directionId: direction,
                    fileFormatId: fileFormat,
                    confirmPassword: confirmPassword,
                    description: description,
                    labels: label,
                    isActive: true,
                    isDeleted: false,
                    activateConnection: false,
                    tokenUrl: tokenUrl,
                    clientId: clientId,
                    clientSecret: clientSecret,
                    customHeaders: customHeaders,
                    apiKeyValue: apiKeyValue,
                    jwtToken: jwtToken,
                    connectionTimeout: connectionTimeout,
                    retryCount: retryCount,
                    retryDelay: retryDelay,
                })
            );
        } else {
            dispatch(
                createConnections({
                    createdBy:
                        loggedInUserDetails.firstName +
                        loggedInUserDetails.lastName,
                    createdOn: epoch(new Date()),
                    updatedBy:
                        loggedInUserDetails.firstName +
                        loggedInUserDetails.lastName,
                    updatedOn: epoch(new Date()),
                    name: connectionName,
                    adapterId: adapterSelected,
                    password: password,
                    userName: userName,
                    url: url,
                    authMethodId: authenticationMethod.split(':')[0],
                    directionId: direction,
                    fileFormatId: fileFormat,
                    confirmPassword: confirmPassword,
                    description: description,
                    labels: label,
                    isActive: true,
                    isDeleted: false,
                    tokenUrl: tokenUrl,
                    clientId: clientId,
                    clientSecret: clientSecret,
                    customHeaders: customHeaders,
                    apiKeyValue: apiKeyValue,
                    jwtToken: jwtToken,
                    connectionTimeout: connectionTimeout,
                    retryCount: retryCount,
                    retryDelay: retryDelay,
                })
            );
        }
        cancelHandle(
            onConnectionCreateModalOpen,
            setOnConnectionCreateModalOpen
        );
    };

    useEffect(() => {
        if (isConnectionCreated || isConnectionUpdated === true) {
            modalShow(
                onConnectionCreateSuccessfullModalOpen,
                setOnConnectionCreateSuccessfullModalOpen
            );
        }
        if (isConnectionUpdated === 100) {
            modalShow(
                onConnectionSaveActivateErrorOpen,
                setOnConnectionSaveActivateErrorOpen
            );
            dispatch(clrearIsConnectionStreaming());
        }
    }, [isConnectionCreated, isConnectionUpdated]);

    const [onAdapterFormCancelClick, setOnAdapterFormCancelClick] =
        useState(false);
    const [onAdapterListingCancelClick, setOnAdapterListingCancelClick] =
        useState(false);

    const onCancelClick = (): any => {
        if (nextClicked === CreateConnectionScreen.adapterForm) {
            modalShow(onAdapterFormCancelClick, setOnAdapterFormCancelClick);
        } else {
            if (adapterSelected) {
                modalShow(
                    onAdapterListingCancelClick,
                    setOnAdapterListingCancelClick
                );
            } else {
                dispatch(setConnectionsState(ConnectionsState.tableView));
            }
        }
    };

    const onBackClickHandler = (): any => {
        if (nextClicked === CreateConnectionScreen.adapterForm) {
            setNextClicked(0);
            navigate(`/connect/business-integrator/${currentTab}/add`);
        } else {
            onCancelClick();
        }
    };

    const onSuccesfulModalCancelHanlder = (): any => {
        dispatch(setConnectionsState(ConnectionsState.tableView));
        cancelHandle(
            onConnectionCreateSuccessfullModalOpen,
            setOnConnectionCreateSuccessfullModalOpen
        );
    };
    useEffect(() => {
        if (paramAdapterId) {
            setNextClicked(1);
        }
    }, [paramAdapterId]);
    useEffect(() => {
        if (connectionsState === ConnectionsState.addConnections) {
            if (paramAdapterId) {
                form.setFieldsValue({ adapterType: adapterSelected });
            }
        }
    }, [paramAdapterId, form, connectionsState]);
    const onCancelOkHandler = (): any => {
        if (
            connectionsState === ConnectionsState.editConnection ||
            connectionsState === ConnectionsState.copyConnections
        ) {
            cancelHandle(onAdapterFormCancelClick, setOnAdapterFormCancelClick);
            dispatch(setConnectionsState(ConnectionsState.tableView));
        } else {
            form.setFieldsValue({ adapterType: null });
            setNextClicked(0);
            cancelHandle(onAdapterFormCancelClick, setOnAdapterFormCancelClick);
            navigate(`/connect/business-integrator/${currentTab}/add`);
        }
    };
    const onAdapterListingCancelOkHandler = (): any => {
        cancelHandle(
            onAdapterListingCancelClick,
            setOnAdapterListingCancelClick
        );
        dispatch(setConnectionsState(ConnectionsState.tableView));
    };

    const getTitle = (): any => {
        if (connectionsState === ConnectionsState.viewConnections) {
            return t(
                'BusinessIntegrator.connections.connectionAdapterDetails.viewAdapter'
            );
        } else if (connectionsState === ConnectionsState.editConnection) {
            return t(
                'BusinessIntegrator.connections.connectionAdapterDetails.editAdapter'
            );
        } else if (nextClicked) {
            return t(
                'BusinessIntegrator.connections.connectionAdapterDetails.addAdapter'
            );
        } else {
            return t(
                'BusinessIntegrator.connections.connectionAdapterDetails.adapter'
            );
        }
    };
    useEffect(() => {
        if (connectionsState !== ConnectionsState.addConnections)
            paramAdapterId && dispatch(getConnectionById(paramAdapterId));
    }, [paramAdapterId, dispatch, connectionsState]);
    useEffect(() => {
        if (connectionById) {
            form.setFieldsValue({
                adapterType: adapterSelected || connectionById?.adapter?.id,
                connectionName: connectionById?.name,
                userName: connectionById?.username,
                url: connectionById?.url,
                authenticationMethod: connectionById?.authMethod?.name,
                direction: connectionById?.direction?.id,
                fileFormat: connectionById?.fileFormat?.id,
                description: connectionById?.description,
                confirmPassword: connectionById?.password,
                password: connectionById?.password,
                tokenUrl: connectionById?.tokenUrl,
                clientId: connectionById?.clientId,
                clientSecret: connectionById?.clientSecret,
                apiKeyValue: connectionById?.apiKeyValue,
                jwtToken: connectionById?.jwtToken,
                connectionTimeout: connectionById?.connectionTimeout,
                retryCount: connectionById?.retryCount,
                retryDelay: connectionById?.retryDelay,
            });
        }
    }, [connectionById, form, adapterSelected]);
    return (
        <>
            <div className="attributeWrapper adapterList">
                {connectionByIdLoader ? (
                    <div className="view__loader">
                        <Spin />
                    </div>
                ) : (
                    <Card bordered={false}>
                        <Row className="adapterList__header">
                            <Col className="adapterList__backIcon" span={0.5}>
                                <BackIcon
                                    onClick={() => {
                                        if (
                                            connectionsState ===
                                                ConnectionsState.viewConnections ||
                                            connectionsState ===
                                                ConnectionsState.editConnection ||
                                            connectionsState ===
                                                ConnectionsState.copyConnections
                                        ) {
                                            dispatch(
                                                setConnectionsState(
                                                    ConnectionsState.tableView
                                                )
                                            );
                                        } else {
                                            onBackClickHandler();
                                        }
                                    }}
                                />
                            </Col>
                            <Col
                                className="adapterList__title fw-400 fs-16"
                                span={22}
                            >
                                {getTitle()}
                            </Col>
                            {connectionsState ===
                                ConnectionsState.viewConnections &&
                                !connectionById.streamingOn && (
                                    <Col span={1}>
                                        <Button
                                            type="primary"
                                            ghost
                                            className="addNewButton"
                                            onClick={() => {
                                                dispatch(
                                                    setConnectionsState(
                                                        ConnectionsState.editConnection
                                                    )
                                                );
                                            }}
                                        >
                                            {t('BusinessIntegrator.edit')}
                                        </Button>
                                    </Col>
                                )}
                        </Row>
                        <Form
                            form={form}
                            layout="vertical"
                            disabled={
                                connectionsState ===
                                ConnectionsState.viewConnections
                            }
                        >
                            <div
                                className={
                                    connectionsState ===
                                    ConnectionsState.viewConnections
                                        ? 'adapterList__viewContent'
                                        : 'adapterList__content'
                                }
                            >
                                {component}
                            </div>

                            {connectionsState !==
                                ConnectionsState.viewConnections && (
                                <div className="adapterList__footer">
                                    <div className="adapterList__buttons">
                                        <CustomButton
                                            type={'Cancel'}
                                            disabled={false}
                                            handleClick={() => {
                                                onCancelClick();
                                            }}
                                        />
                                        {nextClicked ===
                                        CreateConnectionScreen.adapterForm ? (
                                            <>
                                                <CustomButton
                                                    type={'Save'}
                                                    disabled={isSaveDisable}
                                                    handleClick={() => {
                                                        onConnectionCreation();
                                                    }}
                                                />
                                                {connectionsState ===
                                                    ConnectionsState.editConnection && (
                                                    <CustomButton
                                                        type={'Save & Activate'}
                                                        disabled={isSaveDisable}
                                                        handleClick={() => {
                                                            onConnectionSaveAndActivateHandler();
                                                        }}
                                                    />
                                                )}
                                            </>
                                        ) : (
                                            <CustomButton
                                                type={'Next'}
                                                disabled={
                                                    !adapterSelected && true
                                                }
                                                handleClick={() => {
                                                    setNextClicked(1);
                                                    navigate(
                                                        `/connect/business-integrator/connections/add/${adapterSelected}`
                                                    );
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            )}
                        </Form>
                    </Card>
                )}
            </div>
            <ConfirmationModal
                open={onConnectionCreateModalOpen}
                onCancel={() => {
                    cancelHandle(
                        onConnectionCreateModalOpen,
                        setOnConnectionCreateModalOpen
                    );
                }}
                onOk={() => onOkHandler()}
                icon={<ConfirmationIcon />}
                text={'Would you like to save the connection?'}
            />
            <SuccessfulModal
                open={onConnectionCreateSuccessfullModalOpen}
                onOk={() => {}}
                onCancel={() => {
                    onSuccesfulModalCancelHanlder();
                }}
                text={`Connection ${
                    isConnectionUpdated ? 'Updated' : 'Saved'
                } Successfully`}
            />
            <ConfirmationModal
                open={onAdapterFormCancelClick}
                onCancel={() => {
                    cancelHandle(
                        onAdapterFormCancelClick,
                        setOnAdapterFormCancelClick
                    );
                }}
                onOk={() => onCancelOkHandler()}
                icon={<ConfirmationIcon />}
                text={'Are you sure you want to Cancel this?'}
            />
            <ConfirmationModal
                open={onAdapterListingCancelClick}
                onCancel={() => {
                    cancelHandle(
                        onAdapterListingCancelClick,
                        setOnAdapterListingCancelClick
                    );
                }}
                onOk={() => onAdapterListingCancelOkHandler()}
                icon={<ConfirmationIcon />}
                text={'Are you sure you want to Cancel this?'}
            />
            <Modal
                className="validateTagModal validateModal confirmationModal connections"
                open={onConnectionSaveActivateErrorOpen}
                title={'Test Result Area'}
                destroyOnClose={true}
                onOk={() => {}}
                onCancel={() =>
                    cancelHandle(
                        onConnectionSaveActivateErrorOpen,
                        setOnConnectionSaveActivateErrorOpen
                    )
                }
                centered
                footer={[
                    <Button
                        key="ok"
                        type="primary"
                        className="okBtn fw-400 fs-14"
                        onClick={() => {
                            cancelHandle(
                                onConnectionSaveActivateErrorOpen,
                                setOnConnectionSaveActivateErrorOpen
                            );
                            modalShow(
                                onConnectionCreateModalOpen,
                                setOnConnectionCreateModalOpen
                            );
                        }}
                    >
                        Ok
                    </Button>,
                ]}
            >
                <ErrorIcon />
                <div className="error-message">
                    Error Code:{isConnectionUpdatedCode}
                </div>
                <div>
                    <div className="validatedModalTextError fw-400 fs-16">
                        {isConnectionUpdatedMessage}
                    </div>
                    <Divider className="divider" />
                </div>
            </Modal>
        </>
    );
};

export default AddConnections;
