import {
    Button,
    Col,
    Divider,
    Dropdown,
    Input,
    Menu,
    message,
    Row,
    Select,
    Spin,
} from 'antd';
import { PlusOutlined, DownOutlined } from '@ant-design/icons';
import './index.scss';
import { useEffect, useState } from 'react';
import ConfirmationModal from 'components/common/Modals/ConfirmationModal';
import { ReactComponent as QuestionMarkIcon } from 'assets/icons/questionMark.svg';
import { useDispatch, useSelector } from 'react-redux';
import { validateTable } from 'redux/actions/ImplementationActions/tableActions';
import { parseJwt } from 'utils/jwtTokenFunction';
import { RESET_VALIDATE_TABLE_STATUS } from 'redux/types/implementationTypes';
import { useTranslation } from 'react-i18next';
import DynamicTable from './DynamicTable';
import AddRowForm from './AddRowForm';
import { ReactComponent as CSVIcon } from 'assets/icons/csvIcon.svg';
import CustomPagination from 'components/common/CustomPagination';
import { PAGE, PAGE_SIZE } from 'utils/constants';
import {
    exportCsv,
    getTableData,
    importCsvData,
} from 'redux/actions/ConfigureActions/tableAction';
import CustomButton from 'components/common/CustomButton';
import { BUTTONTYPE, tableComparisonOperator } from 'types/enums';
const { Option } = Select;
const TableData: React.FC<any> = () => {
    const { t } = useTranslation('translation');
    const dispatch = useDispatch();
    const details = parseJwt();
    const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [rowId, setRowId] = useState('');
    const [openEditForm, setOpenEditForm] = useState(false);
    const [page, setPage] = useState<number>(PAGE);
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const [sortOrder, setSortOrder] = useState<number | null>(null);
    const [sortColumn, setSortColumn] = useState<number | null>(null);
    const tableDetails = useSelector(
        (state: any) => state.configure?.table?.tableDetails
    );
    const tableData = useSelector(
        (state: any) => state?.configure?.table?.tableData
    );
    const tableListLoading = useSelector(
        (state: any) => state?.configure?.table?.tableListLoading
    );

    const selectedAsset = useSelector(
        (state: any) => state?.implementation?.attribute?.selectedAsset
    );
    const comparisonOperators = [
        {
            label: 'EQUALS',
            value: tableComparisonOperator.EQUALS,
        },
        {
            label: 'NOT_EQUALS',
            value: tableComparisonOperator.NOT_EQUALS,
        },
        {
            label: 'GREATER_THAN',
            value: tableComparisonOperator.GREATER_THAN,
        },
        {
            label: 'LESS_THAN',
            value: tableComparisonOperator.LESS_THAN,
        },
        {
            label: 'GREATER_THAN_EQUALS',
            value: tableComparisonOperator.GREATER_THAN_EQUALS,
        },
        {
            label: 'LESS_THAN_EQUALS',
            value: tableComparisonOperator.LESS_THAN_EQUALS,
        },
        {
            label: 'IN',
            value: tableComparisonOperator.IN,
        },
        {
            label: 'NOT_IN',
            value: tableComparisonOperator.NOT_IN,
        },
        {
            label: 'REGEX',
            value: tableComparisonOperator.REGEX,
        },
    ];

    useEffect(() => {
        dispatch(
            getTableData({
                tableName: tableDetails?.name,
                page: page - 1,
                size: pageSize,
                sortBy:sortColumn,
                sortDirection:sortOrder
            })
        );
    }, [page, pageSize, sortColumn, sortOrder]);
    const tableValidated = useSelector(
        (state: any) => state?.implementation?.table?.tableValidated
    );
    const tableDataAdded = useSelector(
        (state: any) => state?.implementation?.table?.tableDataAdded
    );
    const [defaultValues, setDefaultValues] = useState({});
    useEffect(() => {
        if (rowId?.length) {
            tableDetails?.formId?.length > 0
                ? setOpenEditForm(true)
                : message.error('Form not assigned to the table');
        }
    }, [rowId]);

    useEffect(() => {
        tableValidated && setIsSaveModalOpen(false);
        dispatch({ type: RESET_VALIDATE_TABLE_STATUS });
    }, [tableValidated]);

    const [openModalForm, setOpenModalForm] = useState(false);
    const handleAdd = (): any => {
        if (tableDetails?.formId?.length > 0) {
            setOpenModalForm(true);
        } else {
            message.error('Form not assigned to the table');
        }
    };

    useEffect(() => {
        tableDataAdded && setOpenModalForm(false);
    }, [tableDataAdded]);
    const handleValidate = (): any => {
        const tempdataList = dataSource?.map((obj) => {
            const { key, error, ...rest } = obj;
            return rest;
        });
        dispatch(
            validateTable({
                rowDataList: tempdataList,
                assetId: selectedAsset?.key,
                requestedBy: details?.username,
            })
        );
    };
    const uploadLoader = useSelector(
        (state: any) => state.root.bulkUploadLoader
    );
    useEffect(() => {
        if (uploadLoader)
            message.open({
                type: 'loading',
                content: 'Uploading in progress ... ',
            });
    }, [uploadLoader]);
    const [selectedRowIds, setSelectedRowIds] = useState<any>([]);
    const [selectedOperator, setSelectedOperator] = useState('');
    const [selectedColumn, setSelectedColumn] = useState('');
    const [inputValue, setInputValue] = useState<any>(null);
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): any => {
        const value = e.target.value.trim();

        if (value.includes(',')) {
            // Handle comma-separated values
            const list = value
                .split(',') // Split by comma
                .map((item) => item.trim()) // Trim spaces
                .filter((item) => !isNaN(Number(item))) // Filter only valid numbers
                .map(Number); // Convert to numbers

            setInputValue(list.length > 0 ? list : value); // If valid numbers exist, take list; otherwise, take raw string
        } else if (!isNaN(Number(value)) && value !== '') {
            // Handle single number
            setInputValue(Number(value));
        } else {
            // Handle plain string
            setInputValue(value);
        }
    };
    const exportItems = [
        {
            label: (
                <label
                    onClick={() => {
                        dispatch(
                            exportCsv({
                                tableName: tableDetails?.name,
                            })
                        );
                    }}
                >
                    {t('commonStr.exportCSV')}
                </label>
            ),
            key: 'exportCSV',
            icon: <CSVIcon />,
        },
    ];
    const items = [
        {
            label: (
                <>
                    <div>
                        <input
                            style={{ display: 'none' }}
                            type="file"
                            onChange={(e: any) => {
                                handleChange(e, 'csv');
                            }}
                            id="file-upload-tag-csv"
                        />
                        <div
                            onClick={() => {
                                document
                                    .getElementById('file-upload-tag-csv')
                                    ?.click();
                            }}
                        >
                            Import CSV
                        </div>
                    </div>
                </>
            ),
            key: 'uploadCSV',
            icon: <CSVIcon />,
        },
    ];

    const handleChange = (event: any, fileType: string): void => {
        const formData = new FormData();
        formData.append('csvFile', event.target.files[0]);
        dispatch(
            importCsvData({ formData, fileType, tableName: tableDetails?.name })
        );
    };
    const handleDrawerState = (): any => {
        setRowId('');
        setOpenEditForm(false);
        setOpenModalForm(false);
    };

    const handleColumnChange = (value: any): any => {
        setSelectedColumn(value);
    };
    const handleOperatorChange = (value: any): any => {
        setSelectedOperator(value);
    };

    const handleButtonClick = (): any => {
        dispatch(
            getTableData({
                tableName: tableDetails?.name,
                page: page - 1,
                size: pageSize,
                filters: [
                    {
                        columnName: selectedColumn,
                        comparisonOperator: selectedOperator,
                        value: inputValue,
                    },
                ],
            })
        );
    };
    const handleResetClicked = (): any => {
        dispatch(
            getTableData({
                tableName: tableDetails?.name,
                page: page - 1,
                size: pageSize,
            })
        );
        setSelectedColumn('');
        setSelectedOperator('');
        setInputValue(null);
    };
    return (
        <>
            <div className="tableImplementation">
                <Row className="tableImplementation__header">
                    <Col
                        span={14}
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Select
                            style={{ width: 200 }}
                            placeholder="Select Column"
                            onChange={handleColumnChange}
                            allowClear
                            value={selectedColumn || null}
                        >
                            {tableDetails?.columnList?.map((column: any) => (
                                <Option key={column?.id} value={column?.name}>
                                    {column?.name}
                                </Option>
                            ))}
                        </Select>
                        <Select
                            style={{ width: 200, marginLeft: '10px' }}
                            placeholder="Select Operator"
                            onChange={handleOperatorChange}
                            allowClear
                            value={selectedOperator || null}
                        >
                            {comparisonOperators?.map((column: any) => (
                                <Option
                                    key={column?.label}
                                    value={column?.value}
                                >
                                    {column?.value}
                                </Option>
                            ))}
                        </Select>
                        <div className="inputValue">
                            <Input
                                placeholder={`Enter value`}
                                style={{ width: '200px', marginLeft: '10px' }}
                                onChange={handleInputChange}
                                allowClear
                                value={inputValue}
                            />
                        </div>
                        <span style={{ marginLeft: '10px' }}>
                            <CustomButton
                                type={BUTTONTYPE?.apply}
                                disabled={
                                    !selectedColumn ||
                                    !selectedOperator ||
                                    !inputValue
                                }
                                handleClick={() => {
                                    handleButtonClick();
                                }}
                            />
                        </span>
                        <span style={{ marginLeft: '10px' }}>
                            <CustomButton
                                type={BUTTONTYPE.reset}
                                disabled={false}
                                handleClick={() => {
                                    handleResetClicked();
                                }}
                            />
                        </span>
                    </Col>

                    <Col span={8} className="newRowBtn">
                        <Dropdown
                            overlayClassName="export"
                            overlay={
                                <Menu>
                                    {exportItems.map((item) => (
                                        <Menu.Item key={item?.key}>
                                            {item?.label}
                                        </Menu.Item>
                                    ))}
                                </Menu>
                            }
                        >
                            <Button>
                                {t('commonStr.export')}
                                <DownOutlined />
                            </Button>
                        </Dropdown>
                        <Dropdown
                            menu={{ items }}
                            overlayClassName="bluk__upload"
                        >
                            <Button>
                                {t('commonStr.bulkUpload')}
                                <DownOutlined />
                            </Button>
                        </Dropdown>
                        <Button
                            className="newRowBtn__btn"
                            type="primary"
                            ghost
                            onClick={() => {
                                handleAdd();
                            }}
                            icon={<PlusOutlined />}
                        >
                            New Row
                        </Button>
                    </Col>
                </Row>
                <Divider />
                {tableListLoading ? (
                    <div className="view__loader">
                        <Spin />
                    </div>
                ) : (
                    <div className="tableImplementationTable">
                        <DynamicTable
                            columns={tableDetails?.columnList}
                            dataSource={tableDetails?.columnList}
                            setDataSource={setDataSource}
                            setRowId={setRowId}
                            setDefaultValues={setDefaultValues}
                            selectedRowIds={selectedRowIds}
                            setSelectedRowIds={setSelectedRowIds}
                            setSortColumn={setSortColumn}
                            setSortOrder={setSortOrder}
                            setPage={setPage}
                        />
                    </div>
                )}

                <div>
                    {tableData?.totalElements > PAGE_SIZE && (
                        <CustomPagination
                            totalRecords={tableData?.totalElements}
                            setPage={setPage}
                            page={page}
                            setPageSize={setPageSize}
                            pageSize={pageSize}
                            defaultPageSize={pageSize}
                            isPositionFixed={false}
                            customClassName='customPagination'
                        />
                    )}
                </div>
            </div>
            {isSaveModalOpen && (
                <ConfirmationModal
                    customClassName="confirmationModal tableImplementationModal"
                    icon={<QuestionMarkIcon />}
                    open={isSaveModalOpen}
                    onOk={() => {
                        handleValidate();
                    }}
                    onCancel={() => {
                        setIsSaveModalOpen(false);
                    }}
                    text={t('implementation.table.validateTableModalText')}
                />
            )}
            {(openModalForm || openEditForm) && (
                <AddRowForm
                    open={openModalForm}
                    setOpenModalForm={setOpenModalForm}
                    openEditForm={openEditForm}
                    setOpenEditForm={setOpenEditForm}
                    rowId={rowId}
                    defaultValues={defaultValues}
                    setRowId={setRowId}
                    onClose={handleDrawerState}
                />
            )}
        </>
    );
};
export default TableData;
