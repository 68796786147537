import { useDispatch, useSelector } from 'react-redux';
import { deleteAllTableData } from 'redux/actions/ConfigureActions/tableAction';
import { ReactComponent as DeleteIcon } from 'assets/icons/DeleteIconTable.svg';

const MultipleDeletePopover: React.FC<any> = ({onItemClick}) => {
    const tableDetails = useSelector(
        (state: any) => state.configure?.table?.tableDetails
    );

    const dispatch = useDispatch();
    const values = [
        {
            title: 'Delete',
            icon: <DeleteIcon width={14} height={14} />,
            disable: false,
        },
    ];

    return (
        <>
            <div className="more-container">
                <div className={`more-content`}>
                    <ul>
                        {values &&
                            values.length > 0 &&
                            values.map((item) => (
                                <li
                                    key={item.title}
                                    className="moreContent__items"
                                >
                                    <span
                                        className="moreContent__option"
                                        onClick={() => {
                                            onItemClick();
                                            dispatch(
                                                deleteAllTableData({
                                                    tableName:
                                                        tableDetails?.name,
                                                })
                                            );
                                        }}
                                    >
                                        <span className="moreContentIcon">
                                            {item.icon}
                                        </span>
                                        {item.title}
                                    </span>
                                </li>
                            ))}
                    </ul>
                </div>
            </div>
        </>
    );
};

export default MultipleDeletePopover;
